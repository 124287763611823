

import { ENVIRONMENT } from '../constants/constants'
import jsPDF from 'jspdf';
import he from 'he';

export const getEnvironment = () => {
    const host = window.location.host;
    let envoirment = '';
    if (host.indexOf('dairydev.addieinsights.com') > -1) {
        envoirment = ENVIRONMENT.DEV;
    } else if (host.indexOf('dairystage.addieinsights.com') > -1) {
        envoirment = ENVIRONMENT.QA;
    } else if (host.indexOf('addieinsights.com') > -1) {
        envoirment = ENVIRONMENT.PROD;
    } else {
        envoirment = ENVIRONMENT.LOCAL;
    }
    return envoirment;
}

export function generatePDF(allBreeds, tableName) {
    const doc = new jsPDF();
    var col = ["Breed", "Attention Flag", "Critical Threshold", "Goal Order", "Goal Section", "Goal Value", "Initial Threshold", "Name", "Active"];
    var rows = [];
    allBreeds.forEach((breed) => {
        breed.Goals.forEach((goals) => {
            rows.push([breed.Breed,
            goals.Attention_Flag,
            goals.Critical_Threshold.toString(),
            goals.Goal_Order.toString(),
            goals.Goal_Section,
            goals.Goal_Value.toString(),
            goals.Initial_Threshold.toString(),
            goals.Name,
            goals.active.toString()
            ])
        })
    })
    var header = function (data) {
        // doc.setFontSize(18);
        // doc.setTextColor(40);
        // doc.setFontStyle('normal');
        // doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
        doc.text("Testing Report \n one ", data.settings.margin.left, 10);
    };
    var header2 = function (data) {
        // doc.setFontSize(18);
        // doc.setTextColor(40);
        // doc.setFontStyle('normal');
        // doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
        doc.text("second header ", data.settings.margin.left, 10);
    };
    doc.autoTable(col, rows, { margin: { top: 30 }, didDrawPage: header });
    doc.autoTable(col, rows, { didDrawPage: header2, margin: { top: 30 } });

    doc.save(tableName + '.pdf')
}

export function sortData(list, value) {
    list.sort((a, b) => {
        if (a[value] < b[value]) {
            return -1;
        }
        if (a[value] > b[value]) {
            return 1;
        }
        return 0;
    });
}

export function caseInsensitiveSortData(list, value) {
    list.sort((a, b) => {
        if (a[value].toLowerCase() < b[value].toLowerCase()) {
            return -1;
        }
        if (a[value].toLowerCase() > b[value].toLowerCase()) {
            return 1;
        }
        return 0;
    });
}

export function convertDataToFormData(dataObj) {
    const dataObjArr = Object.keys(dataObj);
    if (dataObjArr.length) {
        const formData = new FormData();
        for (const property in dataObj) {
            formData.append(property, dataObj[property]);
        }
        return formData;
    }
}

export function checkFileType(fileName) {
    if (fileName) {
        const fileExtension = fileName.split('.').pop();
        return fileExtension;
    }
}

/**
     * Method will handle error.
     * @param {*} error error received from API.
     */
export function handleError(error) {
    if (error && error.response) {
        const errorCode = error.response.status;
        const errorStatus = error.response.statusText;
        if (errorCode && errorStatus) {
            return 'Error Code: ' + errorCode + ' ' + errorStatus;
        }
        return error;
    }
    return 'Something went wrong!!.'
}

export function getColumnNames(response) {
    const colDef = response.colDef;
    sortData(colDef, 'col_seq');
    console.log(colDef);
    let allCols = [];
    colDef.forEach(element => {
        allCols.push(element.col_header);
    });
    allCols.unshift("");
    return allCols;
}

export const getReportFormatArray = (modefiedBenchmarkResponse) => {
    const responseArr = [];
    (modefiedBenchmarkResponse.Sections).forEach(item => {
        const obj = {};
        obj['rawData'] = item;
        obj['Section_Seq'] = item.Section_Seq;
        obj['Section_Header'] = item.Section_Header;
        responseArr.push(obj);
    });
    sortData(responseArr, 'Section_Seq');
    return responseArr;
}

export const updateObject = (oldObject,updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

// function to recursively decode all string values in the API response
export const decodeApiResponse = (data) => {
    if (typeof data === 'string') {
        // decode if its a string
        return he.decode(data);
    } else if (Array.isArray(data)) {
        // if its an array, decode each element recursively
        return data.map((item) => decodeApiResponse(item));
    } else if (typeof data === 'object' && data !== null) {
        // if its an object, decode each value recursively
        const decodedObject = {};
        Object.keys(data).forEach((key) => {
            decodedObject[key] = decodeApiResponse(data[key]);
        });
        return decodedObject;
    } else {
        // return as is for the others
        return data;
    }
};