import {
	ADMIN_CHECK
} from '../actions/actions';

import {checkadmin as adminapi} from '../../common/services/herdAccess.service';


const adminchecksuccess= (data) => {
	return {
		type: ADMIN_CHECK,
		payload:data
	};
};

const checkadmin = () => {
	return (dispatch) => {
		return adminapi().then((response)=>{
				dispatch(adminchecksuccess(response));
			},
			(err) => {
				dispatch(adminchecksuccess("non_user"));
			}
		);
	};
};

export {
	checkadmin
};
