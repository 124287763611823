import React from "react";
import { useHistory } from "react-router-dom";
import { 
    Container,
    Breadcrumbs,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    Switch,
    TextareaAutosize,
    Typography,
 } from "@material-ui/core";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import { sendActionToAdobeAnalytics } from '../../../common/analytics/adobeanalytics/adobeanalytics';
// service and constant import
import { SNACKBAR_TYPE } from '../../../common/constants/constants';
import OneButtonDialog from "../../../common/dialogs/one-button-dialog/one-button-dialog";
// Backend Api import
import { getHerdDetailsFromHerdid, saveHerd } from '../../../common/services/herd.service';
import { handleError, decodeApiResponse } from '../../../common/utils/utility';
import PageHeader from '../../../components/pageHeader/pageHeader';
// userdefined component import
import ModalFlyout from "../../../components/ui_utilities/modalFlyout";
import PeerGroup from './PeerGroup/PeerGroup';
import Reporting from './Reporting/Reporting';
import SubherdDetail from './SubherdDetail/SubherdDetail';
import SubherdGoals from './SubherdGoals';
import AddSubherd from "./AddSubherd";

const Subherd = (props) => {
    const history = useHistory();
    const params = new URLSearchParams(props.location.search);
    
    const [activetab, setactivetab] = React.useState("reporting");
    const [activeflag, setactiveflag] = React.useState(true);
    const [addsubherd, setAddsubherd] = React.useState({ active: true, breeds: [], description: '', use_avg_daily_milk: false, use_ecm_peer_avg: false });
    const [addSubherdModal, setAddSubherdModal] = React.useState(false);
    const [editSubherdModal, setEditSubherdModal] = React.useState(false)
    const [subherdDetails, setsubherdDetails] = React.useState({ breeds: [], description: '' });
    const [isdefaultsubherd, setisdefaultsubherd] = React.useState(false);
    const breeds = [{ name: "Crossbred" }, { name: "Holstein" }, { name: "Jersey" }, { name: "Other" }];
    const [selectedIndex, setSelectedIndex] = React.useState();
    const [iserror, seterrordialog] = React.useState(false);
    const [errormessage, seterrormessage] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();

    const [herddata, setherddata] = React.useState(null);
    const [subherddata, setsubherddata] = React.useState(null);

    function handleClick(event, page) {
        event.preventDefault();
        if (page === "herds") {
            history.push("/dashboard/herds/detail?herd_id=" + herddata?.herd_id)
        }

        if (page === "herds_persist") {
            history.push("/dashboard/herds?t=p")
        }
    }

    function fetchHerdData() {
        getHerdDetailsFromHerdid(params.get("herd_id")).then((response) => {
            const decodedResponse = decodeApiResponse(response)
            let subherd = { default: false }
            decodedResponse.sub_herds.forEach((x) => {
                if (x.sub_herd_id === params.get("subherd_id")) {
                    setSelectedIndex(decodedResponse.sub_herds.indexOf(x));
                    subherd = x;
                }
            })
            if (subherd.default) {
                setisdefaultsubherd(true);
            }

            if (!(decodedResponse?.AccessDetails?.access)) {
                decodedResponse.AccessDetails = {
                    ...decodedResponse.AccessDetails?.AccessDetails
                }
            }
            setsubherdDetails({ ...subherd, breeds: subherd.breeds });
            setherddata(decodedResponse);
            setsubherddata(subherd);
        }).catch((err) => {
            history.push("/dashboard/herds");
        })
    }

    React.useEffect(() => {
        if(params.get("software") === "DC305") {
            setactivetab("subherd")
        } else {
            setactivetab("reporting")
        }
    }, [params.get("software")])

    React.useEffect(() => {
        getHerdDetailsFromHerdid(params.get("herd_id")).then((response) => {
            const decodedResponse = decodeApiResponse(response)
            let subherd = { default: false }
            decodedResponse.sub_herds.forEach((x) => {
                if (x.sub_herd_id === params.get("subherd_id")) {
                    setSelectedIndex(decodedResponse.sub_herds.indexOf(x));
                    subherd = x;
                }
            })
            if (subherd.default) {
                setisdefaultsubherd(true);
            }

            if (!(decodedResponse?.AccessDetails?.access)) {
                decodedResponse.AccessDetails = {
                    ...decodedResponse.AccessDetails?.AccessDetails
                }
            }
            setsubherdDetails({ ...subherd, breeds: subherd.breeds });
            setherddata(decodedResponse);
            setsubherddata(subherd);
        }).catch((err) => {
            history.push("/dashboard/herds");
        })
    }, [editSubherdModal])

    React.useEffect(() => {
        setAddsubherd(({ active: true, breeds: [], description: '', use_avg_daily_milk: herddata?.parlor_daily_milk_weights, use_ecm_peer_avg: herddata?.use_ecm_peer_avg }));
        setactiveflag(true);
    }, [addSubherdModal])

    const herdsourcetype = herddata ? herddata.software_herd_software : "";
    const weeklymilkflag = herddata ? (herddata.parlor_daily_milk_weights ? herddata.parlor_daily_milk_weights : false) : false;

    const getbutton = (type) => {
        switch (type) {
            case "saveaddbutton":
                return <React.Fragment>
                    <Button
                        variant="outlined"
                        className="button"
                        type="button"
                        size="small"
                        startIcon={<SaveOutlinedIcon />}
                        onClick={saveAdd}>
                        Save
                    </Button>
                </React.Fragment>
            case "updatebutton":
                return <React.Fragment>
                    <Button
                        variant="outlined"
                        className="button"
                        type="button"
                        size="small"
                        startIcon={<SaveOutlinedIcon />}
                        onClick={savedit}>
                        Save Edits
                    </Button>
                </React.Fragment>
            case "canceladdbutton":
                return <React.Fragment>
                    <Button
                        variant="outlined"
                        className="button grey"
                        type="button"
                        size="small"
                        startIcon={<CancelOutlinedIcon />}
                        onClick={() => { setAddSubherdModal(false); }}>
                        Cancel
                            </Button>
                </React.Fragment>
            case "canceleditbutton":
                return <React.Fragment>
                    <Button
                        variant="outlined"
                        className="button grey"
                        type="button"
                        size="small"
                        startIcon={<CancelOutlinedIcon />}
                        onClick={() => { setEditSubherdModal(false); }}>
                        Cancel Edits
                            </Button>
                </React.Fragment>
            default: return null;

        }

    }

    const saveAdd = () => {
        if (addsubherd.description === "") {
            seterrormessage("Please fill the description");
            seterrordialog(true);
        }
        else if (addsubherd.breeds.length === 0) {
            seterrormessage("Please select a breed.");
            seterrordialog(true);
        }
        else {
            saveAddSubherd()
        }
    }

    const saveAddSubherd = () => {
        const existingSubherd = herddata.sub_herds.find(sub => sub.description.toLowerCase() === addsubherd.description.toLowerCase());
        if (addsubherd.description === "") {
            seterrormessage("Please fill the description");
            seterrordialog(true);
        }
        else if (addsubherd.breeds.length === 0) {
            seterrormessage("Please select a breed.");
            seterrordialog(true);
        }
        else if (existingSubherd)  {
            seterrormessage("Sub-Herd description already exists. \n Please choose another description to continue.");
            seterrordialog(true);
        } 
        else {
            var a = _.cloneDeep(herddata)
            a.sub_herds[a.sub_herds.length] = addsubherd;
            enqueueSnackbar('Added subherd data, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
            saveHerd(a).then(response => {
                setAddSubherdModal(false)
                /** comment not remove for backup purpose **/
                // setherddata(a);
                // setsubherddata(addsubherd);
                enqueueSnackbar("Subherd Added Successfully!", { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                fetchHerdData();
            }, error => {
                setAddSubherdModal(false)
                enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            });
        }
    }

    const savedit = () => {
        if (subherdDetails.description === "") {
            seterrormessage("Please fill the description");
            seterrordialog(true);
        }
        else if (subherdDetails.breeds.length === 0) {
            seterrormessage("Please select a breed.");
            seterrordialog(true);
        }
        else if (subherdDetails.description.toLowerCase() !== herddata.sub_herds[selectedIndex].description.toLowerCase()) {
            // If the description has changed, check for existence
            const existingSubherd = herddata.sub_herds.find(sub => sub.description.toLowerCase() === subherdDetails.description.toLowerCase());
            if (existingSubherd) {
                seterrormessage("Sub-Herd description already exists. \n Please choose another description to continue.");
                seterrordialog(true);
            }
            else {
                // Description changed and not found, proceed with the update
                const a = _.cloneDeep(herddata);
                a.sub_herds[selectedIndex] = subherdDetails;
                enqueueSnackbar('Edited subherd data, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
                saveHerd(a).then(response => {
                    setEditSubherdModal(false);
                    setherddata(a);
                    setsubherddata(subherdDetails);
                    enqueueSnackbar("Subherd Edited Successfully!", { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                }, error => {
                    setEditSubherdModal(false);
                    enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                });
            }
        }
        else {
            // Description has not changed, proceed with the update
            const a = _.cloneDeep(herddata);
            a.sub_herds[selectedIndex] = subherdDetails;
            enqueueSnackbar('Edited subherd data, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
            saveHerd(a).then(response => {
                setEditSubherdModal(false);
                setherddata(a);
                setsubherddata(subherdDetails);
                enqueueSnackbar("Subherd Edited Successfully!", { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
            }, error => {
                setEditSubherdModal(false);
                enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            });
        }
    }

    const editsubherdform = () => {
        return <React.Fragment>
            {
                isdefaultsubherd ? null : <><Grid item xs={12}>
                    <h1 className="appbar-headline text-uppercase">Edit A Sub Herd</h1>
                </Grid>
                </>
            }
            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    disabled={isdefaultsubherd}
                    control={<Switch
                        checked={subherdDetails.active}
                        className="text-bold"
                        onChange={() => {
                            setsubherdDetails({ ...subherdDetails, active: !subherdDetails.active })
                        }}
                    ></Switch>}
                    label={subherdDetails.active ? 'This Sub-Herd is Active' : 'This Sub-Herd is Inactive'}

                />
            </Grid>

            <Grid item xs={12}>
                <TextareaAutosize rowsMin={3} aria-label="empty textarea" placeholder="Enter a Description" className="textarea"
                    disabled={isdefaultsubherd}
                    value={subherdDetails.description}
                    onChange={(event) => {
                        setsubherdDetails({ ...subherdDetails, description: event.target.value })
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    disabled={herdsourcetype === "PCDART" || weeklymilkflag === false}
                    control={<Switch
                        checked={subherdDetails.use_avg_daily_milk}
                        className="text-bold"
                        onChange={() => {
                            setsubherdDetails({ ...subherdDetails, use_avg_daily_milk: !subherdDetails.use_avg_daily_milk })
                        }}
                    ></Switch>}
                    label="Use average daily milk for production calculations"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    control={<Switch
                        checked={subherdDetails.use_ecm_peer_avg}
                        className="text-bold"use_ecm_peer_avg
                        onChange={() => {
                            setsubherdDetails({ ...subherdDetails, use_ecm_peer_avg: !subherdDetails.use_ecm_peer_avg })
                        }}
                        name="checkedECM"
                    ></Switch>}
                    label="Use ECM for peer average calculations"
                />
            </Grid>

            <Grid item xs={12}>
                <h3>Breeds</h3>
                <p>
                    Breed selection will determine both default sub-herd goals and peer group filters. You will be able to customize goals by breed or for each sub-herd. Herds with any combination of breeds including Holstein or Crossbred will be assigned to Holstein-based peer groups. Herds with only Jersey and/or Other Purebred will be assigned to Jersey-based peer groups
                </p>
            </Grid>
            {
                breeds.map((data, index) => {
                    return <Grid item xs={12} key={index}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={herddata.software_herd_software === "DRMS" ? false : isdefaultsubherd}
                                    onChange={(event) => {
                                        let tempbreed = subherdDetails.breeds;
                                        if (tempbreed.includes(data.name)) {
                                            if (!event.target.checked) {
                                                tempbreed = tempbreed.filter((tempbreed) => {
                                                    return data.name !== tempbreed;
                                                })
                                            }
                                        } else {
                                            if (event.target.checked) {
                                                tempbreed = [...tempbreed, data.name]
                                            }
                                        }
                                        // setselectedbreeds(tempbreed);
                                        setsubherdDetails({ ...subherdDetails, breeds: tempbreed })
                                    }}
                                    name={data.name}
                                    color="primary"
                                    checked={subherdDetails.breeds.includes(data.name)}
                                />
                            }
                            label={data.name === "Other" ? "Other Purebred" : data.name}
                        />
                    </Grid>
                })
            }
        </React.Fragment>
    }

    const changetab = (tab) => {
        // Collecting the tab click in adobe analytics
        let body = {
            tabName: tab
        };
        sendActionToAdobeAnalytics("tab", body)
        setactivetab(tab);
    }

    return (
        <React.Fragment>
            <PageHeader h1={herddata?.dairy_name} editbutton={true} herddata={herddata} subherddata={subherddata} subherdDetails={subherdDetails} setisdefaultsubherd={setisdefaultsubherd} setsubherddata={setsubherddata} setsubherdDetails={setsubherdDetails} setSelectedIndex={setSelectedIndex} 
                handler={(type) => {
                    if (type === "add") {
                        setAddSubherdModal(true)
                    } else if (type === "edit") {
                        setEditSubherdModal(true);
                    } else {
                        // upload data
                        history.push("/dashboard/uploadData?herd_id=" + herddata.herd_id + "&subherd_id=" + subherddata.sub_herd_id)
                    }
                }} 
                subherd={true} 
            />
            {/* START: Breadcrumbs */}
            <Container maxWidth={false} className="container-align-left breadcrumb-wrapper">
                <Breadcrumbs separator="›" aria-label="breadcrumb" className="breadcrumb-container">
                    <Link color="inherit" href="#" onClick={(event) => {
                        handleClick(event, "herds_persist");
                    }}>
                        <Typography color="inherit">Herds</Typography>
                    </Link>
                    <Link color="inherit" href="#" onClick={(event) => {
                        handleClick(event, "herds");
                    }}>
                        <Typography color="inherit">{herddata?.dairy_name}</Typography>
                    </Link>

                    <Link color="inherit" href="#" onClick={(event) => {
                        handleClick(event, "");
                    }}>
                        <Typography color="inherit">{subherddata?.description}</Typography>
                    </Link>
                </Breadcrumbs>
            </Container>
            {/* END: Breadcrumbs */}
            {/* START: Tabs */}
            {/* TODO: Convert Tabs to MUI Tabs */}
            <Container maxWidth={false} className="container-align-left page-tabs-wrapper">
                <div className="page-tabs">
                    <div className={activetab === "subherd" ? "tab-item active" : "tab-item"}
                        onClick={() => {
                            changetab("subherd")
                        }}
                    >
                        Sub-Herd Details
                    </div>

                    <div className={activetab === "goals" ? "tab-item active" : "tab-item"}
                        onClick={() => {
                            changetab("goals")
                        }}>
                        Goals
                    </div>

                    <div className={activetab === "reporting" ? "tab-item active" : "tab-item"}
                        onClick={() => {
                            changetab("reporting")
                        }}>
                        Reporting
                    </div>
                </div>
            </Container>
            {/* END: Tabs */}

            <Container maxWidth={false} className="container-align-left">
                {/* Section3 selected tab content */}
                {/* {activetab==="subherd"?<SubherdDetail/>:null} */}

                {activetab === "subherd" && (subherddata !== null && herddata !== null) ? <SubherdDetail edited={editSubherdModal} herddata={herddata} subherddata={subherddata} /> : null}
                {activetab === "peer" ? <PeerGroup /> : null}
                {activetab === "goals" && (subherddata !== null && herddata !== null) ? <SubherdGoals herddata={herddata} subherddata={subherddata} /> : null}
                {activetab === "reporting" && (subherddata !== null && herddata !== null) ? <Reporting herddata={herddata} subherddata={subherddata} /> : null}
            </Container>
            {addSubherdModal ? <ModalFlyout state={addSubherdModal} setstate={setAddSubherdModal}>
                <AddSubherd 
                    addsubherd={addsubherd} 
                    setAddsubherd={setAddsubherd} 
                    activeflag={activeflag}
                    herddata={herddata}
                    breeds={breeds}
                />
                <Grid container spacing={2} className="button-submit-container">
                    <Grid item className="button-submit-container-left" xs={12} sm={6}>
                        {getbutton("saveaddbutton")}
                    </Grid>
                    <Grid item className="button-submit-container-right" xs={12} sm={6}>
                        {getbutton("canceladdbutton")}
                    </Grid>
                </Grid>
            </ModalFlyout> : null}
            {editSubherdModal ? <ModalFlyout state={editSubherdModal} setstate={setEditSubherdModal}>
                {editsubherdform()}
                {
                    <React.Fragment>
                        <Grid container spacing={2} className="button-submit-container">
                            <Grid item className="button-submit-container-left" xs={12} sm={6}>
                                {getbutton("updatebutton")}
                            </Grid>
                            <Grid item className="button-submit-container-right" xs={12} sm={6}>
                                {getbutton("canceleditbutton")}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </ModalFlyout> : null}
            {iserror ? <OneButtonDialog
                content={<div dangerouslySetInnerHTML={{ __html: errormessage.replace(/\n/g, '<br/>') }} />}
                open={iserror}
                handleClose={() => {
                    seterrordialog(false);
                }}
                btnText1="OK"
                handleActionBtn1={() => {
                    seterrordialog(false);
                }}
            /> : null
            }

        </React.Fragment>
    )
};

export default Subherd;

