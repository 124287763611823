import { ApiUrl } from '../../../common/configs/path.config';
import NetworkService from '../../../common/services/networkService';

/**
 * Method will call default goals data.
 */
export async function getReportResponse(requestedData, reportType) {
    let reportResponse = await NetworkService.getData(ApiUrl.getReportUrl(requestedData, reportType));
    return reportResponse;
}

// Save Subherd and My Favorite Report Layout
export async function addReportLayout(requestbody) {
    let endpoint = ApiUrl.saveReportLayout();
    let message = await NetworkService.postData(endpoint, requestbody);
    return message;
}

// Delete My Favorite Report Layout
export async function deleteMyFavoriteReportLayout(requestbody) {
    let endpoint = ApiUrl.deleteMyFavoriteReportLayout();
    let message = await NetworkService.postData(endpoint, requestbody);
    return message;
}

// Get Subherd and My Favorite Report Layout
export async function getReportLayout(type, subherdid) {
    let endpoint = null;
    if (type === "subherd") {
        endpoint = ApiUrl.getReportLayoutSubherd(subherdid);
    } else {
        endpoint = ApiUrl.getMyFavoriteReportLAyouts();
    }
    let reportlayout = await NetworkService.getData(endpoint);
    return reportlayout;
}