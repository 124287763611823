import React , {useEffect}from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = (props) => {
    
    const [value, setValue] = React.useState(true);
    useEffect(() => {
        if (props.type === 'small') {
            setValue(true);
        }
        else {
            setValue(false);
        }
    }, [props]);
    
    return (
        <div className={value ? 'input-loader' : 'loader-lg'}>
            <CircularProgress size={props.radius}/>
        </div>
    );
};

export default Loader;
