import React from "react";
import { useHistory } from "react-router-dom";
import Loader from '../../common/dialogs/loader/loader';
import { getHerds } from '../../common/services/goals.service';
import { getdplanherduploadstatus, saveHerd as SaveEditHerd } from '../../common/services/herd.service';
import EnrollmentForm from '../EnrollmentForm/EnrollmentForm';
import { decodeApiResponse } from "../../common/utils/utility";

const EditHerd = (props) => {

    const [formdata, setformdata] = React.useState({});
    const [dplanuploadstatus, setdplanuploadstatus] = React.useState(false);
    const [loaderstatus, setloaderstatus] = React.useState(false);
    const [herdedited, setherdedited] = React.useState(0);
    const history = useHistory();

    React.useEffect(() => {
        let data = props.herddata;

        if (data === null) {
            history.push('/dashboard/herds');
        } else {
            const seteditdata = (dplanflag) => {
                if (data.sub_herds.length > 1) {
                    data["software_split_report"] = "Yes"
                    let breeds = [];
                    data.sub_herds.forEach(element => {
                        breeds.push(...element.breeds);
                    });
                    breeds = breeds.map(function (x) { return x.replace(/Other(?!\sPurebred)/g, 'Other Purebred'); });
                    data.software_breeds = [...new Set([...data.software_breeds, ...breeds])];
                }

                if (data.dairy_mobile_number && data.dairy_mobile_number.length === undefined) {
                    let temp = data.dairy_mobile_number.toString();
                    temp = temp.substring(0, 10);
                    temp = "(" + temp.substring(0, 3) + ")-" + temp.substring(3, 6) + "-" + temp.substring(6);
                    data.dairy_mobile_number = temp;
                } else {
                    if (data.dairy_mobile_number === undefined)
                        data.dairy_mobile_number = "";
                }

                if (data.dairy_phone_number && data.dairy_phone_number.length === undefined) {
                    let temp = data.dairy_phone_number.toString();
                    temp = temp.substring(0, 10);
                    temp = "(" + temp.substring(0, 3) + ")-" + temp.substring(3, 6) + "-" + temp.substring(6);
                    data.dairy_phone_number = temp;
                } else {
                    if (data.dairy_phone_number === undefined)
                        data.dairy_phone_number = "";
                }

                data.milking_facility_type = data.milking_facility_type.filter((d) => {
                    return ["Rotary", "Robot", "Parallel", "Herringbone", "Parabone", "Polygon", "Side Open", "Flat Barn", "Tie Stall", "Other"].indexOf(d) >= 0 ? true : false
                })


                data.facilties_and_grouping_far_off_dry_form = data.facilties_and_grouping_far_off_dry_form ? data.facilties_and_grouping_far_off_dry_form : {};

                data.facilties_and_grouping_close_up_dry_form = data.facilties_and_grouping_close_up_dry_form ? data.facilties_and_grouping_close_up_dry_form : {};

                data.facilties_and_grouping_single_group_for_dry_cows_form = data.facilties_and_grouping_single_group_for_dry_cows_form ? data.facilties_and_grouping_single_group_for_dry_cows_form : {};

                data.facilties_and_grouping_post_fresh_lactating_cows_form = data.facilties_and_grouping_post_fresh_lactating_cows_form ? data.facilties_and_grouping_post_fresh_lactating_cows_form : {};

                data.facilties_and_grouping_lactating_rations_form = data.facilties_and_grouping_lactating_rations_form ? data.facilties_and_grouping_lactating_rations_form : {};

                data.supplemental_producer_works_with_purina_specialist = data.supplemental_producer_works_with_purina_specialist ? "Yes" : "No";

                data.software_split_report = data.software_split_report ? "Yes" : "No";

                data.dhia_daily_milk_weights_recorded = data.dhia_daily_milk_weights_recorded ? "Yes" : "No";

                data.dhia_dhi_or_other_service_used = data.dhia_dhi_or_other_service_used ? "Yes" : "No";

                data.milking_cows_fed_in_parlor = data.milking_cows_fed_in_parlor ? "Yes" : "No";

                data.reproductive_record_of_sires = data.reproductive_record_of_sires ? "Yes" : "No";

                data.reproductive_record_breed_dates = data.reproductive_record_breed_dates ? "Yes" : "No";

                data.reproductive_cows_designated_do_not_breed = data.reproductive_cows_designated_do_not_breed ? "Yes" : "No";

                data.reproductive_some_bull_breeding = data.reproductive_some_bull_breeding ? "Yes" : "No";

                data.reproductive_record_non_breed_heat_dates = data.reproductive_record_non_breed_heat_dates ? "Yes" : "No";

                data.reproductive_calving_results_recorded_with_details = data.reproductive_calving_results_recorded_with_details ? "Yes" : "No";

                data.reproductive_calving_difficulty_scores_recorded = data.reproductive_calving_difficulty_scores_recorded ? "Yes" : "No";

                data.reproductive_replacement_records_same_Software = data.reproductive_replacement_records_same_Software ? "Yes" : "No";

                data.reproductive_reuse_lactating_cow_ids_within_1_year = data.reproductive_reuse_lactating_cow_ids_within_1_year ? "Yes" : "No";

                data.supplemental_producer_uses_full_anion_supplementation = data.supplemental_producer_uses_full_anion_supplementation ? "Yes" : "No";

                data.supplemental_producer_uses_feed_additives = data.supplemental_producer_uses_feed_additives ? "Yes" : "No";
                data.parlor_daily_milk_weights = data.parlor_daily_milk_weights ? data.parlor_daily_milk_weights : false;
                setformdata(data);
                setloaderstatus(false);
                setdplanuploadstatus(dplanflag);
            }
            if (data.software_herd_software === "DPLAN") {
                getdplanherduploadstatus(data.herd_id).then(response => {
                    const decodedResponse = decodeApiResponse(response);
                    seteditdata(decodedResponse.data.has_testdays);
                }).catch((err) => {
                    history.push('/dashboard/herds');
                })
            } else {
                seteditdata(false);
            }
        }
    }, [history, herdedited])

    const editherd = (values) => {
        setloaderstatus(true);
        delete values?.selectedHerd;
        SaveEditHerd(values).then((response) => {
            getHerds().then((herdresponse) => {
                const decodedResponse = decodeApiResponse(herdresponse);
                let a = decodedResponse.filter((d, i, arr) => {
                    return d.herd_id === values.herd_id
                })[0];
                a["AccessDetails"] = a.AccessDetails ? a.AccessDetails : a["AccessDetails"]["AccessDetails"];
                props.setherddata(a);
                localStorage.setItem("save_edit", "no");
                setherdedited(herdedited + 1);
            });
        }).catch((err) => {
            console.log("Error :: " + err);
            setloaderstatus(false);
        })
    }

    return (
        <React.Fragment>
            {
                Object.keys(formdata).length === 0 && formdata.constructor === Object ? <div></div> : loaderstatus ? <Loader radius={50} type="large" /> : <EnrollmentForm herddata={props.herddata} addeditherd={editherd} formdata={formdata} heading="Editing a Herd" herds={formdata} type="edit" dplanuploadstatus={dplanuploadstatus} />
            }

        </React.Fragment>
    );
};

export default EditHerd;
