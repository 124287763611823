import React from "react";
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';

const ModalFlyout = (props) => {      
    let modalstyle;
    if(props.width){
        modalstyle="wide"
    }

  
    return (
        <Drawer 
            open={props.state}
            onClose={()=>{
                if(props.setswitchoff){
                props.setswitchoff(false);
                }
                props.setstate(false);
            }}
            className={"modal-flyout-wrapper " + modalstyle}
            anchor="right"                        
        >
            <div className="modal-flyout-contents" >                
                <IconButton 
                        aria-label="close"  
                        className="close"
                        onClick={() => {
                        props.setstate(false);
                        
                        if(props.setswitchoff){
                            props.setswitchoff(false);
                            let temp={...props.formdata};
                            temp[props.id]=false;
                            props.setformdata(temp);
                        }
                    }}>
                    <CloseIcon />
                </IconButton>
                {props.children}
            </div>
            
                  
        </Drawer>
        
    );
};

export default ModalFlyout;

