import React from "react";
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Modal from '@material-ui/core/Modal';
import TwoButtonDialog from "../../../common/dialogs/two-button-dialog/two-button-dialog";
import Button from '@material-ui/core/Button';


import AdultFacilityForm from './FacilityForm/AdultFacilityForm';
// import YoungFacilityForm from './FacilityForm/YoungFacilityForm';



const Facilities_and_Grouping = (props) => {
    const [faroffdry,setfaroffdry]=React.useState(props.formdata["facilties_and_grouping_far_off_dry"]);

    const [closeupdry,setcloseupdry]=React.useState(props.formdata["facilties_and_grouping_close_up_dry"]);

    const [single_group_dry_cows,setsingle_group_dry_cows]=React.useState(props.formdata["facilties_and_grouping_single_group_for_dry_cows"]);

    const [postfreshlactating,setpostfreshlactating]=React.useState(props.formdata["facilties_and_grouping_post_fresh_lactating_cows"]);
    
    const [lactating,setlactating]=React.useState(props.formdata["facilties_and_grouping_lactating_rations"]);

    // left dialog  Add close state

    const [faroffdrydialog,setfaroffdrydialog]=React.useState(false);

    const [closeupdrydialog,setcloseupdrydialog]=React.useState(false);

    const [single_group_dry_cowsdialog,setsingle_group_dry_cowsdialog]=React.useState(false);

    const [postfreshlactatingdialog,setpostfreshlactatingdialog]=React.useState(false);
    
    const [lactatingdialog,setlactatingdialog]=React.useState(false);

       // left dialog  View Edit close state

       const [faroffdryeditdialog,setfaroffdryeditdialog]=React.useState(false);

       const [closeupdryeditdialog,setcloseupdryeditdialog]=React.useState(false);
   
       const [single_group_dry_cowseditdialog,setsingle_group_dry_cowseditdialog]=React.useState(false);
   
       const [postfreshlactatingeditdialog,setpostfreshlactatingeditdialog]=React.useState(false);
       
       const [lactatingeditdialog,setlactatingeditdialog]=React.useState(false);
 
 

    const adultcowmapping=[{
        name:"Far-Off Dry Cows",
        value:"far_off_dry",
        state:faroffdry,
        setstate:setfaroffdry,
        id:"facilties_and_grouping_far_off_dry",
        dialog:faroffdrydialog,
        setdialog:setfaroffdrydialog,
        editstate:faroffdryeditdialog,
        seteditstate:setfaroffdryeditdialog

    },{
        name:"Close-Up Dry Cows",
        value:"close_up_dry",
        state:closeupdry,
        setstate:setcloseupdry,
        id:"facilties_and_grouping_close_up_dry",
        dialog:closeupdrydialog,
        setdialog:setcloseupdrydialog,
        editstate:closeupdryeditdialog,
        seteditstate:setcloseupdryeditdialog
    },{
        name:"Single Group Dry Cows",
        value:"single_group_dry_cows",
        state:single_group_dry_cows,
        setstate:setsingle_group_dry_cows,
        id:"facilties_and_grouping_single_group_for_dry_cows",
        dialog:single_group_dry_cowsdialog,
        setdialog:setsingle_group_dry_cowsdialog,
        editstate:single_group_dry_cowseditdialog,
        seteditstate:setsingle_group_dry_cowseditdialog
    },{
        name:"Post-Fresh Lactating Cows",
        value:"postfreshlactating",
        state:postfreshlactating,
        setstate:setpostfreshlactating,
        id:"facilties_and_grouping_post_fresh_lactating_cows",
        dialog:postfreshlactatingdialog,
        setdialog:setpostfreshlactatingdialog,
        editstate:postfreshlactatingeditdialog,
        seteditstate:setpostfreshlactatingeditdialog
    },{
        name:"Lactating Cows",
        value:"lactating",
        state:lactating,
        setstate:setlactating,
        id:"facilties_and_grouping_lactating_rations",
        dialog:lactatingdialog,
        setdialog:setlactatingdialog,
        editstate:lactatingeditdialog,
        seteditstate:setlactatingeditdialog
    }]
    
    const [open,setopen]=React.useState(false);
    const [errormessage,seterrormessage]=React.useState("");
    return (
        <section className="form-section"> 
            <header>
                <h3>Facilities & Grouping</h3>
            </header>       
            <Grid container spacing={2}>
                <Grid item xs={12}>                    
                    <label className="form-label">Adult Cow</label>
                {
                    adultcowmapping.map((data,index)=>{
                    let key=data.id+"_form";    

                    let emptyformdata=Object.keys(props.formdata[key]).length === 0 && props.formdata[key].constructor === Object


                    return <Grid item xs={12} key={index}>
                            <FormControlLabel
                            disabled={props.disableedit}
                            control={<Switch onChange={(()=>{
                                let flag=true;
                                switch(data.value){
                                    case "far_off_dry":
                                    case "close_up_dry":
                                            if(single_group_dry_cows){
                                                seterrormessage("You cannot select 'Far-Off Dry' or 'Close-Up Dry' when 'Single Group For Dry Cows' is selected.");
                                                setopen(!open);
                                                flag=false;
                                            }
                                        break;
                                    case "single_group_dry_cows":
                                        if(faroffdry || closeupdry){
                                            seterrormessage("You cannot select 'Single Group For Dry Cows' when 'Far-Off Dry' or 'Close-Up Dry' is selected.");
                                            setopen(!open);
                                            flag=false;
                                        }
                                        break;
                                    default:
                                }
                                if(flag){
                                    let statevalue=!data.state;
                                    let temp={...props.formdata};
                                    temp[data.id]=statevalue;
                                    props.setformdata(temp);
                                    data.setstate(statevalue);
                                    data.setdialog(true);
                                }
                                })} checked={data.state}/>}   
                            label={data.name}/>
                            {
                                data.state?emptyformdata?
                                <React.Fragment>
                                                <AdultFacilityForm form={data.value}
                                                formdata={props.formdata}
                                                setformdata={props.setformdata}
                                                state={data.dialog}
                                                setstate={data.setdialog}
                                                setswitchoff={data.setstate}
                                                id={data.id}
                                                name={data.name}
                                                type="add"
                                                />
                                            </React.Fragment>:
                                              <Grid container spacing={2}  className="enrollment-form-manage-sub-herd-list-row"> 
                                                <Grid item xs={8} sm={8}  className="sub-herd-list-name">
                                                    <span>
                                                        {data.name} Form
                                                    </span>
                                                </Grid>

                                                <Grid item xs={4} sm={4} className="sub-herd-list-action">                            
                                                    <Button 
                                                        variant="outlined" 
                                                        className="button size-mini variant-" 
                                                        type="button" 
                                                        onClick={()=>{
                                                            data.seteditstate(true)
                                                        }}   
                                                        >
                                                            View / Edit
                                                    </Button>
                                                </Grid>   
                                                {
                                                    data.editstate?<AdultFacilityForm 
                                                        disableedit={props.disableedit}
                                                        form={data.value}
                                                        formdata={props.formdata}
                                                        setformdata={props.setformdata}
                                                        state={data.editstate}
                                                        name={data.name}
                                                        setstate={data.seteditstate}
                                                        type="edit"
                                                    />:null
                                                }                     
                                            </Grid>  
                                            :null
                            }
                     </Grid>
                    })
                }
                </Grid>

            <TwoButtonDialog
                        content={errormessage}
                        open={open}
                        handleClose={setopen}
                        btnText1="OK"
                        btnText2="CLOSE"
                        handleActionBtn1={() => {
                        setopen(false);
                        }}
                        handleActionBtn2={() => {
                        setopen(false);
                        }}
                    />

            </Grid>
        </section>   
    );
};

export default Facilities_and_Grouping;
