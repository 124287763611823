import React from "react";


const DHIA_Enrollment = (props) => {
    return (
        <section className="form-section">
            {/* <header>
                <h3>DHIA Enrollment</h3>
                <span>
                    For DC305 and DPLAN herds, test day or average weekly milk data may be available in the raw data upload from on farm backups. Please use this switch to determine which values to use to calculate production parameters on reports. If these meters are not calibrated or cows are not properly identified, test day milk should be used. For herds with multiple milking facilities, we recommend splitting the export from DC305 or DPLAN and using the sub-herd feature to properly calculate production data.
                </span>
            </header>
            <Grid container spacing={2}>
                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.disableedit}
                        type={"vertical"}
                        data={{
                            name: "parlor_daily_milk_weights",
                            heading: "Does this herd have parlor meters to record daily milk weights?",
                            no: "Not in any milking facility in this herd.",
                            yes: "Yes, in at ssleast one milking facility for this herd.",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }} />
                </Grid> */}
            {/* <Grid item xs={12} className="radio-group">
                    <RadioButton 
                                    disableedit={props.disableedit}
                                    data={{
                                        name:"dhia_daily_milk_weights_recorded",
                                        heading:"Are daily milk weights recorded (parlor milk weight monitoring systems)?",
                                        yes:"Yes",
                                        no:"No",
                                        formdata:props.formdata,
                                        setformdata:props.setformdata
                                        }}/>       
                </Grid> */}
            {/* <Grid item xs={12} className="radio-group">
                    <RadioButton 
                                    disableedit={props.disableedit}
                                    data={{
                                        name:"dhia_dhi_or_other_service_used",
                                        heading:"Is DHI or another testing service used?",
                                        yes:"Yes",
                                        no:"No",
                                        formdata:props.formdata,
                                        setformdata:props.setformdata
                                        }}/> 
                </Grid> */}

            {/* {
                    props.formdata["dhia_dhi_or_other_service_used"] === "Yes" ?
                        <React.Fragment>
                            <Grid item xs={12} sm={4}>
                                <Textbox
                                    disableedit={props.disableedit}
                                    data={{
                                        className: "text-field-root",
                                        name: "dhia_enrollment_tests_per_year",
                                        id: "dhia_enrollment_tests_per_year",
                                        type: "number",
                                        label: "Approximate number of tests per year",
                                        formdata: props.formdata,
                                        setformdata: props.setformdata,
                                    }} />
                            </Grid>
                            <Grid item xs={12} className="switch-button-group">
                                <SwitchButtongroup
                                    disableedit={props.disableedit}
                                    data={
                                        {
                                            size: 14,
                                            label: "Are any of these milk components tested? Select all that apply.",
                                            formdata: props.formdata,
                                            setformdata: props.setformdata,
                                            switchgroup: [{
                                                name: "dhia_fat",
                                                label: "Fat"
                                            }, {
                                                name: "dhia_protein",
                                                label: "Protein"
                                            }, {
                                                name: "dhia_scc",
                                                label: "SCC"
                                            }, {
                                                name: "dhia_mun",
                                                label: "MUN"
                                            }]

                                        }
                                    } />
                            </Grid>
                        </React.Fragment>
                        : null
                } 
                </Grid>
                */}
            {/* END Grid Container*/}
        </section>
    );
};

export default DHIA_Enrollment;
