import { getRecentlyUploadedfilesforsubherd } from '../../services/herd.service';
import { decodeApiResponse } from '../utility';

export async function gettestdates(requestdata) {
    let processingflag = false; //used to call getfilehistory api every 30 seconds if true.
    return new Promise((resolve, reject) => {
        getRecentlyUploadedfilesforsubherd(requestdata.sub_herd_id).then((response) => {
            const decodedResponse = decodeApiResponse(response);
            let formattedresponse = [];
            let breeds = requestdata.breeds ? requestdata.breeds.join(" , ") : "";
            if (response.length > 0) {
                formattedresponse = decodedResponse[0]["files"].map((data) => {
                    let files = [];
                    let filedetails = data.details;

                    files = filedetails.map((obj) => obj.FileName);
                    let uploadtestdates = filedetails.map((obj) => obj.test_day);
                    let uploadTestday = [...new Set(uploadtestdates)];

                    let uploaddatetime = filedetails.map((obj) => obj.upload_time ? obj.upload_time : obj.test_day);
                    let uploaddate = [...new Set(uploaddatetime)];

                    let error = filedetails.map((obj) => obj?.error);
                    let to_be_processed = filedetails.map((obj) => obj?.to_be_processed);
                    let uploaded_by = filedetails.map((obj) => obj?.type === "general" ? obj?.uploaded_by : null).filter((el) => {return el !== null;});
                    if (data.source_system === "DC305") {
                        return {
                            "source_system": data.source_system,
                            "files": files,
                            "test_day": uploadTestday,
                            "herd_id": data.details[0].herd_id,
                            "uploaddate": uploaddate,
                            "to_be_processed": to_be_processed,
                            "error": error,
                            "uploaded_by": uploaded_by
                        }
                    }

                    return {
                        "source_system": data.source_system,
                        "files": files,
                        "test_day": uploadTestday,
                        "herd_id": data.details[0].herd_id,
                        "uploaddate": uploaddate,
                        "uploaded_by": uploaded_by
                    }
                })


                formattedresponse = formattedresponse.filter((d) => {
                    return d.source_system === requestdata.software_herd_software;
                })

                let testdates = [];
                let files = [];
                let source_system = [];
                let herd_id = [];
                let uploaddate = [];
                let uploaded_by = [];

                let error = [];
                let to_be_processed = [];

                formattedresponse = formattedresponse.map((d) => {
                    files.push(d.files)
                    uploaddate.push(d.uploaddate)
                    uploaded_by.push(d.uploaded_by)

                    if (d.source_system === "DC305") {
                        //exclude test day if there is an error or upload is in-progress in backend
                        let temptestday = [];
                        for (let i = 0, j = 0; i < d.test_day.length; i++, j += 3) {
                            if (d.to_be_processed[j] && d.to_be_processed[j + 1] && d.to_be_processed[j + 2]) {
                                to_be_processed.push(true);
                            } else {
                                to_be_processed.push(false)
                            }

                            if (d.error[j] === "" && d.error[j + 1] === "" && d.error[j + 2] === "") {
                                error.push("");
                            } else {
                                let err_list = [];
                                for (let z = 0; z < 3; z++) {
                                    if (!err_list.includes(d.error[j + z])) {
                                        err_list.push(d.error[j + z]);
                                    }
                                }
                                error.push(err_list.toString());
                            }

                            if (!to_be_processed[i] && error[i] === "") {
                                temptestday.push(d.test_day[i])
                            }

                            if (to_be_processed[i] && error[i] === "") {
                                processingflag = true;
                            }
                        }
                        testdates.push(temptestday);
                    } else {
                        testdates.push(d.test_day);
                    }

                    source_system.push(d.source_system)
                    herd_id.push(d.herd_id)
                    return {
                        ...d,
                        breed: breeds,
                        description: requestdata.description,
                        test_day: d.test_day,
                        files: d.files,
                        uploaddate: d.uploaddate,
                        source_system: d.source_system,
                        herd_id: d.herd_id,
                        to_be_processed: to_be_processed,
                        error: error,
                        uploaded_by: d.uploaded_by
                    }
                })

                testdates = testdates.filter((d, index, array) => array.indexOf(d) === index);

                let uploaddatadetails = "";

                if (testdates[0].length > 0) {
                    uploaddatadetails = JSON.stringify([{
                        testdates: testdates,
                        sub_herd_id: response[0].sub_herd_id,
                        source_system: formattedresponse[0].source_system,
                        files: files,
                        uploaddate: uploaddate,
                        herd_id: herd_id,
                        uploaded_by: uploaded_by
                    }]);
                }

                resolve({
                    processingflag: processingflag,
                    uploaddatadetails: uploaddatadetails,
                    formattedresponse: formattedresponse,
                    response: decodedResponse
                });
            } else {
                resolve({
                    processingflag: processingflag,
                    response: []
                });
            }
        }).catch((err) => {
            reject({
                response: [],
                processingflag: processingflag,
                error: err
            });
        })
    })

}


