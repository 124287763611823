import React from "react";
import { makeStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name, item, theme) {
//   return {
//     fontWeight:
//       item.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

const MultiSelectDropdown = (props) => {
    
    const options = props.data?props.data.options:[{name:"saja",value:"askdjask"}];
    const labelid=props.data?props.data.labelid:"demo-mutiple-chip-label";
    const id=props.data?props.data.id:"demo-mutiple-chip";
    const inputid=props.data?props.data.inputid:"select-multiple-chip";
    const labelname=props.data?props.data.labelname:"Chips";

      const classes = useStyles();
    props.data.formdata[props.data.id]=props.data.formdata[props.data.id]?props.data.formdata[props.data.id]:[];
    
    let disabled = false;

    if(props.disableedit){
        disabled=props.disableedit;
    }
    return (
            <FormControl className={props.data.className + " multiselect-root"} disabled={disabled}>
                <InputLabel id={labelid} className="multiselect-label" /*style={{ display: props.labelVisibility ? "none" : ""}}*/>
                  {labelname}
                </InputLabel>
                <Select
                    IconComponent={props => (
                      <i className={'ag-icon ag-icon-list-ul MuiSvgIcon-root MuiSelect-icon'}></i>
                    )}
                    labelId={labelid}
                    id={id}
                    name={id}
                    multiple
                    value={props.data.formdata[props.data.id]}
                    onChange={(event)=>{
                      let temp={...props.data.formdata};
                      temp[props.data.id]=event.target.value;
                      if(props.data.handler){
                        props.data.handler(temp[props.data.id],temp)
                      }else{
                        props.data.setformdata(temp);
                      }
                  }} 
                    input={<Input id={inputid} />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                 >
                    { options.map((data,index) => (
                        <MenuItem key={index} value={data.name}>
                          <Checkbox checked={props.data.formdata[props.data.id]?props.data.formdata[props.data.id].indexOf(data.name) > -1:false} />
                          <ListItemText primary={data.name} />                               
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            //  style={getStyles(data.value, item, theme)}d
    );
};

export default MultiSelectDropdown;
