import CryptoJS from "crypto-js";
import { ENVIRONMENT, LOGOUT_REDIRECT_URI, SSO_BASE_PATH } from '../constants/constants';
import { getEnvironment } from '../utils/utility';


const getsecret = (ciphertext) => {
	var bytes = CryptoJS.AES.decrypt(ciphertext, 'addiebenchmark');
	var originalText = bytes.toString(CryptoJS.enc.Utf8);
	return originalText;
}

const AUTH_CONFIG = {
	AUTHORIZATION: `${SSO_BASE_PATH()}/as/authorization.oauth2`,
	AUTH_TOKEN_URL: `${SSO_BASE_PATH()}/as/token.oauth2`,
	LOGOUT: `${SSO_BASE_PATH()}/idp/startSLO.ping?TargetResource=${LOGOUT_REDIRECT_URI()}`,
	GRANT_TYPE: 'authorization_code',
	RESPONSE_TYPE: 'code',
	SCOPE: 'openid',
	CLIENT_ID: 'rs_client_mendix',
	CLIENT_SECRET: getsecret("U2FsdGVkX19Aq0+OMAUlL/mYH57VrhTXpUMfthbxINr3UhVnB7LwE1xCr+bde2D+"),
	REDIRECT_URI_DEV: 'https://dairydev.addieinsights.com/auth',
	REDIRECT_URI_QA: 'https://dairystage.addieinsights.com/auth',
	REDIRECT_URI_PROD: 'https://www.addieinsights.com/auth',
	REDIRECT_URI_LOCAL: 'http://localhost:3000/auth',

	EXT_CLIENT_ID: 'SZWwRk0JcqQeKljY5kZU',
	EXT_CLIENT_SECRET: getsecret("U2FsdGVkX1/CBG54L0RDHdE2GycYLy7TeO9DxN6clLbFL1sSht3fG4y39BzjwuLazpVdjbmNpWRukEJPzsJ83HbyF80G06dqNIwu7u3PHCukTzUNTZtL71CNHN26kg4R"),
	EXT_REDIRECT_URI_DEV: 'https://dairydev.addieinsights.com/ext/auth',
	EXT_REDIRECT_URI_QA: 'https://dairystage.addieinsights.com/ext/auth',
	EXT_REDIRECT_URI_PROD: 'https://www.addieinsights.com/ext/auth',
	EXT_REDIRECT_URI_LOCAL: 'http://localhost:3000/ext/auth',
};

const ENV = getEnvironment();
let enviromenturl = '';
let analytics_url = "https://assets.adobedtm.com/7390c55bcb09/767b2f29fa84/launch-aaee7b22562d-development.min.js";
if (ENV === ENVIRONMENT.PROD) {
	AUTH_CONFIG.CLIENT_ID = "3rVe9MjI8cvQamL28Va7";
	AUTH_CONFIG.CLIENT_SECRET = getsecret("U2FsdGVkX192KCUf5KftmWBZdhqp6UQqzGGEoP3ZvodXw10WwUCf6cvjBnEN7+6qrNxuDlPURTnlPXy48FyI2kwHNKhYhVMZ75OkFiPrAUc7v4tk4KpP3kVG7bKLYFPI");
	AUTH_CONFIG.EXT_CLIENT_ID = "Vztj89o9HFWpkQeVuVJd";
	AUTH_CONFIG.EXT_CLIENT_SECRET = getsecret('U2FsdGVkX18YKgIYhQfA9+AvzG7YS/PXDcyBeVL20ER0Zw7q6r6obZrUMXSoljUrDOgRAhQCoRxDrRcQVadWH46F9oO7b9ddfcqdSVfayX4eKJXBONBmDNz7JTp1JcC8');
	enviromenturl = 'https://api-dairy.addieinsights.com/';
	analytics_url = "https://assets.adobedtm.com/7390c55bcb09/767b2f29fa84/launch-80cbe26927c9.min.js";
} else if (ENV === ENVIRONMENT.QA) {
	enviromenturl = 'https://api-dairystage.addieinsights.com/';
} else if (ENV === ENVIRONMENT.DEV) {
	enviromenturl = 'https://api-dairydev.addieinsights.com/';
} else {
	enviromenturl = 'https://api-dairydev.addieinsights.com/';
}

const APP_BASE_URL_DEV = enviromenturl;
const ADOBE_ANALYTICS_URL = analytics_url;

export { AUTH_CONFIG, APP_BASE_URL_DEV, ADOBE_ANALYTICS_URL };

