import { ApiUrl } from '../configs/path.config';
import NetworkService from './networkService';

export async function uploadFile(formData) {
    return await NetworkService.postDataUpload(ApiUrl.uploadFile(), formData);
}

export async function deleteUploadedFiles(subHerdId, testDay, sourceType) {
    let response = await NetworkService.postData(ApiUrl.deleteUploadFile(subHerdId, testDay, sourceType));
    console.log(response)
    return response;
}