import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// material import
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { breeds } from '../../../common/constants/constants';
// userdefined component import
import TwoButtonDialog from "../../../common/dialogs/two-button-dialog/two-button-dialog";
import OneButtonDialog from '../../../common/dialogs/one-button-dialog/one-button-dialog';
import ModalFlyout from '../../ui_utilities/modalFlyout';

const AddSubherdReport = (props) => {
    const [active, setactive] = React.useState(false);
    const [description, setdescription] = React.useState("");
    const [selectedbreeds, setselectedbreeds] = React.useState([]);
    const [isDialogOpen, setDialogStatus] = React.useState(false);
    const [editindex, seteditindex] = React.useState(-1);
    const [iserror, seterrordialog] = React.useState(false);
    const [errormessage, seterrormessage] = React.useState("");
    const [isdisabled, setisdisabled] = React.useState(false);
    const [useavgdailymilk, setuseavgdailymilk] = React.useState(false);
    const [useECM_PeerAvg, setUseECM_PeerAvg] = React.useState(false);
    const herdaccess = props.herddata?.AccessDetails.access;

    useEffect(() => {
        let disabled = false;
        if (props.disableedit) {
            disabled = props.disableedit;
        }
        if (props.type === "viewedit") {
            setdescription(props.subherddata.description);
            setselectedbreeds(props.subherddata.breeds);
            seteditindex(props.editindex);
            setactive(props.subherddata.active);
            setuseavgdailymilk(props.subherddata.use_avg_daily_milk);
            setUseECM_PeerAvg(props.subherddata.use_ecm_peer_avg);
            setisdisabled(disabled);
        } else if (props.type === "default") {
            setdescription(props.subherddata.description);
            setselectedbreeds(props.formdata.software_breeds);
            setactive(props.subherddata.active);
            setuseavgdailymilk(props.subherddata.use_avg_daily_milk);
            setUseECM_PeerAvg(props.subherddata.use_ecm_peer_avg);
            setisdisabled(true);
        } else {
            setactive(true);
            setisdisabled(disabled);
        }
    }, [props])

    const closeDeleteDialog = () => {
        setDialogStatus(false);
    };

    const deletesubherd = () => {
        let t = props.formdata["sub_herds"];
        t = t.filter((data, index) => {
            return index !== editindex;
        })
        let temp = { ...props.formdata };
        temp["sub_herds"] = t;
        props.setformdata(temp);
    }

    const savedit = () => {
        if (props.type === "default") { // for editing editing All cows 
            let temp = { ...props.formdata };
            temp["sub_herds"][0] = {
                ...temp["sub_herds"][0],
                active: active,
                breeds: [],
                description: "All Cows",
                use_avg_daily_milk: false,
                use_ecm_peer_avg: useECM_PeerAvg
            }

            props.setformdata(temp);
            localStorage.setItem("save_edit", "yes");
            props.setstate(false);
        } else {
            const isEditing = editindex !== -1; 
            const existingSubherd1 = props.herddata !== undefined 
                ? props.herddata?.sub_herds?.find(sub => sub.description.toLowerCase() === description.toLowerCase())
                : false;

            const existingSubherd2 = props.formdata.sub_herds.find(sub => sub.description.toLowerCase() === description.toLowerCase());
            if (description === "") {
                seterrormessage("Please fill the Description");
                seterrordialog(true);
            } else if (selectedbreeds.length === 0) {
                seterrormessage("Please Select a breed.");
                seterrordialog(true);
            } else if (isEditing && description.toLowerCase() !== props.formdata.sub_herds[editindex].description.toLowerCase()) {
                // If the description has changed during editing, check for existence\
                if (existingSubherd1 || existingSubherd2) {
                    seterrormessage("Sub-Herd description already exists. \n Please choose another description to continue.");
                    seterrordialog(true);
                } else {
                    saveEditSubherd(isEditing)
                }
            } else if (!isEditing && existingSubherd1) { 
                seterrormessage("Sub-Herd description already exists. \n Please choose another description to continue.");
                seterrordialog(true);
            } else {
                // If not editing or description didn't change, or description is unique, proceed with the update
                saveEditSubherd(isEditing)
            }
        }
    }

    const saveEditSubherd = (isEditing) => {
        let temp = { ...props.formdata };
            let defaultsubherd_index = 0;
            for (let i = 0; i < props.formdata.sub_herds.length; i++) {
                if (props.formdata.sub_herds[i].default) {
                    defaultsubherd_index = i;
                }
            }
            let default_subherd_breed_types = temp["sub_herds"][defaultsubherd_index].breeds.map(function (b) { return b.replace(/Other Purebred/g, "Other"); });
            if (isEditing) {
                temp["sub_herds"][editindex] = {
                    ...temp["sub_herds"][editindex],
                    active: active,
                    breeds: selectedbreeds,
                    description: description,
                    use_avg_daily_milk: useavgdailymilk,
                    use_ecm_peer_avg: useECM_PeerAvg
                }
            } else {
                temp["sub_herds"] = [...temp["sub_herds"], {
                    active: active,
                    breeds: selectedbreeds,
                    description: description,
                    use_avg_daily_milk: useavgdailymilk,
                    use_ecm_peer_avg: useECM_PeerAvg
                }];
            }
            temp["sub_herds"][defaultsubherd_index] = {
                ...temp["sub_herds"][defaultsubherd_index],
                breeds: [...new Set([...selectedbreeds, ...default_subherd_breed_types])],
            }
            temp["software_breeds"] = [...temp["sub_herds"][defaultsubherd_index].breeds, ...temp["software_breeds"]];
            temp["software_breeds"] = temp["software_breeds"].map((ele) => {
                if (ele === "Other") {
                    return "Other Purebred";
                } else {
                    return ele;
                }
            })
            temp["software_breeds"] = [...new Set(temp["software_breeds"])];
            props.setformdata(temp);
            localStorage.setItem("save_edit", "yes");
            props.setstate(false);
    }
    
    let subherdform = <React.Fragment>
        {
            props.type === "add" ? <Grid item xs={12}>
                <h1 className="appbar-headline text-uppercase">Add A Sub Herd</h1>
            </Grid> : null
        }
        {
            props.type === "viewedit" ? <Grid item xs={12}>
                <h1 className="appbar-headline text-uppercase">Edit A Sub Herd</h1>
            </Grid> : null
        }

        <Grid item xs={12}>
            <FormControlLabel
                className="text-bold"
                style={{ marginBottom: "15px" }}
                disabled={isdisabled}
                control={<Switch
                    onChange={() => {
                        setactive(!active);
                    }}
                ></Switch>}
                label={active ? 'This Sub-Herd will be Active' : 'This Sub-Herd will be Inactive'}
                checked={active}
            />
        </Grid>

        <Grid item xs={12}>
            <TextareaAutosize rowsMin={2} aria-label="empty textarea" placeholder="Enter a Description"
                disabled={isdisabled}
                className="textarea"
                value={description}
                onChange={(event) => {
                    setdescription(event.target.value);
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel
                control={<Switch
                    disabled={props.formdata.software_herd_software === "PCDART" || props.formdata.parlor_daily_milk_weights === false || herdaccess === "readonly"}
                    checked={useavgdailymilk}
                    onChange={() => {
                        setuseavgdailymilk(!useavgdailymilk);
                    }}
                    name="checkedA"
                ></Switch>}
                label="Use average daily milk for production calculations"
            />
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel
                control={<Switch
                    disabled={props.formdata.use_ecm_peer_avg === false || herdaccess === "readonly"}
                    checked={useECM_PeerAvg}
                    onChange={() => {
                        setUseECM_PeerAvg(!useECM_PeerAvg);
                    }}
                    name="checkedECM"
                ></Switch>}
                label="Use ECM for peer average calculations"
            />
        </Grid>

        <Grid item xs={12}>
            <h3>Breeds</h3>
            <p>
                Breed selection will determine both default sub-herd goals and peer group filters. You will be able to customize goals by breed or for each sub-herd. Herds with any combination of breeds including Holstein or Crossbred will be assigned to Holstein-based peer groups. Herds with only Jersey and/or Other Purebred will be assigned to Jersey-based peer groups
                            </p>
        </Grid>
        {
            breeds.map((data, index) => {
                return <Grid item xs={12} key={index}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    let tempbreed = selectedbreeds;
                                    if (tempbreed.includes(data.name)) {
                                        if (!event.target.checked) {
                                            tempbreed = tempbreed.filter((tempbreed) => {
                                                return data.name !== tempbreed;
                                            })
                                        }
                                    } else {
                                        if (event.target.checked) {
                                            tempbreed = [...tempbreed, data.name]
                                        }
                                    }
                                    setselectedbreeds(tempbreed);
                                }}
                                name={data.name}
                                color="primary"
                                disabled={isdisabled}
                                checked={!data.name.includes("Other") ? selectedbreeds.includes(data.name) : data.name.includes("Other") ? (selectedbreeds.includes(data.name) || selectedbreeds.includes("Other Purebred")) : selectedbreeds.includes(data.name)}
                            />
                        }
                        label={data.name === "Other" ? "Other Purebred" : data.name}
                    />
                </Grid>
            })
        }
        {herdaccess !== "readonly" && 
            <Grid container spacing={2} className="button-submit-container">
                <Grid item className="button-submit-container-left" xs={12} sm={4}>
                    <Button
                        disabled={props.disableedit}
                        variant="contained"
                        className="button"
                        type="button"
                        size="small"
                        startIcon={<SaveOutlinedIcon />}
                        onClick={() => {
                            savedit();
                        }}>
                        Save
                    </Button>
                </Grid>
                <Grid item className="button-submit-container-center" xs={12} sm={4}>
                    {
                        props.type === "viewedit" ? <Button
                            variant="outlined"
                            disabled={props.disableedit}
                            className="button"
                            type="button"
                            size="small"
                            startIcon={<DeleteForeverIcon />}
                            onClick={() => {
                                setDialogStatus(true)
                            }}>
                            Delete
                                                        </Button> : null
                    }

                </Grid>
                <Grid item className="button-submit-container-right" xs={12} sm={4}>


                    <Button
                        variant="outlined"
                        className="button grey"
                        type="button"
                        size="small"
                        startIcon={<CancelOutlinedIcon />}
                        onClick={() => {
                            props.setstate(false);
                        }}>
                        Cancel
                                </Button>
                </Grid>
            </Grid>
        }
        {
            (props.formdata.software_herd_software !== "PCDART" && props.formdata.parlor_daily_milk_weights === true && props.type === "default" && herdaccess !== "readonly") ? <Grid container spacing={2} className="button-submit-container">
                <Grid item className="button-submit-container-left" xs={12}>
                    <Button
                        disabled={props.disableedit}
                        variant="contained"
                        className="button"
                        type="button"
                        size="small"
                        startIcon={<SaveOutlinedIcon />}
                        onClick={() => {
                            let temp = { ...props.formdata };
                            let defaultsubherd_index = 0;
                            for (let i = 0; i < props.formdata.sub_herds.length; i++) {
                                if (props.formdata.sub_herds[i].default) {
                                    defaultsubherd_index = i;
                                }
                            }

                            temp["sub_herds"][defaultsubherd_index] = {
                                ...temp["sub_herds"][defaultsubherd_index],
                                use_avg_daily_milk: useavgdailymilk,
                                use_ecm_peer_avg: useECM_PeerAvg
                            }
                            props.setformdata(temp);
                            localStorage.setItem("save_edit", "yes");
                            props.setstate(false);
                        }}>
                        Save
                        </Button>
                </Grid>
            </Grid> : null

        }
        {
            isDialogOpen ? <TwoButtonDialog
                content={"Are you sure you want to Delete this Subherd?"}
                open={isDialogOpen}
                handleClose={closeDeleteDialog}
                btnText1="Yes"
                btnText2="No"
                handleActionBtn1={() => {
                    closeDeleteDialog();
                    deletesubherd();
                    props.setstate(false);
                }}
                handleActionBtn2={() => {
                    closeDeleteDialog();
                }}
            /> : null
        }

        {
            iserror ? <OneButtonDialog
                content={<div dangerouslySetInnerHTML={{ __html: errormessage.replace(/\n/g, '<br/>') }} />}
                open={iserror}
                handleClose={() => {
                    seterrordialog(false);
                }}
                btnText1="OK"
                handleActionBtn1={() => {
                    seterrordialog(false);
                }}
            /> : null
        }
    </React.Fragment>

    return (
        <ModalFlyout state={props.state} setstate={props.setstate}>
            {subherdform}
        </ModalFlyout>
    );
};


export default AddSubherdReport;

