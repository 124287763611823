import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > * + *': {
          marginTop: theme.spacing(2),
      },
      width: '100%',
    },
    herdNameCell: {
        width: '100px', // Set the width to 20%
    },
}));

const HerdTable = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Paper className={classes.root + " paper-root-table-wrapper"} elevation={0}>
        {props.pagecontent.length === 0 ? <div style={{ textAlign: "center", width: "100%" }}>No Data Found</div> :
            <React.Fragment>
                <TableContainer className={classes.container + " table-wrapper"}>
                    <Table stickyHeader aria-label="sticky table" className="table-root">
                        <TableHead className="table-thead">
                            <TableRow className="table-tr">
                                <TableCell component="th" className="table-th" style={{ width: '10%' }}><h4>Herd Code</h4></TableCell>
                                <TableCell 
                                    component="th" 
                                    className="table-th" 
                                    style={{ width: '20%' }}
                                    onClick={() => props.handleSortHerdName()}
                                >
                                        <h4>
                                            Herd Name <span className="align-end"><SwapVertIcon /></span>
                                        </h4>
                                </TableCell>
                                <TableCell component="th" className="table-th" style={{ width: '10%' }}><h4>State</h4></TableCell>
                                <TableCell component="th" className="table-th" style={{ width: '10%' }}><h4>Source Application</h4></TableCell>
                                <TableCell
                                    component="th"
                                    className="table-th"
                                    style={{ width: '10%' }}
                                    onClick={() => props.handleSortDate()}
                                >
                                    <h4>
                                        Date <span className="align-end"><SwapVertIcon /></span>
                                    </h4>
                                </TableCell>
                                <TableCell component="th" className="table-th" style={{ width: '15%' }}><h4>Owner</h4></TableCell>
                                <TableCell colSpan={2} style={{ width: '25%' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="table-tbody">
                            {
                                props.pagecontent.map((data, index) => {
                                    let ownerlists = []
                                    if (data.HerdAssignments.length > 0) {
                                        data.HerdAssignments.forEach((d) => {
                                            let surname = d.surname ? d.surname : "";
                                            if (d.access === "owner") {
                                                ownerlists.push((d.givenName + " " + surname).toLowerCase());
                                            }
                                        })
                                    }
                                    ownerlists = ownerlists.filter((d, i) => {
                                        return ownerlists.indexOf(d) === i
                                    })

                                    let ownername = ownerlists.join(" , ");

                                    return (
                                        <TableRow key={index} className="table-tr">
                                            <TableCell
                                                scope="row"
                                                className="table-td"
                                            >
                                                {data.software_herd_code}
                                            </TableCell>

                                            <TableCell
                                                scope="row"
                                                className="table-td herdname-column"
                                            >
                                                {data.dairy_name}
                                            </TableCell>

                                            <TableCell
                                                scope="row"
                                                className="table-td"
                                            >
                                                {data.dairy_state}
                                            </TableCell>

                                            <TableCell
                                                scope="row"
                                                className="table-td"
                                            >
                                                {data.software_herd_software}

                                            </TableCell>

                                            <TableCell
                                                scope="row"
                                                className="table-td"
                                            >
                                                {data.summary_latest_data?.test_day}

                                            </TableCell>

                                            <TableCell
                                                scope="row"
                                                className="table-td"
                                            >
                                                {ownername}

                                            </TableCell>

                                            <TableCell
                                                scope="row"
                                                className="btn-wrapper table-td td-cta"
                                                colSpan={2}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    className="button size-mini"
                                                    type="button"
                                                    size="small"

                                                    onClick={() => {
                                                        history.push('/dashboard/herds/detail?herd_id=' + data.herd_id);
                                                    }}
                                                >
                                                    Herd Details
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className="button size-mini"
                                                    type="button"
                                                    size="small"
                                                    endIcon={<OpenInNewIcon />}
                                                    onClick={() => {
                                                        history.push("/dashboard/herds/subherd/detail?herd_id=" + data.herd_id + "&subherd_id=" + data.sub_herds[0].sub_herd_id);
                                                    }}
                                                >
                                                    View a Sub-Herd
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={props.herdlist.length}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={props.handleChangePage}
                    onChangeRowsPerPage={props.handleChangeRowsPerPage}
                />
            </React.Fragment>
        }
    </Paper>
  )
}

export default HerdTable;