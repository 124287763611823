import Button from '@material-ui/core/Button';
import React from "react";
import { useHistory } from "react-router-dom";

const AddHerdSuccess = (props) => {

    const history = useHistory();

    function gotoHerdetailsPage() {
        history.push('/dashboard/herds?t=p');
    }

    function gotoUploadPage() {
        history.push('/dashboard/uploadData?herd_id=' + props.herddata.herd_id + "&subherd_id=" + props.subherddata.sub_herd_id);
    }
    return (
        <div className="upload_success_container" style={{ marginTop: "20%" }}>
            <h3>Herd Added Successfully</h3>
            <Button 
                variant="contained"
                disabled={props.herddata.software_herd_software === "DRMS"}
                className="button"
                type="button"
                size="small" onClick={gotoUploadPage}>
                Upload Data
            </Button>
            <Button variant="contained" className="button" type="button"
                size="small" onClick={gotoHerdetailsPage}>
                View Herds
            </Button>
        </div>
    );
};

export default AddHerdSuccess;

