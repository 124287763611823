import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
// material import
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Alert from '@mui/material/Alert';
import React from "react";
import { sendActionToAdobeAnalytics } from '../../../common/analytics/adobeanalytics/adobeanalytics';

const GoalHeader = (props) => {
    /**
 * Dropdown to select type of breed.
 */
    const breedTabs = () => {
        return (
            <Container maxWidth={false} className="container-align-left page-tabs-wrapper button-tab-negative-margin-hack 34">

                <div className="page-tabs">
                    {props.breeds.map((item, i) => {
                        return <div key={i} className={props.breedCard === item ? props.activebuttonClass + " tab-item active" : props.buttonClass + " tab-item"} onClick={() => {
                            if (props.clickEditColumn) {
                                alert("Please save the edited data to proceed further");
                            } else {
                                let goalcard = "predefined";
                                if (props.customgoalon.includes(item)) {
                                    goalcard = "custom"
                                }
                                props.setGoalCard(goalcard);
                                // Collecting the tab click in adobe analytics
                                let body = {
                                    tabName: item
                                };
                                sendActionToAdobeAnalytics("tab", body)

                                if (localStorage.getItem("save_edit") === "no") {
                                    props.setBreedCard(item);

                                    if (props.type === "usergoals")
                                        props.setgoalname("User Goals : " + item);

                                    if (props.type === "globalgoals") {
                                        props.setGoalCard("custom");
                                        props.setgoalname("Default Goals : " + item);
                                    }
                                    props.setPredefinedBreed(item);
                                    props.handleChangeBreed(item);
                                    props.setclickImport(false);
                                    props.setErrorLactationPercent(false);
                                } else {
                                    props.setopenmodal(true);
                                    props.setBreedClicked(item);
                                }
                            }

                        }}> {item}</div>
                    })}
                </div>

            </Container >
        )
    }

    const renderTankAndLactation = () => {
        let tankandlactation = null;
        if (props.breedSelected) {
            props.breedwithgoal.forEach((item) => {
                if (item.breedName === props.breedCard) {
                    tankandlactation = <div className="card-section">
                        <div className="card-body">
                            <Grid container spacing={3} className="input-fields">
                                <Grid item className="tank-average-section">
                                    <InputLabel className="form-label" id="tank-average-select-label">Tank Average Goal</InputLabel>
                                    <Select
                                        labelId="tank-average-select-label"
                                        id="tank-average-select"
                                        value={props.tankAverage}
                                        onChange={(event) => {
                                            props.setTankAverage(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={65}>61-65</MenuItem>
                                        <MenuItem value={70}>66-70</MenuItem>
                                        <MenuItem value={75}>71-75</MenuItem>
                                        <MenuItem value={80}>76-80</MenuItem>
                                        <MenuItem value={85}>81-85</MenuItem>
                                        <MenuItem value={90}>86-90</MenuItem>
                                        <MenuItem value={95}>91-95</MenuItem>
                                        <MenuItem value={100}>96-100</MenuItem>
                                        <MenuItem value={105}>101-105</MenuItem>
                                        <MenuItem value={110}>106-110</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item>
                                    <label className="form-label">% of Herd in Lactation 1</label>
                                    <OutlinedInput
                                        id="lactation"
                                        value={props.lactationpercent}
                                        onChange={(event) => {
                                            props.setErrorLactationPercent(false);
                                            if (event.target.value > 100) {
                                                props.setErrorLactationPercent(true);
                                            }
                                            props.setLactationpercent(event.target.value);

                                        }}
                                        className="text-field-root required-style popper-fields"
                                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    />
                                    {props.errorLactationPercent ? <div className="error">Please enter a value between 0-100</div> : null}
                                </Grid>

                                <Grid item className="submit-wrapper">
                                    <Button variant="outlined" style={{ marginTop: "32px" }} className="button submit-button" onClick={() => props.saveAllBreeds(null)}>CALCULATE &amp; APPLY</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
            })
            return tankandlactation;
        }
    }

    const importGoal = () => {
        return (
            <div className={props.clickImport ? 'import-section imported' : 'import-section'}>
                <div>
                    To save time, you can import all the goal values from any parent goals (default and/or user goals).
                    <br />
                    This will pre-populate all the values in the goal tables below.
                </div>
                <Button
                    variant="outlined"
                    className="button"
                    type="button"
                    size="small"
                    onClick={(event) => {
                        // window.scrollTo(0, 0);
                        props.setOpenImportModal(true);
                    }
                    }
                > IMPORT</Button>
            </div>
        )
    }

    const customGoal = () => {
        if (props.goalCard === 'custom') {
            return <div className="goal-value-wrapper card-sub-body">
                {props.type === "subherdgoals" ?
                    <Grid item xs={12} className="grid-control-header">
                        <h4>Calculate Goal Values for This {props.breedCard} Sub-Herd</h4>
                        <p style={{ margin: ".75rem 0 .5rem" }}>Tank Average Goal &amp; Percentage (%) of Herd in Lactation 1 are used to calculate baseline goals for a herd.Highlighted rows in the goals table are populated by this calculation.</p>
                        <p style={{ margin: ".75rem 0 1.25rem" }}>Simply update these 2 values, then click CALCULATE & APPLY to run the calculation and update the <b>BOLD</b> goals in the tables below. Once all the goals have been set, click SAVE CHANGES to keep your changes. </p>
                    </Grid> :
                    <Grid item xs={12} className="grid-control-header">
                        <h4>Calculate Goal Values for {props.breedCard} Herds</h4>
                        <p style={{ margin: ".75rem 0 .5rem" }}>Tank Average Goal &amp; Percentage (%) of Herd in Lactation 1 are used to calculate baseline goals for a herd. Highlighted rows in the goals table are populated by this calculation.</p>
                        <p style={{ margin: ".75rem 0 1.25rem" }}>Simply update these 2 values, then click CALCULATE &amp; APPLY to run the calculation and update the <b>BOLD</b> goals in the tables below. Once all the goals have been set, click SAVE CHANGES to keep your changes. </p>
                    </Grid>
                }
                <div className="card-section">
                    <div className="card-body">
                        <Grid container spacing={3} className="input-fields">
                            <Grid item className="tank-average-section">
                                <InputLabel className="form-label" id="tank-average-select-label">Tank Average Goal</InputLabel>
                                <Select
                                    labelId="tank-average-select-label"
                                    id="tank-average-select"
                                    value={props.tankAverage}
                                    onChange={(event) => {
                                        props.setTankAverage(event.target.value);
                                    }}
                                >
                                    <MenuItem value={65}>61-65</MenuItem>
                                    <MenuItem value={70}>66-70</MenuItem>
                                    <MenuItem value={75}>71-75</MenuItem>
                                    <MenuItem value={80}>76-80</MenuItem>
                                    <MenuItem value={85}>81-85</MenuItem>
                                    <MenuItem value={90}>86-90</MenuItem>
                                    <MenuItem value={95}>91-95</MenuItem>
                                    <MenuItem value={100}>96-100</MenuItem>
                                    <MenuItem value={105}>101-105</MenuItem>
                                    <MenuItem value={110}>106-110</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <label className="form-label">% of Herd in Lactation 1</label>
                                <OutlinedInput
                                    id="lactation"
                                    value={props.lactationpercent}
                                    onChange={(event) => {
                                        props.setErrorLactationPercent(false);
                                        if (event.target.value > 100) {
                                            props.setErrorLactationPercent(true);
                                        }
                                        props.setLactationpercent(event.target.value);

                                    }}
                                    className="text-field-root required-style popper-fields"
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                />
                                {props.errorLactationPercent ? <div className="error">Please enter a value between 0-100</div> : null}

                            </Grid>
                            <Grid item className="submit-wrapper">
                                <Button variant="outlined" style={{ marginTop: "25px" }} className="button submit-button" onClick={() => props.saveAllBreeds(null)}>CALCULATE &amp; APPLY</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {importGoal()}
            </div>
        }
    }

    const sourceSelection = () => {
        if (props.breedSelected) {
            return <React.Fragment>
                <Grid container spacing={2} className="toggle-control-input-wrapper">
                    <Grid item xs={1} className="">
                        <span className="addherd_container_span">
                            <Switch onChange={((event) => {
                                if (event.target.checked) {
                                    props.setGoalCard("custom");
                                } else {
                                    props.setGoalCard("predefined");
                                    props.setclickImport(false);
                                }
                            })}
                                checked={props.goalCard === 'custom'}
                            />
                        </span>
                    </Grid>
                    <Grid item xs={11}>
                        <b>Use Custom Goal Values</b>
                        <br />
                        {props.type === "subherdgoals" ?
                            <React.Fragment>
                                When set to active, this <span style={{ textTransform: "lowercase" }}>{props.breedCard}</span> sub-herd you manage will use custom values as a baseline. Otherwise, it will use the current parent goals.
                            </React.Fragment>
                            :
                            <React.Fragment>
                                When set to active, the {props.breedCard} herd(s) you manage will use custom values as a baseline. Otherwise, they will use the current default goals for {props.breedCard} herds.
                            </React.Fragment>
                        }

                    </Grid>
                </Grid>
            </React.Fragment>
        }
    }

    const predefinedGoal = () => {
        if (props.goalCard === 'predefined') {
            let body = null;
            if (props.type === "usergoals") {
                body = <div>
                    <h3>Default Goals: {props.breedCard}</h3>
                       There are currently no custom user goals being used for {props.breedCard} breeds. Currently, the {props.breedCard} herd(s)
                       you manage will use Default Goals: {props.breedCard} as a baseline.
                     </div>
            } else {
                body = <div>
                    <h3>Parent Goals</h3>
                        This sub-herd will use parent goals.<br />
                        If you have User Goals defined for the breed(s) in this sub-herd, those goals will be used in reporting. If you do not have User Goals defined, then Default Goals will be used.
                     </div>
            }
            return <div className="goal-value-wrapper card-sub-body">
                {body}
            </div>
        }
    }

    let preview = null;

    if (props.type === "usergoals" && props.goalCard === "predefined") {
        preview = <div>
            <Alert severity="info" color="warning" variant="outlined">
                <span style={{ cursor: "pointer", color: "#e87722" }}
                    onClick={props.showpreviewfunction}
                >   Preview the current default goals for {props.breedCard}
                </span>
            </Alert>
        </div>
    }

    return (
        <React.Fragment>
            {/* START: Psuedo Tabs from previous breed buttons */}
            {
                props.type !== "subherdgoals" ? <React.Fragment>
                    {breedTabs()}
                </React.Fragment> : null
            }
            {/* END: Psuedo Tabs from previous breed buttons */}

            <section className="content-container compact-bottom">
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <h3>Setting Goals</h3>
                        <div>
                            <p>Production goals can be quickly calculated based on a target bulk tank average and target proportion of first lactation animals in the herd. Values for each breed can be customized from global default goal settings at the user level or for each individual sub-herd. User goals for each breed will be applied to all sub-herds for that breed. Sub-herd goals can be individually customized or reset to any parent set of goals available. Goals may be turned off for any parameter.</p>
                            <p>
                                <u><b>Attention Flags and Threshold Levels</b></u> <span className="expand-link" onClick={() => { props.setDisplayMore(!props.displayMore) }}><u>{props.displayMore ? 'less' : 'more'}</u></span>
                            </p>
                            {props.displayMore ?
                                <div>
                                    <p>
                                        Attention flags work in conjunction with the herd goal so that when current performance in a given parameter falls outside of an “Initial Threshold” level, a single black flag is placed beside the goal for that parameter.  If the current performance falls outside of a “Critical Threshold” level, three black flags will appear in that position.  The “initial” and “critical” threshold levels are percentages of the values for a given parameter.  For example, if you want to be initially “flagged” when current milk production falls 7.5% below the herd goal, you would set the initial threshold level for milk production (Test Day Milk or Latest Milk) at 7.5, likewise, if you want the critical flag to print when production falls 15% or more below the goal, you would use 15 for the critical threshold level.  By adjusting these levels for each parameter, the report can become fairly “sensitive” to changes in key management areas, and can quickly draw attention to them.  Most
                                        of these threshold levels have been preset for each parameter and should work fairly well.  Some may need a bit of ‘tweaking” depending on your particular situation.
                                </p>
                                    <ul className="goals-list-text">
                                        In addition, the “function” of each attention flag can be changed, depending on when you want the flags to print.  For example, with SCC, you would want the flags to print when current performance exceeds the goal by a given percent.  The four possible functions available are designated by a specific character in the “Flag function” field:
                                    <li className="ml-2">- “Lower” triggers the flag when performance is less than the goal.</li>
                                        <li className="ml-2">- “Upper” triggers the flag when performance is greater then the goal.</li>
                                        <li className="ml-2">- “Both” triggers the flag when performance is either less than or greater than the goal.</li>
                                        <li className="ml-2">- “None” deactivates the flag for that parameter.</li>
                                    </ul>
                                    <p>
                                        When a previous report is viewed, the attention flags operate from the selected date, not current performance for the most recent data.
                                </p>
                                </div> : null}
                        </div>

                        {preview}
                    </Grid>
                </Grid>

                {props.errorMessage.length ? <p className="error">{props.errorMessage}</p> : null}
                <Grid container className="page-controls compact-bottom remove-negative-margins" spacing={2}>
                    <Grid item xs={12}>
                        {props.type === "globalgoals" ? <React.Fragment>
                            <h4>Calculate Goal Values for This {props.breedCard} Sub-Herd</h4>
                            <p style={{ margin: ".75rem 0 .5rem" }}>Tank Average Goal & Percentage (%) of Herd in Lactation 1 are used to calculate baseline goals for a herd. </p>
                            <p style={{ margin: ".75rem 0 1.25rem" }}>Simply update these 2 values, then click CALCULATE & APPLY to run the calculation and update the goals in the tables below. Once all the goals have been set, click SAVE CHANGES to keep your changes. </p>
                        </React.Fragment> : null}
                        {props.type !== "globalgoals" ? <React.Fragment>
                            <div className="card-section">
                                <div className="card-body">
                                    {sourceSelection()}
                                </div>
                                {predefinedGoal()}
                                {customGoal()}
                            </div>
                        </React.Fragment> : <React.Fragment>
                                {renderTankAndLactation()}
                                <div className="goal-value-wrapper card-sub-body">{importGoal()}</div>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </section>
            {
                props.goalCard === "predefined" ?
                    <div style={{ textAlign: "left", marginBottom: "10%" }}>
                        <Button
                            variant="contained"
                            className="button"
                            size="large"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                props.savePredefinedMethod();
                            }}>
                            Save Changes
            </Button>
                    </div> : null
            }
        </React.Fragment>
    )
}

export default GoalHeader;

