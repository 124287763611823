import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

function TwoButtonDialog(props) {
    let dialogStatus = false;
    if (props?.open) {
        dialogStatus = props.open;
    }

    let body = <Dialog
        onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={dialogStatus} className="two-button-dialog">
        <DialogContent className="dialog-content">
            <DialogContentText>
                {props.content}
            </DialogContentText>
            <IconButton aria-label="close" onClick={props.handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogContent>
        <DialogActions>
            <Grid container>
                <Grid item xs={6} style={{ textAlign: "end" }}>
                    <Button variant="contained"
                        className="button"
                        type="button"
                        onClick={props.handleActionBtn1}> {props.btnText1} </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "start", paddingLeft: "2%" }}>
                    <Button variant="contained"
                        className="button twodialogcancel"
                        color="disabled"
                        type="button"
                        onClick={props.handleActionBtn2}>{props.btnText2}</Button>
                </Grid>
            </Grid>

        </DialogActions>
    </Dialog>

    if (props.subcontent !== undefined) {
        body = <Dialog
            onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={dialogStatus} className="two-button-dialog">
            <DialogContent className="dialog-content">
                <DialogContentText>
                    <span style={{ color: "#e87722", fontWeight: "bold" }}>{props.content}</span><br />
                    <span>{props.subcontent}</span>
                </DialogContentText>
                <IconButton aria-label="close" onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogContent>
        </Dialog>
    }

    return (
        <React.Fragment>
            {body}
        </React.Fragment>
    );
}

export default TwoButtonDialog;