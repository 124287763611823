// material import
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    Grid,
    InputLabel,
    Select,
    TextField,
} from '@material-ui/core/';
import Autocomplete from '@mui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { SNACKBAR_TYPE } from '../../../common/constants/constants';
// Userdefined component import
import Loader from '../../../common/dialogs/loader/loader';
import TwoButtonDialog from "../../../common/dialogs/two-button-dialog/two-button-dialog";
// Backend Api import
import { getHerdForUser } from '../../../common/services/herd.service';
import { uploadFile } from '../../../common/services/uploadService';
import { caseInsensitiveSortData, checkFileType, convertDataToFormData, handleError } from '../../../common/utils/utility';
import PageHeader from '../../../components/pageHeader/pageHeader';
import UploadSuccess from './uploadSuccess';
import { decodeApiResponse } from '../../../common/utils/utility';
import UploadFileOnServer from '../../../components/Reusable/UploadFIleOnServer';

const UploadData = () => {
    const history = useHistory();
    const paramsURL = new URLSearchParams(history.location.search);
    const selectedHerd_id = paramsURL.get("herd_id") ? paramsURL.get("herd_id") : null;

    const [enableLoader, setEnableLoader] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loaderstatus, setloaderstatus] = useState(false);
    const [isDialogOpen, setDialogStatus] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [validError, setValidError] = useState(false);
    const [permissionerror, closepermissionerror] = useState(false);
    const [noHerdMsg, setnoHerdMsg] = useState(null);
    const [backenderror, setbackenderror] = useState(null);
    const [showerrordetail, setshowerrordetail] = useState(false);

    const [herdsList, setHerdsList] = useState([]);
    const [nonDRMSHerdsList, setNonDRMSHerdsList] = useState([]);
    const [subHerds, setSubHerds] = useState({ allSubHerds: [], selectedSubHerds: '' });
    const [selectedHerd, setSelectedHerd] = useState({ diaryName: '', herdId: '', selectedHerd: {}, renderdata: false });
    const [subHerdSelected, setSubHerdSelected] = useState({ subHerdId: '', subHerdSelected: '', subherdError: false });


    const [herddata, setherddata] = useState(null);
    const [subherddata, setsubherddata] = useState(null);

    const [pcDartData, setPcDartData] = useState({ enablePCDART: false, pcDartDate: new Date(), uploadFileType: {}, herdError: false, subherdError: false, fileTypeError: false, fileType: '' });

    const [dc305Data, setDc305Data] = useState({ enableDC305: false, pcDartDate: new Date(), uploadFileType_Weekly: {}, uploadFileType_FileOut: {}, uploadFileType_EventOut: {}, herdError: false, subherdError: false, fileType_Weekly_Error: false, fileType_FileOut_Error: false, fileType_EventOut_Error: false, fileType_Weekly: '', fileType_FileOut: '', fileType_EventOut: '' });
    const [dplanData, setDplanData] = useState({ enableDPLAN: false, pcDartDate: new Date(), uploadFileType_HerdName: {}, uploadFileType_Weekly: {}, herdError: false, subherdError: false, fileType_HerdName_Error: false, fileType_Weekly_Error: false, fileType_HerdName: '', fileType_Weekly: '' });

    const [selectedHerdId, setSelectedHerdId] = useState(selectedHerd_id !== null ? selectedHerd_id : '');
    const [herdChangeCounter, setHerdChangeCounter] = useState(0);
    
    const admincheckresponse = useSelector(
        (state) => state.admin.usertype
    );

    const filteractivesubherd = (subherdarray) => {
        if (subherdarray) {
            return subherdarray.filter((d) => d.active);
        } else {
            return [];
        }
    }

    useEffect(() => {
        // update the selectedHerdId when selectedHerd_id changes
        setSelectedHerdId(selectedHerd_id !== null ? selectedHerd_id : '');
    }, [selectedHerd_id]);

    useEffect(() => {
        setloaderstatus(true);
        setEnableLoader(false);
        getHerdForUser(admincheckresponse).then((response) => {
            const decodedResponse = decodeApiResponse(response);
            if (response.length > 0) {
                let temp = [];
                let j = 0;
                let flag = false;
                if (admincheckresponse === "Administrator") {
                    flag = true;
                }
                let herdlistdata = decodedResponse;
                for (let i = herdlistdata.length - 1; i >= 0; i--) {
                    let data = herdlistdata[i];
                    if (data?.AccessDetails?.AccessDetails) {
                        temp[j++] = {
                            ...herdlistdata[i],
                            AccessDetails: data.AccessDetails.AccessDetails,
                            UserEmail: data.UserEmail ? data.UserEmail : ""
                        };
                    } else {
                        temp[j++] = {
                            ...herdlistdata[i],
                            UserEmail: data.UserEmail ? data.UserEmail : ""
                        };
                    }
                }

                if (flag) {
                    temp = temp.filter((d) => {
                        return d?.status !== "for_deletion";
                    })
                } else {
                    temp = temp.filter((d) => {
                        return d?.status !== "for_deletion" && d.UserEmail !== "" && (d.HerdAssignments.length !== 0);
                    })
                }
                sortHerds(temp);
                setloaderstatus(false);
            } else {
                setnoHerdMsg("No Herds Available")
                setloaderstatus(false);
            }
        }, error => {
            setloaderstatus(false);
            if (error.response.status === 404) {
                setnoHerdMsg("No Herds Available")
            } else {
                enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                setnoHerdMsg(handleError(error));
            }

        });
    }, [enqueueSnackbar, admincheckresponse]);

    useEffect(() => {
        if (selectedHerd.herdId && selectedHerd.renderdata) {
            if (selectedHerd?.selectedHerd?.sub_herds.length) {

                if (!selectedHerd.selectedHerd.software_split_report) {
                    let defaultsubherd = {};
                    selectedHerd.selectedHerd.sub_herds.forEach((d) => {
                        if (d.default) {
                            defaultsubherd = d;
                        }
                    })
                    if (defaultsubherd !== null) {
                        setSubHerdSelected({
                            subHerdId: defaultsubherd.sub_herd_id, subherdError: false,
                            subHerdSelected: defaultsubherd.description
                        })
                        setsubherddata({
                            ...defaultsubherd,
                            subHerdId: defaultsubherd.sub_herd_id, subherdError: false,
                            subHerdSelected: defaultsubherd.description,
                            description: defaultsubherd.description
                        })
                    }
                }
                setSubHerds({ allSubHerds: filteractivesubherd(selectedHerd?.selectedHerd?.sub_herds), selectedSubHerds: {} });
            } else {
                setSubHerds({ allSubHerds: [], selectedSubHerds: '' });
                enqueueSnackbar('No Sub Herd found', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            }
        }
    }, [selectedHerd, enqueueSnackbar])

    // removing DRMS herds in the dropdown
    useEffect(() => {
        const nonDRMRS = herdsList.filter((herds) => herds.software_herd_software !== "DRMS");
        setNonDRMSHerdsList(nonDRMRS)
    }, [herdsList])


    const sortHerds = (herdlist) => {
        const params = new URLSearchParams(history.location.search);
        if (params.get("herd_id")) {
            let herds = herdlist.find((d) => d.herd_id === params.get("herd_id"));

            if (params.get("subherd_id")) {
                let subherdData = herds.sub_herds.find((d) => d.sub_herd_id === params.get("subherd_id"));

                subherdData = { subHerdId: subherdData.sub_herd_id, subherdError: false, subHerdSelected: subherdData.description, description: subherdData.description }
                let selectedherddata = { ...herds, diaryName: herds.dairy_name, herdId: herds.herd_id, selectedHerd: herds, ...herds, renderdata: false }
                setSubHerds({ allSubHerds: filteractivesubherd(selectedherddata?.selectedHerd?.sub_herds), selectedSubHerds: {} });
                setSelectedHerd(selectedherddata)
                setherddata(herds);
                setsubherddata(subherdData);

                caseInsensitiveSortData(herdlist, 'dairy_name');
                setHerdsList(herdlist);
                setSubHerdSelected(subherdData)
            } else {
                let selectedherddata = { ...herds, diaryName: herds.dairy_name, herdId: herds.herd_id, selectedHerd: herds, ...herds, renderdata: false }
                setSelectedHerd(selectedherddata)
                setherddata(herds);

                caseInsensitiveSortData(herdlist, 'dairy_name');
                setHerdsList(herdlist);
                setSubHerdSelected({ ...subHerdSelected, subHerdId: '', subHerdSelected: '' })
            }
        } else {
            caseInsensitiveSortData(herdlist, 'dairy_name');
            setHerdsList(herdlist);
            setSubHerdSelected({ ...subHerdSelected, subHerdId: '', subHerdSelected: '' })
        }
    }

    const handleChangeHerd = (herd_id) => {
        setSelectedHerdId(herd_id)
        let selectedherddata = {};
        let herd = {};
        let flag = true;
        let adminflag = false;

        if (admincheckresponse === "Administrator") {
            adminflag = true;
        }

        nonDRMSHerdsList.forEach(item => {
            if (item.herd_id === herd_id) {
                if (["owner", "admin", "contributor"].includes(item?.AccessDetails?.access) || adminflag) {
                    selectedherddata = { diaryName: item.dairy_name, herdId: item.herd_id, selectedHerd: item, ...item, renderdata: true }

                    herd = { diaryName: item.dairy_name, herdId: item.herd_id, selectedHerd: item, ...item, renderdata: true }
                    return;
                } else {
                    flag = false;
                    return;
                }
            }
        })

        if (flag) {
            setSubHerdSelected({ ...subHerdSelected, subHerdId: '', subHerdSelected: '' })
            setValidError(false);
            delete herd?.selectedHerd;
            setSelectedHerd(selectedherddata);
            setHerdChangeCounter((prevCounter) => prevCounter + 1);
            setPcDartData({ enablePCDART: false, pcDartDate: new Date(), uploadFileType: {}, herdError: false, subherdError: false, fileTypeError: false, fileType: '' });
            setDc305Data({ enableDC305: false, pcDartDate: new Date(), uploadFileType_Weekly: {}, uploadFileType_FileOut: {}, uploadFileType_EventOut: {}, herdError: false, subherdError: false, fileType_Weekly_Error: false, fileType_FileOut_Error: false, fileType_EventOut_Error: false, fileType_Weekly: '', fileType_FileOut: '', fileType_EventOut: '' });
            setDplanData({ enableDPLAN: false, pcDartDate: new Date(), uploadFileType_HerdName: {}, uploadFileType_Weekly: {}, herdError: false, subherdError: false, fileType_HerdName_Error: false, fileType_Weekly_Error: false, fileType_HerdName: '', fileType_Weekly: '' });
            setherddata({
                report: true,
                ...herd
            })
        } else {
            setValidError(false);
            setSubHerdSelected({ subHerdId: '', subHerdSelected: '', subherdError: false });
            setSelectedHerd({ diaryName: '', herdId: '', selectedHerd: {}, renderdata: false });
            closepermissionerror(true);
        }
    };

    /**
     * Dropdown to select type of herd.
     */

    let permissionerrormessage = "You do not have permission to upload files for the selected Herd. "
    const selectHerd = () => {
        return (
            <React.Fragment>
                <label className="form-label top-border">STEP 1 - Herd</label>
                <FormControl variant="outlined" className="drop-down-root" >
                    {!nonDRMSHerdsList.length &&
                        <Loader radius={20} type="small" />
                    }
                    <Autocomplete
                        key="selectHerd"
                        id="herd_name"
                        defaultValue=""
                        value={nonDRMSHerdsList.find(herd => herd.herd_id === selectedHerdId) || null}
                        className="autocomplete-root"
                        options={nonDRMSHerdsList}
                        getOptionLabel={(option) => option.dairy_name}
                        renderInput={(params) => <TextField {...params} label="Search By Keyword" variant="outlined" />}
                        onChange={(event, selectedOption) => {
                            if (selectedOption) {
                                handleChangeHerd(selectedOption.herd_id);
                            } else {
                                setSelectedHerdId(selectedHerd_id !== null ? selectedHerd_id : '');
                                setSubHerdSelected({ subHerdId: '', subHerdSelected: '', subherdError: false })
                                setSubHerds({allSubHerds: [], selectedSubHerds: '' });
                                setSelectedHerd({ diaryName: '', herdId: '', selectedHerd: {}, renderdata: false })
                            }
                        }}
                    />
                    {selectedHerd.herdError && <label className="error-msg">Please select herd</label>}
                </FormControl>
            </React.Fragment>
        )
    }

    /**
     * Dropdown to select type of subherd.
     */

    const selectSubHerd = () => {
        return (
            <React.Fragment>
                <label className="form-label top-border">STEP 2 - Sub-Herd</label>
                <FormControl variant="outlined" className="drop-down-root" >
                    <InputLabel htmlFor="outlined-age-native-simple">Select Sub Herd</InputLabel>
                    <Select
                        native
                        disabled={!subHerds?.allSubHerds.length}
                        label="Select Sub Herd"
                        error={subHerdSelected.subherdError}
                        value={subHerdSelected.subHerdSelected}
                        onChange={handleChangeSubHerd}
                    >
                        <option key={100} value="" disabled>

                        </option>
                        {subHerds?.allSubHerds.map((item, i) => {
                            return <option key={i} value={item.description}>{item.description} </option>
                        })}
                    </Select>
                    {subHerdSelected.subherdError && <label className="error-msg">Please select Sub Herd</label>}
                </FormControl></React.Fragment>)

    }

    const handleChangeSubHerd = (event) => {
        subHerds.allSubHerds.forEach((subHerditem) => {
            if (subHerditem.description === event.target.value) {
                setSubHerdSelected({
                    ...subHerdSelected, subHerdId: subHerditem.sub_herd_id, subherdError: false,
                    subHerdSelected: event.target.value
                })

                setsubherddata({
                    ...subHerditem,
                    ...subHerdSelected, subHerdId: subHerditem.sub_herd_id, subherdError: false,
                    subHerdSelected: event.target.value,
                    description: event.target.value
                })

            }
        })
    };

    /**
    * Render Upload button UI.
    */
    const renderBrowseLayout = (labelText, sourceType, uploadTypes, extension) => {
        return <div className="upload-file">
            <div className="upload-heading">{labelText}</div>
            <div className="browse-btn" >
                <input type="file" id={labelText} accept={extension} onChange={(event) => {
                    if (selectedHerd.selectedHerd.software_herd_software === "PCDART") {
                        setPcDartData({ ...pcDartData, uploadFileType: event.target.files[0], fileTypeError: false });
                    }
                    if (selectedHerd.selectedHerd.software_herd_software === "DC305") {
                        switch (uploadTypes) {
                            case 'WEEKLY_PRN':
                                setDc305Data({ ...dc305Data, uploadFileType_Weekly: event.target.files[0], fileType_Weekly: false });
                                break;

                            case 'FILE_OUT_PRN':
                                setDc305Data({ ...dc305Data, uploadFileType_FileOut: event.target.files[0], fileType_FileOut: false })
                                break;

                            case 'EVENTS_OUT':
                                setDc305Data({ ...dc305Data, uploadFileType_EventOut: event.target.files[0], fileType_EventOut: false })
                                break;
                            default:
                                console.log("default");

                        }
                    }
                    if (selectedHerd.selectedHerd.software_herd_software === "DPLAN") {
                        switch (uploadTypes) {
                            case 'HERD_NAME':
                                setDplanData({ ...dplanData, uploadFileType_HerdName: event.target.files[0], fileType_HerdName_Error: false })
                                break;

                            case 'WEEKLY_PRN':
                                setDplanData({ ...dplanData, uploadFileType_Weekly: event.target.files[0], fileType_Weekly_Error: false })
                                break;
                            default:
                                console.log("default");
                        }
                    }
                }} />
                <label htmlFor={labelText} >
                    <i className="ag-icon ag-icon-folder-upload"></i>
                </label>
                <span>Select File</span>
            </div>
        </div>
    }

    /**
    * Render PCDART UI on click of it.
    */
    const renderPCDART = () => {
        if (selectedHerd.selectedHerd.software_herd_software === "PCDART") {
            return <section>
                <label className="form-label top-border">STEP 3 - Upload Data from {selectedHerd.selectedHerd.software_herd_software}</label>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className="upload-file-card">{renderBrowseLayout('Upload File (herdcode.csv)', pcDartData, '', '.csv,.CSV')}</div>
                        {pcDartData.fileTypeError && <p className="error-msg">{pcDartData.fileType ? 'Please choose correct file format' : 'Please select a file'}</p>}
                    </Grid>
                    <Grid item xs={12}>
                        {validError ? <p className="invalid-msg error-msg">{validError}</p> : ''}
                        {
                            backenderror !== null ? <React.Fragment>
                                <button style={{ border: "none", cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => {
                                    setshowerrordetail(!showerrordetail)
                                }}> Click here to see more details</button><br /><br />
                                <span className="error-msg">{showerrordetail ? backenderror : ''}</span>
                            </React.Fragment> : ''
                        }
                    </Grid>
                </Grid>
                <Grid container className="button-submit-container">
                    <Grid item xs={12} className="button-submit-container-left">
                        <UploadFileOnServer 
                            data={pcDartData}
                            dataFor="pcdart"
                            setloaderstatus={setloaderstatus}
                            setValidError={setValidError}
                            setbackenderror={setbackenderror}
                            detail={selectedHerd}
                            setUploadSuccess={setUploadSuccess}
                            setDialogStatus={setDialogStatus}
                            setPcDartData={setPcDartData}
                            enableLoader={enableLoader}
                            description="Upload Data"
                            validateInput={validateInput}
                            createPostObj={createPostObj}
                            uploadResponse={uploadResponse}
                            errorResponse={errorResponse}
                        />
                    </Grid>
                </Grid>




            </section>
        }
    }

    /**
    * Render DC305 UI on click of it.
    */
    const renderDC305 = () => {
        if (selectedHerd.selectedHerd.software_herd_software === "DC305") {
            return <section>
                <label className="form-label top-border">STEP 3 - Upload Data from {selectedHerd.selectedHerd.software_herd_software}</label>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className="upload-file-card">{renderBrowseLayout('Upload File (weekly.prn)', dc305Data, 'WEEKLY_PRN', '.prn,.PRN')}</div>
                        {dc305Data.fileType_Weekly_Error && <p className="error-msg">{dc305Data.fileType_Weekly ? 'Please choose correct file format' : 'Please select a file'}</p>}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className="upload-file-card">{renderBrowseLayout('Upload File (fileout1.prn)', dc305Data, 'FILE_OUT_PRN', '.prn,.PRN')}</div>
                        {dc305Data.fileType_FileOut_Error && <p className="error-msg">{dc305Data.fileType_FileOut ? 'Please choose correct file format' : 'Please select a file'}</p>}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className="upload-file-card">{renderBrowseLayout('Upload File (events.out)', dc305Data, 'EVENTS_OUT', '.out,.OUT')}</div>
                        {dc305Data.fileType_EventOut_Error && <p className="error-msg">{dc305Data.fileType_EventOut ? 'Please choose correct file format' : 'Please select a file'}</p>}
                    </Grid>
                    <Grid item xs={12}>
                        {validError ? <p className="invalid-msg error-msg">{validError}</p> : ''}
                        {
                            backenderror !== null ? <React.Fragment>
                                <button style={{ border: "none", cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => {
                                    setshowerrordetail(!showerrordetail)
                                }}> Click here to see more details</button><br /><br />
                                <span className="error-msg">{showerrordetail ? backenderror : ''}</span>
                            </React.Fragment> : ''
                        }
                    </Grid>
                </Grid>


                <Grid container className="button-submit-container">
                    <Grid item xs={12} className="button-submit-container-left">
                        <UploadFileOnServer 
                            data={dc305Data}
                            dataFor="dc305"
                            setloaderstatus={setloaderstatus}
                            setValidError={setValidError}
                            setbackenderror={setbackenderror}
                            detail={selectedHerd}
                            setUploadSuccess={setUploadSuccess}
                            setDialogStatus={setDialogStatus}
                            setDc305Data={setDc305Data}
                            enableLoader={enableLoader}
                            description="Upload Data"
                            validateInput={validateInput}
                            createPostObj={createPostObj}
                            uploadResponse={uploadResponse}
                            errorResponse={errorResponse}
                        />
                    </Grid>
                </Grid>
            </section>
        }
    }

    /**
     * Render Dlpan UI on click of it.
     */
    const renderDPLAN = () => {
        let dailyweeklymilkflag = herddata?.parlor_daily_milk_weights;
        if (selectedHerd.selectedHerd.software_herd_software === "DPLAN") {
            return <section>
                <label className="form-label top-border">STEP 3 - Upload Data from {selectedHerd.selectedHerd.software_herd_software}</label>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className="upload-file-card">{renderBrowseLayout('Upload File (herdname.prn)', dplanData, 'HERD_NAME', '.prn,.PRN')}</div>
                        {dplanData.fileType_HerdName_Error && <p className="error-msg">{dplanData.fileType_HerdName ? 'Please choose correct file format' : 'Please select a file'}</p>}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {
                            dailyweeklymilkflag ? <React.Fragment>
                                <div className="upload-file-card">{renderBrowseLayout('Upload File (weekly.prn)', dplanData, 'WEEKLY_PRN', '.prn,.PRN')}</div>
                                {dplanData.fileType_Weekly_Error && <p className="error-msg">{dplanData.fileType_Weekly ? 'Please choose correct file format' : 'Please select a file'}</p>}
                            </React.Fragment> : null
                        }

                    </Grid>
                    <Grid item xs={12}>
                        {validError ? <p className="invalid-msg error-msg">{validError}
                            {
                                backenderror !== null ? <React.Fragment>
                                    <button style={{ border: "none", cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => {
                                        setshowerrordetail(!showerrordetail)
                                    }}> Click here to see more details</button><br /><br />
                                    <span className="error-msg">{showerrordetail ? backenderror : ''}</span>
                                </React.Fragment> : ''
                            }</p> : ''}
                    </Grid>
                </Grid>

                <Grid container className="button-submit-container">
                    <Grid item xs={12} className="button-submit-container-left">
                        <UploadFileOnServer 
                            data={dplanData}
                            dataFor="dplan"
                            setloaderstatus={setloaderstatus}
                            setValidError={setValidError}
                            setbackenderror={setbackenderror}
                            detail={selectedHerd}
                            setUploadSuccess={setUploadSuccess}
                            setDialogStatus={setDialogStatus}
                            setDplanData={setDplanData}
                            enableLoader={enableLoader}
                            description="Upload Data"
                            validateInput={validateInput}
                            createPostObj={createPostObj}
                            uploadResponse={uploadResponse}
                            errorResponse={errorResponse}
                        />
                    </Grid>
                </Grid>
            </section>
        }
    }

    const closeDeleteDialog = () => {
        setPcDartData({ enablePCDART: false, pcDartDate: new Date(), uploadFileType: {}, herdError: false, subherdError: false, fileTypeError: false, fileType: '' });
        setDc305Data({ enableDC305: false, pcDartDate: new Date(), uploadFileType_Weekly: {}, uploadFileType_FileOut: {}, uploadFileType_EventOut: {}, herdError: false, subherdError: false, fileType_Weekly_Error: false, fileType_FileOut_Error: false, fileType_EventOut_Error: false, fileType_Weekly: '', fileType_FileOut: '', fileType_EventOut: '' });
        setDplanData({ enableDPLAN: false, pcDartDate: new Date(), uploadFileType_HerdName: {}, uploadFileType_Weekly: {}, herdError: false, subherdError: false, fileType_HerdName_Error: false, fileType_Weekly_Error: false, fileType_HerdName: '', fileType_Weekly: '' });
        setDialogStatus(false);
    };

    const yesUploadChanges = (pcDartData, dc305Data, dplanData) => {
        var formData;
        if (selectedHerd.selectedHerd.software_herd_software === "PCDART") {
            formData = convertDataToFormData(createPostObj(pcDartData, true));
        }
        if (selectedHerd.selectedHerd.software_herd_software === "DC305") {
            formData = convertDataToFormData(createPostObj(dc305Data, true));
        }
        if (selectedHerd.selectedHerd.software_herd_software === "DPLAN") {
            formData = convertDataToFormData(createPostObj(dplanData, true));
        }

        setloaderstatus(true)
        uploadFile(formData).then(response => {
            setloaderstatus(false)
            setUploadSuccess(true);
            setDialogStatus(false);
        }, error => {
            setloaderstatus(false);
        });
    }

    const renderYesDialog = () => {
        return (
            <TwoButtonDialog
                content={"Are you sure you want to replace the file?"}
                open={isDialogOpen}
                handleClose={closeDeleteDialog}
                btnText1="Yes"
                btnText2="No"
                handleActionBtn1={() => {
                    yesUploadChanges(pcDartData, dc305Data, dplanData)
                }}
                handleActionBtn2={() => {
                    closeDeleteDialog();
                }}
            />
        );
    };

    /**
     * Validation
     */

    const validateInput = (sourceType) => {
        if (selectedHerd.selectedHerd.software_herd_software === "PCDART") {
            const fileExtention = checkFileType(sourceType.uploadFileType.name);
            if (selectedHerd.herdId === '') {
                setSelectedHerd(prevState => ({
                    ...prevState,
                    herdError: true
                }));
                return false;
            }

            if (subHerdSelected.subHerdSelected === '') {
                setSubHerdSelected(prevState => ({
                    ...prevState,
                    subherdError: true
                }));
                return false;
            }

            if (fileExtention === 'csv' || fileExtention === 'CSV') {
                setPcDartData(prevState => ({
                    ...prevState,
                    fileTypeError: false
                }));
                return true;
            }
            else {
                setPcDartData(prevState => ({
                    ...prevState,
                    fileTypeError: true,
                    fileType: fileExtention
                }));
            }

        }

        if (selectedHerd.selectedHerd.software_herd_software === "DC305") {
            const fileExtention1 = checkFileType(sourceType.uploadFileType_FileOut.name);
            const fileExtention2 = checkFileType(sourceType.uploadFileType_EventOut.name);
            const fileExtention3 = checkFileType(sourceType.uploadFileType_Weekly.name);
            if (selectedHerd.herdId === '') {
                setSelectedHerd(prevState => ({
                    ...prevState,
                    herdError: true
                }));
                return false;
            }
            if (subHerdSelected.subHerdSelected === '') {
                setSubHerdSelected(prevState => ({
                    ...prevState,
                    subherdError: true
                }));
                return false;
            }

            if (fileExtention3 === 'prn' || fileExtention3 === 'PRN') {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: false
                }));
            }
            else {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: true,
                    fileType_Weekly: fileExtention3
                }));
                return false;
            }

            if (fileExtention1 === 'prn' || fileExtention1 === 'PRN') {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_FileOut_Error: false
                }));
            }
            else {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_FileOut_Error: true,
                    fileType_FileOut: fileExtention1
                }));
                return false;
            }


            if (fileExtention2 === 'out' || fileExtention2 === 'OUT') {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_EventOut_Error: false
                }));
            }
            else {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_EventOut_Error: true,
                    fileType_EventOut: fileExtention2
                }));
                return false;
            }

            return true;
        }

        if (selectedHerd.selectedHerd.software_herd_software === "DPLAN") {
            const fileExtention1 = checkFileType(sourceType.uploadFileType_HerdName.name);
            const fileExtention2 = checkFileType(sourceType.uploadFileType_Weekly.name);
            if (selectedHerd.herdId === '') {
                setSelectedHerd(prevState => ({
                    ...prevState,
                    herdError: true
                }));
                return false;
            }

            if (subHerdSelected.subHerdSelected === '') {
                setSubHerdSelected(prevState => ({
                    ...prevState,
                    subherdError: true
                }));
                return false;
            }

            if (fileExtention1 === 'prn' || fileExtention1 === 'PRN') {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_HerdName_Error: false
                }));
            } else {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_HerdName_Error: true,
                    fileType_HerdName: fileExtention1
                }));
                return false;
            }

            let dailyweeklymilkflag = herddata?.parlor_daily_milk_weights;

            if (dailyweeklymilkflag === false || (fileExtention2 === 'prn' || fileExtention2 === 'PRN')) {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: false
                }));
            } else {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: true,
                    fileType_Weekly: fileExtention2
                }));
                return false;
            }
            return true;
        }
    }


    /**
     * Upload file on server.
     * @param {*} sourceType : pcDart, dc305, dplan 
     */
    const uploadResponse = (sourceType) => {
        setloaderstatus(false)
        setUploadSuccess(true);
        setPcDartData({ enablePCDART: false, pcDartDate: new Date(), uploadFileType: {}, herdError: false, subherdError: false, fileTypeError: false, fileType: '' });
        setDc305Data({ enableDC305: false, pcDartDate: new Date(), uploadFileType_Weekly: {}, uploadFileType_FileOut: {}, uploadFileType_EventOut: {}, herdError: false, subherdError: false, fileType_Weekly_Error: false, fileType_FileOut_Error: false, fileType_EventOut_Error: false, fileType_Weekly: '', fileType_FileOut: '', fileType_EventOut: '' });
        setDplanData({ enableDPLAN: false, pcDartDate: new Date(), uploadFileType_HerdName: {}, uploadFileType_Weekly: {}, herdError: false, subherdError: false, fileType_HerdName_Error: false, fileType_Weekly_Error: false, fileType_HerdName: '', fileType_Weekly: '' });
    }

    const errorResponse = (error) => {
        if (error.response.data.includes("The Sub-herd details with same test day already existing")) {
            enqueueSnackbar('The Sub-herd details with same test day already existing', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            setDialogStatus(true);
            renderYesDialog();
        } else if (error.response.status === 500 || error.response.status === 404) {
            enqueueSnackbar('Error occurred while Uploading, Please contact system support team', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            let errorMsg = 'Your ' + selectedHerd.selectedHerd.software_herd_software + ' file version is not supported by the system.  Contact Business System Support if you need further upload assistance and provide the error message in the more details below along with the uploaded file(s).';
            if (selectedHerd.selectedHerd.software_herd_software === "DC305") {
                errorMsg = 'Your ' + selectedHerd.selectedHerd.software_herd_software + ' file version is not supported by the system. Please reupload your files in the designated order: weekly.prn, fileout1.prn, and events.out. Contact Business System Support if you need further upload assistance and provide the error message in the more details below along with the uploaded file(s).';
            }
            setValidError(errorMsg);
            setbackenderror(error.response.data);
        } else {
            enqueueSnackbar('File is invalid, Please Upload a valid file', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            setValidError(error.response.data);
            setbackenderror(error)
        }
    }

    const gotoReportingPage = () => {
        let subherd_id = subherddata.sub_herd_id ? subherddata.sub_herd_id : subherddata.subHerdId;
        history.push('/dashboard/herds/subherd/detail?herd_id=' + herddata.herd_id + "&subherd_id=" + subherd_id);
    }

    /**
     * Create obj as per sourceType ie. pcDart, dc305, dplan.
     * @param {*} sourceType : pcDart, dc305, dplan 
     */
    function createPostObj(sourceType, force) {
        const bodyObj = {};
        if (selectedHerd.selectedHerd.software_herd_software === "PCDART") {
            bodyObj['file_pcdart_herdcode'] = sourceType.uploadFileType;
            bodyObj['sub_herd_id'] = subHerdSelected.subHerdId;
            bodyObj['herd_code'] = selectedHerd.herdId;  //or sourceType.selectedHerd.software_herd_code
            bodyObj['source_system'] = 'PCDART';
            bodyObj['herd_id'] = selectedHerd.herdId;
            bodyObj['upload_time'] = sourceType.pcDartDate.toISOString();
            bodyObj['force'] = force;
            bodyObj['weekly_status'] = herddata?.parlor_daily_milk_weights;
            return bodyObj;
        }
        if (selectedHerd.selectedHerd.software_herd_software === "DC305") {
            bodyObj['file_dc305_weekly'] = sourceType.uploadFileType_Weekly;
            bodyObj['file_dc305_fileout'] = sourceType.uploadFileType_FileOut;
            bodyObj['file_dc305_events'] = sourceType.uploadFileType_EventOut;
            bodyObj['sub_herd_id'] = subHerdSelected.subHerdId;
            bodyObj['herd_code'] = selectedHerd.herdId;  //or sourceType.selectedHerd.software_herd_code
            bodyObj['source_system'] = 'DC305';
            bodyObj['herd_id'] = selectedHerd.herdId;
            bodyObj['upload_time'] = sourceType.pcDartDate.toISOString();
            bodyObj['force'] = force;
            bodyObj['weekly_status'] = herddata?.parlor_daily_milk_weights;
            return bodyObj;
        }
        if (selectedHerd.selectedHerd.software_herd_software === "DPLAN") {
            bodyObj['file_dplan_herdname'] = sourceType.uploadFileType_HerdName;
            bodyObj['file_dplan_weekly'] = sourceType.uploadFileType_Weekly;
            bodyObj['sub_herd_id'] = subHerdSelected.subHerdId;
            bodyObj['herd_code'] = selectedHerd.herdId;  //or sourceType.selectedHerd.software_herd_code
            bodyObj['source_system'] = 'DPLAN';
            bodyObj['herd_id'] = selectedHerd.herdId;
            bodyObj['upload_time'] = sourceType.pcDartDate.toISOString();
            bodyObj['force'] = force;
            bodyObj['weekly_status'] = herddata?.parlor_daily_milk_weights;
            return bodyObj;
        }
    }
    let sourceAppName = selectedHerd.selectedHerd.software_herd_software ? selectedHerd.selectedHerd.software_herd_software : " ";

    return (
        <article>
            <PageHeader h1={"Upload " + sourceAppName + " Data"} />
            <Container maxWidth="lg" className="container-left-align">
                {
                    uploadSuccess ? 
                        <UploadSuccess 
                            gotoReportingPage={gotoReportingPage} 
                            setState={setUploadSuccess} 
                            selectedHerd={selectedHerd} 
                            subHerdSelected={subHerdSelected}
                            software={selectedHerd.selectedHerd.software_herd_software}
                        /> 
                        : <section className="content-container">
                            {
                                loaderstatus ? <Loader radius={50} type="large" /> :
                                    <Grid container spacing={2} className="upload-section">
                                        {loaderstatus ? <Loader radius={50} type="large" /> : null}
                                        {
                                            nonDRMSHerdsList.length > 0 ? <React.Fragment>
                                                <Grid item xs={12} md={6}>
                                                    {selectHerd()}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    {selectSubHerd()}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {renderPCDART()}
                                                    {renderDC305()}
                                                    {renderDPLAN()}
                                                    {renderYesDialog()}
                                                </Grid>
                                            </React.Fragment> : <p className="no_herd_msg">{noHerdMsg}</p>
                                        }
                                    </Grid>
                            }
                            <Dialog
                                onClose={closepermissionerror} aria-labelledby="simple-dialog-title" open={permissionerror} className="two-button-dialog">
                                <DialogContent className="dialog-content">
                                    <DialogContentText>
                                        <span style={{ color: "#e87722", fontWeight: "bold" }}>{permissionerrormessage}</span><br />
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions className="action-btns centeredaction">
                                    <Button size="small" variant="contained" onClick={() => {
                                        closepermissionerror(false)
                                    }}>OK</Button>
                                </DialogActions>
                            </Dialog>
                        </section>
                } </Container></article>

    );
};



export default UploadData;