import {
	ADMIN_CHECK
} from '../actions/actions';
import { updateObject} from '../../common/utils/utility';

const initialState = {
	usertype:""
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ADMIN_CHECK:
			return updateObject(state, {
				usertype: action.payload,
			});
		default:
			return state;
	}
};

export default reducer;
