import { getEnvironment } from '../utils/utility';

export const ENVIRONMENT = {
    DEV: 'DEV',
    QA: 'QA',
    LOCAL: 'LOCAL',
    PROD: 'PROD',
}

export const LOGOUT_REDIRECT_URI = () => {
    const ENV = getEnvironment();
    let url = '';
    if (ENV === ENVIRONMENT.PROD) {
        url = 'https://addieinsights.com';
    } else if (ENV === ENVIRONMENT.QA) {
        url = 'https://dairystage.addieinsights.com';
    } else if (ENV === ENVIRONMENT.DEV) {
        url = 'https://dairydev.addieinsights.com';
    } else {
        url = 'http://localhost:3001';
    }
    return url;
}

export const SSO_BASE_PATH = () => {
    const ENV = getEnvironment();
    let url = '';
    if (ENV === ENVIRONMENT.PROD) {
        url = 'https://sso.landolakesinc.com';
    } else if (ENV === ENVIRONMENT.QA) {
        url = 'https://ssoqa.landolakesinc.com';
    } else if (ENV === ENVIRONMENT.DEV) {
        url = 'https://ssoqa.landolakesinc.com';
    } else {
        url = 'https://ssoqa.landolakesinc.com';
    }
    return url;
}

export const SNACKBAR_TYPE = {
    'ERROR': 'error',
    'WARNING': 'warning',
    'DEFAULT': 'default',
    'SUCCESS': 'success'
}

export const REPORT_TYPE = {
    'BENCHMARK_SNAPSHOT': 'benchmark-report',
    'SNAPSHOT_REPORT': 'snapshot-report',
    'SNAPSIX_REPORT': 'snapsix-report',
    'PERIODCOMPARISON_REPORT': 'periodcomparison-report',
    "CULLINGHISTORY_REPORT": 'culling-report',
    "SNAPSHOTGOAL_REPORT": 'snapshotgoal-report',
    "SUBHERDCOMPARISON_REPORT": 'subherdcomparison-report',
    "HEIFER_REPORT": 'heifer-report'
}

export const primaryfeeddeliverysystemoption = [
    {
        name: "TMR",
        value: "tmr"
    }, {
        name: "PMR",
        value: "pmr"
    }, {
        name: "Top Dress",
        value: "top_dress"
    }, {
        name: "Robots",
        value: "robots"
    }, {
        name: "Grazing",
        value: "grazing"
    }, {
        name: "Parlor Grain",
        value: "parlor_grain"
    }, {
        name: "Other",
        value: "other"
    }
]

export const housingoption = [{
    name: "Free Stalls",
    value: "free_stalls"
}, {
    name: "Dry Lot",
    value: "dry_lot"
}, {
    name: "Pasture",
    value: "pasture"
}, {
    name: "Seasonal Pasture",
    value: "seasonal_pasture"
}, {
    name: "Bedded Pack",
    value: "bedded_pack"
}, {
    name: "Tie Stall",
    value: "tie_stall"
}, {
    name: "Other",
    value: "other"
}]

export const beddingoption = [{
    name: "Sand",
    value: "sand"
}, {
    name: "Mattress",
    value: "mattress"
}, {
    name: "Cow Mats",
    value: "cow_mats"
}, {
    name: "Shavings/Sawdust",
    value: "shaving_sawdust"
}, {
    name: "Straw",
    value: "straw"
}, {
    name: "Paper",
    value: "paper"
}, {
    name: "Composted Manure Solids",
    value: "composted_manure_solids"
}, {
    name: "Pasture",
    value: "pasture"
}, {
    name: "Seasonal Pasture",
    value: "seasonal_pasture"
}, {
    name: "None",
    value: "none"
}, {
    name: "Other",
    value: "other"
}]

export const heatabatementoption = [{
    name: "Fans",
    value: "fans"
}, {
    name: "Sprinklers",
    value: "sprinklers"
}, {
    name: "Shades",
    value: "shades"
}, {
    name: "Ponds",
    value: "ponds"
}, {
    name: "Other",
    value: "other"
}]

export let manuredisposaloptions = [{
    name: "Automatic Scrape",
    value: "automatic_scrape"
}, {
    name: "Tractor Scrape",
    value: "tractor_scrape"
}, {
    name: "Flush System",
    value: "flush_system"
}, {
    name: "Robot",
    value: "robot"
}, {
    name: "Slatted Floor",
    value: "slatted_floor"
}, {
    name: "Other",
    value: "other"
}]

export const breeds = [{
    name: "Crossbred"
}, {
    name: "Holstein"
}, {
    name: "Jersey"
}
    , {
    name: "Other"
}];

export const peergroupbreedsoptions = [{
    name: "Holstein",
    value: "holstein"
}, {
    name: "Jersey",
    value: "jersey"
}, {
    name: "Mixed",
    value: "mixed"
}, {
    name: "Other",
    value: "other"
}]

export const standard_mapping = [{
    name: "S/Standard",
    value: "s_standard"
}, {
    name: "M/Mounting",
    value: "m_mounting"
}, {
    name: "X/Mucus",
    value: "x_mucus"
}, {
    name: "F|Full Kmar",
    value: "f_full_kmar",
}, {
    name: "R|Part Kmar",
    value: "r_part_kmar"
}, {
    name: "O|Ovarian Palpation",
    value: "o_ovarian_palpation"
}, {
    name: "C|Chalk/Paint",
    value: "c_chalk_paint"
}, {
    name: "P|Pedometer",
    value: "p_pedometer"
}, {
    name: "T|Timed AI",
    value: "t_timed_ai"
}, {
    name: "N|Nervousness",
    value: "n_nervousness"
}, {
    name: "E|Edema",
    value: "e_edema"
}, {
    name: "U|Ultrasound",
    value: "u_ultrasound"
}, {
    name: "Y|User def1",
    value: "y_user_def_1"
}, {
    name: "Z|User def 2",
    value: "z_user_def_2"
}, {
    name: "1|Less than 2hr (at Observation)",
    value: "1_less_than_2hr_observation"
}, {
    name: "2|From 2 to 12hrs after Observation",
    value: "2_from_2_to_12_after_observation"
}, {
    name: "3|More than 12 hrs after Observation",
    value: "3_more_than_12_hrs_after_observation"
}]

export let stateoptions = [
    { name: 'AK', value: 'AK' },
    { name: 'AL', value: 'AL' },
    { name: 'AR', value: 'AR' },
    { name: 'AZ', value: 'AZ' },
    { name: 'CA', value: 'CA' },
    { name: 'CO', value: 'CO' },
    { name: 'CT', value: 'CT' },
    { name: 'DC', value: 'DC' },
    { name: 'DE', value: 'DE' },
    { name: 'FL', value: 'FL' },
    { name: 'GA', value: 'GA' },
    { name: 'HI', value: 'HI' },
    { name: 'IA', value: 'IA' },
    { name: 'ID', value: 'ID' },
    { name: 'IL', value: 'IL' },
    { name: 'IN', value: 'IN' },
    { name: 'KS', value: 'KS' },
    { name: 'KY', value: 'KY' },
    { name: 'LA', value: 'LA' },
    { name: 'MA', value: 'MA' },
    { name: 'MD', value: 'MD' },
    { name: 'ME', value: 'ME' },
    { name: 'MI', value: 'MI' },
    { name: 'MN', value: 'MN' },
    { name: 'MO', value: 'MO' },
    { name: 'MS', value: 'MS' },
    { name: 'MT', value: 'MT' },
    { name: 'NC', value: 'NC' },
    { name: 'ND', value: 'ND' },
    { name: 'NE', value: 'NE' },
    { name: 'NH', value: 'NH' },
    { name: 'NJ', value: 'NJ' },
    { name: 'NM', value: 'NM' },
    { name: 'NV', value: 'NV' },
    { name: 'NY', value: 'NY' },
    { name: 'OH', value: 'OH' },
    { name: 'OK', value: 'OK' },
    { name: 'OR', value: 'OR' },
    { name: 'PA', value: 'PA' },
    { name: 'PR', value: 'PR' },
    { name: 'RI', value: 'RI' },
    { name: 'SC', value: 'SC' },
    { name: 'SD', value: 'SD' },
    { name: 'TN', value: 'TN' },
    { name: 'TX', value: 'TX' },
    { name: 'UT', value: 'UT' },
    { name: 'VA', value: 'VA' },
    { name: 'VT', value: 'VT' },
    { name: 'WA', value: 'WA' },
    { name: 'WI', value: 'WI' },
    { name: 'WV', value: 'WV' },
    { name: 'WY', value: 'WY' }
]

export const standard_cull_mapping = [{
    name: "Sold Foot/Leg",
    value: "sold_foot_leg"
}, {
    name: "Sold Dairy",
    value: "sold_dairy"
}, {
    name: "Sold Low Production",
    value: "sold_low_production"
}, {
    name: "Sold Reproductive",
    value: "sold_reproductive"
}, {
    name: "Sold Injury/Other",
    value: "sold_injury_other"
}, {
    name: "Died",
    value: "died"
}, {
    name: "Sold Mastitis",
    value: "sold_mastitis"
}, {
    name: "Sold Disease",
    value: "sold_disease"
}, {
    name: "Sold Udder",
    value: "sold_udder"
}, {
    name: "Not Reported",
    value: "not_reported"
}]

export const healthcodemapping = [{
    label: "Retained Placenta",
    healthcode: "retained_placenta_code",
    name: "Retained_Placenta"
}, {
    label: "Metritis",
    healthcode: "metritis_code",
    name: "Metritis"
}, {
    label: "Ketosis",
    healthcode: "ketosis_code",
    name: "Ketosis"
}, {
    label: "Displaced Abomasum",
    healthcode: "displaced_abomasum_code",
    name: "Displaced_Abomasum"
}, {
    label: "Milk Fever",
    healthcode: "milk_fever_code",
    name: "Milk_Fever"
}, {
    label: "Mastitis",
    healthcode: "mastitis_code",
    name: "Mastitis"
}, {
    label: "Abortions",
    healthcode: "abortions_code",
    name: "Abortions"
}, {
    label: "Lameness",
    healthcode: "lameness_code",
    name: "Lameness"
}, {
    label: "Hoof Trimming",
    healthcode: "hoof_training_code",
    name: "Hoof_Trimming"
}]

export const facility = [
    {
        name: "Rotary",
        value: "rotary"
    }, {
        name: "Robot",
        value: "robot"
    }, {
        name: "Parallel",
        value: "parallel"
    }, {
        name: "Herringbone",
        value: "herring_bone"
    }, {
        name: "Parabone",
        value: "parabone"
    }, {
        name: "Polygon",
        value: "polygon"
    }, {
        name: "Side Open",
        value: "side_open"
    }, {
        name: "Flat Barn",
        value: "flat_barn"
    }, {
        name: "Tie Stall",
        value: "tie_stall"
    }, {
        name: "Other",
        value: "other"
    }]

export const milking_frequency_options = [{
    name: "2x",
    value: "2x"
}, {
    name: "3x",
    value: "3x"
}, {
    name: "4x",
    value: "4x"
}, {
    name: "Robot",
    value: "robot"
}, {
    name: "Mixed",
    value: "mixed"
}, {
    name: "Other",
    value: "other"
}]

export const breeding_tool = [{
    name: "Timed AI",
    value: "timed_ai"
}, {
    name: "Activity",
    value: "activity"
}, {
    name: "Chalk/Paint",
    value: "chalk_paint"
}, {
    name: "Heat Observation",
    value: "heat_observation"
}, {
    name: "Ovarian Palpation",
    value: "ovarian_palpation"
}, {
    name: "Ultrasound",
    value: "ultrasound"
}, {
    name: "Exposure to Bull",
    value: "exposure_to_bull"
}, {
    name: "CIDR",
    value: "cidr"
}, {
    name: "Other",
    value: "other"
}]

export const breedingprogram = [{
    name: "none",
    value: "none"
}, {
    name: "OvSync",
    value: "ovsync"
}, {
    name: "PreSync",
    value: "presync"
}, {
    name: "Ovs+Pre",
    value: "ovspre"
}, {
    name: "Modified",
    value: "modified"
}, {
    name: "Other",
    value: "other"
}]

export const pregnancydetection = [{
    name: "Palpation",
    value: "palpation"
}, {
    name: "Ultrasound",
    value: "ultrasound"
}, {
    name: "Milk Test",
    value: "milk_test"
}, {
    name: "Blood Test",
    value: "blood_test"
}]

export const herdreplacement = [{
    name: "Raised",
    value: "raised"
}, {
    name: "Purchased",
    value: "purchased"
}, {
    name: "Both",
    value: "both"
}]

export const milkcooperativeoptions = [
    {
        name: 'Addison Cooperative Milk Producers Association, Inc',
        value: 'Addison Cooperative Milk Producers Association, Inc'
    },
    { name: 'Agri-Mark, Inc.', value: 'Agri-Mark, Inc.' },
    {
        name: 'Appalachian Dairy Farmers Cooperative',
        value: 'Appalachian Dairy Farmers Cooperative'
    },
    {
        name: 'Associated Milk Producers Inc.',
        value: 'Associated Milk Producers Inc.'
    },
    { name: 'Bongards Creameries', value: 'Bongards Creameries' },
    {
        name: 'Boonville Farms Cooperative, Inc',
        value: 'Boonville Farms Cooperative, Inc'
    },
    {
        name: 'Burnett Dairy Cooperative',
        value: 'Burnett Dairy Cooperative'
    },
    {
        name: 'California Dairies, Inc.',
        value: 'California Dairies, Inc.'
    },
    { name: 'Cayuga Marketing LLC.', value: 'Cayuga Marketing LLC.' },
    {
        name: 'Central Equity Milk Cooperative, Inc',
        value: 'Central Equity Milk Cooperative, Inc'
    },
    {
        name: 'Clarco Farmers Cooperative',
        value: 'Clarco Farmers Cooperative'
    },
    {
        name: 'Cobblestone Milk Cooperative, Inc.',
        value: 'Cobblestone Milk Cooperative, Inc.'
    },
    {
        name: 'Cooperative Milk Producers Association ',
        value: 'Cooperative Milk Producers Association '
    },
    {
        name: 'Cooperative Regions of Organic Producer Pools',
        value: 'Cooperative Regions of Organic Producer Pools'
    },
    {
        name: 'Cumberland Valley Milk Producers',
        value: 'Cumberland Valley Milk Producers'
    },
    {
        name: 'Dairy Farmers of America, Inc.',
        value: 'Dairy Farmers of America, Inc.'
    },
    {
        name: 'Dairystream Cooperative, Inc.',
        value: 'Dairystream Cooperative, Inc.'
    },
    {
        name: 'Down State Milk Producers Cooperative, Inc.',
        value: 'Down State Milk Producers Cooperative, Inc.'
    },
    {
        name: 'Edge Dairy Farmer Cooperative',
        value: 'Edge Dairy Farmer Cooperative'
    },
    {
        name: 'Elba Co-operative Creamery Association',
        value: 'Elba Co-operative Creamery Association'
    },
    {
        name: 'Ellsworth Cooperative Creamery',
        value: 'Ellsworth Cooperative Creamery'
    },
    {
        name: 'Elm Dale Creamery Association',
        value: 'Elm Dale Creamery Association'
    },
    {
        name: 'Erie Cooperative Association, Inc.',
        value: 'Erie Cooperative Association, Inc.'
    },
    {
        name: 'Farmers Co-operative Creamery Company',
        value: 'Farmers Co-operative Creamery Company'
    },
    {
        name: 'Farmers Union Milk Producers Association',
        value: 'Farmers Union Milk Producers Association'
    },
    {
        name: 'FarmFirst Dairy Cooperative',
        value: 'FarmFirst Dairy Cooperative'
    },
    {
        name: 'Fingerlakes Milk Cooperative Inc.',
        value: 'Fingerlakes Milk Cooperative Inc.'
    },
    {
        name: 'Foremost Farms USA, Cooperative',
        value: 'Foremost Farms USA, Cooperative'
    },
    {
        name: 'Gilman Cooperative Creamery',
        value: 'Gilman Cooperative Creamery'
    },
    {
        name: 'Great Lakes Milk Producers',
        value: 'Great Lakes Milk Producers'
    },
    {
        name: 'Great Plains Dairymen’s Association',
        value: 'Great Plains Dairymen’s Association'
    },
    { name: 'High Desert Milk, Inc', value: 'High Desert Milk, Inc' },
    {
        name: 'Hilmar Cheese Company, Inc.',
        value: 'Hilmar Cheese Company, Inc.'
    },
    {
        name: 'H.P. Farmers Cooperative, Inc.',
        value: 'H.P. Farmers Cooperative, Inc.'
    },
    {
        name: 'Independent Dairy Producers, Inc.',
        value: 'Independent Dairy Producers, Inc.'
    },
    {
        name: 'Jefferson Bulk Milk Co-op Inc',
        value: 'Jefferson Bulk Milk Co-op Inc'
    },
    { name: 'Lakes Area Cooperative', value: 'Lakes Area Cooperative' },
    {
        name: 'Lancaster Organic Farmers Co-op',
        value: 'Lancaster Organic Farmers Co-op'
    },
    { name: 'Lanco Dairy Farms Co-op', value: 'Lanco Dairy Farms Co-op' },
    { name: 'Land O’Lakes, Inc.', value: 'Land O’Lakes, Inc.' },
    {
        name: 'Legacy Milk Cooperative, Inc.',
        value: 'Legacy Milk Cooperative, Inc.'
    },
    {
        name: 'Liberty Milk Producers Cooperative, Inc',
        value: 'Liberty Milk Producers Cooperative, Inc'
    },
    {
        name: 'Lone Star Milk Producers, L.C',
        value: 'Lone Star Milk Producers, L.C'
    },
    {
        name: 'Lowville Producers Dairy Cooperative, Inc.',
        value: 'Lowville Producers Dairy Cooperative, Inc.'
    },
    {
        name: 'Magic Valley Quality Milk Producers Inc.',
        value: 'Magic Valley Quality Milk Producers Inc.'
    },
    {
        name: 'Maryland and Virginia Milk Producers Cooperative Association, Inc',
        value: 'Maryland and Virginia Milk Producers Cooperative Association, Inc'
    },
    {
        name: 'Michigan Milk Producers Association',
        value: 'Michigan Milk Producers Association'
    },
    {
        name: 'Middlebury Cooperative Milk Producers Association, Inc.',
        value: 'Middlebury Cooperative Milk Producers Association, Inc.'
    },
    {
        name: 'Mid-West Dairymen’s Company',
        value: 'Mid-West Dairymen’s Company'
    },
    {
        name: 'Mohawk Valley Cooperative, Inc.',
        value: 'Mohawk Valley Cooperative, Inc.'
    },
    {
        name: 'Mount Joy Farmers Co-operative Association',
        value: 'Mount Joy Farmers Co-operative Association'
    },
    {
        name: 'National Farmers Organization, Inc.',
        value: 'National Farmers Organization, Inc.'
    },
    {
        name: 'Nelson Creamery Association',
        value: 'Nelson Creamery Association'
    },
    {
        name: 'Northeast Nebraska Milk Producers Nonstock Cooperative',
        value: 'Northeast Nebraska Milk Producers Nonstock Cooperative'
    },
    {
        name: 'Northwest Dairy Association',
        value: 'Northwest Dairy Association'
    },
    {
        name: 'Oak Park Cooperative Creamery Association',
        value: 'Oak Park Cooperative Creamery Association'
    },
    {
        name: 'Oneida-Lewis Milk Producers Cooperative, Inc.',
        value: 'Oneida-Lewis Milk Producers Cooperative, Inc.'
    },
    {
        name: 'Oneida-Madison Milk Producers Co-operative ',
        value: 'Oneida-Madison Milk Producers Co-operative '
    },
    {
        name: 'Organic Dairy Farmers Cooperative',
        value: 'Organic Dairy Farmers Cooperative'
    },
    {
        name: 'Organic Family Farms Cooperative',
        value: 'Organic Family Farms Cooperative'
    },
    {
        name: 'Osakis Creamery Association',
        value: 'Osakis Creamery Association'
    },
    {
        name: 'Pacific Gold Milk Producers, Inc.',
        value: 'Pacific Gold Milk Producers, Inc.'
    },
    {
        name: 'Pioneer Milk Producers Cooperative, Inc.',
        value: 'Pioneer Milk Producers Cooperative, Inc.'
    },
    {
        name: 'Plainview Milk Products Cooperative',
        value: 'Plainview Milk Products Cooperative'
    },
    {
        name: 'Prairie Farms Dairy, Inc',
        value: 'Prairie Farms Dairy, Inc'
    },
    {
        name: 'Preble Milk Cooperative Association, Inc',
        value: 'Preble Milk Cooperative Association, Inc'
    },
    {
        name: 'Premier Milk, Incorporated',
        value: 'Premier Milk, Incorporated'
    },
    {
        name: 'Pro-Ag Farmers Cooperative',
        value: 'Pro-Ag Farmers Cooperative'
    },
    {
        name: 'Producers Co-operative, Inc.',
        value: 'Producers Co-operative, Inc.'
    },
    {
        name: 'Progressive Dairymen’s Cooperative, Inc.',
        value: 'Progressive Dairymen’s Cooperative, Inc.'
    },
    {
        name: 'Rock Dell Cooperative Creamery Company',
        value: 'Rock Dell Cooperative Creamery Company'
    },
    {
        name: 'Rolling Hills Dairy Producers Cooperative',
        value: 'Rolling Hills Dairy Producers Cooperative'
    },
    {
        name: 'Scenic Central Milk Producers Cooperative Association',
        value: 'Scenic Central Milk Producers Cooperative Association'
    },
    {
        name: 'Scenic Mountain Milk Producers Cooperative, Inc',
        value: 'Scenic Mountain Milk Producers Cooperative, Inc'
    },
    {
        name: 'Scioto County Cooperative Milk Producers’ Association',
        value: 'Scioto County Cooperative Milk Producers’ Association'
    },
    {
        name: 'Select Milk Producers, Inc.',
        value: 'Select Milk Producers, Inc.'
    },
    { name: 'Southeast Milk, Inc', value: 'Southeast Milk, Inc' },
    {
        name: 'Springfield Cooperative Creamery Association',
        value: 'Springfield Cooperative Creamery Association'
    },
    {
        name: 'Steamburg Milk Producers Cooperative, Inc.',
        value: 'Steamburg Milk Producers Cooperative, Inc.'
    },
    { name: 'Sunrise Ag Cooperative', value: 'Sunrise Ag Cooperative' },
    {
        name: 'Swanville Cooperative Creamery Association',
        value: 'Swanville Cooperative Creamery Association'
    },
    { name: 'The Dairy Group, Inc. ', value: 'The Dairy Group, Inc. ' },
    {
        name: 'The First District Association',
        value: 'The First District Association'
    },
    {
        name: 'Tillamook County Creamery Association',
        value: 'Tillamook County Creamery Association'
    },
    {
        name: 'Tri-County Producers’ Cooperative',
        value: 'Tri-County Producers’ Cooperative'
    },
    {
        name: 'United Dairymen of Arizona',
        value: 'United Dairymen of Arizona'
    },
    {
        name: 'Upstate Niagara Cooperative, Inc.',
        value: 'Upstate Niagara Cooperative, Inc.'
    },
    {
        name: 'Venture Dairy Cooperative',
        value: 'Venture Dairy Cooperative'
    },
    {
        name: 'Westby Cooperative Creamery ',
        value: 'Westby Cooperative Creamery '
    },
    {
        name: 'Westco Milk Producers Cooperative',
        value: 'Westco Milk Producers Cooperative'
    },
    {
        name: 'Western Milk Producers Association',
        value: 'Western Milk Producers Association'
    },
    {
        name: 'Western Organic Family Farms Cooperative',
        value: 'Western Organic Family Farms Cooperative'
    },
    {
        name: 'White Eagle Cooperative Association',
        value: 'White Eagle Cooperative Association'
    },
    {
        name: 'Woodstock Progressive Milk Producers’ Association',
        value: 'Woodstock Progressive Milk Producers’ Association'
    }
]

export const supplemental_programsoptions = [{
    name: " American Humane Certified",
    value: " American Humane Certified"
}, {
    name: "Animal Welfare Approved",
    value: "Animal Welfare Approved"
}, {
    name: "Certified Grassfed",
    value: "Certified Grassfed"
}, {
    name: "Certified Humane Certification Program",
    value: "Certified Humane Certification Program"
}, {
    name: "FoodAlliance.org",
    value: "FoodAlliance.org"
}, {
    name: "Global Animal Partnership's 5-Step Program",
    value: "Global Animal Partnership's 5-Step Program"
}, {
    name: "National Dairy FARM Program",
    value: "National Dairy FARM Program"
}, {
    name: "NYSCHAP Cattle Welfare Certification Program",
    value: "NYSCHAP Cattle Welfare Certification Program"
}, {
    name: "USDA Organic",
    value: "USDA Organic"
}, {
    name: "Other",
    value: "Other"
}]

export const accessoptions = [{
    name: "Read Only",
    value: "readonly"
}, {
    name: "Contributor",
    value: "contributor"
}, {
    name: "Owner",
    value: "owner"
}, {
    name: "Remove Access",
    value: "Remove Access"
}]

export const graphidnamemap = {
    "ecmchart": "Energy Corrected Milk",
    "testdaymilkchart": "Test Day Milk",
    "weeklymilkchart": "Weekly Milk",
    "lactation1chart": "1st Lactation",
    "lactation2chart": "2nd Lactation",
    "lactation3chart": "3rd+ Lactation",
    "herddatachart": "Percent Pregnant in Herd",
    "calfheiferchart": "1st Calf Heifer Profiles",
    "daysopenchart": "Days Open",
    "percentfatchart": "Percent Fat",
    "somaticcellchart": "Somatic Cell",
    "percentproteinchart": "Percent Protein",
    "totallbschart": "Total lbs fat+protein",
    "avgdimchart": "Average DIM",
    "calvingintervalchart": "Calving Intervals",
    "annualcullchart": "Annual Cull",
    "lactationcurvechart": "Lactation Curve",
}

export const redcoloredsectionsnamearray = [
    "Herd Inventory",
    "Heifer Enterprise",
    "Reproduction",
    "Culling",
    "Component Analysis",
    "Milk Quality",
    "Calves",
    "Health",
]

export const reporterrorarray = [
    "Please select another name, Report Layout with this name already exists.",
    "Report Layout name should be of at least 3 characters and should be alphanumeric and should not contain any special character other than space.",
    "Name already exists in your Custom Report Layouts. If you continue, you will overwrite your existing custom report layout."
]

export let bargraph = ["test_day_milk_data",
    "percent_pregnant_in_herd_data",
    "first_calf_heifer_profiles_data",
    "days_open_data",
    "percent_fat_data",
    "somatic_cell_count",
    "percent_protein",
    "lbs_fat_protein_data",
    "average_dim",
    "calving_interval",
    "annual_cull_and_death_rates",
    "weekly_milk_data",
    "ecm_data"]

export let formdata = {
    benchmark_report: false,
    // Contact and information
    dairy_name: "",
    dairy_owner_name: "",
    dairy_manager_name: "",
    dairy_street_address: "",
    dairy_street_address_2: "",
    dairy_city: "",
    dairy_state: "",
    dairy_zip_code: "",
    dairy_phone_number: "",
    dairy_mobile_number: "",
    dairy_fax_number: "",
    dairy_email: "",

    // Management Software
    software_herd_software: "PCDART",
    software_herd_code: "",
    software_remote_access_code: "",
    software_breeds: [],
    software_split_report: "No",
    sub_herds: [],

    Retained_Placenta: false,
    retained_placenta_code: "",

    Metritis: false,
    metritis_code: "",

    Ketosis: false,
    ketosis_code: "",

    Displaced_Abomasum: false,
    displaced_abomasum_code: "",

    Milk_Fever: false,
    milk_fever_code: "",

    Mastitis: false,
    mastitis_code: "",

    Abortions: false,
    abortions_code: "",

    Lameness: false,
    lameness_code: "",

    Hoof_Trimming: false,
    hoof_training_code: "",

    software_Breeding_Codes: [],
    // software_culling_codes:[],


    // DHIA
    dhia_daily_milk_weights_recorded: "Yes",
    dhia_dhi_or_other_service_used: "Yes",
    dhia_enrollment_tests_per_year: "",
    dhia_fat: false,
    dhia_protein: false,
    dhia_scc: false,
    dhia_mun: false,
    parlor_daily_milk_weights: false,



    // Milking Facility
    milking_facility_type: [],
    milking_facility_type_other: "",
    milking_stalls_in_parlor: "",
    milking_cows_fed_in_parlor: "No",
    milking_parlor_holding_pen_capacity: "",
    milking_milking_frequency_per_day: "",
    milking_milking_frequency_per_day_mixed_other: "",
    milking_milking_frequency_per_day_robot: "",
    milking_facility_active_hours_per_day: "",


    // Reproductive Management
    reproductive_primary_breeding_method: "AI",
    reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program: "",
    reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program_other: "",
    reproductive_additional_tools_used_in_adult_cow_breeding_program: [],
    reproductive_Primary_Tool_Used_In_Virgin_Heifer_Program: "",
    reproductive_Primary_Tool_Used_In_Virgin_Heifer_Program_other: "",
    reproductive_additional_tools_used_in_virgin_heifer_program: [],
    reproductive_record_of_sires: "Yes",
    reproductive_record_breed_dates: "Yes",
    reproductive_cows_designated_do_not_breed: "Yes",
    reproductive_record_non_breed_heat_dates: "No",
    reproductive_pregnancy_exam_frequency: "",
    reproductive_primary_method_used_to_determine_pregnancy: "",
    reproductive_calving_results_recorded_with_details: "Yes",
    reproductive_calving_difficulty_scores_recorded: "Yes",
    reproductive_herd_replacement_obtained_method: "",
    reproductive_replacement_records_same_Software: "Yes",
    reproductive_reuse_lactating_cow_ids_within_1_year: "Yes",
    reproductive_voluntary_waiting_period: 65,
    weaning_day: 60,

    // Supllement Questions
    supplemental_producer_uses_full_anion_supplementation: "Yes",
    supplemental_producer_uses_feed_additives: "Yes",
    supplemental_milk_cooperative: "",
    supplemental_programs: [],
    supplemental_dairy_fte_employees: "",
    supplemental_years_since_last_major_expansion: "",
    supplemental_years_until_next_major_expansion: "",
    supplemental_producer_works_with_purina_specialist: "Yes",

    // Facilities and Grouping 
    facilties_and_grouping_far_off_dry: false,
    facilties_and_grouping_close_up_dry: false,
    facilties_and_grouping_single_group_for_dry_cows: false,
    facilties_and_grouping_post_fresh_lactating_cows: false,
    facilties_and_grouping_lactating_rations: false,

    facilties_and_grouping_far_off_dry_form: {},
    facilties_and_grouping_close_up_dry_form: {},
    facilties_and_grouping_single_group_for_dry_cows_form: {},
    facilties_and_grouping_post_fresh_lactating_cows_form: {},
    facilties_and_grouping_lactating_rations_form: {},

    // Peer Group
    // peer_group_milk_production_lower_limit:"",
    // peer_group_milk_production_upper_limit:"",
    // peer_group_herd_size_lower_limit:"",
    // peer_group_milk_herd_size_upper_limit:"",
    // peer_group_region_lower_limit:"",
    // peer_group_region_upper_limit:"",
    // peer_group_breeds:[],
    // software_Report_National_Averages_Or_Herd_Goals:"National Averages",

    // Subherd_Report
    Adult_facility_form_primary_feed_delivery_system_chip: [],
    comments: ""
}

export let sourcleapplicationoptions = [{
    name: "DC305",
    value: "DC305"
}, {
    name: "PCDART",
    value: "PCDART"
}, {
    name: "DPLAN",
    value: "DPLAN"
}, {
    name: "DRMS DHIA",
    value: "DRMS"
}]

export const appConstants = { LoginSessionKey: 'LoginSessionKey' }

export const defaultreportlayoutsetting = {
    "sections": [
        {
            "name": "All Cows",
            "show": true,
            "rows": [
                {
                    "name": "Head",
                    "show": true
                },
                {
                    "name": "Milk_Test_Date",
                    "show": true
                },
                {
                    "name": "Summit_Milk",
                    "show": true
                },
                {
                    "name": "Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "ECM",
                    "show": true
                },
                {
                    "name": "Management_Milk_Level",
                    "show": true
                },
                {
                    "name": "Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Early_Slope",
                    "show": true
                },
                {
                    "name": "Percent_Greater_Than_100_Lbs_Milk",
                    "show": true
                },
                {
                    "name": "Average_DIM",
                    "show": true
                },
                {
                    "name": "Current_305_ME",
                    "show": true
                },
                {
                    "name": "First_Month_305_ME",
                    "show": true
                },
                {
                    "name": "Percent_Fat",
                    "show": true
                },
                {
                    "name": "Percent_Protein",
                    "show": true
                },
                {
                    "name": "Latest_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Management_Level_Milk",
                    "show": true
                },
                {
                    "name": "Peak_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Weekly_Milk",
                    "show": true
                }
            ]
        },
        {
            "name": "Lactation 1 Cows",
            "show": true,
            "rows": [
                {
                    "name": "Head",
                    "show": true
                },
                {
                    "name": "Summit_Milk",
                    "show": true
                },
                {
                    "name": "Less_30_DIM",
                    "show": true
                },
                {
                    "name": "31_to_60",
                    "show": true
                },
                {
                    "name": "61_to_90",
                    "show": true
                },
                {
                    "name": "91_to_120",
                    "show": true
                },
                {
                    "name": "121_to_150",
                    "show": true
                },
                {
                    "name": "151_to_180",
                    "show": true
                },
                {
                    "name": "181_to_210",
                    "show": true
                },
                {
                    "name": "211_to_240",
                    "show": true
                },
                {
                    "name": "241_to_270",
                    "show": true
                },
                {
                    "name": "271_to_300",
                    "show": true
                },
                {
                    "name": "Greater_300",
                    "show": true
                },
                {
                    "name": "Milk_Week_2",
                    "show": true
                },
                {
                    "name": "Milk_Week_4",
                    "show": true
                },
                {
                    "name": "Milk_Week_8",
                    "show": true
                },
                {
                    "name": "Milk_Week_15",
                    "show": true
                },
                {
                    "name": "Milk_Week_21",
                    "show": true
                },
                {
                    "name": "Milk_Week_25",
                    "show": true
                },
                {
                    "name": "Milk_Week_32",
                    "show": true
                },
                {
                    "name": "Milk_Week_37",
                    "show": true
                },
                {
                    "name": "Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "ECM",
                    "show": true
                },
                {
                    "name": "Management_Milk_Level",
                    "show": true
                },
                {
                    "name": "Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Latest_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Management_Level_Milk",
                    "show": true
                },
                {
                    "name": "Peak_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Early_Slope",
                    "show": true
                },
                {
                    "name": "Average_DIM",
                    "show": true
                },
                {
                    "name": "Current_305_ME",
                    "show": true
                },
                {
                    "name": "First_Month_305_ME",
                    "show": true
                },
                {
                    "name": "Percent_Fat",
                    "show": true
                },
                {
                    "name": "Percent_Protein",
                    "show": true
                },
                {
                    "name": "Latest_Weekly_Milk_Percent_L3_Plus",
                    "show": true
                },
                {
                    "name": "ECM_Percent_L3_Plus",
                    "show": true
                },
                {
                    "name": "Management_Level_Milk_Percent_L3_Plus",
                    "show": true
                },
                {
                    "name": "Peak_Weekly_Milk_Percent_L3_Plus",
                    "show": true
                },
                {
                    "name": "Test_Day_Milk_Percent_L3_Plus",
                    "show": true
                },
                {
                    "name": "Summit_Milk_Percent_L3_Plus",
                    "show": true
                },
                {
                    "name": "ECM_Percent_L3_Plus",
                    "show": true
                },
                {
                    "name": "Days_To_Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Peak_Test_Day_Milk_Percent_L3_Plus",
                    "show": true
                },
            ]
        },
        {
            "name": "Lactation 2 Cows",
            "show": true,
            "rows": [
                {
                    "name": "Head",
                    "show": true
                },
                {
                    "name": "Summit_Milk",
                    "show": true
                },
                {
                    "name": "Less_30_DIM",
                    "show": true
                },
                {
                    "name": "31_to_60",
                    "show": true
                },
                {
                    "name": "61_to_90",
                    "show": true
                },
                {
                    "name": "91_to_120",
                    "show": true
                },
                {
                    "name": "121_to_150",
                    "show": true
                },
                {
                    "name": "151_to_180",
                    "show": true
                },
                {
                    "name": "181_to_210",
                    "show": true
                },
                {
                    "name": "211_to_240",
                    "show": true
                },
                {
                    "name": "241_to_270",
                    "show": true
                },
                {
                    "name": "271_to_300",
                    "show": true
                },
                {
                    "name": "Greater_300",
                    "show": true
                },
                {
                    "name": "Milk_Week_2",
                    "show": true
                },
                {
                    "name": "Milk_Week_4",
                    "show": true
                },
                {
                    "name": "Milk_Week_8",
                    "show": true
                },
                {
                    "name": "Milk_Week_15",
                    "show": true
                },
                {
                    "name": "Milk_Week_21",
                    "show": true
                },
                {
                    "name": "Milk_Week_25",
                    "show": true
                },
                {
                    "name": "Milk_Week_32",
                    "show": true
                },
                {
                    "name": "Milk_Week_37",
                    "show": true
                },
                {
                    "name": "Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "ECM",
                    "show": true
                },
                {
                    "name": "Management_Milk_Level",
                    "show": true
                },
                {
                    "name": "Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Latest_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Management_Level_Milk",
                    "show": true
                },
                {
                    "name": "Peak_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Early_Slope",
                    "show": true
                },
                {
                    "name": "Average_DIM",
                    "show": true
                },
                {
                    "name": "Current_305_ME",
                    "show": true
                },
                {
                    "name": "First_Month_305_ME",
                    "show": true
                },
                {
                    "name": "Percent_Fat",
                    "show": true
                },
                {
                    "name": "Percent_Protein",
                    "show": true
                }
            ]
        },
        {
            "name": "Lactation 3+ Cows",
            "show": true,
            "rows": [
                {
                    "name": "Head",
                    "show": true
                },
                {
                    "name": "Summit_Milk",
                    "show": true
                },
                {
                    "name": "Less_30_DIM",
                    "show": true
                },
                {
                    "name": "31_to_60",
                    "show": true
                },
                {
                    "name": "61_to_90",
                    "show": true
                },
                {
                    "name": "91_to_120",
                    "show": true
                },
                {
                    "name": "121_to_150",
                    "show": true
                },
                {
                    "name": "151_to_180",
                    "show": true
                },
                {
                    "name": "181_to_210",
                    "show": true
                },
                {
                    "name": "211_to_240",
                    "show": true
                },
                {
                    "name": "241_to_270",
                    "show": true
                },
                {
                    "name": "271_to_300",
                    "show": true
                },
                {
                    "name": "Greater_300",
                    "show": true
                },
                {
                    "name": "Milk_Week_2",
                    "show": true
                },
                {
                    "name": "Milk_Week_4",
                    "show": true
                },
                {
                    "name": "Milk_Week_8",
                    "show": true
                },
                {
                    "name": "Milk_Week_15",
                    "show": true
                },
                {
                    "name": "Milk_Week_21",
                    "show": true
                },
                {
                    "name": "Milk_Week_25",
                    "show": true
                },
                {
                    "name": "Milk_Week_32",
                    "show": true
                },
                {
                    "name": "Milk_Week_37",
                    "show": true
                },
                {
                    "name": "Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "ECM",
                    "show": true
                },
                {
                    "name": "Management_Milk_Level",
                    "show": true
                },
                {
                    "name": "Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Test_Day_Milk",
                    "show": true
                },
                {
                    "name": "Latest_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Management_Level_Milk",
                    "show": true
                },
                {
                    "name": "Peak_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Days_to_Peak_Weekly_Milk",
                    "show": true
                },
                {
                    "name": "Early_Slope",
                    "show": true
                },
                {
                    "name": "Average_DIM",
                    "show": true
                },
                {
                    "name": "Current_305_ME",
                    "show": true
                },
                {
                    "name": "First_Month_305_ME",
                    "show": true
                },
                {
                    "name": "Percent_Fat",
                    "show": true
                },
                {
                    "name": "Percent_Protein",
                    "show": true
                }
            ]
        },
        {
            "name": "Component Analysis",
            "show": true,
            "rows": [
                {
                    "name": "Total_Lbs_Fat_Protein",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_Lactation_1",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_Lactation_2",
                    "show": true
                },
                {
                    "name": "Total_Pounds_of_Fat_and_Protein_Lactation_3+",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_Less_30_DIM",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_31_to_60_DIM",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_61_to_90_DIM",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_91_to_120_DIM",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_121_to_200_DIM",
                    "show": true
                },
                {
                    "name": "Total_Lbs_Fat_Protein_Greater_200_DIM",
                    "show": true
                },
                {
                    "name": "Percent_1st_Fat_Test_Greater_5_5",
                    "show": true
                },
                {
                    "name": "Percent_1st_Fat_Test_Greater_5_5_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_1st_Fat_Test_Greater_5_5_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_1st_Fat_Test_Greater_5_5_Lactation_3",
                    "show": true
                }
            ]
        },
        {
            "name": "Reproduction",
            "show": true,
            "rows": [
                {
                    "name": "Number_Pregnant",
                    "show": true
                },
                {
                    "name": "Percent_Pregnant",
                    "show": true
                },
                {
                    "name": "Percent_Herd_Coded_DNB",
                    "show": true
                },
                {
                    "name": "Percent_Herd_Coded_DNB_This_Period",
                    "show": true
                },
                {
                    "name": "Number_Open_Eligible",
                    "show": true
                },
                {
                    "name": "Percent_Pregnant_Vet_Check_Last_20_Days",
                    "show": true
                },
                {
                    "name": "Percent_Herd_Diagnosed_Pregnant_Last_30_Days",
                    "show": true
                },
                {
                    "name": "Days_Open_Pregnant_Cows",
                    "show": true
                },
                {
                    "name": "Number_Not_Bred_Greater_80_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Not_Bred_Greater_80_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Eligible_Cows_Bred",
                    "show": true
                },
                {
                    "name": "Percent_Eligible_Cows_Not_Bred",
                    "show": true
                },
                {
                    "name": "Average_DIM_1st_Service",
                    "show": true
                },
                {
                    "name": "Percent_Pregnant_Days_Open_Greater_180",
                    "show": true
                },
                {
                    "name": "Percent_New_Pregnant_Days_Open_Greater_180",
                    "show": true
                },
                {
                    "name": "Percent_New_Pregnant_Days_Open_Greater_Than_180",
                    "show": true
                },
                {
                    "name": "Percent_Open_Eligible_Days_Open_Greater_180",
                    "show": true
                },
                {
                    "name": "Percent_Pregnant_150_DIM",
                    "show": true
                },
                {
                    "name": "Percent_New_Pregnant_150_DIM",
                    "show": true
                },
                {
                    "name": "Number_Pregnant_115_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Pregnant_115_DIM",
                    "show": true
                },
                {
                    "name": "Percent_New_Pregnant_115_DIM",
                    "show": true
                },
                {
                    "name": "1st_Service_Conception_Rate",
                    "show": true
                },
                {
                    "name": "Recent_First_Service_Conception_Rate",
                    "show": true
                },
                {
                    "name": "Services_Per_Conception",
                    "show": true
                },
                {
                    "name": "Recent_Services_per_Conception",
                    "show": true
                },
                {
                    "name": "Services_Per_Aminal",
                    "show": true
                },
                {
                    "name": "Conception_Rate",
                    "show": true
                },
                {
                    "name": "Recent_Conception_Rate",
                    "show": true
                },
                {
                    "name": "Calving_Interval_Fresh_Cows",
                    "show": true
                },
                {
                    "name": "Projected_Calving_Interval_Recent_Pregnant",
                    "show": true
                },
                {
                    "name": "Min_Projected_Calving_Interval",
                    "show": true
                }
            ]
        },
        {
            "name": "Health",
            "show": true,
            "rows": [
                {
                    "name": "Number_Retained_Placenta",
                    "show": true
                },
                {
                    "name": "Percent_Retained_Placenta",
                    "show": true
                },
                {
                    "name": "Number_Metritis",
                    "show": true
                },
                {
                    "name": "Percent_Metritis",
                    "show": true
                },
                {
                    "name": "Number_Displaced_Abomasum_Fresh_Cows",
                    "show": true
                },
                {
                    "name": "Percent_Displaced_Abomasum_Fresh_Cows",
                    "show": true
                },
                {
                    "name": "DIM_Displaced_Abomasum_Fresh_Cows",
                    "show": true
                },
                {
                    "name": "Number_Displaced_Abomasum_All_Cows",
                    "show": true
                },
                {
                    "name": "Percent_Displaced_Abomasum_All_Cows",
                    "show": true
                },
                {
                    "name": "DIM_Displaced_Abomasum_All_Cows",
                    "show": true
                },
                {
                    "name": "Number_Milk_Fever",
                    "show": true
                },
                {
                    "name": "Percent_Milk_Fever",
                    "show": true
                },
                {
                    "name": "Number_Ketosis",
                    "show": true
                },
                {
                    "name": "Percent_Ketosis",
                    "show": true
                },
                {
                    "name": "Number_Lame",
                    "show": true
                },
                {
                    "name": "Percent_Lame",
                    "show": true
                },
                {
                    "name": "Number_Foottrim",
                    "show": true
                },
                {
                    "name": "Percent_Foottrim",
                    "show": true
                },
                {
                    "name": "Number_Dystocia",
                    "show": true
                },
                {
                    "name": "Percent_Dystocia",
                    "show": true
                },
                {
                    "name": "Number_Mastitis",
                    "show": true
                },
                {
                    "name": "Percent_Mastitis",
                    "show": true
                },
                {
                    "name": "Number_Uterine_Problem",
                    "show": true
                },
                {
                    "name": "Percent_Uterine_Problem",
                    "show": true
                },
                {
                    "name": "Number_Abortions",
                    "show": true
                },
                {
                    "name": "Number_Bloat",
                    "show": true
                },
                {
                    "name": "Number_Bloat_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Bloat_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Bloat_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Bloat_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Diarrhea",
                    "show": true
                },
                {
                    "name": "Number_Diarrhea_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Diarrhea_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Diarrhea_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Diarrhea_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Hernia",
                    "show": true
                },
                {
                    "name": "Number_Hernia_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Hernia_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Hernia_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Hernia_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Injury",
                    "show": true
                },
                {
                    "name": "Number_Injury_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Injury_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Injury_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Injury_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Lameness",
                    "show": true
                },
                {
                    "name": "Number_Lameness_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Lameness_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Lameness_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Lameness_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Mycoplasma",
                    "show": true
                },
                {
                    "name": "Number_Mycoplasma_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Mycoplasma_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Mycoplasma_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Mycoplasma_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Navel_3",
                    "show": true
                },
                {
                    "name": "Number_Navel_3_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Navel_3_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Navel_3_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Navel_3_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Pneumonia",
                    "show": true
                },
                {
                    "name": "Number_Pneumonia_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Pneumonia_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Pneumonia_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Pneumonia_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Undetermined",
                    "show": true
                },
                {
                    "name": "Number_Undetermined_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Undetermined_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Undetermined_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Undetermined_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Bloat_0_To_Weaning_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Bloat_0_To_Weaning_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Bloat_Weaning_Days_To_120_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Bloat_Weaning_Days_To_120_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Bloat_120_Days_To_180_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Bloat_120_Days_To_180_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Bloat_Bred_Heifers_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Bloat_Bred_Heifers_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Navel",
                    "show": true
                },
                {
                    "name": "Number_Navel_0_To_Weaning_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Navel_0_To_Weaning_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Navel_Weaning_Days_To_120_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Navel_Weaning_Days_To_120_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Navel_120_Days_To_180_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Navel_120_Days_To_180_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Navel_Bred_Heifers_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Navel_Bred_Heifers_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Injury_0_To_Weaning_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Injury_0_To_Weaning_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Injury_Weaning_Days_To_120_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Injury_Weaning_Days_To_120_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Injury_120_Days_To_180_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Injury_120_Days_To_180_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Injury_Bred_Heifers_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Injury_Bred_Heifers_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Lameness_0_To_Weaning_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Lameness_0_To_Weaning_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Lameness_Weaning_Days_To_120_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Lameness_Weaning_Days_To_120_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Lameness_120_Days_To_180_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Lameness_120_Days_To_180_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Lameness_Bred_Heifers_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Lameness_Bred_Heifers_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Brd",
                    "show": true
                },
                {
                    "name": "Number_Brd_0_To_Weaning_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Brd_0_To_Weaning_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Brd_0_To_Weaning_Days_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Brd_Weaning_Days_To_120_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Brd_Weaning_Days_To_120_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Brd_Weaning_Days_To_120_Days_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Brd_120_Days_To_180_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Brd_120_Days_To_180_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Brd_120_Days_To_180_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Brd_180_Days_To_Breeding_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Brd_180_Days_To_Breeding_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Brd_180_Days_To_Breeding_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Brd_Bred_Heifers_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Brd_Bred_Heifers_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Brd_Bred_Heifers_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Scours",
                    "show": true
                },
                {
                    "name": "Number_Scours_0_To_Weaning_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Scours_0_To_Weaning_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Scours_0_To_Weaning_Days_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Scours_Weaning_Days_To_120_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Scours_Weaning_Days_To_120_Days_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Scours_Weaning_Days_To_120_Days_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Scours_120_Days_To_180_Days_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Scours_120_Days_To_180_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Scours_120_Days_To_180_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Scours_180_Days_To_Breeding_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Scours_180_Days_To_Breeding_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Scours_180_Days_To_Breeding_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Scours_Bred_Heifers_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Scours_Bred_Heifers_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Scours_Bred_Heifers_Event_Count",
                    "show": true
                },
                {
                    "name": "Number_Bloat_180_Days_To_Breeding_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Bloat_180_Days_To_Breeding_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Navel_180_Days_To_Breeding_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Navel_180_Days_To_Breeding_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Injury_180_Days_To_Breeding_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Injury_180_Days_To_Breeding_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Lameness_180_Days_To_Breeding_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Lameness_180_Days_To_Breeding_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Scours_180_Days_To_Breeding_Initial_Case",
                    "show": true
                },
                {
                    "name": "Number_Scours_180_Days_To_Breeding_Recurrence",
                    "show": true
                },
                {
                    "name": "Number_Scours_180_Days_To_Breeding_Event_Count",
                    "show": true
                },
            ]
        },
        {
            "name": "Milk Quality",
            "show": true,
            "rows": [
                {
                    "name": "Number_New_Clinicals_Fresh",
                    "show": true
                },
                {
                    "name": "Percent_New_Clinicals_Fresh",
                    "show": true
                },
                {
                    "name": "Number_New_Clinicals_All",
                    "show": true
                },
                {
                    "name": "Percent_New_Clinicals_All",
                    "show": true
                },
                {
                    "name": "Percent_New_Chronic_All",
                    "show": true
                },
                {
                    "name": "SCC",
                    "show": true
                },
                {
                    "name": "SCC_Lactation_1",
                    "show": true
                },
                {
                    "name": "SCC_Lactation_2",
                    "show": true
                },
                {
                    "name": "SCC_Lactation_3",
                    "show": true
                },
                {
                    "name": "SCC_Less_30_DIM",
                    "show": true
                },
                {
                    "name": "SCC_Less_30_DIM_Lactation_1",
                    "show": true
                },
                {
                    "name": "SCC_Less_30_DIM_Lactation_2",
                    "show": true
                },
                {
                    "name": "SCC_Less_30_DIM_Lactation_3",
                    "show": true
                },
                {
                    "name": "SCC_Dried_Cows",
                    "show": true
                },
                {
                    "name": "SCC_Lactation_1_Dried_Cows",
                    "show": true
                },
                {
                    "name": "SCC_Lactation_2_Dried_Cows",
                    "show": true
                },
                {
                    "name": "SCC_Lactation_3_Dried_Cows",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Less_50",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Less_50_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Less_50_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Less_Than_50_Lactation_3+",
                    "show": true
                },
                {
                    "name": "Percent_SCC_50_to_200",
                    "show": true
                },
                {
                    "name": "Percent_SCC_50_to_200_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_SCC_50_to_200_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_SCC_50_to_200_Lactation_3",
                    "show": true
                },
                {
                    "name": "Percent_SCC_200_to_400",
                    "show": true
                },
                {
                    "name": "Percent_SCC_200_to_400_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_SCC_200_to_400_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_SCC_200_to_400_Lactation_3",
                    "show": true
                },
                {
                    "name": "Percent_SCC_400_to_800",
                    "show": true
                },
                {
                    "name": "Percent_SCC_400_to_800_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_SCC_400_to_800_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_SCC_400_to_800_Lactation_3",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Greater_800",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Greater_800_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Greater_800_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_SCC_Greater_800_Lactation_3",
                    "show": true
                }
            ]
        },
        {
            "name": "Herd Inventory",
            "show": true,
            "rows": [
                {
                    "name": "Number_Milking",
                    "show": true
                },
                {
                    "name": "Number_Dry",
                    "show": true
                },
                {
                    "name": "Percent_Milking",
                    "show": true
                },
                {
                    "name": "Percent_Dry",
                    "show": true
                },
                {
                    "name": "Percent_1st_Calf_Heifers",
                    "show": true
                },
                {
                    "name": "Age_1st_Calving",
                    "show": true
                },
                {
                    "name": "Number_Fresh",
                    "show": true
                },
                {
                    "name": "Number_Dried",
                    "show": true
                },
                {
                    "name": "Number_Fresh_Gone",
                    "show": true
                },
                {
                    "name": "Percent_Heifers_Fresh",
                    "show": true
                },
                {
                    "name": "Average_Days_Dry_Fresh_Cows",
                    "show": true
                },
                {
                    "name": "Percent_Fresh_Dry_Days_Less_30",
                    "show": true
                },
                {
                    "name": "Percent_Fresh_Dry_Days_Less_40",
                    "show": true
                },
                {
                    "name": "Percent_Fresh_Dry_Days_Greater_70",
                    "show": true
                },
                {
                    "name": "Projected_Days_Dry_Dried_Cows",
                    "show": true
                },
                {
                    "name": "Number_Milking_Lactation_1",
                    "show": true
                },
                {
                    "name": "Number_Dry_Lactation_1",
                    "show": true
                }
            ]
        },
        {
            "name": "Calves",
            "show": true,
            "rows": [
                {
                    "name": "Number_Heifers_Born",
                    "show": true
                },
                {
                    "name": "Number_Bulls_Born",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Calves",
                    "show": true
                },
                {
                    "name": "Number_Sets_Twins",
                    "show": true
                },
                {
                    "name": "Percent_Twinning",
                    "show": true
                },
                {
                    "name": "Percent_Born_Dead",
                    "show": true
                },
                {
                    "name": "Number_Heifers_Dead",
                    "show": true
                },
                {
                    "name": "Number_Bulls_Dead",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Heifer_Born",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Beef_Heifer_Born",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Bulls_Born",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Beef_Bulls_Born",
                    "show": true
                },
                {
                    "name": "Percent_Dairy_Heifer_Calves",
                    "show": true
                },
                {
                    "name": "Number_Set_Of_Dairy_Twins",
                    "show": true
                },
                {
                    "name": "Number_Set_Of_Dairy_Beef_Twins",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Heifer_Dead",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Beef_Heifer_Dead",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Bulls_Dead",
                    "show": true
                },
                {
                    "name": "Number_Dairy_Beef_Bulls_Dead",
                    "show": true
                }
            ]
        },
        {
            "name": "Heifer Enterprise",
            "show": true,
            "rows": [
                {
                    "name": "Number_In_Development",
                    "show": true
                },
                {
                    "name": "Number_Less_3_Months",
                    "show": true
                },
                {
                    "name": "Number_3_to_5_Months",
                    "show": true
                },
                {
                    "name": "Number_6_to_8_Months",
                    "show": true
                },
                {
                    "name": "Number_9_to_11_Months",
                    "show": true
                },
                {
                    "name": "Number_12_to_13_Months",
                    "show": true
                },
                {
                    "name": "Number_Greater_14_Months",
                    "show": true
                },
                {
                    "name": "Age_1st_Breeding",
                    "show": true
                },
                {
                    "name": "Percent_pregnant",
                    "show": true
                },
                {
                    "name": "services_per_conception",
                    "show": true
                },
                {
                    "name": "annual_cull_rate",
                    "show": true
                },
                {
                    "name": "annual_death_rate",
                    "show": true
                },
                {
                    "name": "Number_Less_1_Month",
                    "show": true
                },
                {
                    "name": "Number_1_to_2_Months",
                    "show": true
                },
                {
                    "name": "Number_2_to_3_Months",
                    "show": true
                },
                {
                    "name": "Age_1st_Breeding",
                    "show": true
                },
                {
                    "name": "Age_At_Conception",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Breeding_Code",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Standing",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Timed",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Double_Ovsync",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Gnrh",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Embryo_Transfer",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_CIDR",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_IVF",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_AI24",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Frozen_Conv",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Frozen_IVF",
                    "show": true
                },
                {
                    "name": "Age_At_Conception_By_Chalk",
                    "show": true
                },
                {
                    "name": "HE_First_Service_Conception_Rate",
                    "show": true
                },
                {
                    "name": "HE_Conception_Rate",
                    "show": true
                },
                {
                    "name": "Percent_bred",
                    "show": true
                },
                {
                    "name": "First_Service_Conception_Rate",
                    "show": true
                },
                {
                    "name": "Number_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Bred_Heifers",
                    "show": true
                },
            ]
        },
        {
            "name": "Culling",
            "show": true,
            "rows": [
                {
                    "name": "Annual_Cull_Rate",
                    "show": true
                },
                {
                    "name": "Annual_Death_Rate",
                    "show": true
                },
                {
                    "name": "Speed",
                    "show": true
                },
                {
                    "name": "Number_Culled",
                    "show": true
                },
                {
                    "name": "Number_Culled_0_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Culled_Weaning_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Culled_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_Culled_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Culled_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Number_Died",
                    "show": true
                }, {
                    "name": "Culled_Dry",
                    "show": true
                }, {
                    "name": "Culled_Fresh",
                    "show": true
                },
                {
                    "name": "Number_Culled_Dry",
                    "show": true
                }, {
                    "name": "Percent_Culled_DIM_Less_31_DIM",
                    "show": true
                }, {
                    "name": "Percent_Culled_DIM_31_to_60_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Less_31_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Culled_31_to_60_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Culled_61_to_90_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Culled_91_to_120_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Culled_121_to_300_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Greater_300_DIM",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Dry",
                    "show": true
                },
                {
                    "name": "Reason_Culled",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Feet_Legs",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Dairy",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Production",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Breeding",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Injury_Other",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Died",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Mastitis",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Disease",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Udder_Traits",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Unknown_Reasons",
                    "show": true
                },
                {
                    "name": "Culling_by_Lactation_Group",
                    "show": true
                },
                {
                    "name": "Speed_Lactation_1",
                    "show": true
                },
                {
                    "name": "Speed_Lactation_2",
                    "show": true
                },
                {
                    "name": "Speed_Lactation_3+",
                    "show": true
                },
                {
                    "name": "Percent_Died_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_Died_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_Died_Lactation_3+",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Lactation_2",
                    "show": true
                },
                {
                    "name": "Percent_Culled_Lactation_3+",
                    "show": true
                },
                {
                    "name": "Annual_Cull_Rate_Lactation_1",
                    "show": true
                },
                {
                    "name": "Annual_Death_Rate_Lactation_1",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_Less_1_Month",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_1_To_2_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_2_To_3_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_3_To_4_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_4_To_5_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_5_To_6_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_6_To_7_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_7_To_8_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_8_To_9_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_9_To_10_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_10_To_11_Months",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_In_Months_Greater_11_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_Less_1_Month",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_1_To_2_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_2_To_3_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_3_To_4_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_4_To_5_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_5_To_6_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_6_To_7_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_7_To_8_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_8_To_9_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_9_To_10_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_10_To_11_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Culled_By_Age_In_Months_Greater_11_Months",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Stillborn",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_Number_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Scours",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Scours_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Scours_Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Scours_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Scours_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Scours_Number_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_Number_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Bloat_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Diarrhea",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Diarrhea_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Diarrhea_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Diarrhea_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Diarrhea_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Hernia",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Hernia_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Hernia_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Hernia_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Hernia_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_Number_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Injury_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_Number_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Lameness_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Mycoplasma",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Mycoplasma_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Mycoplasma_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Mycoplasma_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Mycoplasma_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_3",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_3_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_3_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_3_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Navel_3_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Pneumonia",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Pneumonia_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Pneumonia_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Pneumonia_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Pneumonia_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Undetermined",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Undetermined_2_To_30_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Undetermined_30_To_Weaning_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Undetermined_Weaning_To_90_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Undetermined_90_To_120_Days",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age",
                    "show": true
                },
                {
                    "name": "Percent_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Percent_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Percent_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Percent_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Percent_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Brd",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Brd_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Brd_Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Brd_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Brd_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Number_Heifer_Died_Of_Brd_Number_Bred_Heifers",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_0_Days_To_Weaning",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_Number_Weaned_To_120_Days",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_120_Days_To_180_Days",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_180_Days_To_Breeding",
                    "show": true
                },
                {
                    "name": "Percent_Heifer_Culled_By_Age_Number_Bred_Heifers",
                    "show": true
                },
            ]
        }
    ]
}

export const defaultchartsetting = [
    { key: "1", id: "ecmchart", name: "Energy Corrected Milk", value: true, width: "half_page" },
    { key: "2", id: "testdaymilkchart", name: "Test Day Milk", value: true, width: "half_page" },
    { key: "3", id: "lactation1chart", name: "1st Lactation", value: true, width: "half_page" },
    { key: "4", id: "lactation2chart", name: "2nd Lactation", value: true, width: "half_page" },
    { key: "5", id: "lactation3chart", name: "3rd+ Lactation", value: true, width: "half_page" },
    { key: "6", id: "herddatachart", name: "Percent Pregnant in Herd", value: true, width: "half_page" },
    { key: "7", id: "calfheiferchart", name: "1st Calf Heifer Profiles", value: true, width: "half_page" },
    { key: "8", id: "daysopenchart", name: "Days Open", value: true, width: "half_page" },
    { key: "9", id: "percentfatchart", name: "Percent Fat", value: true, width: "half_page" },
    { key: "10", id: "somaticcellchart", name: "Somatic Cell", value: true, width: "half_page" },
    { key: "11", id: "percentproteinchart", name: "Percent Protein", value: true, width: "half_page" },
    { key: "12", id: "totallbschart", name: "Total lbs fat+protein", value: true, width: "half_page" },
    { key: "13", id: "avgdimchart", name: "Average DIM", value: true, width: "half_page" },
    { key: "14", id: "calvingintervalchart", name: "Calving Intervals", value: true, width: "half_page" },
    { key: "15", id: "annualcullchart", name: "Annual Cull", value: true, width: "half_page" },
    { key: "16", id: "lactationcurvechart", name: "Lactation Curve", value: true, width: "half_page" },
];

export const defaultchartsettingwithoutlactationcurve = [
    { key: "1", id: "ecmchart", name: "Energy Corrected Milk", value: true, width: "half_page" },
    { key: "2", id: "testdaymilkchart", name: "Test Day Milk", value: true, width: "half_page" },
    { key: "3", id: "lactation1chart", name: "1st Lactation", value: true, width: "half_page" },
    { key: "4", id: "lactation2chart", name: "2nd Lactation", value: true, width: "half_page" },
    { key: "5", id: "lactation3chart", name: "3rd+ Lactation", value: true, width: "half_page" },
    { key: "6", id: "herddatachart", name: "Percent Pregnant in Herd", value: true, width: "half_page" },
    { key: "7", id: "calfheiferchart", name: "1st Calf Heifer Profiles", value: true, width: "half_page" },
    { key: "8", id: "daysopenchart", name: "Days Open", value: true, width: "half_page" },
    { key: "9", id: "percentfatchart", name: "Percent Fat", value: true, width: "half_page" },
    { key: "10", id: "somaticcellchart", name: "Somatic Cell", value: true, width: "half_page" },
    { key: "11", id: "percentproteinchart", name: "Percent Protein", value: true, width: "half_page" },
    { key: "12", id: "totallbschart", name: "Total lbs fat+protein", value: true, width: "half_page" },
    { key: "13", id: "avgdimchart", name: "Average DIM", value: true, width: "half_page" },
    { key: "14", id: "calvingintervalchart", name: "Calving Intervals", value: true, width: "half_page" },
    { key: "15", id: "annualcullchart", name: "Annual Cull", value: true, width: "half_page" },
];

export const testdayANDWeeklyMilkArray = [
    "Less_30_DIM",
    "31_to_60",
    "61_to_90",
    "91_to_120",
    "121_to_150",
    "151_to_180",
    "181_to_210",
    "211_to_240",
    "241_to_270",
    "271_to_300",
    "Greater_300",
    "Milk_Week_2",
    "Milk_Week_4",
    "Milk_Week_8",
    "Milk_Week_15",
    "Milk_Week_21",
    "Milk_Week_25",
    "Milk_Week_32",
    "Milk_Week_37",
]

export const reporttotaltestdays = {
    "snapshot": 1,
    "benchmark": 1,
    "snapsix": 1,
    "cullinghistory": 1,
    "periodcomparison": 4,
    "subherdcomparison": 1,
    "snapshotgoals": 1,
    "heiferreport": 1
}

export const reportsetting = [{
    name: "snapshot",
    totaldates: reporttotaltestdays["snapshot"],
    label: "SNAPSHOT"
}, {
    name: "benchmark",
    totaldates: reporttotaltestdays["benchmark"],
    label: "BENCHMARK"
}, {
    name: "snapsix",
    totaldates: reporttotaltestdays["snapsix"],
    label: "SNAPSIX"
}, {
    name: "cullinghistory",
    totaldates: reporttotaltestdays["cullinghistory"],
    label: "CULLING HISTORY"
}, {
    name: "periodcomparison",
    totaldates: reporttotaltestdays["periodcomparison"],
    label: "PERIOD COMPARISON"
}, {
    name: "snapshotgoals",
    totaldates: reporttotaltestdays["snapshotgoals"],
    label: "SNAPSHOT GOALS"
}, {
    name: "subherdcomparison",
    totaldates: reporttotaltestdays["subherdcomparison"],
    label: "SUB-HERD COMPARISON"
}, {
    name: "heiferreport",
    totaldates: reporttotaltestdays["heiferreport"],
    label: "YOUNG ANIMAL"
}]

export const snapshotgoalreporttext = "The Snapshot Goals report will show the most recent Snapshot report’s performance, the most recent quarterly performance, the minimum and maximum performance available for up to the previous 12 months, and the current goal setting for each parameter.  There will also be a space for you and/or the client to designate what the desired goal should be and can then be used to customize these for the client.  The report may also be useful to show the variability in performance over the last year.";

export const cullinghistoryreporttext = " The Culling History report will summarize the culling history of the herd for up to the last 12 months as long as the data are available in the records.  The report may be useful to see on initial herd setup and occasionally thereafter (maybe once or twice a year) but is probably not necessary with each Snapshot.";

export const benchmarkreporttext = "The Purina Herdview Benchmark report summarizes recent dairy herd performance. Peer data are aggregated to compare herd performance to recent data for other herds with the same breed and geography. Peer data are not specific to herd management styles and should be interpreted with caution when targeting areas that need further attention. Reports should be saved to view changes in peer performance over time.";

export const periodcomparisonreporttext = "The Period Comparison report is a report that allows the user to define report periods sequentially starting with the oldest date. Each period is displayed as an aggregation of the test dates within the selected ranges. This report is useful as proof material before and after a management change or to view the same period in a prior year.";

export const snapsixreporttext = "The Snapsix report allows the user to view a summary of up to 6 previous periods consecutively. Previously stored data uploads are required to generate a Snapsix report. This report does not include a goals column, but the final column is an   overall summary of the values for the time period reported. This report can demonstrate longer trends in data, which may be useful as proof material.";

export const snapshotreporttext = "The Purina Dairy Snapshot report summarizes recent dairy herd performance. The report is customizable to meet the specific goals for most herd management styles and can be modified to be responsive to the current level of herd performance for a variety of parameters. The report is designed to give a complete overview while quickly targeting areas that may need further attention. The report also serves as a proof development tool, automatically accumulating trends over time in the course of consistent data uploads.";

export const dc35cullingreporttext = "Culling codes may be customized in DC305, which may not match the culling reasons listed. Please confirm culling reasons descriptions in DC305 Setup prior to interpretation of these parameters. For more information, refer to the parameter tooltips in Goals.\n\nOn-farm backups may not be collected with respect to test day intervals unless data are downloaded from a dairy records processing center. Report dates reflect backup date and last test day on record is listed under Production - All Cows. Average daily milk (MAVG) will update with backup date for herds with parlor meters (assign in Herd Details). Choice of test day milk (MILK) or MAVG is available in Sub-Herd Details for herds with parlor meters. Calculations for other report parameters are always relative to backup date.";

export const subherdscomparisonreporttext = "The Sub-Herd Comparison report allows the user to compare the performance of Sub-Herds at the same test date. At least two Sub-Herds are required to generate a Sub-Herd Comparison report.";

export const heiferreporttext = "The Purina Young Animal Report summarizes recent heifer data available and recorded in on-farm software. The report is designed to quickly target areas that may need further attention.\n\nThe report includes available young animal records for heifers, bulls and dairy beef animals when clearly reported. Calf inventories include sex and beef animals based on the dam’s calving record. Young animal inventories will reflect the sum of heifers and bulls with a calf record within each age demographic, with the exception for bred heifers. Dairy beef heifers and bulls remain in the inventory as >=180 d and Not Bred until sold or died.\n\nAccurate recording of health events should be encouraged and reports for DC305 herds may be customized to support consultative efforts to improve calf health. Health events from DC305 include bloat, navel, injury, lameness, bovine respiratory disease (BRD), and scours. Pneumonia events are mapped to BRD. Avoid mapping unrelated health events to these categories to maintain clean data. Health event records for each age demographic include initial cases, recurrences for an individual animal, and total event counts within the age group. Initial and recurring cases are determined by standardized event gaps. Health event gaps are: bloat = 21 d, navel = 21 d, injury = 21 d, lameness = 21 d, BRD = 10 d, and scours = 4 d. An individual animal with a recurrence of the same event beyond the designated event gap will only show up in the recurring and total event counts in any age demographic on future reports.";

export const goal_description = {
    "All Cows": {
        "Test_Day_Milk": "average of test day milk of all milking cows\nwhere test day milk is nonzero",
        "Latest_Weekly_Milk": "average weekly milk of all milking cows where MAVG is nonzero",
        "ECM": "average of energy corrected milk of individual milking cows\n(0.327*milk+(12.95*pctf*milk)+(7.65*pctp*milk))",
        "Management_Milk_Level": "average of management level milk of all milking cows where MLM is nonzero",
        "Summit_Milk": "average of summit milk of cows with DIM less than or equal 120 where summit milk is nonzero",
        "Peak_Weekly_Milk": "average of peak weekly milk occurs in current reporting period for cows more than 30 DIM and week less than 19 (lact1) or week less than 14(lact2+)",
        "Peak_Test_Day_Milk": "average of peak test day milk occurs in current reporting period for cows greater than 40 DIM",
        "Days_to_Peak_Weekly_Milk": "Average DIM at the time when peak weekly milk is reached.  Only cows with peak weekly milk values are used to calculate this average",
        "Days_to_Peak_Test_Day_Milk": "Average DIM at the time when peak test day milk is reached. Only cows with peak test day milk values are used to calculate this average",
        "Early_Slope": "the ratio of milk production to DIM for milking cows during 7 to 45 DIM",
        "Percent_Greater_Than_100_Lbs_Milk": "the percentage of milking cows with milk production greater than 100 lbs",
        "Average_DIM": "average DIM of all milking cows in herd, we recommend no adjustment to the default goals for average DIM",
        "Current_305_ME": "average 305 Mature Equivalent for milking cows in herd",
        "First_Month_305_ME": "average first month 305 Mature Equivalent for cows less than 66 DIM",
        "Percent_Fat": "average of percentage of milk fat for all milking cows where milk fat is nonzero",
        "Percent_Protein": "average of percentage of milk protein for all milking cows where milk protein is nonzero"
    },
    "Lactation 1": {
        "Test_Day_Milk_Less_Than_30_DIM": "average milk production of cows within 1 to 30 DIM for lactation 1",
        "Test_Day_Milk_31_to_60_DIM": "average milk production of cows within 31 to 60 DIM for lactation 1",
        "Test_Day_Milk_61_to_90_DIM": "average milk production of cows within 61 to 90 DIM for lactation 1",
        "Test_Day_Milk_91_to_120_DIM": "average milk production of cows within 91 to 120 DIM for lactation 1",
        "Test_Day_Milk_121_to_150_DIM": "average milk production of cows within 121 to 150 DIM for lactation 1",
        "Test_Day_Milk_151_to_180_DIM": "average milk production of cows within 151 to 180 DIM for lactation 1",
        "Test_Day_Milk_181_to_210_DIM": "average milk production of cows within 181 to 210 DIM for lactation 1",
        "Test_Day_Milk_211_to_240_DIM": "average milk production of cows within 211 to 240 DIM for lactation 1",
        "Test_Day_Milk_241_to_270_DIM": "average milk production of cows within 241 to 270 DIM for lactation 1",
        "Test_Day_Milk_271_to_300_DIM": "average milk production of cows within 271 to 300 DIM for lactation 1",
        "Test_Day_Milk_Greater_Than_300_DIM": "average milk production of cows >300 DIM for lactation 1",
        "Milk_Week_2": "average week 2 milk for cows in lactation 1 that passed through 14 DIM at any time during the reporting period",
        "Milk_Week_4": "average week 4 milk for cows in lactation 1 that passed through 28 DIM at any time during the reporting period",
        "Milk_Week_8": "average week 8 milk for cows in lactation 1 that passed through 56 DIM at any time during the reporting period",
        "Milk_Week_15": "average week 15 milk for cows in lactation 1 that passed through 105 DIM at any time during the reporting period",
        "Milk_Week_21": "average week 21 milk for cows in lactation 1 that passed through 147 DIM at any time during the reporting period",
        "Milk_Week_25": "average week 25 milk for cows in lactation 1 that passed through 175 DIM at any time during the reporting period",
        "Milk_Week_32": "average week 32 milk for cows in lactation 1 that passed through 224 DIM at any time during the reporting period",
        "Milk_Week_37": "average week 37 milk for cows in lactation 1 that passed through 259 DIM at any time during the reporting period",
        "Test_Day_Milk": "average test day milk of milking cows where test day milk is nonzero in lactation 1",
        "Latest_Weekly_Milk": "average weekly milk of all milking cows where MAVG is nonzero in lactation 1",
        "ECM": "average energy corrected milk of individual milking cows in lactation 1\n(0.327*milk+(12.95*pctf*milk)+(7.65*pctp*milk))",
        "Management_Milk_Level": "average management level milk of milking cows where MLM is nonzero in lactation 1",
        "Summit_Milk": "average of summit milk of cows with DIM less than or equal 120 where summit milk is nonzero in lactation 1",
        "Peak_Test_Day_Milk": "average peak test day milk occurs in current reporting period for cows greater than 40 DIM in lactation 1",
        "Peak_Weekly_Milk": "average peak weekly milk occurs in current reporting period for cows more than 30 DIM and week less than 19 in lactation 1",
        "Days_to_Peak_Test_Day_Milk": "days to peak test day milk applied to same group of cows for peak test day milk",
        "Days_to_Peak_Weekly_Milk": "days to peak weekly milk applied to same group of cows for peak weekly milk",
        "Early_Slope": "the ratio of milk production to DIM for milking cows in lactation 1 during 7 to 45 DIM",
        "Average_DIM": "average DIM of all 1st lactating cows in herd, we recommend no adjustment to the default goals for average DIM",
        "Current_305_ME": "average 305 Mature Equivalent for cows in lactation 1",
        "First_Month_305_ME": "average first month 305 Mature Equivalent for cows < 66 DIM in lactation 1",
        "Percent_Fat": "average percentage of milk fat where nonzero for cows in lactation 1",
        "Percent_Protein": "average percentage of milk protein where nonzero for cows in lactation 1"
    },
    "Lactation 2": {
        "Test_Day_Milk_Less_Than_30_DIM": "average milk production of cows within 1 to 30 DIM for lactation 2",
        "Test_Day_Milk_31_to_60_DIM": "average milk production of cows within 31 to 60 DIM for lactation 2",
        "Test_Day_Milk_61_to_90_DIM": "average milk production of cows within 61 to 90 DIM for lactation 2",
        "Test_Day_Milk_91_to_120_DIM": "average milk production of cows within 91 to 120 DIM for lactation 2",
        "Test_Day_Milk_121_to_150_DIM": "average milk production of cows within 121 to 150 DIM for lactation 2",
        "Test_Day_Milk_151_to_180_DIM": "average milk production of cows within 151 to 180 DIM for lactation 2",
        "Test_Day_Milk_181_to_210_DIM": "average milk production of cows within 181 to 210 DIM for lactation 2",
        "Test_Day_Milk_211_to_240_DIM": "average milk production of cows within 211 to 240 DIM for lactation 2",
        "Test_Day_Milk_241_to_270_DIM": "average milk production of cows within 241 to 270 DIM for lactation 2",
        "Test_Day_Milk_271_to_300_DIM": "average milk production of cows within 271 to 300 DIM for lactation 2",
        "Test_Day_Milk_Greater_Than_300_DIM": "average milk production of cows >300 DIM for lactation 2",
        "Milk_Week_2": "average week 2 milk for cows in lactation 2 that passed through 14 DIM at any time during the reporting period",
        "Milk_Week_4": "average week 4 milk for cows in lactation 2 that passed through 28 DIM at any time during the reporting period",
        "Milk_Week_8": "average week 8 milk for cows in lactation 2 that passed through 56 DIM at any time during the reporting period",
        "Milk_Week_15": "average week 15 milk for cows in lactation 2 that passed through 105 DIM at any time during the reporting period",
        "Milk_Week_21": "average week 21 milk for cows in lactation 2 that passed through 147 DIM at any time during the reporting period",
        "Milk_Week_25": "average week 25 milk for cows in lactation 2 that passed through 175 DIM at any time during the reporting period",
        "Milk_Week_32": "average week 32 milk for cows in lactation 2 that passed through 224 DIM at any time during the reporting period",
        "Milk_Week_37": "average week 37 milk for cows in lactation 2 that passed through 259 DIM at any time during the reporting period",
        "Test_Day_Milk": "average test day milk of milking cows where test day milk is nonzero in lactation 2",
        "Latest_Weekly_Milk": "average weekly milk of all milking cows where MAVG is nonzero in lactation 2",
        "ECM": "average energy corrected milk of individual milking cows in lactation 2\n(0.327*milk+(12.95*pctf*milk)+(7.65*pctp*milk))",
        "Management_Milk_Level": "average management level milk of milking cows where MLM is nonzero in lactation 2",
        "Summit_Milk": "average of summit milk of cows with DIM less than or equal 120 where summit milk is nonzero in lactation 2",
        "Peak_Test_Day_Milk": "average peak test day milk occurs in current reporting period for cows greater than 40 DIM in lactation 2",
        "Peak_Weekly_Milk": "average peak weekly milk occurs in current reporting period for cows more than 30 DIM and week less than 19 in lactation 2",
        "Days_to_Peak_Test_Day_Milk": "days to peak test day milk applied to same group of cows for peak test day milk",
        "Days_to_Peak_Weekly_Milk": "days to peak weekly milk applied to same group of cows for peak weekly milk",
        "Early_Slope": "the ratio of milk production to DIM for milking cows in lactation 1 during 7 to 45 DIM",
        "Average_DIM": "average DIM of all 2nd lactating cows in herd, we recommend no adjustment to the default goals for average DIM",
        "Current_305_ME": "average 305 Mature Equivalent for cows in lactation 2",
        "First_Month_305_ME": "average first month 305 Mature Equivalent for cows < 66 DIM in lactation 2",
        "Percent_Fat": "average percentage of milk fat where nonzero for cows in lactation 2",
        "Percent_Protein": "average percentage of milk protein where nonzero for cows in lactation 2"
    },
    "Lactation 3+": {
        "Test_Day_Milk_Less_Than_30_DIM": "average milk production of cows within 1 to 30 DIM for lactation 3+",
        "Test_Day_Milk_31_to_60_DIM": "average milk production of cows within 31 to 60 DIM for lactation 3+",
        "Test_Day_Milk_61_to_90_DIM": "average milk production of cows within 61 to 90 DIM for lactation 3+",
        "Test_Day_Milk_91_to_120_DIM": "average milk production of cows within 91 to 120 DIM for lactation 3+",
        "Test_Day_Milk_121_to_150_DIM": "average milk production of cows within 121 to 150 DIM for lactation 3+",
        "Test_Day_Milk_151_to_180_DIM": "average milk production of cows within 151 to 180 DIM for lactation 3+",
        "Test_Day_Milk_181_to_210_DIM": "average milk production of cows within 181 to 210 DIM for lactation 3+",
        "Test_Day_Milk_211_to_240_DIM": "average milk production of cows within 211 to 240 DIM for lactation 3+",
        "Test_Day_Milk_241_to_270_DIM": "average milk production of cows within 241 to 270 DIM for lactation 3+",
        "Test_Day_Milk_271_to_300_DIM": "average milk production of cows within 271 to 300 DIM for lactation 3+",
        "Test_Day_Milk_Greater_Than_300_DIM": "average milk production of cows >300 DIM for lactation 3+",
        "Milk_Week_2": "average week 2 milk for cows in lactation 3+ that passed through 14 DIM at any time during the reporting period",
        "Milk_Week_4": "average week 4 milk for cows in lactation 3+ that passed through 28 DIM at any time during the reporting period",
        "Milk_Week_8": "average week 8 milk for cows in lactation 3+ that passed through 56 DIM at any time during the reporting period",
        "Milk_Week_15": "average week 15 milk for cows in lactation 3+ that passed through 105 DIM at any time during the reporting period",
        "Milk_Week_21": "average week 21 milk for cows in lactation 3+ that passed through 147 DIM at any time during the reporting period",
        "Milk_Week_25": "average week 25 milk for cows in lactation 3+ that passed through 175 DIM at any time during the reporting period",
        "Milk_Week_32": "average week 32 milk for cows in lactation 3+ that passed through 224 DIM at any time during the reporting period",
        "Milk_Week_37": "average week 37 milk for cows in lactation 3+ that passed through 259 DIM at any time during the reporting period",
        "Test_Day_Milk": "average test day milk of milking cows where test day milk is nonzero in lactation 3+",
        "Latest_Weekly_Milk": "average weekly milk of all milking cows where MAVG is nonzero in lactation 3+",
        "ECM": "average energy corrected milk of individual milking cows in lactation 3+\n(0.327*milk+(12.95*pctf*milk)+(7.65*pctp*milk))",
        "Management_Milk_Level": "average management level milk of milking cows where MLM is nonzero in lactation 3+",
        "Summit_Milk": "average of summit milk of cows with DIM less than or equal 120 where summit milk is nonzero in lactation 3+",
        "Peak_Test_Day_Milk": "average peak test day milk occurs in current reporting period for cows greater than 40 DIM in lactation 3+",
        "Peak_Weekly_Milk": "average peak weekly milk occurs in current reporting period for cows more than 30 DIM and week less than 19 in lactation 3+",
        "Days_to_Peak_Test_Day_Milk": "days to peak test day milk applied to same group of cows for peak test day milk",
        "Days_to_Peak_Weekly_Milk": "days to peak weekly milk applied to same group of cows for peak weekly milk",
        "Early_Slope": "the ratio of milk production to DIM for milking cows in lactation 1 during 7 to 45 DIM",
        "Average_DIM": "average DIM of all 3+ lactating cows in herd, we recommend no adjustment to the default goals for average DIM",
        "Current_305_ME": "average 305 Mature Equivalent for cows in lactation 3+",
        "First_Month_305_ME": "average first month 305 Mature Equivalent for cows < 66 DIM in lactation 3+",
        "Percent_Fat": "average percentage of milk fat where nonzero for cows in lactation 3+",
        "Percent_Protein": "average percentage of milk protein where nonzero for cows in lactation 3+"
    },
    "Component Analysis": {
        "Percent_1st_Fat_Test_Greater_5_5": "percentage of fat percent greater than 5.5% in cows <=30 DIM",
        "Percent_1st_Fat_Test_Greater_5_5_Lactation_1": "percentage of fat percent greater than 5.5% in 1st lactating cows <=30 DIM",
        "Percent_1st_Fat_Test_Greater_5_5_Lactation_2": "percentage of fat percent greater than 5.5% in 2nd lactating cows <=30 DIM",
        "Percent_1st_Fat_Test_Greater_5_5_Lactation_3": "percentage of fat percent greater than 5.5% in 3+ lactating cows <=30 DIM"
    },
    "Reproduction": {
        "Number_Pregnant": "number of cows pregnant in the herd",
        "Percent_Pregnant": "percentage of pregnant cows in all cows in the herd",
        "Number_Open_Eligible": "number of cows with DIM greater than vwp in open status",
        "Percent_Pregnant_Vet_Check_Last_20_Days": "percentage of cows diagnosed pregnant in all cows that had pregnancy check in current reporting period",
        "Percent_Herd_Diagnosed_Last_30_Days": "percentage of cows diagnosed pregnancy in last 30 days in all cows in the herd",
        "Days_Open_Pregnant_Cows": "average of days open for all pregnant cows",
        "Number_Not_Bred_Greater_80_DIM": "number of cows greater than 80 DIM and not bred or were bred for the first time at greater than 80 DIM during the reporting period",
        "Percent_Not_Bred_Greater_80_DIM": "percentage of cows not bred in all cows greater than 80 DIM",
        "Percent_Eligible_Cows_Bred": "percentage of cows bred in all eligible cows",
        "Percent_Eligible_Cows_Not_Bred": "percentage of cows not bred in all eligible cows",
        "Average_DIM_1st_Service": "average first insemination DIM of all milking cows",
        "Percent_New_Pregnant_Days_Open_Greater_180": "percentage of cows with days open greater than 180 in all pregnant cows",
        "Percent_New_Pregnant_Days_Open_Greater_Than_180": "percentage of cows with days open greater than 180 in all cows diagnosed pregnant in current reporting period",
        "Percent_Open_Eligible_Days_Open_Greater_180": "percentage of cows with days open greater than 180 in all eligible cows",
        "Percent_Pregnant_150_DIM": "percentage of cows with days open less than or equal 150 in all pregnant cows",
        "Percent_New_Pregnant_150_DIM": "percentage of cows with days open less than or equal 150 in all cows diagnosed pregnancy in current reporting period",
        "Number_Pregnant_115_DIM": "number of all pregnant cows in the herd with days open less than or equal 115 days",
        "Percent_Pregnant_115_DIM": "percentage of cows with days open less than or equal 115 in all pregnant cows",
        "Percent_New_Pregnant_115_DIM": "percentage of cows with days open less than or equal 115 in all cows diagnosed pregnancy in current reporting period",
        "1st_Service_Conception_Rate": "percentage of cows diagnosed pregnant after 1st time bred",
        "Recent_First_Service_Conception_Rate": "percentage of cows diagnosed pregnant in current reporting period after 1st time bred",
        "Services_Per_Conception": "average times bred of all pregnant cows",
        "Recent_Services_per_Conception": "average times bred of all cows diagnosed pregnant in current reporting period",
        "Services_Per_Aminal": "average times bred of all eligible cows",
        "Conception_Rate": "pregnant cows as percentage of total times bred",
        "Recent_Conception_Rate": "pregnant cows diagnosed in current reporting period as percentage of total times bred of these cows",
        "Calving_Interval_Fresh_Cows": "average calving interval of cows calved in current reporting period",
        "Projected_Calving_Interval_Recent_Pregnant": "average projected calving interval (=due date – calving date) of cows diagnosed pregnant in current reporting period",
        "Min_Projected_Calving_Interval": "Estimate of the minimum projected calving interval for all cows in the herd. Uses the projected calving interval for confirmed pregnant cows. Assumes:  all bred, non-pregnant cows will become pregnant on the last recorded breeding; all non-bred, non-pregnant cows with DIM greater the vwp will be bred and become pregnant in the next 30 days; all fresh cows (DIM less than vwp) will have a 13 month calving interval",
        "Percent_Herd_Coded_DNB": "percentage of ‘do not bred’ cows in all cows in the herd",
        "Percent_Herd_Coded_DNB_This_Period": "percentage of ‘do not bred’ cows in current reporting period in all cows in the herd"
    },
    "Health": {
        "Number_Retained_Placenta": "number of cows that have retained placenta in current reporting period",
        "Percent_Retained_Placenta": "percentage of cows that have retained placenta in all cows fresh in current period",
        "Number_Metritis": "number of cows that have metritis in current reporting period",
        "Percent_Metritis": "percentage of cows that have metritis in all cows calved in current period",
        "Number_Displaced_Abomasum_Fresh_Cows": "number of fresh cows (DIM<45) that have displaced abomasum in current reporting period",
        "Percent_Displaced_Abomasum_Fresh_Cows": "percentage of fresh cows that have displaced abomasum (DIM<45) in all cows calved in current period",
        "DIM_Displaced_Abomasum_Fresh_Cows": "average DIM of fresh cows (DIM<45) that have displaced abomasum in current reporting period",
        "Number_Displaced_Abomasum_All_Cows": "number of cows that have displaced abomasum in current reporting period",
        "Percent_Displaced_Abomasum_All_Cows": "cows that have displaced abomasum in last 365 days as percentage of all cows in herd. It is an estimated annual incidence rate of the occurrence of DAs in the herd.",
        "DIM_Displaced_Abomasum_All_Cows": "average DIM of cows that have DAs in current reporting period",
        "Number_Milk_Fever": "number of cows that have milk fever in current reporting period",
        "Percent_Milk_Fever": "percentage of cows that have milk fever in all cows calved in current period",
        "Number_Ketosis": "number of cows that have ketosis in current reporting period",
        "Percent_Ketosis": "percentage of cows that have ketosis in all cows calved in current period",
        "Number_Lame": "number of cows that have lame in current reporting period",
        "Percent_Lame": "cows that have lame in last 365 days as percentage of all cows in herd. It is an estimated annual incidence rate of the occurrence of lameness in the herd",
        "Number_Foot_Trim": "number of cows that have foot trim in current reporting period",
        "Percent_Foot_Trim": "cows that have foot trim in last 365 days as percentage of all cows in the herd. It is an estimated annual incidence rate of the occurrence of foot trimming in the herd.",
        "Number_Dystocia": "number of cows that have dystocia (calving ease >2) in current reporting period",
        "Percent_Dystocia": "percentage of cows that have dystocia in all cows calved in current period",
        "Number_Mastitis": "number of cows that have mastitis in current reporting period",
        "Percent_Mastitis": "percentage of cows that have mastitis in all cows calved in current period",
        "Number_Uterine_Problem": "number of cows that have uterine problem in current reporting period",
        "Percent_Uterine_Problem": "percentage of cows that have uterine problem in all cows calved in current period",
        "Number_Abortions": "number of cows that have abortion in current reporting period"
    },
    "Milk Quality": {
        "Number_New_Clinicals_Fresh": "DC305: number of cows less than 45 DIM that got first mastitis in current reporting period\n\nPCDART: number of cows calved in current period with scc>200 and previous scc<200",
        "Percent_New_Clinicals_Fresh": "New Clinical Mastitis - Fresh Cows as percentage of cows calved in current period",
        "Number_New_Clinicals_All": "DC305: number of cows that got first mastitis in current reporting period\n\nPCDART: number of milking cows with scc>200 and previous scc<200",
        "Percent_New_Clinicals_All": "cows that got first mastitis in last 365 days as percentage of total milking plus dry cows. It is an estimate of the annual incidence rate of the occurrence of new clinical mastitis cases in the herd",
        "Percent_New_Chronic_All": "cows that got three or more mastitis in last 365 days as percentage of total milking plus dry cows. An estimate of the annual incidence rate of the occurrence of mastitis cases in the herd that are classified as chronic (two or more cases in a lactation)",
        "SCC_Dried_Cows": "for DC305, this value only populates if dry date > test date",
        "SCC_Lactation_1_Dried_Cows": "for DC305, this value only populates if dry date > test date",
        "SCC_Lactation_2_Dried_Cows": "for DC305, this value only populates if dry date > test date",
        "SCC_Lactation_3_Dried_Cows": "for DC305, this value only populates if dry date > test date",

    },
    "Herd Inventory": {
        "Number_Milking": "number of milking cows in the herd",
        "Percent_Milking": "number of milking cows as percentage of all cows in the herd",
        "Number_Dry": "number of dry cows in the herd",
        "Percent_Dry": "number of dry cows as percentage of all cows in the herd",
        "Percent_1st_Calf_Heifers": "cows at 1st lactation as percentage of all cows in the herd",
        "Age_1st_Calving": "average age at calving for 1st lactation cows",
        "Number_Fresh": "number of cows that calved in current reporting period",
        "Number_Dried": "number of cows that were dried in current reporting period",
        "Number_Fresh_Gone": "number of cows that calved in current period are culled",
        "Percent_Heifers_Fresh": "of the cows that calved current period, the percent of them that were 1st calf heifers",
        "Average_Days_Dry_Fresh_Cows": "average previous dry days of 2+ lactation cows that calved in current reporting period",
        "Percent_Fresh_Dry_Days_Less_30": "2+ lactation fresh cows with previous dry days less than 30 as percentage of all 2+ lactation fresh cows in current period",
        "Percent_Fresh_Dry_Days_Less_40": "2+ lactation fresh cows with previous dry days less than 40 as percentage of all 2+ lactation fresh cows in current period",
        "Percent_Fresh_Dry_Days_Greater_70": "2+ lactation fresh cows with previous dry days greater than 70 as percentage of all 2+ lactation fresh cows in current period",
        "Projected_Days_Dry_Dried_Cows": "average projected dry days (due date- dry date) for cows were dried in current period",            
    },
    "Calves": {
        "Number_Heifers_Born": "number of heifers born (either dead or alive) in current period",
        "Number_Bulls_Born": "number of bulls born (either dead or alive) in current period",
        "Percent_Heifer_Calves": "heifers as percentage of all calves born in current period",
        "Number_Sets_Twins": "number of twins born in current period",
        "Percent_Twinning": "calvings resulted in twins as percentage of all calvings in current period",
        "Percent_Born_Dead": "dead calves as percentage of all calves born in current period",
        "Number_Heifers_Dead": "number of dead heifers in current period",
        "Number_Bulls_Dead": "number of dead bulls in current period",        
    },
    "Heifer Enterprise": {
        "Number_In_Development": "number of heifers in the records",
        "Number_Less_3_Months": "number of heifers in records that were less than 3 months of age",
        "Number_3_to_5_Months": "number of heifers in records that were from 3 to 5 months of age",
        "Number_6_to_8_Months": "number of heifers in records that were from 6 to 8 months of age ",
        "Number_9_to_11_Months": "number of heifers in records that were from 9 to 11 months of age",
        "Number_12_to_13_Months": "number of heifers in records that were from 12 to 14 months of age",
        "Number_Greater_14_Months": "number of heifers in records that were greater than 14 months of age",
        "Age_1st_Breeding": "average age (as month) of heifers received first breeding",
        "Percent_pregnant": "pregnant heifers as percentage of heifers received breeding",
        "services_per_conception": "average services of pregnant heifers",
        "annual_cull_rate": "number of heifers left the herd in last 365 days as percentage of total heifers in development",
        "annual_death_rate": "number died in last 365 days as percentage of total heifers in development",
    },
    "Culling": {
        "Annual_Cull_Rate": "cows left the herd in last 365 days as percentage of all cows in the herd at the backup time ",
        "Annual_Death_Rate": "cows died in last 365 days as percentage of all cows in the herd at the backup time",
        "Speed": "an annualized culling speed based on the culling that occurred during the period being reviewed",
        "Number_Culled": "number of cows culled in current reporting period",
        "Number_Died": "number of cows died in current reporting period",
        "Percent_Culled_DIM_Less_31_DIM": "fresh cows <=30 DIM culled in current reporting period as percentage of all cows calved at the same period",
        "Percent_Culled_DIM_31_to_60_DIM": "fresh cows 31 to 60 DIM culled in current reporting period as percentage of all cows calved at the same period",
        "Percent_Culled_Less_31_DIM": "cows <=30 DIM culled in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Culled_31_to_60_DIM": "cows 31 to 60 DIM culled in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Culled_61_to_90_DIM": "cows 61 to 90 DIM culled in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Culled_91_to_120_DIM": "cows 91 to 120 DIM culled in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Culled_121_to_300_DIM": "cows 121 to 300 DIM culled in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Culled_Greater_300_DIM": "cows >300 DIM culled in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Culled_Dry": "dry cows culled in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Culled_Feet_Legs": "cows culled in current period for reason 1(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Dairy": "cows culled in current period for reason 2(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Production": "cows culled in current period for reason 3(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Breeding": "cows culled in current period for reason 4(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Injury_Other": "cows culled in current period for reason 5(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Died": "cows culled in current period for reason 6 as percentage of all cows in the herd at the backup time (defined by user in dplan)",
        "Percent_Culled_Mastitis": "cows culled in current period for reason 7(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Disease": "cows culled in current period for reason 8(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Udder_Traits": "cows culled in current period for reason 9(pcdart, defined by user in dc305 and dplan) as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Unknown_Reasons": "cows culled in current period for unknow reasons as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Lactation_1": "1st lactating cows culled in current period as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Lactation_2": "2nd lactating cows culled in current period as percentage of all cows in the herd at the backup time",
        "Percent_Culled_Lactation_3+": "3+ lactating cows culled in current period as percentage of all cows in the herd at the backup time",
        "Speed_Lactation_1": "1st lactating cows culled in last 365 days as percentage of total 1st lactating cow",
        "Speed_Lactation_2": "2nd lactating cows culled in last 365 days as percentage of total 2nd lactating cow",
        "Speed_Lactation_3+": "3+ lactating cows culled in last 365 days as percentage of total 3+ lactating cow",
        "Percent_Died_Lactation_1": "1st lactating cows died in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Died_Lactation_2": "2nd lactating cows die in current period as percentage of all cows in the herd at the backup time ",
        "Percent_Died_Lactation_3+": "3+ lactating cows die in current period as percentage of all cows in the herd at the backup time ",        
    }
}

export const non_editable_component_analysis = [
    "Total_Lbs_Fat_Protein",
    "Total_Lbs_Fat_Protein_Lactation_1",
    "Total_Lbs_Fat_Protein_Lactation_2",
    "Total_Pounds_of_Fat_and_Protein_Lactation_3+",
    "Total_Lbs_Fat_Protein_Less_30_DIM",
    "Total_Lbs_Fat_Protein_31_to_60_DIM",
    "Total_Lbs_Fat_Protein_61_to_90_DIM",
    "Total_Lbs_Fat_Protein_91_to_120_DIM",
    "Total_Lbs_Fat_Protein_121_to_200_DIM",
    "Total_Lbs_Fat_Protein_Greater_200_DIM"
]

export const table_headers = [
    "Reason_Culled", "Culling_by_Lactation_Group", "Culled_Dry", "Culled_Fresh", "Number_Culled_Dry",
    "Number_Bloat", "Number_Diarrhea", "Number_Hernia", "Number_Injury", "Number_Lameness", "Number_Mycoplasma",
    "Number_Navel_3", "Number_Pneumonia", "Number_Undetermined", "Percent_Heifer_Culled_By_Age_In_Months",
    "Percent_Heifer_Culled_By_Age", "Number_Heifer_Culled_By_Age_In_Months", "Number_Navel", "Number_Brd", "Number_Scours",
    "Number_Heifer_Died_Of_Bloat", "Number_Heifer_Died_Of_Scours", "Number_Heifer_Died_Of_Navel", "Number_Heifer_Died_Of_Injury",
    "Number_Heifer_Died_Of_Lameness", "Number_Heifer_Died_Of_Brd"
]

export const row_subheader = [
    "Number_Bloat", "Number_Diarrhea", "Number_Hernia", "Number_Injury", "Number_Lameness", "Number_Mycoplasma",
    "Number_Navel_3", "Number_Pneumonia", "Number_Undetermined", "Percent_Heifer_Culled_By_Age_In_Months",
    "Percent_Heifer_Culled_By_Age", "Number_Heifer_Culled_By_Age_In_Months", "Number_Navel", "Number_Brd", "Number_Scours",
    "Number_Heifer_Died_Of_Bloat", "Number_Heifer_Died_Of_Scours", "Number_Heifer_Died_Of_Navel", "Number_Heifer_Died_Of_Injury",
    "Number_Heifer_Died_Of_Lameness", "Number_Heifer_Died_Of_Brd", "Number_Culled"
]