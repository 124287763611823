import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';


export async function getherdaccess(herdId) {
    let herdaccess = await NetworkService.getData(ApiUrl.getHerdAccess(herdId));
    return herdaccess;
}

export async function getuserlists() {
    let userlists = await NetworkService.getData(ApiUrl.getuserlists());
    return userlists;
}

export async function getuserdetails() {
    let userlists = await NetworkService.getData(ApiUrl.getuserdetails());
    return userlists;
}

export async function saveherdaccess(herdaccess) {
    let response = await NetworkService.postData(ApiUrl.saveherdaccess(), herdaccess);
    return response;
}


export async function checkadmin() {
    let response = await NetworkService.getData(ApiUrl.getcheckadminurl());
    return response;
}

export async function assignDefaultHerds() {
    let user_detail = await NetworkService.getData(ApiUrl.getuserdetails());
    let response = await NetworkService.postData(ApiUrl.assignDefaultHerds(), user_detail);
    return response;
}
