import { useEffect } from "react";
import { ADOBE_ANALYTICS_URL } from "../../configs/auth.config";

export const InitAdobeAnalytics = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = ADOBE_ANALYTICS_URL;
        script.async = true;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);
}

export async function sendActionToAdobeAnalytics(eventName, body) {
    window.adobeDataLayer = window.adobeDataLayer || [];
    if (body !== undefined && body !== null) {
        body["event"] = eventName;
        window.adobeDataLayer.push(body);
    }
}