import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
//material ui and services components import
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
//external library imports
import { useSnackbar } from 'notistack';
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
//constants and services import
import { SNACKBAR_TYPE } from '../../../common/constants/constants';
//components import
import Loader from '../../../common/dialogs/loader/loader';
import { getHerdForUser } from '../../../common/services/herd.service';
import { caseInsensitiveSortData, handleError, decodeApiResponse } from '../../../common/utils/utility';
import Reporting from '../../../components/herds/Subherd/Reporting/Reporting';
import PageHeader from '../../../components/pageHeader/pageHeader';

const ReportMain = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [loaderstatus, setloaderstatus] = React.useState(false);
    const [herdsList, setHerdsList] = React.useState([]);
    const [subHerds, setSubHerds] = React.useState({ allSubHerds: [], selectedSubHerds: '' });
    const [selectedHerd, setSelectedHerd] = React.useState({ diaryName: '', herdId: '', selectedHerd: {} });
    const [subHerdSelected, setSubHerdSelected] = React.useState({ subHerdId: '', subHerdSelected: '', subherdError: false });
    const [noHerdMsg, setnoHerdMsg] = React.useState(null);
    const [herddata, setherddata] = React.useState(null);
    const [subherddata, setsubherddata] = React.useState(null);

    const admincheckresponse = useSelector(
        (state) => state.admin.usertype
    );

    const filteractivesubherd = (subherdarray) => {
        if (subherdarray) {
            return subherdarray.filter((d) => d.active);
        } else {
            return [];
        }
    }

    useEffect(() => {
        setloaderstatus(true);
        let flag = false;
        if (admincheckresponse === "Administrator") {
            flag = true;
        }
        getHerdForUser(admincheckresponse).then((response) => {
            const decodedResponse = decodeApiResponse(response)
            if (response.length > 0) {
                response = decodedResponse.map((data) => {
                    if (data?.AccessDetails.access) {
                        return data
                    } else {
                        data.AccessDetails = {
                            ...data?.AccessDetails?.AccessDetails
                        }
                    }
                    return data;
                })
                let temp = [];
                let j = 0;
                for (let i = response.length - 1; i >= 0; i--) {
                    let data = response[i];
                    if (data?.AccessDetails?.AccessDetails) {
                        temp[j++] = {
                            ...response[i],
                            AccessDetails: data.AccessDetails.AccessDetails,
                            UserEmail: data.UserEmail ? data.UserEmail : ""
                        };
                    } else {
                        temp[j++] = {
                            ...response[i],
                            UserEmail: data.UserEmail ? data.UserEmail : ""
                        };
                    }
                }

                if (flag) {
                    temp = temp.filter((d) => {
                        return d?.status !== "for_deletion";
                    })
                } else {
                    temp = temp.filter((d) => {
                        return d?.status !== "for_deletion" && d.UserEmail !== "" && (d.HerdAssignments.length !== 0);
                    })
                }
                sortHerds(temp);
                setloaderstatus(false);
            } else {
                setloaderstatus(false);
                setnoHerdMsg("No Herds Available")
            }
        }, error => {
            setloaderstatus(false);
            if (error.response.status === 404) {
                setnoHerdMsg("No Herds Available")
            } else {
                enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                setnoHerdMsg(handleError(error));
            }
        });
    }, [enqueueSnackbar, admincheckresponse]);

    const sortHerds = (herds) => {
        caseInsensitiveSortData(herds, 'dairy_name');
        setHerdsList(herds);
    }


    /**
     * Dropdown to select type of herd.
     */

    const selectHerd = () => {
        return (
            <React.Fragment>
                <label className="form-label top-border">Herd</label>
                <FormControl variant="outlined" className="drop-down-root" >
                    {!herdsList.length &&
                        <Loader radius={20} type="small" />
                    }
                    <Autocomplete
                        key="selectHerd"
                        id="herd_name"
                        defaultValue={null}
                        className="autocomplete-root"
                        options={herdsList}
                        getOptionLabel={(option) => option.dairy_name}
                        renderInput={(params) => <TextField {...params} label="Search By Keyword" variant="outlined" />}
                        onChange={(event, selectedOption) => {
                            if (selectedOption) {
                                handleChangeHerd(selectedOption.herd_id);
                            } else {
                                setSelectedHerd({ diaryName: '', herdId: '', selectedHerd: {} })
                                setSubHerds({allSubHerds: [], selectedSubHerds: '' });
                                setSubHerdSelected({ subHerdId: '', subHerdSelected: '', subherdError: false })
                            }
                        }}
                    />
                    {selectedHerd.herdError && <label className="error-msg">Please select herd</label>}
                </FormControl>
            </React.Fragment>
        )
    }

    const handleChangeHerd = (herd_id) => {
        let selectedherddata = {};
        let defaultsubherd = null;

        herdsList.forEach(item => {
            if (item.herd_id === herd_id) {
                setherddata(item);
                selectedherddata = { render: true, diaryName: herd_id, herdId: item.herd_id, selectedHerd: item }
                selectedherddata.selectedHerd.sub_herds.forEach((d) => {
                    if (d.default) {
                        defaultsubherd = d;
                    }
                })
            }
        })


        if ((!selectedherddata.selectedHerd.software_split_report) && defaultsubherd !== null) {
            let subherdselectedlocal = {
                ...defaultsubherd,
                subHerdId: defaultsubherd.sub_herd_id,
                subherdError: false,
                subHerdSelected: defaultsubherd,
            };
            let name = subherdselectedlocal.subHerdSelected ? subherdselectedlocal.subHerdSelected.description : ""
            setSubHerdSelected({
                ...subherdselectedlocal,
                render: false,
                subHerdSelected: name
            });
            setsubherddata(subherdselectedlocal);
            setSubHerds({ allSubHerds: filteractivesubherd(selectedherddata?.selectedHerd?.sub_herds), selectedSubHerds: {} });
        } else {
            setSubHerdSelected({ render: false, subHerdId: '', subHerdSelected: '' })
            setSubHerds({ allSubHerds: filteractivesubherd(selectedherddata?.selectedHerd?.sub_herds), selectedSubHerds: {} });
        }
        setSelectedHerd(selectedherddata);
    };

    /**
     * Dropdown to select type of subherd.
     */

    const selectSubHerd = () => {
        return (
            <React.Fragment>
                <label className="form-label top-border">Sub-Herd</label>
                <FormControl variant="outlined" className="drop-down-root" >
                    <InputLabel htmlFor="outlined-age-native-simple">Select Sub Herd</InputLabel>
                    <Select
                        native
                        disabled={!subHerds?.allSubHerds.length}
                        label="Select Sub Herd"
                        value={subHerdSelected.subHerdSelected}
                        onChange={handleChangeSubHerd}
                    >
                        <option key={100} value="" disabled>

                        </option>
                        {subHerds?.allSubHerds.map((item, i) => {
                            return <option key={i} value={item.description}>{item.description} </option>
                        })}
                    </Select>
                    {subHerdSelected.subherdError && <label className="error-msg">Please select Sub Herd</label>}
                </FormControl></React.Fragment>)

    }

    const handleChangeSubHerd = (event) => {
        let subherdselected = {}, subherddetaildata = null, flag = false;
        subHerds.allSubHerds.forEach((subHerditem, index) => {
            if (subHerditem.description === event.target.value) {
                subherddetaildata = herddata.sub_herds[index];
                flag = true;
                subherdselected = {
                    render: true, subHerdId: subHerditem.sub_herd_id, subherdError: false,
                    subHerdSelected: event.target.value, description: event.target.value,
                }
            }
        })
        if (flag) {
            setsubherddata({
                ...subherddetaildata,
                subHerdId: subherdselected.sub_herd_id, subherdError: false,
                subHerdSelected: subherdselected.description,
                description: subherdselected.descripti
            });
            subherdselected.render = false;
            setSubHerdSelected(subherdselected);
        }
    };
    return (
        <article>
            <PageHeader h1="Reporting" />
            {/* START: Breadcrumbs */}
            {/* <Container maxWidth="lg" className="container-left-align"> */}
            <Container className="container-left-align">
                <section className="content-container">
                    <Grid container spacing={2} className="report_page">
                        {loaderstatus ? <Loader radius={50} type="large" /> : null}
                        {
                            herdsList.length > 0 ? <React.Fragment>
                                <Grid item xs={12}>
                                    <h3>Herd Selection</h3>
                                    <p className="sub-heading">To generate report, start by selecting a herd & sub-herd.</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {selectHerd()}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {selectSubHerd()}
                                </Grid>
                                {loaderstatus ? <Loader radius={50} type="large" /> :
                                    <Grid item xs={12} >
                                        {
                                            subHerdSelected.subHerdId !== "" ? <React.Fragment>
                                                <Reporting herddata={herddata} subherddata={subherddata} />
                                            </React.Fragment> : null
                                        }
                                    </Grid>
                                }
                            </React.Fragment> : <p className="no_herd_msg">{noHerdMsg}</p>
                        }

                    </Grid>
                </section>
            </Container>
        </article>
    )
};

export default ReportMain;
