import React from "react";
import Button from '@material-ui/core/Button';
import { convertDataToFormData } from "../../common/utils/utility";
import Loader from "../../common/dialogs/loader/loader";
import { uploadFile } from "../../common/services/uploadService";

const UploadFileOnServer = (props) => {

  const uploadFileOnServer = (sourceType) => {
    if (props.validateInput(sourceType)) {
      const formData = convertDataToFormData(props.createPostObj(sourceType, false));
      props.setloaderstatus(true);
      props.setValidError(false);
      uploadFile(formData).then(response => {
        // upload response pass in as props
        props.uploadResponse(sourceType)
      }, error => {
          props.setloaderstatus(false);
          if (error && error.response) {
            // error response pass in as props
            props.errorResponse(error);
          }
      });
    }
  }

  return (
    <Button
        variant="contained"
        className="button"
        type="button"
        size="small"
        onClick={() => uploadFileOnServer(props.data)}>
        <span className="btn-text">{props.description}</span>
        {props.enableLoader && <Loader radius={20} type="small" />}
    </Button>
  )
}

export default UploadFileOnServer;