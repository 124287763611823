import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
// material import
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from "react";
import { useSelector } from 'react-redux';
import { SNACKBAR_TYPE } from '../../../../common/constants/constants';
import Loader from '../../../../common/dialogs/loader/loader';
import TwoButtonDialog from "../../../../common/dialogs/two-button-dialog/two-button-dialog";
// Backend Api import
import { deleteUploadedFiles, uploadFile } from '../../../../common/services/uploadService';
import { gettestdates } from "../../../../common/utils/Reporting/testdates.service";
// service and utility import 
import { checkFileType, convertDataToFormData, handleError } from '../../../../common/utils/utility';
// userdefined component
import ModalFlyout from "../../../../components/ui_utilities/modalFlyout";
import UploadFileOnServer from '../../../Reusable/UploadFIleOnServer';

const SubherdDetail = (props) => {
    const useStyles = makeStyles({
        root: {
            width: '100%',
            marginBottom: '3%'
        },
        container: {
            maxHeight: 440,
        },
    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [confirmdeletion, setconfirmdeletion] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loaderstatus, setloaderstatus] = React.useState(true);
    const [uploaddata, setuploaddata] = React.useState([]);
    const [subherddetail, setsubherddetail] = React.useState({
        description: "",
        breeds: ""
    });
    const [viewDetail, setViewDetail] = React.useState(false);
    const [uploadDetails, setUploadDetails] = React.useState(false);
    const [detail, setDetail] = React.useState({ rowId: '', testDate: '', breed: '', description: '', files: '', source_system: '', herd_id: '' });
    const [pcDartData, setPcDartData] = React.useState({ enablePCDART: false, pcDartDate: new Date(), uploadFileType: {}, herdError: false, subherdError: false, fileTypeError: false, fileType: '' });
    const [dc305Data, setDc305Data] = React.useState({ enableDC305: false, pcDartDate: new Date(), uploadFileType_Weekly: {}, uploadFileType_FileOut: {}, uploadFileType_EventOut: {}, herdError: false, subherdError: false, fileType_Weekly_Error: false, fileType_FileOut_Error: false, fileType_EventOut_Error: false, fileType_Weekly: '', fileType_FileOut: '', fileType_EventOut: '' });
    const [dplanData, setDplanData] = React.useState({ enableDPLAN: false, pcDartDate: new Date(), uploadFileType_HerdName: {}, uploadFileType_Weekly: {}, herdError: false, subherdError: false, fileType_HerdName_Error: false, fileType_Weekly_Error: false, fileType_HerdName: '', fileType_Weekly: '' });

    const [enableLoader, setEnableLoader] = React.useState(false);

    const [isDialogOpen, setDialogStatus] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [validError, setValidError] = React.useState(false);

    const [pendinguploadflag, setpendinguploadflag] = React.useState(false);
    const [uploaddatadetails, setuploaddatadetails] = React.useState([])
    const [uploaddeletecounter, setuploaddeletecounter] = React.useState(-1)

    // TODO:::: check this later
    let accessPermission = ["owner", "admin", "contributor"].includes(props.herddata.AccessDetails.access);

    const adminCheckResp = useSelector(
        (state) => state.admin.usertype
    );

    let adminflag = adminCheckResp === "Administrator";
    accessPermission = accessPermission || adminflag;
    let disabledflag = props.herddata?.active ? props.herddata?.active : false;

    React.useEffect(() => {
        if (subherddetail === null) {
            setloaderstatus(false);
            setEnableLoader(false);
        }
    }, [subherddetail])

    React.useEffect(() => {
        window.scrollTo(0, 0);
        let subherddetaildata = props.subherddata;
        setEnableLoader(false);
        let breeds = subherddetaildata.breeds ? subherddetaildata.breeds.join(" , ") : "";

        gettestdates({
            ...subherddetaildata,
            software_herd_software: props.herddata.software_herd_software
        }).then((data) => {
            if (data.response.length > 0) {
                if (data.processingflag) {
                    setpendinguploadflag(true);
                }
                setuploaddatadetails(JSON.parse(data.uploaddatadetails));
                setuploaddata(data.formattedresponse);
                setsubherddetail({
                    ...subherddetaildata,
                    breeds: breeds
                })
                setloaderstatus(false);
            } else {
                setuploaddatadetails([]);
                setsubherddetail({
                    ...subherddetaildata,
                    breeds: breeds
                })
                setloaderstatus(false);
            }
        }).catch((err) => {
            setsubherddetail({
                ...subherddetaildata,
                breeds: breeds
            })
            setloaderstatus(false);
        })
    }, [props.edited, props.subherddata, uploaddeletecounter])


    React.useEffect(() => {
        let interval = null, timeout = null;
        if (pendinguploadflag) {
            timeout = setTimeout(() => {
                //do nothing
            }, 30000);
            interval = setInterval(() => {
                let subherddetaildata = props.subherddata;
                gettestdates(subherddetaildata).then((data) => {
                    if(typeof data.response !== "undefined") {
                        if (data.response.length > 0) {
                            if (!data.processingflag) {
                                setuploaddatadetails(JSON.parse(data.uploaddatadetails));
                                setuploaddata(data.formattedresponse);
                                clearInterval(interval);
                            }
                        }
                    }
                })
            }, 30000);
        }

        return (() => {
            if (interval !== null)
                clearInterval(interval);

            if (timeout !== null) {
                clearTimeout(timeout);
            }
        })

    }, [pendinguploadflag])


    let dailyweeklymilkflag = true;
    dailyweeklymilkflag = props.herddata.parlor_daily_milk_weights;
    const viewDetails = (details, index1, index2) => {
        var files, dplanFiles;
        if (details.source_system === "PCDART") {
            files = details.files[index2];
        } else if (details.source_system === "DPLAN") {
            dplanFiles = dailyweeklymilkflag ? [details.files[2 * index2], details.files[(2 * index2) + 1]] : [details.files[index2]];
            files = dplanFiles;
        } else if (details.source_system === "DC305") {
            dplanFiles = [details.files[3 * index2], details.files[(3 * index2) + 1], details.files[(3 * index2) + 2]];
            files = dplanFiles;
        }
        var testDays = details.test_day[index2]
        var uploaddatetime = details.uploaddate[index2]
        var uploaded_by = details.uploaded_by[index2]
        // var testDates = testDays.substring(5,7)+"/"+testDays.substring(0,4)+"/"+testDays.substring(8,10)

        setDetail({ ...detail, rowId: index2, testDate: testDays, files: files, source_system: details.source_system, herd_id: details.herd_id, uploaddatetime: uploaddatetime, uploaded_by: uploaded_by })
        setViewDetail(true);
    }


    /**
    * display upload files in viewdetails
    */

    const displayFiles = (detail) => {
        if (detail.source_system === "PCDART") {
            return (
                <p className="fileListStyling">{detail.files}</p>
            )
        } else {
            return <div>
                {detail.files.map((option, i) =>
                    <p key={i} className="fileListStyling">{option}</p>
                )}
            </div>
        }

    }

    /**
    * Replace upload files functionality
    */

    const renderBrowseLayout = (labelText, sourceType, uploadTypes, extension) => {
        return <div className="upload-file">
            <div className="upload-heading">{labelText}</div>
            <div className="browseBtn" >
                <input type="file" id={labelText} accept={extension} onChange={(event) => {
                    if (detail.source_system === "PCDART") {
                        setPcDartData({ ...pcDartData, uploadFileType: event.target.files[0] });
                    }
                    if (detail.source_system === "DC305") {
                        switch (uploadTypes) {
                            case 'WEEKLY_PRN':
                                setDc305Data({ ...dc305Data, uploadFileType_Weekly: event.target.files[0] });
                                break;

                            case 'FILE_OUT_PRN':
                                setDc305Data({ ...dc305Data, uploadFileType_FileOut: event.target.files[0] })
                                break;

                            case 'EVENTS_OUT':
                                setDc305Data({ ...dc305Data, uploadFileType_EventOut: event.target.files[0] })
                                break;
                            default:
                                console.log("default");
                        }
                    }
                    if (detail.source_system === "DPLAN") {
                        switch (uploadTypes) {
                            case 'HERD_NAME':
                                setDplanData({ ...dplanData, uploadFileType_HerdName: event.target.files[0] })
                                break;

                            case 'WEEKLY_PRN':
                                setDplanData({ ...dplanData, uploadFileType_Weekly: event.target.files[0] })
                                break;
                            default:
                                console.log("default");
                        }
                    }
                }} />
                <label htmlFor={labelText} >
                    <i className="ag-icon ag-icon-folder-upload"></i>
                </label>
                <span>Select File</span>
            </div>
        </div >
    }

    /**
    * Render PCDART UI on on herd dropdown selection
    */
    const renderPCDART = () => {
        if (detail.source_system === "PCDART") {
            return <div>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {renderBrowseLayout('Upload File (herdcode.csv)', pcDartData, '', '.csv,.CSV')}
                                {pcDartData.fileTypeError && <p className="error-msg">{pcDartData.fileType ? 'Please choose correct file format' : 'Please select a file'}</p>}
                                {validError ? <p className="error-msg">{validError}</p> : ''}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card >
                <UploadFileOnServer 
                    data={pcDartData}
                    setloaderstatus={setloaderstatus}
                    setValidError={setValidError}
                    uploaddatadetails={uploaddatadetails}
                    detail={detail}
                    setuploaddatadetails={setuploaddatadetails}
                    setViewDetail={setViewDetail}
                    setUploadDetails={setUploadDetails}
                    setuploaddeletecounter={setuploaddeletecounter}
                    setDialogStatus={setDialogStatus}
                    setPcDartData={setPcDartData}
                    setDc305Data={setDc305Data}
                    setDplanData={setDplanData}
                    enableLoader={enableLoader}
                    description="Submit Replacement Data"
                    validateInput={validateInput}
                    createPostObj={createPostObj}
                    uploadResponse={uploadResponse}
                    errorResponse={errorResponse}
                />
            </div>
        }
    }

    /**
    * Render DC305 on herd dropdown selection
    */
    const renderDC305 = () => {
        if (detail.source_system === "DC305") {
            return <div>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {renderBrowseLayout('Upload File (weekly.prn)', dc305Data, 'WEEKLY_PRN', '.prn,.PRN')}
                                {dc305Data.fileType_Weekly_Error && <p className="error-msg">{dc305Data.fileType_Weekly ? 'Please choose correct file format' : 'Please select a file'}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                {renderBrowseLayout('Upload File (fileout1.prn)', dc305Data, 'FILE_OUT_PRN', '.prn,.PRN')}
                                {dc305Data.fileType_FileOut_Error && <p className="error-msg">{dc305Data.fileType_FileOut ? 'Please choose correct file format' : 'Please select a file'}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                {renderBrowseLayout('Upload File (events.out)', dc305Data, 'EVENTS_OUT', '.out,.OUT')}
                                {dc305Data.fileType_EventOut_Error && <p className="error-msg">{dc305Data.fileType_EventOut ? 'Please choose correct file format' : 'Please select a file'}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                {validError ? <p className="error-msg">{validError}</p> : ''}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <UploadFileOnServer 
                    data={dc305Data}
                    setloaderstatus={setloaderstatus}
                    setValidError={setValidError}
                    uploaddatadetails={uploaddatadetails}
                    detail={detail}
                    setuploaddatadetails={setuploaddatadetails}
                    setViewDetail={setViewDetail}
                    setUploadDetails={setUploadDetails}
                    setuploaddeletecounter={setuploaddeletecounter}
                    setDialogStatus={setDialogStatus}
                    setPcDartData={setPcDartData}
                    setDc305Data={setDc305Data}
                    setDplanData={setDplanData}
                    enableLoader={enableLoader}
                    description="Submit Replacement Data"
                    validateInput={validateInput}
                    createPostObj={createPostObj}
                    uploadResponse={uploadResponse}
                    errorResponse={errorResponse}
                />
            </div>
        }
    }

    /**
     * Render Dlpan UI on herd dropdown selection
     */
    const renderDPLAN = () => {
        if (detail.source_system === "DPLAN") {
            return <div>
                <Card>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {renderBrowseLayout('Upload File (herdname.prn)', dplanData, 'HERD_NAME', '.prn,.PRN')}
                                    {dplanData.fileType_HerdName_Error && <p className="error-msg">{dplanData.fileType_HerdName ? 'Please choose correct file format' : 'Please select a file'}</p>}
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        dailyweeklymilkflag ? <React.Fragment>
                                            {renderBrowseLayout('Upload File (weekly.prn)', dplanData, 'WEEKLY_PRN', '.prn,.PRN')}
                                            {dplanData.fileType_Weekly_Error && <p className="error-msg">{dplanData.fileType_Weekly ? 'Please choose correct file format' : 'Please select a file'}</p>}
                                        </React.Fragment> : null
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {validError ? <p className="error-msg">{validError}</p> : ''}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Card>
                <UploadFileOnServer 
                    data={dplanData}
                    setloaderstatus={setloaderstatus}
                    setValidError={setValidError}
                    uploaddatadetails={uploaddatadetails}
                    detail={detail}
                    setuploaddatadetails={setuploaddatadetails}
                    setViewDetail={setViewDetail}
                    setUploadDetails={setUploadDetails}
                    setuploaddeletecounter={setuploaddeletecounter}
                    setDialogStatus={setDialogStatus}
                    setPcDartData={setPcDartData}
                    setDc305Data={setDc305Data}
                    setDplanData={setDplanData}
                    enableLoader={enableLoader}
                    description="Submit Replacement Data"
                    validateInput={validateInput}
                    createPostObj={createPostObj}
                    uploadResponse={uploadResponse}
                    errorResponse={errorResponse}
                />
            </div>
        }
    }

    function createPostObj(sourceType, force) {
        const bodyObj = {};
        if (detail.source_system === "PCDART") {
            bodyObj['file_pcdart_herdcode'] = sourceType.uploadFileType;
            bodyObj['sub_herd_id'] = subherddetail.sub_herd_id;
            bodyObj['herd_code'] = detail.herd_id;  //or sourceType.selectedHerd.software_herd_code
            bodyObj['source_system'] = 'PCDART';
            bodyObj['herd_id'] = detail.herd_id;
            bodyObj['upload_time'] = sourceType.pcDartDate.toISOString();
            bodyObj['force'] = true;
            bodyObj['weekly_status'] = props.herddata.parlor_daily_milk_weights;
            return bodyObj;
        }
        if (detail.source_system === "DC305") {
            bodyObj['file_dc305_weekly'] = sourceType.uploadFileType_Weekly;
            bodyObj['file_dc305_fileout'] = sourceType.uploadFileType_FileOut;
            bodyObj['file_dc305_events'] = sourceType.uploadFileType_EventOut;
            bodyObj['sub_herd_id'] = subherddetail.sub_herd_id;
            bodyObj['herd_code'] = detail.herd_id;  //or sourceType.selectedHerd.software_herd_code
            bodyObj['source_system'] = 'DC305';
            bodyObj['herd_id'] = detail.herd_id;
            bodyObj['upload_time'] = sourceType.pcDartDate.toISOString();
            bodyObj['force'] = true;
            bodyObj['weekly_status'] = props.herddata.parlor_daily_milk_weights;
            return bodyObj;
        }
        if (detail.source_system === "DPLAN") {
            bodyObj['file_dplan_herdname'] = sourceType.uploadFileType_HerdName;
            bodyObj['file_dplan_weekly'] = sourceType.uploadFileType_Weekly;
            bodyObj['sub_herd_id'] = subherddetail.sub_herd_id;
            bodyObj['herd_code'] = detail.herd_id;  //or sourceType.selectedHerd.software_herd_code
            bodyObj['source_system'] = 'DPLAN';
            bodyObj['herd_id'] = detail.herd_id;
            bodyObj['upload_time'] = sourceType.pcDartDate.toISOString();
            bodyObj['force'] = true;
            bodyObj['weekly_status'] = props.herddata.parlor_daily_milk_weights;
            return bodyObj;
        }
    }


    /**
     * Validation
     */

    const validateInput = (sourceType) => {
        if (detail.source_system === "PCDART") {
            const fileExtention = checkFileType(sourceType.uploadFileType.name);
            if (fileExtention === 'csv' || fileExtention === 'CSV') {
                setPcDartData(prevState => ({
                    ...prevState,
                    fileTypeError: false
                }));
                return true;
            }
            else {
                setPcDartData(prevState => ({
                    ...prevState,
                    fileTypeError: true,
                    fileType: fileExtention
                }));
            }

        }

        if (detail.source_system === "DC305") {
            const fileExtention1 = checkFileType(sourceType.uploadFileType_FileOut.name);
            const fileExtention2 = checkFileType(sourceType.uploadFileType_EventOut.name);
            const fileExtention3 = checkFileType(sourceType.uploadFileType_Weekly.name);
            if (fileExtention3 === 'prn' || fileExtention3 === 'PRN') {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: false
                }));
            }
            else {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: true,
                    fileType_Weekly: fileExtention3
                }));
                return false;
            }

            if (fileExtention1 === 'prn' || fileExtention1 === 'PRN') {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_FileOut_Error: false
                }));
            }
            else {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_FileOut_Error: true,
                    fileType_FileOut: fileExtention1
                }));
                return false;
            }


            if (fileExtention2 === 'out' || fileExtention2 === 'OUT') {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_EventOut_Error: false
                }));
            }
            else {
                setDc305Data(prevState => ({
                    ...prevState,
                    fileType_EventOut_Error: true,
                    fileType_EventOut: fileExtention2
                }));
                return false;
            }

            return true;
        }

        if (detail.source_system === "DPLAN") {
            const fileExtention1 = checkFileType(sourceType.uploadFileType_HerdName.name);
            const fileExtention2 = checkFileType(sourceType.uploadFileType_Weekly.name);
            if (fileExtention1 === 'prn' || fileExtention1 === 'PRN') {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_HerdName_Error: false
                }));
            }
            else {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_HerdName_Error: true,
                    fileType_HerdName: fileExtention1
                }));
                return false;
            }

            if (dailyweeklymilkflag === false || fileExtention2 === 'prn' || fileExtention2 === 'PRN') {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: false
                }));
            }
            else {
                setDplanData(prevState => ({
                    ...prevState,
                    fileType_Weekly_Error: true,
                    fileType_Weekly: fileExtention2
                }));
                return false;
            }
            return true;
        }
    }

    /**
     * Upload file on server.
     * @param {*} sourceType : pcDart, dc305, dplan 
     */

    const uploadResponse = (sourceType) => {
        const uploadData = _.cloneDeep(uploaddatadetails);
        if (detail.source_system === "PCDART") {
            uploadData[0].files[0] = [sourceType.uploadFileType.name];
        }
        if (detail.source_system === "DC305") {
            uploadData[0].files[0] = [sourceType.uploadFileType_Weekly, sourceType.uploadFileType_FileOut, sourceType.uploadFileType_EventOut];
        }
        if (detail.source_system === "DPLAN") {
            uploadData[0].files[0] = [sourceType.uploadFileType_HerdName.name, sourceType.uploadFileType_Weekly.name];
        }
        setuploaddatadetails(uploadData);
        setloaderstatus(false)
        setViewDetail(false);
        setUploadDetails(false);
        setuploaddeletecounter(uploaddeletecounter + 1);
    }

    const errorResponse = (error) => {
        if (error.response.data === "Sub-Herd data not found, please create herd data or request access to this herd.  Contact AdministratorJStamey@landolakes.com .Please see following for details : The Sub-herd details with same test day already existing ") {
            enqueueSnackbar('The Sub-herd details with same test day already existing', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            setDialogStatus(true);
            renderYesDialog();
        } else if (error.response.status === 500 || error.response.status === 404) {
            enqueueSnackbar('Error occurred while Uploading, Please contact system support team', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            setValidError('Error occurred while Uploading, Please contact system support team');
        } else {
            enqueueSnackbar('File is invalid, Please Upload valid file', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            setValidError(error.response.data);
        }
    }

    const closeDeleteDialog = () => {
        setDialogStatus(false);
    };

    const yesUploadChanges = (pcDartData, dc305Data, dplanData) => {
        var formData;
        if (detail.source_system === "PCDART") {
            formData = convertDataToFormData(createPostObj(pcDartData, true));
        }
        if (detail.source_system === "DC305") {
            formData = convertDataToFormData(createPostObj(dc305Data, true));
        }
        if (detail.source_system === "DPLAN") {
            formData = convertDataToFormData(createPostObj(dplanData, true));
        }

        setloaderstatus(true)
        uploadFile(formData).then(response => {
            const uploadData = _.cloneDeep(uploaddatadetails);
            if (detail.source_system === "PCDART") {
                uploadData[0].files[0] = [pcDartData.uploadFileType.name];
            }
            if (detail.source_system === "DC305") {
                uploadData[0].files[0] = [dc305Data.uploadFileType_Weekly, dc305Data.uploadFileType_FileOut, dc305Data.uploadFileType_EventOut];
            }
            if (detail.source_system === "DPLAN") {
                uploadData[0].files[0] = [dplanData.uploadFileType_HerdName.name, dplanData.uploadFileType_Weekly.name];
            }
            setuploaddatadetails(uploadData);
            setloaderstatus(false)
            setUploadDetails(false)
            setViewDetail(false);
            window.location.reload(false);
        }, error => {
            setloaderstatus(false);
        });
    }

    const renderYesDialog = () => {
        return (
            <TwoButtonDialog
                content={"Are you sure you want to replace the file?"}
                open={isDialogOpen}
                handleClose={closeDeleteDialog}
                btnText1="Yes"
                btnText2="No"
                handleActionBtn2={() => {
                    closeDeleteDialog();
                }}
                handleActionBtn1={() => { yesUploadChanges(pcDartData, dc305Data, dplanData) }}
            />
        );
    };

    let uploadtesttable = <React.Fragment>
        {uploaddatadetails.length > 0 ? <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell><h4>{props.herddata.software_herd_software === "DC305" ? "BACKUP DATE" : "TEST DATE"}</h4></TableCell>
                            <TableCell><h4>BREED</h4></TableCell>
                            <TableCell><h4>DESCRIPTION</h4></TableCell>
                            <TableCell><h4>UPLOADED BY</h4></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            uploaddata.map((data, index) => {
                                return (
                                    data.test_day.map((testDay, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell component="th"
                                                    scope="row"
                                                >
                                                    {testDay.substring(5, 7) + "/" + testDay.substring(8, 10) + "/" + testDay.substring(0, 4)}
                                                </TableCell>

                                                <TableCell component="th"
                                                    scope="row"
                                                >
                                                    {subherddetail.breeds}
                                                </TableCell>

                                                <TableCell component="th"
                                                    scope="row"
                                                >
                                                    {subherddetail.description}
                                                </TableCell>

                                                <TableCell component="th"
                                                    scope="row"
                                                >
                                                    <a className="uploaded_by" href={"mailto:" + data.uploaded_by[i]}>{data.uploaded_by[i]}</a>
                                                </TableCell>

                                                {props.herddata.software_herd_software !== "DRMS" &&
                                                    <TableCell component="th"
                                                        scope="row"
                                                    >
                                                        {
                                                            props.herddata.software_herd_software === "DC305" ?
                                                                <Grid container spacing={2}>
                                                                    <Grid item sm={4}>
                                                                        <Button
                                                                            variant="contained"
                                                                            className="button"
                                                                            type="button"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                if (data.error[i] !== "" || !data.to_be_processed[i]) {
                                                                                    viewDetails(data, index, i)
                                                                                }
                                                                            }}>
                                                                            VIEW DETAILS
                                                                        </Button>
                                                                    </Grid>


                                                                    {
                                                                        data.error[i] !== "" ?
                                                                            <Grid item sm={8}>
                                                                                <div>
                                                                                    {adminflag ? data.error[i] : "The files for this test date have errors. Please check with administrator."}
                                                                                </div>
                                                                            </Grid>
                                                                            : data.to_be_processed[i] ?
                                                                                <React.Fragment>
                                                                                    <Grid item sm={5}>
                                                                                        <div style={{ textAlign: "right" }}>
                                                                                            This test date is still being processed
                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item sm={3}>
                                                                                        <CircularProgress color="secondary" size={20} />
                                                                                    </Grid>
                                                                                </React.Fragment> : null
                                                                    }
                                                                </Grid> :
                                                                <Button
                                                                    variant="contained"
                                                                    className="button"
                                                                    disabled={props?.herddata?.software_herd_software === "DRMS"}
                                                                    type="button"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        viewDetails(data, index, i)
                                                                    }}>
                                                                    VIEW DETAILS
                                                        </Button>
                                                        }

                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )
                                    })
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="boundary"></div>
        </Paper> : <div>No Testdata Found.</div>
        }</React.Fragment>

    return (
        <React.Fragment>
            {loaderstatus ? <Loader radius={50} type="large" /> : <React.Fragment>
                <section className="content-container">
                    <Grid container className="subherd-details-overview-container">
                        <Grid item xs={12} sm={2}>
                            <h5>STATUS</h5>
                            <p>{subherddetail.active ? "Active" : "Inactive"}</p>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <h5>DESCRIPTION</h5>
                            <p>{subherddetail.description}</p>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <h5>BREEDS</h5>
                            <p>{subherddetail.breeds}</p>
                        </Grid>
                    </Grid>
                </section>

                <section className="content-container">
                    <Grid container>
                        <Grid item xs={12}>
                            <h3>Recently Uploaded Data</h3>
                        </Grid>

                        <Grid item xs={12}>
                            {uploadtesttable}
                        </Grid>
                    </Grid>
                </section>

                {viewDetail ? <ModalFlyout state={viewDetail} setstate={setViewDetail}>
                    <h1>UPLOADED DATA DETAILS</h1>
                    <h5>{props.herddata.software_herd_software === "DC305" ? "Backup Date" : "Test Date"}: {detail.testDate.substring(5, 7) + "/" + detail.testDate.substring(8, 10) + "/" + detail.testDate.substring(0, 4)}</h5>
                    <h5>Uploaded By: {detail.uploaded_by}</h5>
                    <h5>File Upload Date / Time: {detail.uploaddatetime}</h5>                    
                    <h5>Breeds: {subherddetail.breeds}</h5>
                    <h5>Description:  {subherddetail.description}</h5>
                    <div className="replace-upload-wrapper">
                        <h5>Files Uploaded</h5>
                        <div id="deleteUpload">
                            {displayFiles(detail)}
                        </div>
                        {accessPermission ? <div className="buttonWrapper">
                            <Button
                                variant="contained"
                                className="button"
                                type="button"
                                disabled={disabledflag}
                                size="small"
                                onClick={() => setUploadDetails(true)}>
                                Replace Data
                            </Button>

                            <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} className="button" style={{ marginLeft: "2%" }} disabled={disabledflag} onClick={() => {
                                setconfirmdeletion(true)
                            }}>
                                Delete This Upload

                                    </Button>

                        </div> : null}
                        <div style={{ marginTop: "10px" }}>
                            <Alert severity="info">
                                Please use <b>Replace Data</b> if you have uploaded the wrong sub-herd data for this test date. Please use <b>Delete this Upload</b> if you have uploaded the wrong test date for this sub-herd.
                            </Alert>
                        </div>
                    </div>
                </ModalFlyout> : null}
                {
                    confirmdeletion ? <TwoButtonDialog
                        content={"Are you sure you want to Delete this Upload?"}
                        open={confirmdeletion}
                        handleClose={() => {
                            setconfirmdeletion(false)
                        }}
                        btnText1="Yes"
                        btnText2="No"
                        handleActionBtn1={() => {
                            //    Delete upload files functionality
                            deleteUploadedFiles(subherddetail.sub_herd_id, detail.testDate, detail.source_system)
                                .then(response => {
                                    if (response) {
                                        setconfirmdeletion(false);
                                        setViewDetail(false);
                                        setuploaddeletecounter(uploaddeletecounter + 1)
                                    }
                                }, error => {
                                    setconfirmdeletion(false);
                                    enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                                });
                        }}
                        handleActionBtn2={() => {
                            setconfirmdeletion(false)
                        }}
                    /> : null
                }

                {
                    uploadDetails ? <ModalFlyout state={uploadDetails} setstate={setUploadDetails}>
                        <h1>UPLOADED DATA DETAILS</h1>
                        <h5>{props.herddata.software_herd_software === "DC305" ? "Backup Date" : "Test Date"}: {detail.testDate.substring(5, 7) + "/" + detail.testDate.substring(8, 10) + "/" + detail.testDate.substring(0, 4)}</h5>
                        <h5>Uploaded By: {detail.uploaded_by}</h5>
                        <h5>File Upload Date/Time: {detail.uploaddatetime}</h5>                        
                        <h5>Breeds: {subherddetail.breeds}</h5>
                        <h5>Description: {subherddetail.description}</h5>
                        <div className="uploadFilesWrapper">
                            {renderPCDART()}
                            {renderDC305()}
                            {renderDPLAN()}
                            {renderYesDialog()}
                        </div>
                    </ModalFlyout> : null}
            </React.Fragment>
            }
        </React.Fragment>
    )
};

export default SubherdDetail;