import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { 
  AppBar,
  Button,
  Popover,
  Toolbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublishIcon from '@mui/icons-material/Publish';

const useStyles = makeStyles((theme) => ({
  fillDiv: {
    flexGrow: 1,
  },
}));

const PageHeader = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const setHeadlineClass = Boolean(props.subherd) ? "appbar-altheadline" : "appbar-headline";
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const history = useHistory();

  const adminCheckResp = useSelector(
    (state) => state.admin.usertype
  );

  let adminflag = adminCheckResp === "Administrator";

  const handlepopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlepopClose = () => {
    setAnchorEl(null);
  };

  let herddata = props.herddata ? props.herddata : {};
  let subherdsdata = props.herddata ? herddata.sub_herds : [];

  let accessPermission = true;

  if (props.herddata) {
    accessPermission = ["owner", "admin", "contributor"].includes(props.herddata?.AccessDetails?.access);
  }
  accessPermission = accessPermission || adminflag;

  return (
    <header className={classes.root + ' page-header-wrapper'}>
      <AppBar
        position="static"
        elevation={0}
      >
        <Toolbar>
          <div className="appbar-wrapper">
            <h1 className={setHeadlineClass}>
              {props.h1}
            </h1>
            {props.editbutton ?
              <div className="herd-name">
                <h1 className="appbar-headline">{props.subherddata?.description}</h1>
                <div className="subherd-name">
                  <ExpandMoreIcon aria-describedby={id} onClick={handlepopClick}
                    style={{ cursor: "pointer" }} />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlepopClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    className="subherdList-popper"
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <div className="popper-wrapper">
                      {
                        subherdsdata.map((subherd, index, array) => {
                          let circlestyle = { width: "12px", height: "12px", marginRight: "15px", borderRadius: "50%", display: "inline-block" };
                          if (subherd.active) {
                            circlestyle = {
                              ...circlestyle,
                              background: "green"
                            }
                          } else {
                            circlestyle = {
                              ...circlestyle,
                              background: "#c7c2c2"
                            }
                          }
                          return (
                            <React.Fragment key={subherd.sub_herd_id + index}>
                              <div onClick={() => {
                                if (props.setsubherddata) {
                                  herddata.sub_herds.forEach((x) => {
                                    if (x.sub_herd_id === subherd.sub_herd_id) {
                                      props.setSelectedIndex(herddata.sub_herds.indexOf(x));
                                      subherd = x;
                                    }
                                  })
                                  if (subherd.default) {
                                    props.setisdefaultsubherd(true);
                                  } else {
                                    props.setisdefaultsubherd(false);
                                  }
                                  props.setsubherddata(subherd)
                                  props.setsubherdDetails({ ...subherd, breeds: subherd.breeds });
                                }
                                handlepopClose();
                                history.push("/dashboard/herds/subherd/detail?herd_id=" + herddata.herd_id + "&subherd_id=" + subherd.sub_herd_id);
                              }} >
                                <div style={circlestyle}>
                                </div>
                                {subherd.description}
                              </div>
                            </React.Fragment>
                          )
                        })
                      }
                    </div>
                  </Popover>
                </div>
              </div>
              : null}
          </div>
          <div className={classes.fillDiv}></div>
          {props.addbutton ? <Button
            variant={"contained"}
            className={"button"}
            onClick={props.handler}
            startIcon={<AddOutlinedIcon />}
            size="small">Add a Herd</Button> : null
          }
          {props.editbutton && accessPermission ? 
            <> 
              <Button
                onClick={() => {
                  props.handler("add")
                }}
                variant="contained"
                disabled={props?.herddata?.software_herd_software === "DRMS"}
                className="button"
                type="button"
                size="small"
                startIcon={<AddOutlinedIcon />}
              >
                Add a Sub-herd
              </Button>
              <Button 
                onClick={() => {
                  props.handler("edit")
                }}
                variant="contained"
                className="button"
                type="button"
                size="small"
                style={{ marginLeft: "2%" }}
                startIcon={<BorderColorOutlinedIcon />}
                >Edit Sub-herd
              </Button>
              {props?.herddata?.software_herd_software !== "DRMS" && (
                <Button onClick={() => {
                  props.handler("upload")
                }}
                  variant="contained"
                  disabled={props?.subherddata?.active === false}
                  className="button"
                  type="button"
                  size="small"
                  style={{ marginLeft: "2%" }}
                  startIcon={<PublishIcon />}
                >Upload Data</Button>
              )}
            </> : null
          }

        </Toolbar>
      </AppBar>
    </header>
  );

}

export default PageHeader;
