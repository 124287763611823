import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';
import {sortData} from '../utils/utility';

/**
 * Method will call default goals data.
 */
export async function getGoals(goalType, herdId, subHerdId) {
    try{
        let goals = await NetworkService.getData(ApiUrl.getGoalsUrl(goalType, herdId, subHerdId));
        if(Array.isArray(goals) && goals.length>0){
            goals[0].Breeds.map(element => {
                    sortData(element.Goals,"Goal_Order");
                    return element;
            });
        }
        return goals;
    }catch(err){
       return null;
    }
}

export async function saveGoals(goalType, reqBody) {
    let cropsListResponse = await NetworkService.postData(ApiUrl.saveGoalsUrl(goalType), reqBody);
    return cropsListResponse;
}
export async function getHerds() {
    let userrole = await NetworkService.getData(ApiUrl.getcheckadminurl());
    let herdResponse = await NetworkService.getData(ApiUrl.getHerdsUrl(userrole));
    return herdResponse;
}

export async function getSubHerds(subHerdId) {
    let subHerdResponse = await NetworkService.getData(ApiUrl.getSubHerdsUrl(subHerdId));
    return subHerdResponse;
}