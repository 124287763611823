import React from "react";
import { formdata } from '../../../common/constants/constants';
import Loader from '../../../common/dialogs/loader/loader';
import { getHerdDetailsFromHerdid, saveHerdWithAssignment } from '../../../common/services/herd.service';
import { getuserdetails } from '../../../common/services/herdAccess.service';
import EnrollmentForm from '../../../components/EnrollmentForm/EnrollmentForm';
import PageHeader from '../../../components/pageHeader/pageHeader';
import AddHerdSuccess from './AddHerdSuccess';
import { decodeApiResponse } from "../../../common/utils/utility";

const AddHerds = (props) => {
  const [formdatastoredStatus, setformdatastoredStatus] = React.useState(false);
  const [loaderstatus, setloaderstatus] = React.useState(false);
  const [herddata, setherddata] = React.useState(null);
  const [subherddata, setsubherddata] = React.useState(null);

  async function addherd(values) {
    setloaderstatus(true);

    getuserdetails().then((response) => {
      let accessdetails = [];
      accessdetails.push({
        ...response,
        name: response.givenName + " " + response.surname,
        email: response.mail,
        assigned: true,
        access: "owner"
      })
      let requestbody = {
        herd_details: values,
        herd_assignment: {
          AccessDetails: accessdetails
        }
      }
      saveHerdWithAssignment(requestbody).then((saveresponse) => {

        getHerdDetailsFromHerdid(saveresponse.Herd_id).then((herdresponse) => {
          const decodedResponse = decodeApiResponse(herdresponse);
          let herd = {
            AccessDetails: {
              ...response,
              name: response.givenName + " " + response.surname,
              email: response.mail,
              assigned: true,
              access: "owner"
            },
            HerdAssignments: [{
              Herdid: saveresponse.Herd_id,
              ...response,
              name: response.givenName + " " + response.surname,
              email: response.mail,
              assigned: true,
              access: "owner"
            }],
            ...decodedResponse
          }

          setherddata(herd);
          setsubherddata(herd.sub_herds[0]);
          setformdatastoredStatus(true);
          setloaderstatus(false);
        }).catch((errresponse) => {
          setloaderstatus(false);
        })
      }).catch((err) => {
        setloaderstatus(false);
      })
    }).catch((error) => {
      setloaderstatus(false);
    })
  };

  return (
    <article>
      <PageHeader h1="Add a Herd" />
      {
        loaderstatus ? <Loader radius={50} type="large" /> : formdatastoredStatus ? <AddHerdSuccess herddata={herddata} subherddata={subherddata} />
          : <EnrollmentForm addeditherd={addherd} formdata={formdata} heading="Adding a New Herd" type="add" dplanuploadstatus={false} />
      }
    </article>
  );
};

export default AddHerds;
