import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// material ui component's import
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import React from "react";
import Goal from '../../../components/Goal/Goal';
// component import
import PageHeader from '../../../components/pageHeader/pageHeader';




const UserGoals = () => {
    const [goalname, setgoalname] = React.useState("User Goals : Holstein");

    React.useEffect(() => {
        localStorage.setItem("save_edit", "no");
    }, [])
    return (
        <article>
            <PageHeader h1={goalname} />
            {/* START: Breadcrumbs */}
            <Container maxWidth={false} className="container-align-left breadcrumb-wrapper hide">
                <Breadcrumbs separator="›" aria-label="breadcrumb" className="breadcrumb-container">
                    <Link color="inherit" href="#" >
                        Default Goals
                    </Link>
                </Breadcrumbs>
            </Container>
            {/* END: Breadcrumbs */}
            <Goal type="usergoals" setgoalname={setgoalname} />
        </article >
    );
};

export default UserGoals;

