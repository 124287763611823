// material import
import {
    Breadcrumbs,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Link,
    Switch
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Autocomplete from '@mui/lab/Autocomplete';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
//constant and utility import
import { SNACKBAR_TYPE, sourcleapplicationoptions, stateoptions } from '../../../common/constants/constants';
import Loader from '../../../common/dialogs/loader/loader';
import TwoButtonDialog from "../../../common/dialogs/two-button-dialog/two-button-dialog";
import { getHerds } from '../../../common/services/goals.service';
// Backend Api import
import { getHerdForUser, saveHerd } from '../../../common/services/herd.service';
import { assignDefaultHerds, getuserlists } from '../../../common/services/herdAccess.service';
import { getEnvironment, caseInsensitiveSortData, handleError, decodeApiResponse } from '../../../common/utils/utility';
import PageHeader from '../../../components/pageHeader/pageHeader';
//Userdefined component import
import Dropdown from "../../../components/ui_utilities/Dropdown";
import ModalFlyout from "../../../components/ui_utilities/modalFlyout";
import HerdTable from './herdTable';
import { useState } from 'react';

const Herds = (props) => {
    const history = useHistory();
    const ENV = getEnvironment();

    const [loaderstatus, setloaderstatus] = React.useState(true);
    const [herdlist, setherdlist] = React.useState([]);
    const [searchherdlist, setsearchherdlist] = React.useState([]);
    const [userherddata, setuserherddata] = React.useState([]);
    const [addSubherdModal, setAddSubherdModal] = React.useState(false);
    const [activeflag, setactiveflag] = React.useState(true);
    const [adminflag, setadminflag] = React.useState(false);

    const breeds = [{ name: "Crossbred" }, { name: "Holstein" }, { name: "Jersey" }, { name: "Other" }];
    const [addsubherd, setAddsubherd] = React.useState({ active: true, breeds: [], description: '', use_avg_daily_milk: false, use_ecm_peer_avg: false });

    const [iserror, seterrordialog] = React.useState(false);
    const [errormessage, seterrormessage] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [state, setstate] = React.useState("");
    const [keyword, setkeyword] = React.useState("");
    const [sourceapplication, setsourceapplication] = React.useState("");
    const [userlist, setuserlist] = React.useState([]);

    const [showsubherd, setshowsubherd] = React.useState(false);
    const [modaldata, setmodaldata] = React.useState({});
    const [savingdata, setsavingdata] = React.useState(false);
    const [accessPermission, setAccessPermission] = React.useState(false);
    const [resetkeyword, setresetkeyword] = React.useState(false);
    const [keyworddefault, setkeyworddefault] = React.useState(null);
    const [herdcodelist, setherdcodelist] = React.useState([]);
    const [herddata, setherddata] = React.useState(null);
    const [pagecontent, setPagecontent] = React.useState([]);

    const [sortAscOrder, setAscOrder] = React.useState(false);
    const [sortNameAscOrder, setNameAscOrder] = React.useState(false);
    const [selectedSort, setSelectedSort] = useState("");

    let temp_content = [];

    const admincheckresponse = useSelector(
        (state) => state.admin.usertype
    );
    
    // hide the DRMS option if user is not an Admin and ENV not equal to LOCAL or DEV
    let newSourceApplicationOption = sourcleapplicationoptions.filter(src => src.value !== "DRMS");
    if (admincheckresponse === "Administrator" && (ENV === "LOCAL" || ENV === "DEV"))
        newSourceApplicationOption = sourcleapplicationoptions;

    useEffect(() => {
        const removeduplicate = (listarray) => {
            listarray = listarray.filter((data, i) => {
                return listarray.indexOf(data) === i
            })

            listarray = listarray.map((data) => {
                return {
                    name: data,
                    value: data
                }
            })
            return listarray;
        }

        const herdforuserpromise = new Promise((resolve, reject) => {
            getHerdForUser(admincheckresponse).then((response) => {
                const decodedResponse = decodeApiResponse(response)
                resolve(decodedResponse)
            }).catch((err) => {
                reject(err);
            })
        })

        const getuserlistsdata = new Promise((resolve, reject) => {
            getuserlists().then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            })
        })

        Promise.all([herdforuserpromise, getuserlistsdata]).then((values) => {
            let admin_flag = false;
            if (admincheckresponse === "Administrator") {
                admin_flag = true;
            }
            let herdlistdata = [...values[0]]
            if (herdlistdata.length <= 0) {
                assignDefaultHerds().then((response) => { window.location.reload(); });
            }
            let temp = [];
            let j = 0;
            var herdcodelistdata = [];
            let duplicateherdremoval = {};
            for (let i = herdlistdata.length - 1; i >= 0; i--) {
                let data = herdlistdata[i];
                if (data.software_herd_code !== undefined) {
                    herdcodelistdata.push(data.software_herd_code.toString());
                    duplicateherdremoval[data.software_herd_code.toString()] = {
                        admin_flag: true
                    }
                }

                temp[j++] = {
                    ...herdlistdata[i],
                    UserEmail: data.UserEmail ? data.UserEmail : ""
                };
            }

            if (admin_flag) {
                temp = temp.filter((d) => {
                    return d?.status !== "for_deletion";
                })
            } else {
                temp = temp.filter((d) => {
                    return d?.status !== "for_deletion" && d.UserEmail !== "" && (d.HerdAssignments.length !== 0);
                })
            }
            herdlistdata = temp;
            let herddata = herdlistdata.map((data) => {
                return data.dairy_name;
            })

            herddata = removeduplicate(herddata)
            herdcodelistdata = removeduplicate(herdcodelistdata)
            let userlistdata = values[1].map((d) => {
                return {
                    name: d.givenName + " " + d.surname,
                    value: d.givenName + " " + d.surname
                }
            });

            caseInsensitiveSortData(herdlistdata, "dairy_name");

            if (history.location.search !== "") {
                let herd_sourceapplication, herd_state, herd_keyword, herd_page, herd_rowppage;

                herd_sourceapplication = localStorage.getItem("herd_sourceapplication") !== null ? localStorage.getItem("herd_sourceapplication") : ""
                herd_state = localStorage.getItem("herd_state") !== null ? localStorage.getItem("herd_state") : ""
                herd_keyword = localStorage.getItem("herd_keyword") !== null ? localStorage.getItem("herd_keyword") : ""
                herd_page = localStorage.getItem("herd_page") !== null ? parseInt(localStorage.getItem("herd_page")) : 0;
                herd_rowppage = localStorage.getItem("herd_rowppage") !== null ? parseInt(localStorage.getItem("herd_rowppage")) : 10;

                var localstate = herd_state, localkeyword = herd_keyword.toLowerCase(), localsourceapplication = herd_sourceapplication;
                temp = [];

                if (!(localstate === "" && localkeyword === "" && localsourceapplication === "")) {
                    temp = herdlistdata.filter((data) => {
                        let flag = false;
                        let keywordflag = data.dairy_name.toLowerCase().includes(localkeyword.toLowerCase())

                        let ownerlists = []
                        if (data.HerdAssignments.length > 0) {
                            data.HerdAssignments.forEach((d) => {
                                let surname = d.surname ? d.surname : "";
                                ownerlists.push((d.givenName + " " + surname).toLowerCase());
                            })
                        }

                        if (data.software_herd_code !== undefined) {
                            keywordflag = data.dairy_name.toLowerCase().includes(localkeyword.toLowerCase()) || data.software_herd_code === localkeyword || JSON.stringify(ownerlists).indexOf(localkeyword.toLowerCase()) > -1;
                        } else {
                            keywordflag = data.dairy_name.toLowerCase().includes(localkeyword.toLowerCase()) || JSON.stringify(ownerlists).indexOf(localkeyword.toLowerCase()) > -1;
                        }


                        let stateflag = localstate.toUpperCase() === data.dairy_state.toUpperCase();
                        let sourceapplicationflag = localsourceapplication.toUpperCase() === data.software_herd_software.toUpperCase();

                        if (localstate !== "" && localkeyword !== "" && localsourceapplication !== "") {
                            if (keywordflag && stateflag && sourceapplicationflag) {
                                flag = true;
                            }
                        } else if (localstate !== "" && localkeyword !== "") {
                            if (stateflag && keywordflag) {
                                flag = true;
                            }
                        } else if (localstate !== "" && localsourceapplication !== "") {
                            if (stateflag && sourceapplicationflag) {
                                flag = true;
                            }
                        } else if (localkeyword !== "" && localsourceapplication !== "") {
                            if (keywordflag && sourceapplicationflag) {
                                flag = true;
                            }
                        } else if (localkeyword !== "") {
                            if (keywordflag) {
                                flag = true;
                            }
                        } else if (localstate !== "") {
                            if (stateflag) {
                                flag = true;
                            }
                        } else {
                            if (sourceapplicationflag) {
                                flag = true;
                            }
                        }
                        return flag;
                    })

                    setherdlist(temp);
                } else {
                    setherdlist(herdlistdata);
                }

                setPage(herd_page);
                setRowsPerPage(herd_rowppage);
                setkeyword(herd_keyword);
                setstate(herd_state);
                setsourceapplication(herd_sourceapplication);
                setkeyworddefault({ name: herd_keyword, value: herd_keyword })

                setherdcodelist(herdcodelistdata)
                setuserlist(userlistdata);
                setuserherddata(herddata);
                setsearchherdlist(herdlistdata);
                setloaderstatus(false);
                setadminflag(admin_flag);
            } else {
                setherdlist(herdlistdata);

                setherdcodelist(herdcodelistdata)
                setuserlist(userlistdata);
                setuserherddata(herddata);
                setsearchherdlist(herdlistdata);

                setloaderstatus(false);
                setadminflag(admin_flag);
                localStorage.setItem("herd_rowppage", 10)
                localStorage.setItem("herd_page", 0)
                localStorage.removeItem("herd_sourceapplication")
                localStorage.removeItem("herd_state")
                localStorage.removeItem("herd_keyword")
            }
        }).catch((err) => {
            setloaderstatus(false);
        })
    }, [history.location.search, admincheckresponse]);

    const gotoaddherdpage = () => {
        history.push("/dashboard/addHerd");
    }

    useEffect(() => {
        setAddsubherd(({ active: true, breeds: [], description: '', use_avg_daily_milk: herddata?.parlor_daily_milk_weights, use_ecm_peer_avg: herddata?.use_ecm_peer_avg }));
        setactiveflag(true);
    }, [addSubherdModal])

    useEffect(() => {
        if (savingdata) {
            let localmodaldata = { ...modaldata }
            localmodaldata.sub_herds.push(addsubherd);
            var a = _.cloneDeep(herddata);
            a.sub_herds = localmodaldata.sub_herds;
            var e = _.cloneDeep(a);
            if (a.sub_herds.length > 1) {
                let default_breed_index = a.sub_herds.map(function (e) { return e.default }).indexOf(true);
                let default_subherd_breed_types = a.sub_herds[default_breed_index].breeds.map(function (b) { return b.replace(/Other Purebred/g, "Other"); });
                let new_subherd_breed_types = addsubherd.breeds.map(function (b) { return b.replace(/Other Purebred/g, "Other"); });
                let combined_breeds = [...new Set([...default_subherd_breed_types, ...new_subherd_breed_types])];
                a.software_breeds = combined_breeds.map(function (b) { return b.replace(/Other(?!\sPurebred)/g, "Other Purebred") });
                a.sub_herds[default_breed_index].breeds = combined_breeds;
            }
            setherddata(a);
            enqueueSnackbar('Adding subherd, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });

            saveHerd(e).then(response => {
                getHerds().then((herdresponse) => {
                    let tempherdlist = _.cloneDeep(herdlist);
                    let tempherd = herdresponse.find((d) => d.herd_id === e.herd_id);
                    tempherdlist.find((d) => d.herd_id === e.herd_id).sub_herds = tempherd.sub_herds;
                    setherdlist(tempherdlist)
                    setAddSubherdModal(false);
                    setmodaldata({
                        herdbreeds: tempherd.software_breeds,
                        herd_id: tempherd.herd_id,
                        herd_name: tempherd.dairy_name,
                        herd_code: tempherd.software_herd_code,
                        address: tempherd.dairy_street_address + " , " + tempherd.dairy_state + " " + tempherd.dairy_zip_code,
                        phone: tempherd.dairy_phone_number ? tempherd.dairy_phone_number : tempherd.dairy_mobile_number,
                        owner_name: tempherd.dairy_owner_name,
                        sub_herds: tempherd.sub_herds,
                        index: null
                    });
                    setsavingdata(false);
                    enqueueSnackbar("Subherd Added Successfully!!", { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                });
            }, error => {
                enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                setAddSubherdModal(false);
                setsavingdata(false);
            });
        }
    }, [savingdata, enqueueSnackbar])

    useEffect(() => {
        let temp_pagecontent = [];
        const sortedData = temp_content.length > 1 ? [...temp_content] : [...herdlist];
        
        if (selectedSort === "date") {
            const newSortData = sortedData.sort((a, b) => {
                if (a.summary_latest_data?.test_day === undefined) return 1;
                if (b.summary_latest_data?.test_day === undefined) return -1;

                const dateA = new Date(a.summary_latest_data.test_day);
                const dateB = new Date(b.summary_latest_data.test_day);
        
                if (sortAscOrder) {
                    return dateA - dateB;
                } else {
                    return dateB - dateA;
                }
            });

            temp_pagecontent = newSortData.filter((d, index) => {
                if (index >= ind && index < (ind + rowsPerPage))
                    return true;
                return false;
            })
        } else {
            const newSortDataByName = sortedData.sort((a, b) => {
                const dairyNameA = a.dairy_name.toLowerCase();
                const dairyNameB = b.dairy_name.toLowerCase();
          
                if (sortNameAscOrder) {
                    return dairyNameB.localeCompare(dairyNameA);
                } else {
                    return dairyNameA.localeCompare(dairyNameB);
                }
            });
            
            temp_pagecontent = newSortDataByName.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        }

        temp_content = temp_pagecontent;
        setPagecontent(temp_pagecontent)
    }, [herdlist, sortAscOrder, sortNameAscOrder, rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem("herd_page", newPage)
        //save to localstorage
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        localStorage.setItem("herd_rowppage", event.target.value)
        //save to localstorage
    };

    var ind = 0;
    if (page !== 0)
        ind = (page - 1) * rowsPerPage + rowsPerPage;

    const selectkeyword = (event) => {
        setkeyword(event.target.value)
        localStorage.setItem("herd_keyword", event.target.value)
    }

    const selectstate = (event) => {
        setstate(event.target.value);
        localStorage.setItem("herd_state", event.target.value)
    }

    const selectsourceapplication = (event) => {
        setsourceapplication(event.target.value);
        localStorage.setItem("herd_sourceapplication", event.target.value)
    }

    const searchherd = () => {
        var temp = [], localstate = state, localkeyword = keyword, localsourceapplication = sourceapplication;

        if (!(localstate === "" && localkeyword === "" && localsourceapplication === "")) {
            temp = searchherdlist.filter((data) => {
                let flag = false;
                let keywordflag = data.dairy_name.toLowerCase().includes(localkeyword.toLowerCase())

                let ownerlists = []
                if (data.HerdAssignments.length > 0) {
                    data.HerdAssignments.forEach((d) => {
                        let surname = d.surname ? d.surname : "";
                        ownerlists.push((d.givenName + " " + surname).toLowerCase());
                    })
                }

                if (data.software_herd_code !== undefined) {
                    keywordflag = data.dairy_name.toLowerCase().includes(localkeyword.toLowerCase()) || data.software_herd_code === localkeyword || JSON.stringify(ownerlists).indexOf(localkeyword.toLowerCase()) > -1;
                } else {
                    keywordflag = data.dairy_name.toLowerCase().includes(localkeyword.toLowerCase()) || JSON.stringify(ownerlists).indexOf(localkeyword.toLowerCase()) > -1;
                }

                let stateflag = localstate.toUpperCase() === data.dairy_state.toUpperCase();
                let sourceapplicationflag = localsourceapplication.toUpperCase() === data.software_herd_software.toUpperCase();

                if (localstate !== "" && localkeyword !== "" && localsourceapplication !== "") {
                    if (keywordflag && stateflag && sourceapplicationflag) {
                        flag = true;
                    }
                } else if (localstate !== "" && localkeyword !== "") {
                    if (stateflag && keywordflag) {
                        flag = true;
                    }
                } else if (localstate !== "" && localsourceapplication !== "") {
                    if (stateflag && sourceapplicationflag) {
                        flag = true;
                    }
                } else if (localkeyword !== "" && localsourceapplication !== "") {
                    if (keywordflag && sourceapplicationflag) {
                        flag = true;
                    }
                } else if (localkeyword !== "") {
                    if (keywordflag) {
                        flag = true;
                    }
                } else if (localstate !== "") {
                    if (stateflag) {
                        flag = true;
                    }
                } else {
                    if (sourceapplicationflag) {
                        flag = true;
                    }
                }
                return flag;
            })
            setherdlist(temp);
        } else {
            setherdlist(searchherdlist);
        }
    }

    const saveAdd = () => {
        if (addsubherd.description === "") {
            seterrormessage("Please fill the description");
            seterrordialog(true);
        }
        else if (addsubherd.breeds.length === 0) {
            seterrormessage("Please select a breed.");
            seterrordialog(true);
        }
        else {
            setsavingdata(true);
        }
    }

    const addasubherdform = () => {
        return <React.Fragment>
            <Grid item xs={12}>
                <h1 className="appbar-headline text-uppercase">Add A Sub Herd</h1>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    control={<Switch
                        onChange={() => {
                            setAddsubherd({ ...addsubherd, active: true });
                        }}
                        checked={activeflag}
                    ></Switch>}
                    label={activeflag ? 'This Sub-Herd is Active' : 'This Sub-Herd is Inactive'}
                />
            </Grid>


            <Grid item xs={12}>
                <TextareaAutosize rowsMin={2} aria-label="empty textarea" placeholder="Enter a Description" className="textarea"
                    value={addsubherd.description}
                    onChange={(event) => {
                        setAddsubherd({ ...addsubherd, description: event.target.value });
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    disabled={herddata.software_herd_software === "PCDART" || herddata.parlor_daily_milk_weights === false}
                    control={<Switch
                        checked={addsubherd.use_avg_daily_milk}
                        className="text-bold"
                        onChange={() => {
                            setAddsubherd({ ...addsubherd, use_avg_daily_milk: !addsubherd.use_avg_daily_milk })
                        }}
                    ></Switch>}
                    label="Use average daily milk for production calculations"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    checked={addsubherd.use_ecm_peer_avg}
                    disabled={herddata.use_ecm_peer_avg === false}
                    className="text-bold"
                    control={<Switch
                        checked={addsubherd.use_ecm_peer_avg}
                        className="text-bold"
                        onChange={() => {
                            setAddsubherd({ ...addsubherd, use_ecm_peer_avg: !addsubherd.use_ecm_peer_avg })
                        }}
                    ></Switch>}
                    label="Use ECM for peer average calculations"
                />
            </Grid>

            <Grid item xs={12}>
                <h3>Breeds</h3>
                <p>
                    Breed selection will determine both default sub-herd goals and peer group filters. You will be able to customize goals by breed or for each sub-herd. Herds with any combination of breeds including Holstein or Crossbred will be assigned to Holstein-based peer groups. Herds with only Jersey and/or Other Purebred will be assigned to Jersey-based peer groups
                </p>
            </Grid>
            {
                breeds.map((data, index) => {
                    return <Grid item xs={12} key={index}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        let tempbreed = addsubherd.breeds;
                                        if (tempbreed.includes(data.name)) {
                                            if (!event.target.checked) {
                                                tempbreed = tempbreed.filter((tempbreed) => {
                                                    return data.name !== tempbreed;
                                                })
                                            }
                                        } else {
                                            if (event.target.checked) {
                                                tempbreed = [...tempbreed, data.name]
                                            }
                                        }
                                        setAddsubherd({ ...addsubherd, breeds: tempbreed });
                                    }}
                                    name={data.name}
                                    color="primary"
                                    checked={addsubherd.breeds.includes(data.name)}
                                />
                            }
                            label={data.name === "Other" ? "Other Purebred" : data.name}
                        />
                    </Grid>
                })
            }
        </React.Fragment>
    }

    const getbutton = (type) => {
        switch (type) {
            case "saveaddbutton":
                return <React.Fragment>
                    <Button
                        variant="contained"
                        className="button"
                        type="button"
                        size="small"
                        startIcon={<SaveOutlinedIcon />}
                        onClick={saveAdd}>
                        Save
                            </Button>
                </React.Fragment>

            case "canceladdbutton":
                return <React.Fragment>
                    <Button
                        variant="outlined"
                        className="button grey"
                        type="button"
                        size="small"
                        startIcon={<CancelOutlinedIcon />}
                        onClick={() => {
                            setAddSubherdModal(false);
                        }}>
                        Cancel
                        </Button>
                </React.Fragment>
            default: return null;

        }

    }

    const handleSortHerdName = () => {
        setNameAscOrder(!sortNameAscOrder);
        setSelectedSort('herd_name')
    }

    const handleSortDate = () => {
        setAscOrder(!sortAscOrder);
        setSelectedSort('date')
    }
    
    let herdtable = <HerdTable 
        herdlist={herdlist}
        pagecontent={pagecontent}
        sortNameAscOrder={sortNameAscOrder}
        handleSortHerdName={handleSortHerdName}
        sortAscOrder={sortAscOrder}
        handleSortDate={handleSortDate}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
    />

    let herdlistbody =
        <React.Fragment>
            <Grid container className="page-controls remove-negative-margins" spacing={1}>
                <Grid item xs={12} className="grid-control-header">
                    <h4>Filter & Search Herd List</h4>
                </Grid>

                <Grid item xs={2}>
                    <Dropdown data={{
                        className: "drop-down-root",
                        label: "State",
                        name: "herd_state",
                        id: "herd_state",
                        options: stateoptions
                    }}
                        handler={selectstate}
                        value={state}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Dropdown data={{
                        className: "drop-down-root",
                        label: "Source Application",
                        name: "source_application",
                        id: "source_application",
                        options: newSourceApplicationOption
                    }}
                        handler={selectsourceapplication}
                        value={sourceapplication}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Autocomplete
                        key={resetkeyword}
                        id="herd_name"
                        defaultValue={keyworddefault}
                        getOptionSelected={(option) => {
                            return option.name === (keyworddefault !== null ? keyworddefault.name : "")
                        }}
                        className="autocomplete-root"
                        options={adminflag ? [...userherddata, ...userlist, ...herdcodelist] : [...userherddata, ...herdcodelist]}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Search By Keyword" variant="outlined"
                            value={keyword}
                        />}
                        onBlur={(event) => {
                            selectkeyword(event)
                        }}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        className="button fullwidthbutton"
                        type="button"
                        onClick={searchherd}>
                        Search
                            </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        className="button resetbutton"
                        type="button"
                        onClick={() => {
                            localStorage.removeItem("herd_rowppage")
                            localStorage.removeItem("herd_page")
                            localStorage.removeItem("herd_sourceapplication")
                            localStorage.removeItem("herd_state")
                            localStorage.removeItem("herd_keyword")
                            setkeyword("");
                            setresetkeyword(!resetkeyword)
                            setsourceapplication("");
                            setstate("");
                            setherdlist(searchherdlist);
                            setkeyworddefault(null)
                            setPage(0)
                            setRowsPerPage(10)
                        }}>
                        RESET
                            </Button>
                </Grid>
            </Grid>


            <Grid container className="remove-negative-margins" spacing={2}>
                {herdtable}
            </Grid>

        </React.Fragment>

    return (
        <article>
            <PageHeader h1="ALL Herds" addbutton={true} handler={gotoaddherdpage} />
            <Container maxWidth={false} className="container-align-left breadcrumb-wrapper">
                <Breadcrumbs separator="›" aria-label="breadcrumb" className="breadcrumb-container">
                    <Link color="inherit" href="#" >
                        Herds
                    </Link>
                </Breadcrumbs>
            </Container>
            <Container maxWidth={false} className="container-align-left">
                <section className="content-container">
                    {
                        loaderstatus ? <Loader radius={50} type="large" /> : herdlistbody
                    }
                </section>
                <ModalFlyout state={showsubherd} setstate={setshowsubherd} >
                    <Grid item xs={12}>
                        <h1 className="appbar-headline text-uppercase">{modaldata.herd_name}</h1>
                    </Grid>

                    <Grid item xs={12}>
                        Herd Code: {modaldata.herd_code}
                    </Grid>

                    <Grid item xs={12}>
                        Address: {modaldata.address}
                    </Grid>

                    <Grid item xs={12}>
                        Phone: {modaldata.phone}
                    </Grid>

                    <Grid item xs={12}>
                        Owner: {modaldata.owner_name}
                    </Grid>

                    <Grid item xs={12}>
                        <br />
                        <h3>Sub-Herds</h3>
                    </Grid>
                    <div className="subherd-wrapper">
                        {modaldata.sub_herds ?
                            modaldata.sub_herds.map((subherd, index) => {
                                let defaultbreeds = [];
                                if (subherd.default) {
                                    defaultbreeds = modaldata.sub_herds.map((d) => {
                                        return d.breeds.map((b) => { return b.replace(/Other(?!\sPurebred)/g, 'Other Purebred') })
                                    })
                                    defaultbreeds = [...defaultbreeds, ...modaldata.herdbreeds]
                                    subherd.breeds = [...new Set([].concat.apply([], defaultbreeds))];
                                }

                                return (
                                    <div key={index} className="subherd-list" onClick={() => {
                                        //change
                                        history.push("/dashboard/herds/subherd/detail?herd_id=" + herddata.herd_id + "&subherd_id=" + subherd.sub_herd_id);
                                    }}>
                                        <div className={subherd.active ? 'subherd-active' : 'subherd-inactive'}></div>
                                        <div>
                                            <h5>{subherd.description ? subherd.description : '-'}</h5>
                                            <div>{subherd.breeds.map((b) => { return b.replace(/Other(?!\sPurebred)/g, 'Other Purebred') }).join(" , ")}</div>
                                        </div>
                                    </div>
                                )
                            }) : <div>No Subherd Found.</div>
                        }

                    </div>
                    {accessPermission ?
                        <Button
                            variant="contained"
                            className="button"
                            type="button"
                            size="small"
                            startIcon={<AddOutlinedIcon />}
                            onClick={() => setAddSubherdModal(true)}>Add a Sub-Herd</Button> : null}
                </ModalFlyout>
            </Container>
            {addSubherdModal ? <ModalFlyout state={addSubherdModal} setstate={setAddSubherdModal}>
                {addasubherdform()}
                <Grid container spacing={2} className="button-submit-container">
                    <Grid item className="button-submit-container-left" xs={12} sm={6}>
                        {getbutton("saveaddbutton")}
                    </Grid>
                    <Grid item className="button-submit-container-right" xs={12} sm={6}>
                        {getbutton("canceladdbutton")}
                    </Grid>
                </Grid>
            </ModalFlyout> : null}
            {
                iserror ? <TwoButtonDialog
                    content={errormessage}
                    open={iserror}
                    handleClose={() => {
                        seterrordialog(false);
                    }}
                    btnText1="OK"
                    btnText2="Close"
                    handleActionBtn1={() => {
                        seterrordialog(false);
                    }}
                    handleActionBtn2={() => {
                        seterrordialog(false);
                    }}
                /> : null
            }
        </article>
    );
};

export default Herds;

