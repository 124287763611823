import { AUTH_CONFIG } from '../configs/auth.config';
import { ApiUrl } from '../configs/path.config';
import { ENVIRONMENT } from '../constants/constants';
import NetworkService from '../services/networkService';
import { getEnvironment } from '../utils';

async function getTokenId(authCode, logintype) {
    const ENV = getEnvironment();
    let REDIRECT_URI = logintype === "internal" ? AUTH_CONFIG.REDIRECT_URI_LOCAL : AUTH_CONFIG.EXT_REDIRECT_URI_LOCAL;
    if (ENV === ENVIRONMENT.PROD) {
        REDIRECT_URI = logintype === "internal" ? AUTH_CONFIG.REDIRECT_URI_PROD : AUTH_CONFIG.EXT_REDIRECT_URI_PROD;
    } else if (ENV === ENVIRONMENT.QA) {
        REDIRECT_URI = logintype === "internal" ? AUTH_CONFIG.REDIRECT_URI_QA : AUTH_CONFIG.EXT_REDIRECT_URI_QA;
    } else if (ENV === ENVIRONMENT.DEV) {
        REDIRECT_URI = logintype === "internal" ? AUTH_CONFIG.REDIRECT_URI_DEV : AUTH_CONFIG.EXT_REDIRECT_URI_DEV;
    }

    let clientid = logintype === "internal" ? AUTH_CONFIG.CLIENT_ID : AUTH_CONFIG.EXT_CLIENT_ID;
    let client_secret = logintype === "internal" ? AUTH_CONFIG.CLIENT_SECRET : AUTH_CONFIG.EXT_CLIENT_SECRET;

    const loginData = 'grant_type=' + AUTH_CONFIG.GRANT_TYPE + '&code=' + authCode + '&client_secret=' + client_secret + '&client_id=' + clientid + '&redirect_uri=' + REDIRECT_URI;
    return await NetworkService.postData(ApiUrl.getAuthTokenURL(), loginData, { contentType: 'application/x-www-form-urlencoded' });

}

export { getTokenId };

