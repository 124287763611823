import React, { useEffect } from "react";
// import { ThemeProvider } from "@material-ui/core/styles";
import AppRoute from './App.route';
import { createTheme , MuiThemeProvider } from '@material-ui/core';
import { appConstants } from '../common/constants/constants';
import NetworkService from '../common/services/networkService';
import { SnackbarProvider } from 'notistack';
import { InitAdobeAnalytics } from '../common/analytics/adobeanalytics/adobeanalytics';

const App = () => {
  InitAdobeAnalytics();
  const theme = createTheme ({
    typography: {
      fontFamily: [
        'Nunito Sans',
        'Helvetica',
        'Arial',
        'sans-serif'
      ],
    }
  });

  const readToken = () => {
    const token = JSON.parse(localStorage.getItem(appConstants.LoginSessionKey));
    if (token) {
      NetworkService.setAccessTokenAuthHeader(token);
    }
  };

  useEffect(() => {
    document.title = 'Addie';
    readToken();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={6}>
        <AppRoute />
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};


export default App;
