import { APP_BASE_URL_DEV, AUTH_CONFIG } from "../configs/auth.config";
import { REPORT_TYPE } from '../constants/constants';

export const ApiUrl = {
    getAuthTokenURL() {
        return AUTH_CONFIG.AUTH_TOKEN_URL;
    },

    getGoalsUrl(goalType, herdId, subHerdId) {
        if (goalType && herdId && subHerdId) {
            return APP_BASE_URL_DEV + 'herd-management/goals/get_goals?goal_type=' + goalType + '&herd_id=' + herdId + '&subherd_id=' + subHerdId;
        }
        return APP_BASE_URL_DEV + 'herd-management/goals/get_goals?goal_type=' + goalType;
    },

    getHerdsUrl(role) {
        return APP_BASE_URL_DEV + 'herd-management/herds/useraccessableherds?user_type=' + role;
    },

    getHerddetailsfromherdid(herdid) {
        return APP_BASE_URL_DEV + 'herd-management/herds/herddetailsforherdid?herd-id=' + herdid;
    },

    getSnapshotGraphsUrl(subHerdId, sourceType, testDay) {
        return APP_BASE_URL_DEV + 'herd-management/generate_report/snapshot-report-graph?sub_herd_id=' + subHerdId + '&source_system=' + sourceType + '&test_day=' + testDay;
    },

    postSnapshotGraphsUrl() {
        return APP_BASE_URL_DEV + 'herd-management/generate_report/graph-data';
    },

    getSubHerdsUrl(subHerdId) {
        return APP_BASE_URL_DEV + 'herd-management/herds/subherdsforherdid?herd-id=' + subHerdId;
    },
    /**
     * Saving the goals.
     * @param {*} goalType 
     */
    saveGoalsUrl(goalType) {
        return APP_BASE_URL_DEV + 'herd-management/goals/post_goals?goal_type=' + goalType;
    },

    uploadFile() {
        return APP_BASE_URL_DEV + 'herd-management/sub-herds/subherdfileupload';
    },

    deleteUploadFile(subHerdId, testDay, sourceType) {
        return APP_BASE_URL_DEV + 'herd-management/sub-herds/deletefile?sub-herd-id=' + subHerdId + '&test-day=' + testDay + '&source-system=' + sourceType;
    },

    saveHerdUrl() {
        return APP_BASE_URL_DEV + 'herd-management/herds/saveherddetails';
    },

    saveHerdWithAssignmentUrl() {
        return APP_BASE_URL_DEV + 'herd-management/herds/save-herd-and-assignments';
    },

    getSubherdFromHerdIdUrl(herd_id) {
        return APP_BASE_URL_DEV + 'herd-management/herds/subherdsforherdid?herd-id=' + herd_id;
    },

    getReportUrl(requestedData, reportType) {
        switch (reportType) {
            case REPORT_TYPE.BENCHMARK_SNAPSHOT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/benchmark-report?sub_herd_id=' + requestedData.sub_herd_id + '&test_day=' + requestedData.test_day + '&source_system=' + requestedData.source_system;

            case REPORT_TYPE.SNAPSHOT_REPORT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/snapshot-report?sub_herd_id=' + requestedData.sub_herd_id + '&test_day=' + requestedData.test_day + '&source_system=' + requestedData.source_system;

            case REPORT_TYPE.SNAPSIX_REPORT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/snap-six-report?sub_herd_id=' + requestedData.sub_herd_id + '&test_day=' + requestedData.test_day + '&source_system=' + requestedData.source_system;

            case REPORT_TYPE.PERIODCOMPARISON_REPORT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/period-compare-report?sub_herd_id=' + requestedData.sub_herd_id + '&test_days=' + requestedData.test_day + '&source_system=' + requestedData.source_system;

            case REPORT_TYPE.CULLINGHISTORY_REPORT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/culling-report?sub_herd_id=' + requestedData.sub_herd_id + '&test_day=' + requestedData.test_day + '&source_system=' + requestedData.source_system;

            case REPORT_TYPE.SNAPSHOTGOAL_REPORT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/snapshot-goal-report?sub_herd_id=' + requestedData.sub_herd_id + '&source_system=' + requestedData.source_system + '&test_day=' + requestedData.test_day;
            
            case REPORT_TYPE.SUBHERDCOMPARISON_REPORT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/subherd-comparison-report?sub_herd_id=' + requestedData.sub_herd_id + '&test_day=' + requestedData.test_day + '&source_system=' + requestedData.source_system;

            case REPORT_TYPE.HEIFER_REPORT:
                return APP_BASE_URL_DEV + 'herd-management/generate_report/heifer-report?sub_herd_id=' + requestedData.sub_herd_id + '&test_day=' + requestedData.test_day + '&source_system=' + requestedData.source_system;

            default:
                return null;
        }
    },

    // Report Layout Endpoint setup 
    saveReportLayout() {
        return APP_BASE_URL_DEV + 'herd-management/settings/post-report-layout';
    },

    deleteMyFavoriteReportLayout() {
        return APP_BASE_URL_DEV + 'herd-management/settings/delete-report-layout';
    },

    getReportLayoutSubherd(subherd_id) {
        return APP_BASE_URL_DEV + 'herd-management/settings/get-report-layouts?type=Subherd&sub_herd_id=' + subherd_id;
    },
    getMyFavoriteReportLAyouts() {
        return APP_BASE_URL_DEV + 'herd-management/settings/get-report-layouts?type=User';
    },

    getRecentlyUploadedfilesforsubherd(sub_herd_id) {
        return APP_BASE_URL_DEV + 'herd-management/sub-herds/getfilehistory?sub-herd-id=' + sub_herd_id;
    }

    ,
    getHerdAccess(herdId) {
        return APP_BASE_URL_DEV + 'herd-management/assignment/getherdaccess?herd-id=' + herdId;
    },

    getuserlists() {
        return APP_BASE_URL_DEV + 'benchmarkingmg/getuserlist';
    },

    getuserdetails() {
        return APP_BASE_URL_DEV + 'benchmarkingmg/getuserdetails';
    },

    saveherdaccess() {
        return APP_BASE_URL_DEV + 'herd-management/assignment/saveherdaccess';
    },
    getcheckadminurl() {
        // let jwttoken =JSON.parse(localStorage.getItem("LoginSessionKey")).access_token;
        // const jwt = JSON.parse(atob(jwttoken.split('.')[1]));
        // return APP_BASE_URL_DEV + 'benchmarkingmg/checkuserrole?email='+jwt.mail;
        return APP_BASE_URL_DEV + 'benchmarkingmg/checkuserrole';
    },
    deleteHerd() {
        return APP_BASE_URL_DEV + 'herd-management/herds/tag-herd-for-deletion';
    },
    assignDefaultHerds() {
        return APP_BASE_URL_DEV + 'herd-management/assignment/update-herd-assignments';
    },
    getdplanherduploadstatus(herdid) {
        return APP_BASE_URL_DEV + 'herd-management/sub-herds/get-tdd-count?herd-id=' + herdid;
    },
}