import Grid from '@material-ui/core/Grid';
import React from "react";
import { facility, milking_frequency_options } from '../../../common/constants/constants';
import Dropdown from '../../ui_utilities/Dropdown';
import MultiSelectDropdown from '../../ui_utilities/MultiSelectDropdown';
import RadioButton from '../../ui_utilities/RadioButton';
import Textbox from '../../ui_utilities/Textbox';

const Milking_Systems = (props) => {

    return (
        <section className="form-section">
            <header>
                <h3>Milking Systems</h3>
            </header>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6} id="milking_facility_type">
                    <label className="form-label">What type of facility is used?*</label>
                    <MultiSelectDropdown
                        disableedit={props.disableedit}
                        data={
                            {
                                labelid: "milking_system_facility-chip-label",
                                id: "milking_facility_type",
                                inputid: "milking_system_facility",
                                labelname: "Facility",
                                options: facility,
                                formdata: props.formdata,
                                setformdata: props.setformdata,
                                className: "addherd_container_input required-style"
                            }
                        } />
                    {
                        props.formerror.milking_facility_type ? (
                            <div className="required-style">{props.formerror.milking_facility_type}</div>
                        ) : null

                    }
                    <p className="helper-text">Select all that apply</p>
                </Grid>


                {
                    (props.formdata["milking_facility_type"]).indexOf("Other") !== -1 ? <Grid item xs={12} md={6} lg={6}>
                        <label className="form-label">Describe 'OTHER'</label>
                        <Textbox
                            disableedit={props.disableedit}
                            data={{
                                className: "text-field-root",
                                name: "milking_facility_type_other",
                                id: "milking_facility_type_other",
                                type: "text",
                                label: "Describe 'OTHER'",
                                formdata: props.formdata,
                                setformdata: props.setformdata,
                            }} />
                    </Grid> : null
                }
                <Grid item xs={12}></Grid>

                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.disableedit}
                        data={{
                            name: "milking_cows_fed_in_parlor",
                            heading: "Are cows fed in the parlor?",
                            yes: "Yes",
                            no: "No",
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                        }} />
                </Grid>

                <Grid item xs={12} sm={8} md={6} id="milking_milking_frequency_per_day">
                    <b>Milking Frequency (per day)*</b>
                    <Dropdown
                        disableedit={props.disableedit}
                        data={{
                            className: "drop-down-root required-style",
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            label: "Milking Frequency (per day)",
                            size: 4,
                            name: "milking_milking_frequency_per_day",
                            id: "milking_milking_frequency_per_day",
                            options: milking_frequency_options
                        }} />
                    {
                        props.formerror.milking_milking_frequency_per_day ? (
                            <div className="required-style">{props.formerror.milking_milking_frequency_per_day}</div>
                        ) : null
                    }
                </Grid>

                {
                    props.formdata["milking_milking_frequency_per_day"] === "mixed" ||
                        props.formdata["milking_milking_frequency_per_day"] === "other" ? <Grid item xs={12} sm={12} md={6}>
                            <Textbox
                                disableedit={props.disableedit}
                                data={{
                                    className: "text-field-root",
                                    name: "milking_milking_frequency_per_day_mixed_other",
                                    id: "milking_milking_frequency_per_day_mixed_other",
                                    type: "text",
                                    label: "Describe 'OTHER' or 'MIXED'",
                                    formdata: props.formdata,
                                    setformdata: props.setformdata,
                                }} />
                        </Grid> : null
                }

                {
                    props.formdata["milking_milking_frequency_per_day"] === "robot" ? <Grid item xs={12} sm={12} md={6}>
                        <Textbox
                            disableedit={props.disableedit}
                            data={{
                                className: "text-field-root",
                                name: "milking_milking_frequency_per_day_robot",
                                id: "milking_milking_frequency_per_day_robot",
                                type: "text",
                                label: "If Robot, describe target visits per day",
                                formdata: props.formdata,
                                setformdata: props.setformdata,
                            }} />
                    </Grid> : null
                }

                {
                    props.formdata["milking_milking_frequency_per_day"] === "mixed" ||
                        props.formdata["milking_milking_frequency_per_day"] === "robot" ||
                        props.formdata["milking_milking_frequency_per_day"] === "other" ? null : <Grid item xs={6}></Grid>
                }



                <Grid item xs={12} sm={8} md={6}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            className: "text-field-root",
                            name: "milking_stalls_in_parlor",
                            id: "milking_stalls_in_parlor",
                            type: "number",
                            label: "Total Number of Stalls in Parlor",

                            formdata: props.formdata,
                            setformdata: props.setformdata,
                        }} />
                    <p className="helper-text">To describe a herd with 2 double 50 parallel parlors, please enter 200.</p>
                </Grid>
                <Grid item xs={12} sm={4} md={6}></Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            className: "text-field-root",
                            name: "milking_parlor_holding_pen_capacity",
                            id: "milking_parlor_holding_pen_capacity",
                            type: "number",
                            label: "Parlor Holding Pen Capacity",
                            helpText: "(Numeric value only)",
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                        }} />
                </Grid>
                <Grid item xs={12} sm={4} md={6}></Grid>

                <Grid item xs={12} sm={8} md={6}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            className: "text-field-root",
                            name: "milking_facility_active_hours_per_day",
                            id: "milking_facility_active_hours_per_day",
                            type: "number",
                            label: "Hours Milking Facility is Active Each Day",
                            helpText: "(Numeric value only)",
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                        }} />
                </Grid>

            </Grid>
        </section>
    );
};

export default Milking_Systems;
