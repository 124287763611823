import Grid from '@material-ui/core/Grid';
import React from "react";
import { milkcooperativeoptions, supplemental_programsoptions } from '../../../common/constants/constants';
import Dropdown from '../../ui_utilities/Dropdown';
import MultiSelectDropdown from '../../ui_utilities/MultiSelectDropdown';
import RadioButton from '../../ui_utilities/RadioButton';
import Textbox from '../../ui_utilities/Textbox';

const Supplementary_Questions = (props) => {

  return (
    <section className="form-section">
      <header>
        <h3>Supplementary Questions</h3>
      </header>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={6}>
          <Dropdown
            disableedit={props.disableedit}
            data={{
              className: "drop-down-root",
              formdata: props.formdata,
              setformdata: props.setformdata,
              label: "Milk cooperative",
              size: 4,
              name: "supplemental_milk_cooperative",
              id: "supplemental_milk_cooperative",
              options: milkcooperativeoptions
            }} />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12} md={10} lg={8}>
          <label className="form-label">Is this producer a member of any programs?</label>
          <MultiSelectDropdown
            disableedit={props.disableedit}
            data={
              {
                labelid: "supplemental_programs_label",
                id: "supplemental_programs",
                inputid: "supplemental_programs_inputid",
                labelname: "Supplemental Program",
                options: supplemental_programsoptions,
                formdata: props.formdata,
                setformdata: props.setformdata,
                className: "addherd_container_input"
              }
            } />
          <p className="helper-text">Select all that apply</p>
        </Grid>
        <Grid item xs={12} md={2} lg={4}></Grid>

        <Grid item xs={12} md={10} lg={8}>
          <Textbox
            disableedit={props.disableedit}
            data={{
              className: "text-field-root",
              name: "supplemental_dairy_fte_employees",
              id: "supplemental_dairy_fte_employees",
              type: "number",
              label: "How many full-time equivalent persons does the dairy employ?",
              formdata: props.formdata,
              setformdata: props.setformdata,
            }
            } />
        </Grid>
        <Grid item xs={12} md={2} lg={4}></Grid>

        <Grid item xs={12} md={10} lg={8}>
          <Textbox
            disableedit={props.disableedit}
            data={{
              className: "text-field-root",
              name: "supplemental_years_since_last_major_expansion",
              id: "supplemental_years_since_last_major_expansion",
              type: "number",
              label: "How many years ago was the last major expansion or remodel on this dairy?",
              formdata: props.formdata,
              setformdata: props.setformdata,
            }} />
        </Grid>
        <Grid item xs={12} md={2} lg={4}></Grid>

        <Grid item xs={12} md={10} lg={8}>
          <Textbox
            disableedit={props.disableedit}
            data={{
              className: "text-field-root",
              name: "supplemental_years_until_next_major_expansion",
              id: "supplemental_years_until_next_major_expansion",
              type: "number",
              label: "When, in years, is the next major expansion or remodel planned for this dairy?",
              formdata: props.formdata,
              setformdata: props.setformdata,
            }} />
        </Grid>
        <Grid item xs={12} md={2} lg={4}></Grid>

        <Grid item xs={12} className="radio-group">
          <RadioButton
            disableedit={props.disableedit}
            data={{
              name: "supplemental_producer_works_with_purina_specialist",
              heading: "Does this producer work with a Purina Animal Nutrition Calf & Heifer Specialist?",
              yes: "Yes",
              no: "No",
              formdata: props.formdata,
              setformdata: props.setformdata
            }} />
        </Grid>

      </Grid>
    </section>
  );
};

export default Supplementary_Questions;
