import React from "react";
// material import
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

const CustomImport = (props) => {
    const url = window.location.href;
    const parts = url.split('/');
    const type = parts[parts.length - 1]

    let updatedBreeds = props.customimportbreed;
    if (type === 'defaultGoals') {
        const foundIndex = props.customimportbreed.indexOf('Default - ' + props.breedSelected);
        updatedBreeds = props.customimportbreed.filter((breed, index) => index !== foundIndex);
    }
    
    return (
       <React.Fragment>
            {
                props.openImportModal ? <div className="goal-modal-wrapper">
                    <div className="import-modal">
                        <div className='modal-title'>
                            <h4>Import Goal values</h4>
                            <CloseIcon className="close-icon" onClick={() => { props.setOpenImportModal(false); }} />
                        </div>
                        <div>Your selection will import these goals.</div>

                        <TextField
                            id="default-flag"
                            select
                            label="Select"
                            className="text-field-root required-style"
                            value={props.importBreed}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            onChange={
                                (event) => {
                                    props.setImportBreed(event.target.value);
                                }
                            }
                        >
                            {updatedBreeds.map((option,i) => (
                                <MenuItem key={i} value={option} name={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <div className="modal-buttons">
                            <Button
                                variant="outlined"
                                className="button"
                                type="button"
                                size="small"
                                onClick={(event) => {
                                    props.importDefaultGoalsMethod();
                                    props.setOpenImportModal(false);
                                    props.setclickImport(true);
                                }
                                }
                            > Import Goals</Button>
                            <Button
                                variant="outlined"
                                className="button grey"
                                type="button"
                                size="small"
                                onClick={(event) => {
                                    props.setOpenImportModal(false);
                                }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div> : null
           }
       </React.Fragment>
   )
}

export default CustomImport;

