import React from "react";
import Button from '@material-ui/core/Button';
// material import
import Grid from '@material-ui/core/Grid';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { beddingoption, heatabatementoption, housingoption, manuredisposaloptions, primaryfeeddeliverysystemoption } from '../../../../common/constants/constants';
import Dropdown from '../../../ui_utilities/Dropdown';
import ModalFlyout from '../../../ui_utilities/modalFlyout';
import MultiSelectDropdown from '../../../ui_utilities/MultiSelectDropdown';
import RadioButton from '../../../ui_utilities/RadioButton';
// userdefined component import
import Textbox from '../../../ui_utilities/Textbox';


// import LeftModal from "../../../ui_utilities/LeftModal";

const Adult_Facility_form = (props) => {
    const [adultformdata, setadultformdata] = React.useState({});


    React.useEffect(() => {
        if (props.type === "edit") {
            let form = getformname(props.form);
            setadultformdata(props.formdata[form]);
        } else {
            var temp = {};
            temp["Adult_facility_form_" + props.form + "_fed_group"] = "Yes";
            temp["Adult_facility_form_" + props.form + "_multiporous_group_cow"] = "Yes";
            temp["Adult_facility_form_" + props.form + "_average_days_in_group"] = "";
            temp["Adult_facility_form_" + props.form + "_primary_feed_delivery_system"] = [];
            temp["Adult_facility_form_" + props.form + "_cows_fed"] = "";
            temp["Adult_facility_form_" + props.form + "_minimum_feed_space"] = "";
            temp["Adult_facility_form_" + props.form + "_share_major_nutrient_and_ingrdeint_ration"] = "No";
            temp["Adult_facility_form_" + props.form + "_housing"] = [];
            temp["Adult_facility_form_" + props.form + "_bedding"] = [];
            temp["Adult_facility_form_" + props.form + "_heat_abatement"] = [];
            temp["Adult_facility_form_" + props.form + "_fan_wind_speed"] = "";
            temp["Adult_facility_form_" + props.form + "_manual_disposal_system"] = "";
            temp["Adult_facility_form_" + props.form + "_animal_present"] = "";
            temp["Adult_facility_form_" + props.form + "_facilities_insulated"] = "No";
            temp["Adult_facility_form_" + props.form + "_stalls_lactating"] = "";
            temp["Adult_facility_form_" + props.form + "_lactating_ration"] = "";
            temp["supplemental_producer_uses_feed_additives"] = "No";
            temp["supplemental_producer_uses_full_anion_supplementation"] = "No";
            // supplemental_producer_uses_feed_additives
            //supplemental_producer_uses_full_anion_supplementation
            setadultformdata(temp);
        }
    }, [props.type, props.form, props.formdata])

    const getformname = (form) => {
        switch (form) {
            case "far_off_dry":
                form = "facilties_and_grouping_far_off_dry_form";
                break;

            case "close_up_dry":
                form = "facilties_and_grouping_close_up_dry_form";
                break;

            case "single_group_dry_cows":
                form = "facilties_and_grouping_single_group_for_dry_cows_form";
                break;

            case "postfreshlactating":
                form = "facilties_and_grouping_post_fresh_lactating_cows_form";
                break;

            case "lactating":
            default:
                form = "facilties_and_grouping_lactating_rations_form";
        }
        return form;
    }

    const saveform = () => {
        let form = getformname(props.form);
        console.log("Saveform called :: " + form);
        let temp = { ...props.formdata };
        temp[form] = { ...{ ...adultformdata } };
        props.setformdata(temp);
        props.setstate(false);
    }

    let a = <Grid item xs={12} className="radio-group">
        <RadioButton
            disableedit={props.disableedit}
            data={{
                name: "Adult_facility_form_" + props.form + "_fed_group",
                heading: "Is this group fed by Purina Animal Nutrition?",
                yes: "Yes",
                no: "No",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>;

    let b = <Grid item xs={12} className="radio-group">
        <RadioButton
            disableedit={props.disableedit}
            data={{
                name: "Adult_facility_form_" + props.form + "_multiporous_group_cow",
                heading: "Are Springing heifers or first lactation animals grouped with multiparous cows?",
                yes: "Yes",
                no: "No",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>;

    let c = <Grid item xs={12}>
        <br />
        <Textbox
            disableedit={props.disableedit}
            data={{
                className: "text-field-root",
                name: "Adult_facility_form_" + props.form + "_average_days_in_group",
                id: "Adult_facility_form_" + props.form + "_average_days_in_group",
                type: "number",
                label: "Average days in group",
                formdata: adultformdata,
                setformdata: setadultformdata
            }
            } />
    </Grid>;

    let d = <Grid item xs={12}>
        <label className="form-label">Primary Feed Delivery System</label>
        <MultiSelectDropdown
            disableedit={props.disableedit}
            data={
                {
                    labelid: "Adult_facility_form_" + props.form + "_primary_feed_delivery_system-chip-label",
                    id: "Adult_facility_form_" + props.form + "_primary_feed_delivery_system",
                    inputid: "Adult_facility_form_" + props.form + "_primary_feed_delivery_system",
                    labelname: "Primary Feed Delivery System",
                    options: primaryfeeddeliverysystemoption,
                    formdata: adultformdata,
                    setformdata: setadultformdata
                }
            } />
    </Grid>

    let e = <Grid item xs={12}>
        <Textbox
            disableedit={props.disableedit}
            data={{
                className: "text-field-root",
                name: "Adult_facility_form_" + props.form + "_cows_fed",
                id: "Adult_facility_form_" + props.form + "_cows_fed",
                type: "number",
                label: "How many times are cows fed TMR or PMR per day? (1-3+)",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    let f = <Grid item xs={12}>
        <Textbox
            disableedit={props.disableedit}
            data={{
                className: "text-field-root",
                name: "Adult_facility_form_" + props.form + "_minimum_feed_space",
                id: "Adult_facility_form_" + props.form + "_minimum_feed_space",
                type: "number",
                label: "Approximate minimum amount of feed space available (inches/cow)?",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    let g = <Grid item xs={12} className="radio-group">
        <RadioButton
            disableedit={props.disableedit}
            data={{
                name: "Adult_facility_form_" + props.form + "_share_major_nutrient_and_ingrdeint_ration",
                heading: "Would you be willing to share the major nutrient and ingredient characteristics of the rations?",
                yes: "Yes",
                no: "No",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>;

    let h = <Grid item xs={12}>
        <label className="form-label">Housing</label>
        <MultiSelectDropdown
            disableedit={props.disableedit}
            data={
                {
                    labelid: "Adult_facility_form_" + props.form + "_housing-chip-label",
                    id: "Adult_facility_form_" + props.form + "_housing",
                    inputid: "Adult_facility_form_" + props.form + "_housing",
                    labelname: "Housing",
                    options: housingoption,
                    formdata: adultformdata,
                    setformdata: setadultformdata
                }
            } />
    </Grid>

    let i = <Grid item xs={12}>
        <label className="form-label">Bedding</label>
        <MultiSelectDropdown
            disableedit={props.disableedit}
            data={
                {
                    labelid: "Adult_facility_form_" + props.form + "_bedding-chip-label",
                    id: "Adult_facility_form_" + props.form + "_bedding",
                    inputid: "Adult_facility_form_" + props.form + "_bedding",
                    labelname: "Bedding",
                    options: beddingoption,
                    formdata: adultformdata,
                    setformdata: setadultformdata
                }
            } />
    </Grid>

    let j = <Grid item xs={12}>
        <label className="form-label">Heat Abatement</label>
        <MultiSelectDropdown
            disableedit={props.disableedit}
            data={
                {
                    labelid: "Adult_facility_form_" + props.form + "_heat_abatement-chip-label",
                    id: "Adult_facility_form_" + props.form + "_heat_abatement",
                    inputid: "Adult_facility_form_" + props.form + "_heat_abatement",
                    labelname: "Heat Abatement",
                    options: heatabatementoption,
                    formdata: adultformdata,
                    setformdata: setadultformdata
                }
            } />
    </Grid>

    let k = <Grid item xs={12}>
        <Textbox
            disableedit={props.disableedit}
            data={{
                className: "text-field-root",
                name: "Adult_facility_form_" + props.form + "_fan_wind_speed",
                id: "Adult_facility_form_" + props.form + "_fan_wind_speed",
                type: "number",
                label: "If using fans, what is the wind-speed in mph?",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    let l = <Grid item xs={12}>
        <Dropdown
            disableedit={props.disableedit}
            data={{
                className: "drop-down-root",
                formdata: adultformdata,
                setformdata: setadultformdata,
                label: "Manure Disposal System",
                size: 4,
                name: "Adult_facility_form_" + props.form + "_manual_disposal_system",
                id: "Adult_facility_form_" + props.form + "_manual_disposal_system",
                options: manuredisposaloptions
            }} />
    </Grid>

    let m = <Grid item xs={12}>
        <Textbox
            disableedit={props.disableedit}
            data={{
                className: "text-field-root",
                name: "Adult_facility_form_" + props.form + "_animal_present",
                id: "Adult_facility_form_" + props.form + "_animal_present",
                type: "number",
                label: "When these facilities are at capacity, how many animals are present?",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    let n = <Grid item xs={12}>
        <RadioButton
            disableedit={props.disableedit}
            data={{
                name: "Adult_facility_form_" + props.form + "_facilities_insulated",
                heading: "Are any of these facilities insulated?",
                yes: "Yes",
                no: "No",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    let o = <Grid item xs={12}>
        <Textbox
            disableedit={props.disableedit}
            data={{
                className: "text-field-root",
                name: "Adult_facility_form_" + props.form + "_stalls_lactating",
                id: "Adult_facility_form_" + props.form + "_stalls_lactating",
                type: "number",
                label: "How many stalls are available?",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    // let p=<Grid item xs={12}>
    //     <Textbox 
    //             disableedit={props.disableedit}
    //             data={{
    //                     className:"text-field-root",
    //                     name:"Adult_facility_form_"+props.form+"_lactating_ration",
    //                     id:"Adult_facility_form_"+props.form+"_lactating_ration",
    //                     type:"number",
    //                     label:"Lactating Rations(turn on 1,2 or 3+)",
    //                     formdata:adultformdata,
    //                     setformdata:setadultformdata
    //                     }}/>
    // </Grid>

    let q = <Grid item xs={12} className="radio-group">
        <RadioButton
            disableedit={props.disableedit}
            data={{
                name: "supplemental_producer_uses_full_anion_supplementation",
                heading: "Does this producer use full anion supplementation in the pre-fresh period?",
                yes: "Yes",
                no: "No",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    let r = <Grid item xs={12} className="radio-group">
        <RadioButton
            disableedit={props.disableedit}
            data={{
                name: "supplemental_producer_uses_feed_additives",
                heading: "Does this producer use feed additives such as yeasts, plant-based compounds, prebiotics, enzymes, or direct-fed microbials?",
                yes: "Yes",
                no: "No",
                formdata: adultformdata,
                setformdata: setadultformdata
            }} />
    </Grid>

    return (
        <ModalFlyout state={props.state} setstate={props.setstate}
            width="xl"
            id={props.id}
            formdata={props.formdata}
            setformdata={props.setformdata}
            setswitchoff={props.setswitchoff ? props.setswitchoff : null}>

            <div style={{ maxHeight: "calc( 100vh - 70px)", height: "auto", overflowY: "auto", marginBottom: "20px", padding: "0 10px" }}>
                <Grid container spacing={2}>
                    {/* Feeding Strategy */}
                    {a}{b}{c}{d}{e}{f}
                    {
                        props.form === "close_up_dry" || props.form === "single_group_dry_cows" ? q : null
                    }

                    {r}{g}

                    {/* Housing */}
                    {h}{m}{o}{j}

                    {/* Climate Control */}
                    {k}{n}

                    {/* Comfort and Cleaniness */}
                    {i}{l}
                    {/* {
                props.form==="postfreshlactating" || props.form==="lactating"?<React.Fragment>
                    {m}{n}{o}{p}
                </React.Fragment>:null
            } */}
                    <Grid item xs={12}>
                        <Button
                            disabled={props.disableedit}
                            variant="contained"
                            className="button"
                            type="button"
                            size="small"
                            startIcon={<SaveOutlinedIcon />}
                            onClick={() => {
                                saveform()
                            }}>
                            SAVE FORM
            </Button>
                    </Grid>
                </Grid>
            </div>
        </ModalFlyout>

    );
};

export default Adult_Facility_form;
