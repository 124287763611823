import ApexCharts from "apexcharts";
import React from "react";
import critical_thresold_flag from "../../../assets/images/critical_thresold_flag.png";
import initial_thresold_flag from "../../../assets/images/initial_thresold_flag.jpg";
import { benchmarkreporttext, cullinghistoryreporttext, defaultchartsetting, defaultchartsettingwithoutlactationcurve, periodcomparisonreporttext, reportsetting, reporttotaltestdays, snapshotgoalreporttext, snapshotreporttext, subherdscomparisonreporttext, heiferreporttext, snapsixreporttext, testdayANDWeeklyMilkArray } from '../../constants/constants';

const getreporttext = (name) => {
    let reporttext = "";
    switch (name) {
        case "snapshotgoals":
            reporttext = snapshotgoalreporttext;
            break;
        case "cullinghistory":
            reporttext = cullinghistoryreporttext;
            break;
        case "benchmark":
            reporttext = benchmarkreporttext;
            break;
        case "periodcomparison":
            reporttext = periodcomparisonreporttext;
            break;
        case "snapsix":
            reporttext = snapsixreporttext;
            break;
        case "subherdcomparison":
            reporttext = subherdscomparisonreporttext;
            break;
        case "heiferreport":
            reporttext = heiferreporttext;
            break;
        case "snapshot":
        default:
            reporttext = snapshotreporttext;
            break;
    }
    return reporttext;
}

const customizationallowedreport = ["snapshot", "benchmark", "snapsix", "snapshotgoals", "periodcompare", "periodcomparison", "subherdcomparison", "heiferreport"];

const setchartgraph = (selectedGraphdata) => {
}


// Generate pdf HTML 
const getrowheadercontent = (row, section) => {
    let trclassname = null;

    let rowheadercontent = row.Row_Header;
    if (section.firstlevelpadding_Row_Names) {
        if (
            section.firstlevelpadding_Row_Names.includes(
                row.Row_Name
            )
        ) {
            trclassname = "move-left-wrapper";
            rowheadercontent = (
                <div className="firstlevelpadding">
                    {row.Row_Header}
                </div>
            );
        }
    }
    if (section.secondlevelpadding_Row_Names?.length > 0) {
        if (
            section.secondlevelpadding_Row_Names.includes(
                row.Row_Name
            )
        ) {
            trclassname = "move-left-wrapper";
            rowheadercontent = (
                <div className="secondlevelpadding">
                    {row.Row_Header}
                </div>
            );
        }
    }

    return {
        rowheadercontent, trclassname
    }
}

const getsectionname = (sectionname) => {
    switch (sectionname) {
        case "All_Cows":
            sectionname = "All Cows";
            break;
        case "Lactation_1":
            sectionname = "Lactation 1 Cows";
            break;
        case "Lactation_2":
            sectionname = "Lactation 2 Cows";
            break;
        case "Lactation_3":
            sectionname = "Lactation 3+ Cows";
            break;
        case "ComponentAnalysis":
            sectionname = "Component Analysis";
            break;
        case "Milk_Quality":
            sectionname = "Milk Quality";
            break;
        case "HerdInventory":
            sectionname = "Herd Inventory";
            break;
        case "HeiferEnterprise":
            sectionname = "Heifer Enterprise";
            break;
        default:
            console.log()
    }
    return sectionname;
}

const getcelldata = (row, column, rowindex, response, report, pdfflagclass) => {
    let celldata = row.Row_val[column.col_name];
    let dashstyle = "";
    if (column.col_name === "dash") {
        dashstyle = "dashstyle"
    }
   
    if (
        response.TestDates.hasOwnProperty(column.col_name)
    ) {
        if (column.col_name === "test_day_1" && report !== "snapshotgoals") {
            if (testdayANDWeeklyMilkArray.includes(row.Row_Name)
            ) {
                let hd = null,
                    dim = null;
                if (row.Row_val.hasOwnProperty("HD")) {
                    if (
                        row.Row_Name === "Less_30_DIM" ||
                        row.Row_Name === "Milk_Week_2"
                    ) {
                        hd = (
                            <React.Fragment>
                                <b>HD</b>&nbsp;&nbsp;
                                {row.Row_val["HD"]}
                            </React.Fragment>
                        );

                        dim = row.Row_val["DIM"] ? (
                            <React.Fragment>
                                <b>DIM</b>&nbsp;&nbsp;
                                {row.Row_val["DIM"]}
                            </React.Fragment>
                        ) : null;
                    } else {
                        hd = row.Row_val["HD"];
                        dim = row.Row_val["DIM"] ? row.Row_val["DIM"] : null;
                    }
                }

                if ((dim !== null || hd !== null) && report !== "periodcompare") {
                    if (report === "subherdcomparison") {
                        celldata = row.Row_val[column.col_name];
                    } else {
                        celldata = (
                            <div className="reportTableinner-wrapper">
                                <div className="reportTableinner-data">
                                    {hd}
                                </div>
                                <div className="reportTableinner-data">
                                    {dim}
                                </div>
                                <div className="reportTableinner-data">
                                    {row.Row_val[column.col_name]}
                                </div>
                            </div>
                        );
                    }
                }
            }
        }
    }
    if (report === "periodcompare" && column.col_header === "Period 1") {
        celldata = row.Row_val["period_1"]
    }
    if (report === "periodcompare" && column.col_header === "Period 2") {
        celldata = row.Row_val["period_2"]
    }

    if (column.col_name === "Flag") {
        if (row.Row_val[column.col_name] === 2) {
            celldata = (
                <img
                    src={critical_thresold_flag}
                    className={pdfflagclass}
                    alt="critical_thresold_flag"
                />
            );
        } else if (row.Row_val[column.col_name] === 1) {
            celldata = (
                <img
                    src={initial_thresold_flag}
                    className={pdfflagclass}
                    alt="initial_thresold_flag"
                />
            );
        } else if (row.Row_val[column.col_name] === null || row.Row_val[column.col_name] === "None" || row.Row_val[column.col_name] === 0) {
            celldata = <span></span>;
        }
    }
    if (celldata === -1) {
        celldata = null
    }
    
    return { celldata, dashstyle };
}

const addWatermark = (doc) => {
    var totalPages = doc.internal.getNumberOfPages();
    for (var i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setTextColor("#FF0000");
        doc.setFontSize(70);
        doc.saveGraphicsState();
        doc.setGState(new doc.GState({
            opacity: 0.2
        }));
        doc.text('Report in Development', 100, doc.internal.pageSize.height * .75, { angle: 45 });
        doc.restoreGraphicsState();
    }
    return doc;
}

const getchartimagebloburlandgenerateReport = async (data, displayChart, graphdata) => {
    let temp = [];
    if (displayChart) {
        let testday_chartIMG = await ApexCharts.exec("testdaymilkchart", 'dataURI', { width: 700 });
        let weekly_chartIMG = await ApexCharts.exec("weeklymilkchart", 'dataURI', { width: 700 });
        let chart2_imgURI = "";
        for (const graph of graphdata) {
            if(graph.key === "2" || graph.key === 2) {
                chart2_imgURI = testday_chartIMG !== undefined ? testday_chartIMG?.imgURI : weekly_chartIMG?.imgURI;
            }
            let data = await ApexCharts.exec(graph.id, 'dataURI', { width: 700 });
            const graphImgURI = graph.bloburl !== undefined ? graph.bloburl : data?.imgURI
            temp.push({
                ...graph,
                bloburl: graph.key === "2" || graph.key === 2 ? chart2_imgURI : graphImgURI
            })
        }
    }
    return temp;
}

const getPdfDynamicSettings = (settings, rowperpage, after1page_maxrow) => {
    let section_seq = 1;
    let sections_array = [];
    let row_ctr = 0;
    let result = [];
    let pageCtr = 1;
    let existing_sections = [];
  
    const foundExistSect = (value) => {
      const found = existing_sections.includes(value);
      return found;
    };
  
    settings.forEach((data) => {
        data.sections.forEach((section, index) => {
            let maxrowsperpage = rowperpage;
            let row_array = [];
            row_ctr += 1;

            if (pageCtr === 1) {
                maxrowsperpage -= 2;
            }
    
            section.Rows.forEach((row, row_index) => {
                row_array.push({
                    Row_Header: row.Row_Header,
                    Row_Name: row.Row_Name,
                    Row_Seq: row.Row_Seq,
                    Row_val: row.Row_val,
                });
                row_ctr += 1;
                
                
                if (row_ctr >= maxrowsperpage) { // condition to determine when will you add new page
                    if (!foundExistSect(section.Section_Header)) {
                        existing_sections.push(section.Section_Header);
                    }
                    
                    if (section.firstlevelpadding_Row_Names !== undefined) {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            firstlevelpadding_Row_Names: section.firstlevelpadding_Row_Names,
                            show: true,
                        });
                    } else if (section.secondlevelpadding_Row_Names !== undefined) {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            firstlevelpadding_Row_Names: section.firstlevelpadding_Row_Names,
                            secondlevelpadding_Row_Names: section.secondlevelpadding_Row_Names,
                            show: true,
                        });
                    } else {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            show: true,
                        });
                    }
        
                    result.push({
                        page: pageCtr,
                        sections: sections_array,
                    });
        
                    pageCtr += 1;
                    sections_array = [];
                    row_array = [];
                    row_ctr = 0;
                }
            });
    
            if (row_array.length > 0) { 
                const found = foundExistSect(section.Section_Header);
                if (found) {
                    if (section.firstlevelpadding_Row_Names !== undefined) {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            firstlevelpadding_Row_Names: section.firstlevelpadding_Row_Names,
                            show: false,
                        });
                    } else if (section.secondlevelpadding_Row_Names !== undefined) {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            firstlevelpadding_Row_Names: section.firstlevelpadding_Row_Names,
                            secondlevelpadding_Row_Names: section.secondlevelpadding_Row_Names,
                            show: true,
                        });
                    } else {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            show: false,
                        });
                    }
                } else {
                    if (section.firstlevelpadding_Row_Names !== undefined) {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            firstlevelpadding_Row_Names: section.firstlevelpadding_Row_Names,
                            show: true,
                        });
                    } else if (section.secondlevelpadding_Row_Names !== undefined) {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            firstlevelpadding_Row_Names: section.firstlevelpadding_Row_Names,
                            secondlevelpadding_Row_Names: section.secondlevelpadding_Row_Names,
                            show: true,
                        });
                    } else {
                        sections_array.push({
                            Rows: row_array,
                            Section_Header: section.Section_Header,
                            Section_Name: section.Section_Name,
                            Section_Seq: section_seq,
                            show: true,
                        });
                    }
                }
            }
        
        });
        section_seq += 1;
    });
  
    if (sections_array.length > 0) {
        let rowCtr = 0;
        sections_array.map((sec) => {
            rowCtr += 1;
            sec.Rows.map((row) => {
                rowCtr += 1;
            })
        })
        
        result.push({
            page: pageCtr,
            sections: sections_array,
        });

        // check number of rows and add another page if it will overlapse in the footer
        if(rowCtr > after1page_maxrow) {
            result.push({
                page: pageCtr + 1,
                sections: [],
            });
        }
    }
    
    return result;
};

// Report Setting Logic
const getdynamicsettings = (settings, rowperpage, report, type, graphimgurlarraydata, headerCtr) => {
    const getlastpagesetting = (temprow, d, tempsetting, show, report, type, rowperpage) => {
        //this is last page.
        let rows = [];
        let lastpagerows = [];
        let offset = temprow.length + d.Rows.length;
        let rowsInlastpage = 44;
        if (report === "benchmark") {
            rowsInlastpage = 33;
        }
        
        if (report === "snapshot") {
            rowsInlastpage = 36;
        }

        if (type === "view") {
            rowsInlastpage = rowperpage - 2
        }
        offset = offset - rowsInlastpage - 1;
        // try add here + headerCtr

        d.Rows.forEach((data, rowindex) => {
            if (rowindex > offset) {
                rows.push(data)
            } else {
                lastpagerows.push(data)
            }
        })
        tempsetting[pageincrement].sections.push({
            ...d,
            show: show,
            Rows: rows
        })

        if (lastpagerows.length > 0) {
            temprow = [];
            pageincrement += 1;
            tempsetting[pageincrement] = {
                page: pageincrement + 1,
                sections: []
            }
            tempsetting[pageincrement].sections.push({
                ...d,
                show: false,
                Rows: lastpagerows
            })
        }
        return tempsetting;
    }

    let allsections = [];
    let allsectionnames = [];
    settings.forEach((d) => { allsections.push(...d.sections) });
    
    allsections.forEach((d) => {
        allsectionnames.push({
            name: d.Section_Name,
            touched: false
        })
    });
    let temprow = [];
    let pageincrement = 0;
    let tempsetting = [{
        page: pageincrement + 1,
        sections: []
    }]
    allsections.forEach((d, secindex) => {
        // let rowLenght = d.Rows.length;
        let pagerowlength = temprow.length + d.Rows.length;
        let show = false;
        if (pagerowlength === rowperpage) {
            if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                show = true;
            } else {
                show = false;
            }

            if (graphimgurlarraydata.length > 0) {
                tempsetting[pageincrement].sections.push({
                    ...d,
                    show: show,
                    Rows: [...d.Rows]
                })
                if (allsections.length - 1 !== secindex) {
                    // if not last page
                    temprow = [];
                    pageincrement += 1;
                    tempsetting[pageincrement] = {
                        page: pageincrement + 1,
                        sections: []
                    }
                }
            } else {
                // check on if this is the last page.if yes dont add new page
                if (allsections.length - 1 === secindex) {
                    // Last page 
                    tempsetting = getlastpagesetting(temprow, d, tempsetting, show, report, type, rowperpage);
                } else {
                    tempsetting[pageincrement].sections.push({
                        ...d,
                        show: show,
                        Rows: [...d.Rows]
                    })
                    temprow = [];
                    pageincrement += 1;
                    tempsetting[pageincrement] = {
                        page: pageincrement + 1,
                        sections: []
                    }
                }
            }
        } else if (pagerowlength > rowperpage) {
            const splitarrayofobject = (arryofobject, offset) => {
                let rows = [];
                temprow = [];
                arryofobject.forEach((r, rindex) => {
                    if (rindex < offset) {
                        rows.push(r)
                    } else {
                        temprow.push(r)
                    }
                })
                return { rows, temprow };
            }

            const handlepagedata = () => {
                // const new_rowperpage = rowperpage;
                // let offset = new_rowperpage - temprow.length;
                let offset = rowperpage - temprow.length;
                let arryobj = splitarrayofobject(d.Rows, offset)
                let rows = arryobj.rows;
                temprow = arryobj.temprow;
                if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                    allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                    show = true;
                } else {
                    show = false;
                }
                tempsetting[pageincrement].sections.push({
                    ...d,
                    show: show,
                    Rows: rows
                })

                while (temprow.length > rowperpage) {
                    offset = rowperpage;
                    arryobj = splitarrayofobject(temprow, offset);
                    rows = arryobj.rows;
                    temprow = arryobj.temprow;

                    pageincrement += 1;
                    tempsetting[pageincrement] = {
                        page: pageincrement + 1,
                        sections: []
                    }
                    if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                        allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                        show = true;
                    } else {
                        show = false;
                    }
                    tempsetting[pageincrement].sections.push({
                        ...d,
                        show: show,
                        Rows: rows
                    })

                }
                pageincrement += 1;
                tempsetting[pageincrement] = {
                    page: pageincrement + 1,
                    sections: []
                }
                if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                    allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                    show = true;
                } else {
                    show = false;
                }
                tempsetting[pageincrement].sections.push({
                    ...d,
                    show: show,
                    Rows: temprow
                })
            }
            if (graphimgurlarraydata.length > 0) {
                handlepagedata();
            } else {
                if (allsections.length - 1 === secindex) {
                    // Last page 
                    let offset = rowperpage - temprow.length;
                    let arryobj = splitarrayofobject(d.Rows, offset)
                    let rows = arryobj.rows;
                    temprow = arryobj.temprow;
                    if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                        allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                        show = true;
                    } else {
                        show = false;
                    }
                    tempsetting[pageincrement].sections.push({
                        ...d,
                        show: show,
                        Rows: rows
                    })

                    while (temprow.length > rowperpage) {
                        offset = rowperpage;
                        arryobj = splitarrayofobject(temprow, offset);
                        rows = arryobj.rows;
                        temprow = arryobj.temprow;

                        pageincrement += 1;
                        tempsetting[pageincrement] = {
                            page: pageincrement + 1,
                            sections: []
                        }
                        if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                            allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                            show = true;
                        } else {
                            show = false;
                        }
                        tempsetting[pageincrement].sections.push({
                            ...d,
                            show: show,
                            Rows: rows
                        })

                    }
                    pageincrement += 1;
                    tempsetting[pageincrement] = {
                        page: pageincrement + 1,
                        sections: []
                    }
                    if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                        allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                        show = true;
                    } else {
                        show = false;
                    }
                    if (temprow.length > 24) {
                        let lastpagerow = [];
                        lastpagerow.push(temprow[temprow.length - 1]);
                        temprow = temprow.filter((dr, i) => { return i !== (temprow.length - 1) })
                        tempsetting[pageincrement].sections.push({
                            ...d,
                            show: show,
                            Rows: temprow
                        })
                        pageincrement += 1;
                        tempsetting[pageincrement] = {
                            page: pageincrement + 1,
                            sections: []
                        }
                        tempsetting[pageincrement].sections.push({
                            ...d,
                            show: show,
                            Rows: lastpagerow
                        })
                    } else {
                        tempsetting[pageincrement].sections.push({
                            ...d,
                            show: show,
                            Rows: temprow
                        })
                    }
                } else {
                    handlepagedata();
                }
            }
        } else {
            if (allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name)?.touched === false) {
                allsectionnames.find((sectionnames) => sectionnames.name === d.Section_Name).touched = true
                show = true;
            } else {
                show = false;
            }
            if (graphimgurlarraydata.length > 0) {
                temprow = [...temprow, ...d.Rows];
                tempsetting[pageincrement].sections.push({
                    show: show,
                    ...d
                })
            } else {
                if (allsections.length - 1 === secindex) {
                    // Last page 
                    tempsetting = getlastpagesetting(temprow, d, tempsetting, show, report, type, rowperpage, pagerowlength);
                } else {
                    temprow = [...temprow, ...d.Rows];
                    tempsetting[pageincrement].sections.push({
                        show: show,
                        ...d
                    })
                }
            }
        }
    })
    return tempsetting;
}

// Report Charting Logic
const new_getcondensedchartpagesetting = (chartpagesetting) => {
    let chartdata = [];
    // Changing the chartpagesetting into a 1 dimensional array
    chartpagesetting.forEach((data) => {
        chartdata.push(...data.chart);
    })
    const tempChartSetting = [];

    const processChartData = (chartData) => {
        let currentSetting = {
            chart: [],
        };

        chartData.forEach((chart) => {
            // Check the conditions and update the current setting
            const condition1 =
                currentSetting.chart.filter((c) => c.width === 'full_page').length === 1 &&
                currentSetting.chart.filter((c) => c.width === 'half_page').length === 2;

            const condition2 =
                currentSetting.chart.filter((c) => c.width === 'full_page').length === 1 &&
                currentSetting.chart.filter((c) => c.width === 'one_third').length === 3

            const condition3 =
                currentSetting.chart.filter((c) => c.width === 'one_third').length === 3 &&
                currentSetting.chart.filter((c) => c.width === 'half_page').length === 4

            const condition4 =
                currentSetting.chart.filter((c) => c.width === 'half_page').length === 2 &&
                currentSetting.chart.filter((c) => c.width === 'one_third').length === 6;

            const condition5 =
                currentSetting.chart.filter((c) => c.width === 'full_page').length === 1 &&
                currentSetting.chart.filter((c) => c.width === 'half_page').length === 1 &&
                currentSetting.chart.filter((c) => c.width === 'one_third').length === 1;

            const condition6 =
                currentSetting.chart.filter((c) => c.width === 'half_page').length === 6;

            const condition7 =
                currentSetting.chart.filter((c) => c.width === 'one_third').length === 9;

            const condition8 =
                currentSetting.chart.filter((c) => c.width === 'full_page').length === 1 &&
                chart.width === "full_page"

            const condition9 = 
                currentSetting.chart.filter((c) => c.width === 'full_page').length === 1 &&
                currentSetting.chart.filter((c) => c.width === 'one_third').length === 2 &&
                chart.width === "half_page"

            const condition10 = 
                currentSetting.chart.filter((c) => c.width === 'half_page').length >= 3 &&
                chart.width === "full_page"

            if (
                (condition1) ||
                (condition2) ||
                (condition3) ||
                (condition4) ||
                (condition5) ||
                (condition6) ||
                (condition7) ||
                (condition8) ||
                (condition9) ||
                (condition10)
            ) {
                tempChartSetting.push(currentSetting);
                currentSetting = { chart: [] };
            }

            currentSetting.chart.push(chart);
        });

        // Push the remaining charts
        if (currentSetting.chart.length > 0) {
            tempChartSetting.push(currentSetting);
        } 
    };
    
    processChartData(chartdata);

    return chartdata.length === 0 ? [] : tempChartSetting;
}

export {
    getrowheadercontent, getsectionname, getcelldata, addWatermark, getchartimagebloburlandgenerateReport, defaultchartsetting, defaultchartsettingwithoutlactationcurve, customizationallowedreport, reportsetting, reporttotaltestdays, getPdfDynamicSettings, getdynamicsettings, new_getcondensedchartpagesetting, getreporttext, setchartgraph
};

