import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router-dom";
import TwoButtonDialog from "../../common/dialogs/two-button-dialog/two-button-dialog";



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    padding: "10px 0 6px",
    margin: "21px 0 13px",
    background: "none",
    borderRadius: 0,
    borderTop: "solid 1px rgba(255, 255, 255, .13)",
    borderBottom: "solid 1px rgba(255, 255, 255, .13)",
  },
  header: {
    padding: 0,
    textAlign: "left",
    "&:hover": { curser: "pointer" },
    marginBottom: "6px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: 0,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    margin: "6px 8px 0 0",
    color: "#fff",
    opacity: ".55",
    borderRadius: "5px",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "transparent",
    width: "25px",
    height: "25px",
    textAlign: "center",
  },
  avatarIcon: {
    fontSize: "1.7rem",
    opacity: ".8",
  },
  userButton: {
    width: "100%",
    fontSize: ".875rem",
    color: "#fff",
    textAlign: "left",
    background: "rgba(16, 32, 45, .08)",
    borderRadius: "99px",
    height: "26px",
    padding: "13px",
    margin: "5px 0 5px",
  },
  userButtonText: {
    width: "100%",
  },
  cardContent: {
    padding: "0",
    "&:last-child": { padding: "0 !important" },
  },
  title: {
    color: "#fff",
    fontWeight: "900",
    whiteSpace: "nowrap",
    maxWidth: "156px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subheader: {
    color: "#fff",
    fontWeight: "400",
    fontSize: "80%",
  },
}));

export default function NavigationUserCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isDialogOpen, setDialogStatus] = React.useState(false);
  const [userName, setUserName] = React.useState('');
  const history = useHistory();

  React.useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem("LoginSessionKey")).access_token;
    const name = JSON.parse(atob(accessToken.split('.')[1])).fname + ' ' + JSON.parse(atob(accessToken.split('.')[1])).lname
    setUserName(name);
  }, [])

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSignOutClick = () => {
    setDialogStatus(true);
    renderSignOutDialog();
  };

  const closeDeleteDialog = () => {
    setDialogStatus(false);
  };
  /**
   * Render sign out dialog before use logging off from app.
   */
  const renderSignOutDialog = () => {
    return (
      <TwoButtonDialog
        content={"Are you sure you want to sign out?"}
        open={isDialogOpen}
        handleClose={closeDeleteDialog}
        btnText1="Yes"
        btnText2="No"
        handleActionBtn1={() => {
          localStorage.clear();
          history.push("/login");
          window.location.reload();
          closeDeleteDialog();
        }}
        handleActionBtn2={() => {
          closeDeleteDialog();
        }}
      />
    );
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        className={classes.header}
        onClick={handleExpandClick}
        classes={{
          title: classes.title,
          subheader: classes.subheader,
        }}
        avatar={
          <Avatar aria-label="name" className={classes.avatar}>
            <i
              className={classes.avatarIcon + " ag-icon ag-icon-user-circle"}
            ></i>
          </Avatar>
        }
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <i
              className={classes.expandArrow + " ag-icon ag-icon-chevron-down"}
            ></i>
          </IconButton>
        }
        title={userName}
      // subheader="Nutritionist"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContent}>
          {/* <IconButton className={classes.userButton}>
            <span className={classes.userButtonText}>Edit Profile</span>
            <i className="ag-icon ag-icon-user-circle"></i>
          </IconButton> */}
          <IconButton
            className={classes.userButton}
            onClick={handleSignOutClick}
          >
            <span className={classes.userButtonText}>Sign Out</span>
            <i className="ag-icon ag-icon-sign-out"></i>
          </IconButton>
          {renderSignOutDialog()}
        </CardContent>
      </Collapse>
    </Card>
  );
}
