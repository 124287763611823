import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
// External library import
import queryString from 'query-string';
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sendActionToAdobeAnalytics } from '../../common/analytics/adobeanalytics/adobeanalytics';
import { appConstants, ENVIRONMENT, SNACKBAR_TYPE } from '../../common/constants/constants';
import { getTokenId } from '../../common/services/authService';
import { checkadmin } from '../../common/services/herdAccess.service';
import NetworkService from '../../common/services/networkService';
//constant,service,utility import
import { getEnvironment } from '../../common/utils';

const ExternalLoginScreen = (props) => {
    const history = useHistory();
    const [authCode, setAuthCode] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState('');
    const { enqueueSnackbar } = useSnackbar();

    const EnvFlag = getEnvironment();
    let logoENV = '';
    if (EnvFlag === ENVIRONMENT.PROD) {
        logoENV = '';
    } else if (EnvFlag === ENVIRONMENT.QA) {
        logoENV = '-qa';
    } else if (EnvFlag === ENVIRONMENT.DEV) {
        logoENV = '-dev';
    } else {
        logoENV = '-local';
    }


    useEffect(() => {
        let code = getAuthCode(props);
        if (code) {
            setAuthCode(code);
            enqueueSnackbar('Logging in, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });

            getTokenId(code, "external").then(response => {
                NetworkService.setAccessTokenAuthHeader(response);
                localStorage.setItem(appConstants.LoginSessionKey, JSON.stringify(response));
                checkadmin().then((response) => {
                    let jwt = JSON.parse(atob((JSON.parse(localStorage.getItem("LoginSessionKey")).access_token).split('.')[1]));
                    if (["Administrator", "User"].includes(response)) {
                        sendActionToAdobeAnalytics("login", { "user": { "userId": jwt.userid, "userType": response } });
                        enqueueSnackbar('Login Successfully!!', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                        history.push('/dashboard/herds');
                    } else {
                        enqueueSnackbar('You are not an authorized user of this system.  Please request access.', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, persist: true, variant: SNACKBAR_TYPE.ERROR });
                        localStorage.clear();
                        setAuthCode(false);
                        localStorage.setItem("businessLinkId", jwt.userid);
                        history.push('/login');
                    }
                });
            }, error => {
                setErrorMsg('Error: ' + error.response.data.error + '\n Error Description: ' + error.response.data.error_description + '\n Status Code: ' + error.response.status);
            });
        }

    }, [history, props, enqueueSnackbar])


    /**
    * Method will return auth code coming from server.
    * @param {*} props 
    */
    function getAuthCode(props) {
        if (props) {
            let params = queryString.parse(props.location.search)
            return params.code
        }
    }

    return (
        <div className="login-container">
            <div className="login-form-container">
                <div className="login-form">
                    <i className={"ag-icon logo ag-icon-benchmark-logo" + logoENV}></i>
                    {errorMsg &&
                        <Alert className="alert-error" severity="warning">{errorMsg}</Alert>
                    }
                </div>
            </div>
        </div >
    )

}

export default ExternalLoginScreen;