import Grid from '@material-ui/core/Grid';
import React from "react";
import { breeding_tool, herdreplacement, pregnancydetection } from '../../../common/constants/constants';
import Dropdown from '../../ui_utilities/Dropdown';
import MultiSelectDropdown from '../../ui_utilities/MultiSelectDropdown';
import RadioButton from '../../ui_utilities/RadioButton';
import Textbox from '../../ui_utilities/Textbox';
const Reproductive_Management = (props) => {

    let benchmarkonly = <React.Fragment>
        <Grid item xs={12} className="radio-group">
            <RadioButton
                disableedit={props.disableedit}
                data={{
                    name: "reproductive_primary_breeding_method",
                    heading: "Primary Breeding Method *",
                    yes: "AI",
                    no: "Bull",
                    formdata: props.formdata,
                    setformdata: props.setformdata
                }} />
        </Grid>

        <Grid item xs={12}>
            <label className="form-label top-border">ADULT COWS BREEDING TOOLS</label>
        </Grid>

        <Grid item xs={12} md={6} id="reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program">
            <b>Primary Tool Used in Breeding Program *</b>
            <Dropdown
                disableedit={props.disableedit}
                data={{
                    className: "drop-down-root",
                    formdata: props.formdata,
                    setformdata: props.setformdata,
                    label: "Primary tool used in ADULT COW breeding program",
                    size: 12,
                    name: "reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program",
                    id: "reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program",
                    options: breeding_tool
                }} />
            {
                props.formerror.reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program ? (
                    <div className="required-style">{props.formerror.reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program}</div>
                ) : null
            }
        </Grid>

        {
            props.formdata["reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program"] === "other" ? <Grid item xs={12} md={6}>
                <label className="form-label small">Describe 'Other'</label>
                <Textbox
                    disableedit={props.disableedit}
                    data={{
                        className: "text-field-root",
                        name: "reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program_other",
                        id: "reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program_other",
                        type: "text",
                        label: "Describe 'Other'",
                        formdata: props.formdata,
                        setformdata: props.setformdata
                    }
                    } />
            </Grid> : null
        }

        <Grid item xs={12} md={6}>
            <label className="form-label small">Additional Tools Used in Breeding Program</label>
            <MultiSelectDropdown
                disableedit={props.disableedit}
                data={
                    {
                        labelid: "reproductive_breeding_adult_additional_tool-chip-label",
                        id: "reproductive_additional_tools_used_in_adult_cow_breeding_program",
                        inputid: "reproductive_breeding_adult_additional_tool",
                        labelname: "Additional Tool",
                        helpText: "Some important text",
                        options: breeding_tool,
                        className: "addherd_container_input",
                        formdata: props.formdata,
                        setformdata: props.setformdata
                    }
                } />
            <p className="helper-text">Select all that apply.</p>

        </Grid>

        <Grid item xs={12}>
            <label className="form-label top-border">VIRGIN HEIFERS BREEDING TOOLS</label>
        </Grid>


        <Grid item xs={12} md={6} >
            <label className="form-label small">Primary Tool Used in Breeding Program</label>
            <Dropdown
                disableedit={props.disableedit}
                data={{
                    className: "drop-down-root",
                    label: "Primary Tool Used In VIRGIN HEIFERS Breeding Program",
                    size: 4,
                    name: "reproductive_Primary_Tool_Used_In_Virgin_Heifer_Program",
                    id: "reproductive_Primary_Tool_Used_In_Virgin_Heifer_Program",
                    options: breeding_tool,
                    helpText: "testtest",
                    formdata: props.formdata,
                    setformdata: props.setformdata
                }} />
        </Grid>

        {
            props.formdata["reproductive_Primary_Tool_Used_In_Virgin_Heifer_Program"] === "other" ? <Grid item xs={12} md={6} >
                <label className="form-label small">Describe 'Other'</label>
                <Textbox
                    disableedit={props.disableedit}
                    data={{
                        className: "text-field-root",
                        name: "reproductive_Primary_Tool_Used_In_Virgin_Heifer_Program_other",
                        id: "reproductive_Primary_Tool_Used_In_Virgin_Heifer_Program_other",
                        type: "text",
                        label: "Describe 'Other'",
                        formdata: props.formdata,
                        setformdata: props.setformdata
                    }
                    } />
            </Grid> : null
        }

        <Grid item xs={12} md={6} >
            <label className="form-label small">Additional Tools Used in Breeding Program</label>
            <MultiSelectDropdown
                disableedit={props.disableedit}
                data={
                    {
                        labelid: "reproductive_breeding_virgin_heifer_additional_tool-chip-label",
                        id: "reproductive_additional_tools_used_in_virgin_heifer_program",
                        inputid: "reproductive_breeding_virgin_heifer_additional_tool",
                        labelname: "Additional Tool",
                        options: breeding_tool,
                        className: "addherd_container_input",
                        formdata: props.formdata,
                        setformdata: props.setformdata
                    }
                } />
            <p className="helper-text">Select all that apply</p>
        </Grid>
        <Grid item xs={12}></Grid>

        {
            props.formdata["reproductive_primary_breeding_method"] === "AI" ? <React.Fragment>

                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.disableedit}
                        data={{
                            name: "reproductive_record_of_sires",
                            heading: "Are attempts made to accurately record the identity of breeding sires?",
                            yes: "Yes",
                            no: "No",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }} />
                </Grid>

                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.disableedit}
                        data={{
                            name: "reproductive_record_breed_dates",
                            heading: "Are attempts made to accurately record all breeding dates?",
                            yes: "Yes",
                            no: "No",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }} />
                </Grid>

                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.disableedit}
                        data={{
                            name: "reproductive_cows_designated_do_not_breed",
                            heading: "Are cows designated 'DO NOT BREED' if a decision to stop breeding is made?",
                            yes: "Yes",
                            no: "No",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }} />
                </Grid>

                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.disableedit}
                        data={{
                            name: "reproductive_record_non_breed_heat_dates",
                            heading: "Are non-breeding heat dates recorded?",
                            yes: "Yes",
                            no: "No",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }} />
                </Grid>
            </React.Fragment> : null
        }

        <Grid item xs={12} sm={6}>
            <label className="form-label">Pregnancy</label>
            <Textbox
                disableedit={props.disableedit}
                data={{
                    className: "text-field-root",
                    name: "reproductive_pregnancy_exam_frequency",
                    id: "reproductive_pregnancy_exam_frequency",
                    type: "number",
                    label: "Pregnancy exam frequency (weeks)",
                    formdata: props.formdata,
                    setformdata: props.setformdata
                }
                } />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12} sm={6}>
            <Dropdown
                disableedit={props.disableedit}
                data={{
                    className: "drop-down-root",
                    formdata: props.formdata,
                    setformdata: props.setformdata,
                    label: "Primary method to determine pregnancy",
                    size: 4,
                    name: "reproductive_primary_method_used_to_determine_pregnancy",
                    id: "reproductive_primary_method_used_to_determine_pregnancy",
                    options: pregnancydetection
                }} />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>


        <Grid item xs={12} className="radio-group">
            <RadioButton
                disableedit={props.disableedit}
                data={{
                    name: "reproductive_calving_results_recorded_with_details",
                    heading: "Are calving results recorded with sex, single or multiple, live vs. dead, etc.?",
                    yes: "Yes",
                    no: "No",
                    formdata: props.formdata,
                    setformdata: props.setformdata
                }} />
        </Grid>

        {
            props.formdata["reproductive_calving_results_recorded_with_details"] === "Yes" ?
                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.disableedit}
                        data={{
                            name: "reproductive_calving_difficulty_scores_recorded",
                            heading: "Are calving difficulty scores (scale 1 - 5) recorded?",
                            yes: "Yes",
                            no: "No",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }} />
                </Grid> : null
        }

        <Grid item xs={12} sm={6}>
            <label className="form-label">Herd Replacements </label>
            <Dropdown
                disableedit={props.disableedit}
                data={{
                    className: "drop-down-root",
                    label: "How are MOST herd replacements obtained?",
                    size: 4,
                    name: "reproductive_herd_replacement_obtained_method",
                    id: "reproductive_herd_replacement_obtained_method",
                    formdata: props.formdata,
                    setformdata: props.setformdata,
                    options: herdreplacement
                }} />
        </Grid>

        <Grid item xs={12} className="radio-group">
            <RadioButton
                disableedit={props.disableedit}
                data={{
                    name: "reproductive_replacement_records_same_Software",
                    heading: "Are replacement records kept in the same software used for cows?",
                    yes: "Yes",
                    no: "No",
                    formdata: props.formdata,
                    setformdata: props.setformdata
                }} />
        </Grid>

        <Grid item xs={12} className="radio-group">
            <RadioButton
                disableedit={props.disableedit}
                data={{
                    name: "reproductive_reuse_lactating_cow_ids_within_1_year",
                    heading: "Are lactating cow IDs re-used within 1 year after cows leave the herd?",
                    yes: "Yes",
                    no: "No",
                    formdata: props.formdata,
                    setformdata: props.setformdata
                }} />
        </Grid>
    </React.Fragment>

    return (
        <section className="form-section">
            <header>
                <h3>Reproductive Management</h3>
            </header>
            <Grid container spacing={2}>
                <Grid item xs={12} md={10} lg={8}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            className: "text-field-root",
                            name: "reproductive_voluntary_waiting_period",
                            id: "reproductive_voluntary_waiting_period",
                            type: "number",
                            label: "What is the voluntary waiting period (days to AI or bull exposure)? *",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }}
                        onlyNumber={true}
                    />
                </Grid>
            </Grid>
            <header>
                    <h3>Calf Management</h3>
            </header>
            <Grid container spacing={2}>
                <Grid item xs={12} md={10} lg={8}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            className: "text-field-root",
                            name: "weaning_day",
                            id: "weaning_day",
                            type: "number",
                            label: "What is the weaning age (d)? *",
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }}
                        onlyNumber={true}
                    />
                </Grid>
            </Grid>
            <p> * Calculations using volunteer waiting period (VWP) and weaning age in days will take time to refresh if editing a previously saved value.  
                Please look up the VWP in the source system prior to submitting herd so that reports are accurate.</p>
        </section>
    );
};

export default Reproductive_Management;
