import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import React from "react";
import defaultuserpic from '../../assets/images/user.png';
import { accessoptions } from '../../common/constants/constants';
import Loader from '../../common/dialogs/loader/loader';
import { checkadmin, getherdaccess, getuserlists, saveherdaccess } from '../../common/services/herdAccess.service';
import { sortData, decodeApiResponse } from '../../common/utils/utility';
import { useLocation } from 'react-router-dom';

const HerdAccess = (props) => {

    const [userwithherdaccess, setuserwithherdaccess] = React.useState([]);
    const [availableusers, setavailableusers] = React.useState([]);
    const [userfilter, setuserfilter] = React.useState("");
    const [filteredavailableuser, setfilteredavailableuser] = React.useState([]);
    const [savedata, setsavedata] = React.useState(false);
    const [loaderstatus, setloaderstatus] = React.useState(true);
    const [herdiddata, setherdiddata] = React.useState(null);
    const [adminflag, setadminflag] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
        checkadmin().then((response) => {
            if (JSON.stringify(response) === '"Administrator"') {
                setadminflag(true);
            }
        }).catch((err) => {
            setadminflag(false);
        })
    }, [])

    React.useEffect(() => {
        let herd_id = props.herddata?.herd_id;
        if(typeof(herd_id) === "undefined") {
            const params = new URLSearchParams(location.search);
            herd_id = params.get("herd_id");
        }
        const getherdaccessdata = new Promise((resolve, reject) => {
            getherdaccess(herd_id).then((response) => {
                const decodedResponse = decodeApiResponse(response);
                resolve(decodedResponse);
            }).catch((error) => {
                if (error.response.data === "Herd pemissions not found, Please assign access permissions to the herd.") {
                    resolve("initial");
                } else {
                    reject(error);
                }
            })
        })

        const getuserlistsdata = new Promise((resolve, reject) => {
            getuserlists().then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            })
        })

        setloaderstatus(true);
        Promise.all([getherdaccessdata, getuserlistsdata]).then((values) => {
            let herdaccessdetails = [];

            if (values[0] !== "initial") {
                herdaccessdetails = values[0][0].AccessDetails;
                herdaccessdetails = herdaccessdetails.map((d) => {
                    return {
                        ...d,
                        name: d.givenName + " " + d.surname,
                        email: d.mail,
                        access: d.access,
                        assigned: true
                    }
                })
            }

            let userlists = values[1];

            if (values[0] !== "initial") {
                userlists = userlists.map((d) => {
                    return {
                        ...d,
                        name: d.givenName + " " + d.surname,
                        email: d.mail,
                        assigned: false
                    }
                })
                setherdiddata(values[0][0]);
            } else {
                userlists = userlists.map((d) => {
                    return {
                        ...d,
                        name: d.givenName + " " + d.surname,
                        email: d.mail,
                        assigned: false
                    }
                })
            }

            let assignedmail = herdaccessdetails.map((d) => d.email);

            userlists = userlists.filter((d) => {
                if (assignedmail.includes(d.email))
                    return false;
                return true;
            })

            sortData(herdaccessdetails, "name");
            sortData(userlists, "name");
            setuserwithherdaccess(herdaccessdetails);
            setavailableusers(userlists);
            setfilteredavailableuser(userlists);
            setuserfilter("");
            setloaderstatus(false);
        }).catch((error) => {
            setloaderstatus(false);
        });
    }, [savedata])

    const rendersection = (data, switchflag, index, type) => {
        let options = [...accessoptions];

        if (type === "AVAILABLE USERS") {
            options = options.filter((data) => {
                if (data.name === "Remove Access")
                    return false;
                return true;
            })
        }

        let imagesection = <div className="container_div">
            <img alt={data.name} src={defaultuserpic} className="container_div_image" />
            <div className="container_div_text">
                <b>{data.name}</b>{(data.jobTitle === undefined || data.jobTitle === "") ? "" : (", " + data.jobTitle)}{(data.department === undefined || data.department === "") ? "" : (", " + data.department)}<br />
                <span>{data.email}</span>
            </div>
        </div>
        let dropdownsection = <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">Permission</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Permission"
                disabled={!adminflag}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    getContentAnchorEl: null,
                    classes: { paper: 'dropdownStyle' }
                }}
                value={data.access === undefined ? "" : data.access}
                onChange={(event) => {
                    if (type === "USER") {
                        let temp = [...userwithherdaccess];
                        temp = temp.map((userdata) => {
                            if (data.email === userdata.email)
                                userdata.access = event.target.value
                            return userdata;
                        })
                        sortData(temp, "name");
                        setuserwithherdaccess(temp);
                    } else {
                        // Available Users
                        let temp = [...availableusers];
                        temp = temp.map((userdata) => {
                            if (data.email === userdata.email)
                                userdata.access = event.target.value
                            return userdata;
                        })
                        sortData(temp, "name");
                        setavailableusers(temp);
                    }

                }}
            >
                {
                    options.map((d, i) => (
                        <MenuItem value={d.value} key={i}>{d.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        let body = <React.Fragment>
            <Grid item xs={6}>
                {imagesection}
            </Grid>
            <Grid item xs={2}></Grid>

            <Grid item xs={4}>
                {dropdownsection}
            </Grid>
        </React.Fragment>

        if (switchflag) {
            body = <React.Fragment>
                <Grid item xs={1}>
                    <Switch onChange={((event) => {
                        let temp = [...availableusers];

                        temp = temp.map((userdata) => {
                            if (data.email === userdata.email)
                                userdata.assigned = event.target.checked
                            return userdata;
                        })
                        sortData(temp, "name");
                        setavailableusers(temp);
                    })}
                        checked={data.assigned} />
                </Grid>

                <Grid item xs={7}>
                    {imagesection}
                </Grid>
                <Grid item xs={4}>
                    {dropdownsection}
                </Grid>
            </React.Fragment>
        }

        return <Grid container style={{ border: "1px solid #00b1ad", padding: "1%", borderRadius: "5px", marginTop: "1%" }}>
            {body}
        </Grid>
    }

    const saveherdaccessassignment = () => {
        setloaderstatus(true);
        let accessdetails = [...userwithherdaccess];

        let transferaccessdetails = [];

        accessdetails = accessdetails.filter((data) => {
            if (data.access === "Remove Access") {
                return false;
            }
            return true;
        })
        availableusers.forEach((data) => {
            if (data.assigned) {
                transferaccessdetails.push(data);
            }
        })

        accessdetails = [...accessdetails, ...transferaccessdetails];



        let requestbody = herdiddata !== null ? {
            ...herdiddata,
            AccessDetails: accessdetails,
        } : {
                AccessDetails: accessdetails,
                HerdId: props.herddata.herd_id
            }
        saveherdaccess(requestbody).then((response) => {
            setsavedata(!savedata);
            setloaderstatus(false);
        }).catch((error) => {
            setloaderstatus(false);
        })
    }

    const searchuser = () => {
        if (userfilter !== "") {
            let temp = [];
            temp = availableusers.filter((data) => {
                if (data.name === userfilter)
                    return true;
                return false;
            })
            setfilteredavailableuser(temp);
        } else {
            setfilteredavailableuser(availableusers);
        }
    }

    let herdassignmentbody = <Container maxWidth={false} className="container-align-left">
        <h3 style={{ textAlign: "left", paddingTop: "1%" }}>Herd Access & Assignments</h3>

        <div style={{ textAlign: "left", margin: "1%" }}>
            <h5>Herd access can be assigned at 3 levels.</h5>
            <ul style={{ listStyleType: "disc", marginLeft: "4%" }}>
                <li><b>Read Only</b>: Role will allow user to generate Reports or edit Sub-Herd goals for these herds. User will be unable to edit any Herd Details or Upload Data.</li>
                <li><b>Contributor </b>: Role will allow users full editing capacity for Herd Details, Upload Data and Sub-Herd Goals. This user is the primary person adding data and will not receive email notifications.</li>
                <li><b>Owner</b>: Primary nutrition consultant for the Herd. Role will allow users full editing capacity for Herd Details, Upload Data and Sub-Herd Goals.</li>
            </ul>
        </div>

        <div className="content-container" style={{ textAlign: "left", paddingTop: "1%" }}>
            <h3>Users with Herd Access</h3>
            <div className="available_user_wrapper">
                {
                    userwithherdaccess.length > 0 ? userwithherdaccess.map((data, index) => {
                        return <React.Fragment key={index}>
                            {rendersection(data, false, index, "USER")}
                        </React.Fragment>
                    }) : "No User Assigned to the Herd"
                }
            </div>
        </div>
        {adminflag ? <React.Fragment>
            <div className="content-container" style={{ textAlign: "left", paddingTop: "3%" }}>
                <h3> Available Users</h3>

                <Grid container className="page-controls remove-negative-margins" spacing={2}>
                    <Grid item xs={10}>
                        <Autocomplete
                            id="available_user"
                            className="autocomplete-root"
                            options={availableusers}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Search By Keyword(s)" variant="outlined"
                            />}
                            onBlur={(event) => {
                                setuserfilter(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                        <Button
                            variant="contained"
                            className="button"
                            type="button"
                            onClick={() => {
                                searchuser();
                            }}>
                            Search
                                </Button>
                    </Grid>
                </Grid>

                <div className="available_user_wrapper">
                    {
                        filteredavailableuser.map((data, index) => {
                            return <React.Fragment key={index}>
                                {rendersection(data, true, index, "AVAILABLE USERS")}
                            </React.Fragment>
                        })
                    }
                </div>
            </div>

            <div style={{ paddingBottom: "5%", marginTop: "2%" }}>
                <Button variant="contained" className="button" onClick={saveherdaccessassignment}>
                    Save
                    </Button>
            </div>
        </React.Fragment> : null
        }
    </Container>


    return (<React.Fragment>
        {loaderstatus ? <Loader radius={50} type="large" /> : herdassignmentbody}
    </React.Fragment>);
};

export default HerdAccess;
