import React from 'react';
import NavigationDrawer from '../../components/navigationDrawer/navigationDrawer'
import DashboardMainRoute from './dashboardMain.route'
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';


import {
	checkadmin,
} from './../../store/actions';



const Dashboard = () => {
    const dispatch = useDispatch();

    const admincheckresponse= useSelector(
		(state) => state.admin.usertype
	);

    React.useEffect(()=>{
        dispatch(checkadmin());
    },[dispatch])

    let content = ""

    if(admincheckresponse!==""){
        content = <div style={{margin:"20% 10%"}}>  
                        <Alert variant="outlined" severity="info">
                            You are not an authorized user of this system.  Please request access.
                        </Alert>
                    </div>
    }

    if(admincheckresponse==="User" || admincheckresponse==="Administrator"){
        return (<div className="dashboard-wrapper">
        <NavigationDrawer/>
        <main className="content-wrapper">     
            <DashboardMainRoute/>
        </main>        
    </div>);
    }else{
        return (<div className="dashboard-wrapper">
        <NavigationDrawer/>
        <main className="content-wrapper">   
         {content}
        </main>        
    </div>);
    }
}

export default Dashboard;