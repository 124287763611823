import React from "react";
import { useHistory, useRouteMatch} from "react-router-dom";

// material import
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {useSelector } from 'react-redux';

import { getEnvironment } from '../../common/utils/index';

//Userdefined component import
import NavigationUserCard from "./navigationUserCard";
import TwoButtonDialog from "../../common/dialogs/two-button-dialog/two-button-dialog";

const drawerWidth = 255;
const iconHerd = "ag-icon-cow";
const iconAdd = "ag-icon-plus-circle";
const iconUpload = "ag-icon-folder-upload";
const iconCrosshair = "ag-icon-crosshairs";
const iconPieChart = "ag-icon-pie-chart";
const iconTarget= "ag-icon-bullseye";
const EnvFlag = getEnvironment();
    let logoENV = '';
    if (EnvFlag === 'PROD') {
        logoENV = '';
    } else if (EnvFlag === 'QA') {
        logoENV = '-qa';
    } else if (EnvFlag === 'DEV') {
        logoENV = '-dev';
    } else {
        logoENV = '-local';
    }

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    // background is also a color in _variables.scss
    background: "#102030",
    border: 0,
  },
}));

const NavigationDrawer = (props) => {

  const [navList,setnavList] = React.useState([]);
  const [openmodal,setopenmodal] = React.useState(false);
  const [menudata,setmenudata] =React.useState({})

  const admincheckresponse= useSelector(
		(state) => state.admin.usertype
	);

  React.useEffect(()=>{
    if (admincheckresponse==="User"){
      setnavList([
        { name: "Herds", icon: iconHerd },
        { name: "Add a Herd", icon: iconAdd },
        { name: "Upload Data", icon: iconUpload },
        { name: "User Goals", icon: iconCrosshair },
        { name: "Reporting", icon: iconPieChart}
      ])
    } else if(admincheckresponse==="Administrator"){
      setnavList([
        { name: "Herds", icon: iconHerd },
        { name: "Add a Herd", icon: iconAdd },
        { name: "Upload Data", icon: iconUpload },
        { name: "Default Goals", icon: iconCrosshair },
        { name: "User Goals", icon: iconTarget },
        { name: "Reporting", icon: iconPieChart}
      ])
    } else{
      setnavList([])
    }
  },[admincheckresponse])
  const history = useHistory();
  let { path } = useRouteMatch();
  const [selectedNavTab, setSelectNavTab] = React.useState({
    selectedTabName: "",
    selectedTabIndex: null,
  });

  /**
   * Selected nav tab.
   * @param {*} text List tab name
   * @param {*} index index number
   */
  const navigateToSelectedTab = (text, index) => {
    switch (text) {
      case "Herds":
        history.push(path + "/herds");
        break;

      case "Add a Herd":
        history.push(path + "/addHerd");
        break;

      case "Upload Data":
        history.push(path + "/uploadData");
        break;

      case "Default Goals":
        history.push(path + "/defaultGoals");
        break;

      case "User Goals":
        history.push(path + "/userGoals");
        break;

        case "Reporting":
          history.push(path + "/reporting");
          break;

      default:
        console.log("testing")
    }
  };

  const PrivacyPolicy = () => {
    let pp_url =  "https://www.landolakesinc.com/Privacy-Policy"
    return <div className="privacy-policy"><a href={pp_url} target="_blank" rel="noreferrer">Privacy &amp; Terms</a></div>
  }

  /**
   * Add list items in navigation bar.
   * @param {*} anchor
   */
  const list = (anchor) => {
    let styleclass="navigation-list-container"
    if(anchor === "top" || anchor === "bottom"){
      styleclass="navigation-list-container fullList";
    }

    return <div
            className={styleclass}
            role="presentation"
          >
            <div className="navigation-app-info">
              <i className={"ag-icon logo ag-icon-benchmark-logo" + logoENV}></i>
              <NavigationUserCard />
            </div>
            <List className="list">
              {navList.map((text, index) => (
                <div
                  key={index}
                  className={
                    selectedNavTab.selectedTabName.name === text.name
                      ? "nav-list-item active"
                      : "nav-list-item"
                  }
                >
                  <ListItem
                    button
                    key={text.name}
                    onClick={() => {
                      if(localStorage.getItem("save_edit")==="yes"){
                        setopenmodal(true);
                        setmenudata({
                          text: text,
                          index: index,
                        })
                      }else{
                        setSelectNavTab({
                          selectedTabName: text,
                          selectedTabIndex: index,
                        });
                        navigateToSelectedTab(text.name, index);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <i className={text.icon + " ag-icon"}></i>
                    </ListItemIcon>
                    <ListItemText primary={text.name} />
                  </ListItem>
                </div>
              ))}
            </List>
          </div>
  };

  const classes = useStyles();

  return (
    <nav className="navigation-drawer-container">
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            className={classes.drawer + " navigation-drawer"}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
          >
            {list(anchor)}
            <PrivacyPolicy />
          </Drawer>
        </React.Fragment>
      ))}
      {openmodal ? <TwoButtonDialog
          content={"Do you want to proceed without saving?"}
          open={openmodal}
          handleClose={()=>{setopenmodal(false)}}
          btnText1="Yes"
          btnText2="No"
          handleActionBtn1={() => {
              setopenmodal(false);
              localStorage.setItem("save_edit","no")
              setSelectNavTab({
                selectedTabName: menudata.text,
                selectedTabIndex: menudata.index,
              });
              navigateToSelectedTab(menudata.text.name,menudata.index);
          }}
          handleActionBtn2={() => {
              setopenmodal(false);
          }}
      /> : null}
    </nav>
  );
};

export default NavigationDrawer;


