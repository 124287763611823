// material import
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import React, { useEffect } from "react";
import { sendActionToAdobeAnalytics } from '../../common/analytics/adobeanalytics/adobeanalytics';
import { SNACKBAR_TYPE } from '../../common/constants/constants';
// Component import
import Loader from '../../common/dialogs/loader/loader';
import TwoButtonDialog from "../../common/dialogs/two-button-dialog/two-button-dialog";
// Api service import
import { getGoals, saveGoals } from '../../common/services/goals.service';
import { handleError, sortData, decodeApiResponse } from '../../common/utils/utility';
import ModalFlyout from '../ui_utilities/modalFlyout';
import CustomImport from "./Goalcomponents/CustomImport";
import GoalAccordion from './Goalcomponents/GoalAccordion';
import GoalHeader from "./Goalcomponents/GoalHeader";
import GoalSection from "./Goalcomponents/GoalSection";

const Goal = (props) => {

    const [loaderstatus, setloaderstatus] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);
    const [expandedPanel, setExpandedPanel] = React.useState('');
    const flags = ['None', 'Lower', 'Upper', 'Both'];
    const { enqueueSnackbar } = useSnackbar();

    // Goal Section States
    const [allcows, setallcows] = React.useState([]);
    const [lactation1, setlactation1] = React.useState([]);
    const [lactation2, setlactation2] = React.useState([]);
    const [lactation3, setlactation3] = React.useState([]);
    const [herdinventory, setherdinventory] = React.useState([]);
    const [calves, setcalves] = React.useState([]);
    const [health, sethealth] = React.useState([]);
    const [reproduction, setreproduction] = React.useState([]);
    const [culling, setculling] = React.useState([]);
    const [heiferenterprise, setheiferenterprise] = React.useState([]);
    const [componentAnalysis, setcomponentAnalysis] = React.useState([]);
    const [mastitis, setmastitis] = React.useState([]);


    const [breedwithgoal, setBreedwithgoal] = React.useState([]);


    const [breedSelected, setBreedSelected] = React.useState('Holstein');
    const [breedClicked, setBreedClicked] = React.useState('');
    const [breedCard, setBreedCard] = React.useState('Holstein');


    const [openmodal, setopenmodal] = React.useState(false);
    const [reloadData, setReloadData] = React.useState(true);


    const [clickEditColumn, setclickEditColumn] = React.useState(false);
    const [editable, setEditable] = React.useState(true);
    const [beforeEdit, setbeforeEdit] = React.useState([]);
    const [updateValue, setUpdateValue] = React.useState('');
    const [updateColumnDetails, setUpdateDetails] = React.useState({ id: '', label: '' });

    const [emptyValue, setemptyValue] = React.useState(false);
    const [open, setOpen] = React.useState(false);



    const [tankAverage, setTankAverage] = React.useState('');
    const [lactationpercent, setLactationpercent] = React.useState('');

    const [errorLactationPercent, setErrorLactationPercent] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [displayMore, setDisplayMore] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [openImportModal, setOpenImportModal] = React.useState(false);
    const [clickImport, setclickImport] = React.useState(false);
    const [importBreed, setImportBreed] = React.useState('');
    const [goalCard, setGoalCard] = React.useState('');
    const [predefinedBreed, setPredefinedBreed] = React.useState('Holstein');
    const [customimportbreed, setcustomimportbreed] = React.useState(['Default - Holstein', 'Default - Jersey', 'Default - Crossbred', 'Default - Other', 'Default - Multiple Breeds'])

    const [customgoalon, setcustomgoalon] = React.useState([]);

    const [showsnackbaralert, setshowsnackbaralert] = React.useState(false);

    const [servererror, setservererror] = React.useState(null);

    // State to support the custom goal for diffrent breeds
    const [usergoalsdata, setusergoalsdata] = React.useState([]);
    const [defaultgoaldata, setdefaultgoaldata] = React.useState([]);

    //state to show preview
    const [showpreview, setshowpreview] = React.useState(false);
    const [defaultgoalsarray, setdefaultgoalsarray] = React.useState([]);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    let buttonClass = "psudeo-input-item";
    let activebuttonClass = "psudeo-input-item active ";
    let breeds = ['Holstein', 'Jersey', 'Crossbred', 'Other', 'Multiple Breeds'];


    const updategoalsectionrow = (item) => {
        if (props.type === "subherdgoals") {
            setBreedSelected(item.breedName);
        }

        setTankAverage(item.tankAverage);
        setLactationpercent(item.lactationpercentage);
        setallcows(item["goalSection"]["All Cows"]);
        setlactation1(item["goalSection"]["Lactation 1"]);
        setlactation2(item["goalSection"]["Lactation 2"]);
        setlactation3(item["goalSection"]["Lactation 3+"]);
        setherdinventory(item["goalSection"]["Herd Inventory"]);
        setcalves(item["goalSection"]["Calves"]);
        sethealth(item["goalSection"]["Health"]);
        setreproduction(item["goalSection"]["Reproduction"]);
        setculling(item["goalSection"]["Culling"]);
        setheiferenterprise(item["goalSection"]["Heifer Enterprise"]);
        setcomponentAnalysis(item["goalSection"]["Component Analysis"]);
        setmastitis(item["goalSection"]["Milk Quality"]);
    }

    useEffect(() => {
        localStorage.setItem("save_edit", "no")
        let defaultbreedselected = "Holstein";
        if (props.type === "subherdgoals") {
            let subherdbreeds = props.subherddata.breeds
            if (subherdbreeds.length > 1) {
                defaultbreedselected = "Multiple Breeds";
            } else {
                if (subherdbreeds.length === 0) {
                    defaultbreedselected = "Other";
                } else {
                    defaultbreedselected = subherdbreeds[0];
                }
            }
        } else {
            setPredefinedBreed("Holstein");
        }

        const initializegoalsection = (item, localcustomimportbreed) => {

            setBreedSelected(item.breedName);
            setTankAverage(item.tankAverage);
            setLactationpercent(item.lactationpercentage);

            setallcows(item["goalSection"]["All Cows"]);
            setlactation1(item["goalSection"]["Lactation 1"]);
            setlactation2(item["goalSection"]["Lactation 2"]);
            setlactation3(item["goalSection"]["Lactation 3+"]);
            setherdinventory(item["goalSection"]["Herd Inventory"]);
            setcalves(item["goalSection"]["Calves"]);
            sethealth(item["goalSection"]["Health"]);
            setreproduction(item["goalSection"]["Reproduction"]);
            setculling(item["goalSection"]["Culling"]);
            setheiferenterprise(item["goalSection"]["Heifer Enterprise"]);
            setcomponentAnalysis(item["goalSection"]["Component Analysis"]);
            setmastitis(item["goalSection"]["Milk Quality"]);

            if (localcustomimportbreed !== null)
                setcustomimportbreed(localcustomimportbreed);

        }

        if (reloadData) {
            setReloadData(false);
            setloaderstatus(true);
            let getfuncref = null;
            if (props.type === "subherdgoals") {
                let herdId = props.herddata.herd_id;
                let subherdId = props.subherddata.sub_herd_id;
                getfuncref = getGoals(props.type, herdId, subherdId);
            } else {
                getfuncref = getGoals(props.type);
            }

            try {
                getfuncref.then((response) => {
                    let customgoal_ON_local = [];
                    response[0].Breeds.forEach((d) => {
                        if (d.custom_goal !== undefined && d.custom_goal === "yes") {
                            customgoal_ON_local.push(d.Breed)
                        }
                    })
                    let b = getBreed(response[0].Breeds);
                    let item;
                    b.forEach((d) => {
                        if (d.breedName === defaultbreedselected) {
                            item = d;
                        }
                    })

                    if (props.type === "subherdgoals") {
                        getGoals("usergoals").then((usergoalresponse) => {
                            const decodedResponse = decodeApiResponse(usergoalresponse);
                            let localcustomimportbreed = ['Default - Holstein', 'Default - Jersey', 'Default - Crossbred', 'Default - Other', 'Default - Multiple Breeds'];

                            decodedResponse[0].Breeds.forEach((breed) => {
                                if (breed.custom_goal !== undefined && breed.custom_goal === "yes") {
                                    localcustomimportbreed.push('User - ' + breed.Breed);
                                }
                            })

                            let goalcardlocal = "predefined";
                            setloaderstatus(false);
                            if (customgoal_ON_local.includes(defaultbreedselected)) {
                                goalcardlocal = "custom";
                                setusergoalsdata(getBreed(decodedResponse[0].Breeds));
                                setBreedwithgoal(b);
                                setErrorMessage('');
                                setGoalCard(goalcardlocal);
                                setcustomgoalon(customgoal_ON_local);
                                setBreedCard(defaultbreedselected);
                                setPredefinedBreed(defaultbreedselected);
                                initializegoalsection(item, localcustomimportbreed);
                            } else {
                                b = getBreed(decodedResponse[0].Breeds);
                                let item;
                                b.forEach((d) => {
                                    if (d.breedName === defaultbreedselected) {
                                        item = d;
                                    }
                                })
                                setusergoalsdata(b);
                                setBreedwithgoal(b);
                                setErrorMessage('');
                                setGoalCard(goalcardlocal);
                                setcustomgoalon(customgoal_ON_local);
                                setBreedCard(defaultbreedselected);
                                setPredefinedBreed(defaultbreedselected);
                                initializegoalsection(item, localcustomimportbreed);
                            }
                        }).catch(err => {
                            setloaderstatus(false);
                            setservererror("")
                        })
                    } else {
                        let goalcardlocal = "predefined";
                        if (props.type === "globalgoals") {
                            goalcardlocal = "custom";
                            setloaderstatus(false);
                            setBreedwithgoal(b);
                            setErrorMessage('');
                            setGoalCard(goalcardlocal);
                            setcustomgoalon(customgoal_ON_local)
                            setBreedCard(defaultbreedselected)
                            setPredefinedBreed(defaultbreedselected);
                            initializegoalsection(item, null);
                        } else {
                            getGoals("globalgoals").then((defaultgoal) => {
                                const decodedResponse = decodeApiResponse(defaultgoal);
                                let defaultgoaldatalocal = getBreed(decodedResponse[0].Breeds);
                                let item;
                                b.forEach((d) => {
                                    if (d.breedName === defaultbreedselected) {
                                        if (d.custom_goal === "yes")
                                            goalcardlocal = "custom";
                                        item = d;
                                    }
                                })
                                setGoalCard(goalcardlocal);
                                setusergoalsdata(b);
                                setdefaultgoaldata(defaultgoaldatalocal);
                                setloaderstatus(false);
                                setBreedwithgoal(b);
                                setErrorMessage('');
                                setcustomgoalon(customgoal_ON_local)
                                setBreedCard(defaultbreedselected)
                                setPredefinedBreed(defaultbreedselected);
                                initializegoalsection(item, null);

                            }).catch(err => {
                                setloaderstatus(false);
                                setservererror("")
                            })
                        }

                    }
                }).catch(error => {
                    setloaderstatus(false);
                    setservererror("")
                });
            } catch (error) {
                setloaderstatus(false);
                enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                if (error.response) {
                    setErrorMessage(error.response.data);
                    /* For internal server error response data is empty */
                    if (error.response.data === '') {
                        setErrorMessage(error.response.statusText);
                    }
                } else {
                    setErrorMessage(handleError(error));
                }
            }
        }

    }, [reloadData, enqueueSnackbar, props]);


    const importDefaultGoalsMethod = () => {
        let goaltype = "globalgoals";

        if (importBreed.split("-")[0].trim() === "User") {
            goaltype = "usergoals";
        }

        let breedtoimport = importBreed.split("-")[1];
        setloaderstatus(true);
        getGoals(goaltype).then((response) => {
            const decodedResponse = decodeApiResponse(response);
            const a = getBreed(decodedResponse[0].Breeds);
            localStorage.setItem("save_edit", "yes");
            setshowsnackbaralert(false);
            setshowsnackbaralert(true);
            setloaderstatus(false);
            a.forEach(item => {
                if (item.breedName === breedtoimport.trim()) {
                    updategoalsectionrow(item);
                }
            })
        }, error => {
            setloaderstatus(false);
            enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            if (error.response) {
                setErrorMessage(error.response.data);
                if (error.response.data === '') {
                    setErrorMessage(error.response.statusText);
                }
            }
            else {
                setErrorMessage(handleError(error));
            }
        });
    }


    const getbreedobjectusedinsaveherd = (temp) => {
        let breedObj = {};
        breedObj['Breed'] = temp.breedName;
        breedObj['custom_goal'] = temp.custom_goal === undefined ? "no" : temp.custom_goal;
        breedObj['TankAverage'] = temp.tankAverage;
        breedObj['LactationPercentage'] = parseInt(temp.lactationpercentage);
        let goalsArray = [];
        if (Object.keys(temp.goalSection).length) {
            const allGoalsNameArr = Object.keys(temp.goalSection);
            let length = allGoalsNameArr.length - 1;
            while (length >= 0) {
                (temp.goalSection[allGoalsNameArr[length]]).forEach(goalItemArr => {
                    goalsArray.push({
                        Attention_Flag: goalItemArr.Attention_Flag,
                        Critical_Threshold: goalItemArr.Critical_Threshold,
                        Goal_Order: goalItemArr.Goal_Order,
                        Goal_Section: goalItemArr.Goal_Section,
                        Goal_Value: goalItemArr.Goal_Value,
                        Initial_Threshold: goalItemArr.Initial_Threshold,
                        Name: goalItemArr.Name,
                        active: goalItemArr.active,
                        Header: goalItemArr.Header,
                        Id: goalItemArr.Id
                    })
                });
                length--;
            }
        }
        breedObj['Goals'] = goalsArray;
        return breedObj;
    }

    const savePredefinedMethod = () => {
        let goaltobesaved = null;
        if (props.type === "subherdgoals") {
            goaltobesaved = breedwithgoal.map((d) => {
                if (d.breedName === breedCard) {
                    let temp = {};
                    usergoalsdata.forEach((item) => {
                        if (item.breedName === breedCard) {
                            temp = {
                                ...d,
                                custom_goal: "no"
                            }
                        }
                    })
                    return temp;
                } else {
                    return d;
                }
            });
            saveAllBreeds(goaltobesaved)
        } else {
            goaltobesaved = breedwithgoal.map((d) => {
                if (d.breedName === breedCard) {
                    let temp = {};
                    defaultgoaldata.forEach((item) => {
                        if (item.breedName === breedCard) {
                            temp = {
                                ...d,
                                custom_goal: "no"
                            }
                        }
                    })
                    return temp;
                } else {
                    return d;
                }
            });
            saveAllBreeds(goaltobesaved)
        }
    }

    /**
     * Method will group the data based on GOAL_SECTION
     */
    function getBreed(breeds) {
        let breed = [];
        (breeds).forEach(breedItem => {
            const groupBreedObj = { 'goalSection': {} };
            groupBreedObj['breedName'] = breedItem.Breed;
            groupBreedObj['tankAverage'] = breedItem.TankAverage;
            groupBreedObj['lactationpercentage'] = breedItem.LactationPercentage;
            groupBreedObj['custom_goal'] = breedItem.custom_goal === undefined ? false : breedItem.custom_goal;

            if (breedItem.Goals.length) {
                let id = 0;
                (breedItem.Goals).forEach(goalsItem => {
                    goalsItem['id'] = id;
                    goalsItem['isEditable'] = false;
                    goalsItem['isDisabled'] = true;
                    const key = goalsItem.Goal_Section;
                    if (!groupBreedObj.goalSection.hasOwnProperty(goalsItem.Goal_Section)) {
                        groupBreedObj.goalSection[key] = [];
                        groupBreedObj.goalSection[key].push(goalsItem);
                    } else {
                        groupBreedObj.goalSection[key].push(goalsItem);
                    }

                    id++;
                });
            }
            breed.push(groupBreedObj);

        });
        sortData(breed, 'breedName');
        return breed;
    }

    /**
     * Handle select breed dropdown. 
     * @param {*} event 
     */
    const handleChangeBreed = (breed) => {
        if (!customgoalon.includes(breed) && props.type === "usergoals") {
            defaultgoaldata.forEach(item => {
                if (item.breedName === breed) {
                    setBreedSelected(item.breedName);
                    updategoalsectionrow(item);
                }
            })
        } else {
            breedwithgoal.forEach(item => {
                if (item.breedName === breed) {
                    setBreedSelected(item.breedName);
                    updategoalsectionrow(item);
                }
            })
        }
    };



    const goals = [{
        name: "All Cows",
        state: allcows,
        setstate: setallcows,
        expanded: "allcows"
    }, {
        name: "Lactation 1",
        state: lactation1,
        setstate: setlactation1,
        expanded: "lactation1"
    }, {
        name: "Lactation 2",
        state: lactation2,
        setstate: setlactation2,
        expanded: "lactation2"
    }, {
        name: "Lactation 3+",
        state: lactation3,
        setstate: setlactation3,
        expanded: "lactation3"
    }, {
        name: "Component Analysis",
        state: componentAnalysis,
        setstate: setcomponentAnalysis,
        expanded: "componentAnalysis"
    }, {
        name: "Reproduction",
        state: reproduction,
        setstate: setreproduction,
        expanded: "reproduction"
    }, {
        name: "Health",
        state: health,
        setstate: sethealth,
        expanded: "health"
    }, {
        name: "Milk Quality",
        state: mastitis,
        setstate: setmastitis,
        expanded: "mastitis"
    }, {
        name: "Herd Inventory",
        state: herdinventory,
        setstate: setherdinventory,
        expanded: "herdinventory"
    }, {
        name: "Calves",
        state: calves,
        setstate: setcalves,
        expanded: "calves"
    }, {
        name: "Heifer Enterprise",
        state: heiferenterprise,
        setstate: setheiferenterprise,
        expanded: "heiferenterprise"
    }, {
        name: "Culling",
        state: culling,
        setstate: setculling,
        expanded: "culling"
    }]



    function convertSelectedBreed() {
        let breedObj = {};
        let goalsArray = [];
        breedObj['Breed'] = breedCard;
        breedObj['TankAverage'] = tankAverage;
        breedObj['LactationPercentage'] = parseInt(lactationpercent);

        goals.forEach((goaldata) => {
            goaldata.state.forEach((y) => {
                goalsArray.push({
                    Attention_Flag: y.Attention_Flag,
                    Critical_Threshold: y.Critical_Threshold,
                    Goal_Order: y.Goal_Order,
                    Goal_Section: y.Goal_Section,
                    Goal_Value: y.Goal_Value,
                    Initial_Threshold: y.Initial_Threshold,
                    Name: y.Name,
                    active: y.active,
                    Header: y.Header,
                    Id: y.Id
                })
            })
        })
        breedObj['Goals'] = goalsArray;
        return breedObj;
    }

    /**
     * Save the goals.
     */
    const saveAllBreeds = (savedataparam) => {
        if (clickEditColumn) {
            alert("Please save the edited fields to proceed further");
        } else {
            setloaderstatus(true);
            const allBreedsObj = {};
            let saveArr = [];
            const allBreedsArray = [];
            let customgoalonlocal = {};
            let checkbreed = props.type === "subherdgoals" ? breedCard : breedSelected;

            if (Array.isArray(savedataparam)) {
                savedataparam.forEach((data) => {
                    saveArr.push(getbreedobjectusedinsaveherd(data));
                })
                savedataparam.forEach((b) => {
                    customgoalonlocal[b.breedName] = b.custom_goal === undefined ? "no" : b.custom_goal;
                    if (b.breedName === checkbreed) {
                        customgoalonlocal[checkbreed] = "no";
                    }
                })
            } else {
                saveArr.push(convertSelectedBreed());
                breedwithgoal.forEach((nonSelectedBreedItem, index) => {
                    if (nonSelectedBreedItem.breedName !== breedCard) {
                        saveArr.push(getbreedobjectusedinsaveherd(nonSelectedBreedItem));
                    }
                })
                breedwithgoal.forEach((b) => {
                    customgoalonlocal[b.breedName] = b.custom_goal === undefined ? "no" : b.custom_goal;
                    if (b.breedName === checkbreed) {
                        customgoalonlocal[checkbreed] = "yes";
                    }
                })
            }
            let goaltype = null, goalid = null;
            switch (props.type) {
                case "subherdgoals":
                    let herdId = props.herddata.herd_id;
                    let subherdId = props.subherddata.sub_herd_id;
                    allBreedsObj.Herd_ID = herdId;
                    allBreedsObj.Subherd_ID = subherdId;
                    goaltype = "Subherd";
                    goalid = "SUBHERDGOALS";
                    break;
                case "usergoals":
                    goaltype = "User";
                    goalid = "usergoals";
                    break;
                case "globalgoals":
                default:
                    goaltype = "Global";
                    goalid = "global";
            }
            allBreedsObj.Breeds = saveArr;
            allBreedsObj.GoalType = goaltype;
            allBreedsArray.push(allBreedsObj);


            allBreedsArray[0].Breeds = allBreedsArray[0].Breeds.map((breed) => {
                return {
                    ...breed,
                    custom_goal: customgoalonlocal[breed.Breed]
                }
            })

            enqueueSnackbar('Saving data, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
            saveGoals(goalid, allBreedsArray).then(response => {
                let body = {
                    goal: {
                        goalType: props.type,
                        herd: "",
                        subHerd: ""
                    }
                }

                if (props.type === "subherdgoals") {
                    body.goal.herd = props.herddata.dairy_name
                    body.goal.subHerd = props.subherddata.description
                }
                sendActionToAdobeAnalytics("goal", body)
                let getfuncref = getGoals(props.type);
                if (props.type === "subherdgoals") {
                    let herdId = props.herddata.herd_id;
                    let subherdId = props.subherddata.sub_herd_id;
                    getfuncref = getGoals(props.type, herdId, subherdId);
                }
                getfuncref.then((response) => {
                    const b = getBreed(response[0].Breeds);
                    let item = {};
                    b.forEach(localitem => {
                        if (breedCard === localitem.breedName) {
                            item = localitem
                        }
                    })

                    let customgoalonlocal = [];
                    response[0].Breeds.forEach((d) => {
                        if (d.custom_goal !== undefined && d.custom_goal === "yes") {
                            customgoalonlocal.push(d.Breed)
                        }
                    })
                    localStorage.setItem("save_edit", "no");
                    setshowsnackbaralert(false);
                    setBreedwithgoal(b);
                    setloaderstatus(false);
                    setcustomgoalon(customgoalonlocal);

                    setBreedSelected(item.breedName);
                    updategoalsectionrow(item);
                    setErrorMessage('');
                    enqueueSnackbar("Saved Successfully", { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                }, error => {
                    setloaderstatus(false);
                    enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                    if (error.response) {
                        setErrorMessage(error.response.data);
                        if (error.response.data === '') {
                            setErrorMessage(error.response.statusText);
                        }
                    }
                    else {
                        setErrorMessage(handleError(error));
                    }
                });
            }, error => {
                console.log("Error Occurred :: " + error)
                setloaderstatus(false);
                enqueueSnackbar(handleError(error), { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
            })
            return saveArr;
        }
    }



    const showpreviewfunction = () => {
        let item;
        defaultgoaldata.forEach((d) => {
            if (d.breedName === breedSelected) {
                item = d;
            }
        })
        let defaultgoalslocalarray = [{
            name: "All Cows",
            state: item["goalSection"]["All Cows"],
            setstate: setallcows,
            expanded: "allcows"
        }, {
            name: "Lactation 1",
            state: item["goalSection"]["Lactation 1"],
            setstate: setlactation1,
            expanded: "lactation1"
        }, {
            name: "Lactation 2",
            state: item["goalSection"]["Lactation 2"],
            setstate: setlactation2,
            expanded: "lactation2"
        }, {
            name: "Lactation 3+",
            state: item["goalSection"]["Lactation 3+"],
            setstate: setlactation3,
            expanded: "lactation3"
        }, {
            name: "Component Analysis",
            state: item["goalSection"]["Component Analysis"],
            setstate: setcomponentAnalysis,
            expanded: "componentAnalysis"
        }, {
            name: "Reproduction",
            state: item["goalSection"]["Reproduction"],
            setstate: setreproduction,
            expanded: "reproduction"
        }, {
            name: "Health",
            state: item["goalSection"]["Health"],
            setstate: sethealth,
            expanded: "health"
        }, {
            name: "Milk Quality",
            state: item["goalSection"]["Milk Quality"],
            setstate: setmastitis,
            expanded: "mastitis"
        }, {
            name: "Herd Inventory",
            state: item["goalSection"]["Herd Inventory"],
            setstate: setherdinventory,
            expanded: "herdinventory"
        }, {
            name: "Calves",
            state: item["goalSection"]["Calves"],
            setstate: setcalves,
            expanded: "calves"
        }, {
            name: "Heifer Enterprise",
            state: item["goalSection"]["Heifer Enterprise"],
            setstate: setheiferenterprise,
            expanded: "heiferenterprise"
        }, {
            name: "Culling",
            state: item["goalSection"]["Culling"],
            setstate: setculling,
            expanded: "culling"
        }]
        setshowpreview(true);
        setdefaultgoalsarray(defaultgoalslocalarray)
    }

    let goalbody = <React.Fragment>
        <Container maxWidth="xl" className="container-left-align">
            <GoalHeader
                setDisplayMore={setDisplayMore}
                displayMore={displayMore}
                errorMessage={errorMessage}
                type={props.type}
                goalCard={goalCard}
                setGoalCard={setGoalCard}
                clickImport={clickImport}
                breedCard={breedCard}
                setOpenImportModal={setOpenImportModal}
                tankAverage={tankAverage}
                setTankAverage={setTankAverage}
                setErrorLactationPercent={setErrorLactationPercent}
                setLactationpercent={setLactationpercent}
                errorLactationPercent={errorLactationPercent}
                lactationpercent={lactationpercent}
                breedSelected={breedSelected}
                activebuttonClass={activebuttonClass}
                buttonClass={buttonClass}
                setPredefinedBreed={setPredefinedBreed}
                predefinedBreed={predefinedBreed}
                setclickImport={setclickImport}
                breeds={breeds}
                savePredefinedMethod={savePredefinedMethod}
                clickEditColumn={clickEditColumn}
                setopenmodal={setopenmodal}
                setBreedClicked={setBreedClicked}
                setBreedCard={setBreedCard}
                breedwithgoal={breedwithgoal}
                handleChangeBreed={handleChangeBreed}
                customgoalon={customgoalon}
                saveAllBreeds={saveAllBreeds}
                showpreviewfunction={showpreviewfunction}
                setgoalname={props.type !== "subherdgoals" ? props.setgoalname : null}
            />

            {
                goalCard !== 'predefined' ?
                    <GoalSection
                        clickEditColumn={clickEditColumn}
                        setExpanded={setExpanded}
                        setExpandedPanel={setExpandedPanel}
                        open={open}
                        setAnchorEl={setAnchorEl}
                        setOpen={setOpen}
                        setUpdateDetails={setUpdateDetails}
                        setclickEditColumn={setclickEditColumn}
                        goals={goals}
                        expandedPanel={expandedPanel}
                        updateValue={updateValue}
                        setUpdateValue={setUpdateValue}
                        setemptyValue={setemptyValue}
                        flags={flags}
                        setEditable={setEditable}
                        setbeforeEdit={setbeforeEdit}
                        editable={editable}
                        type={props.type}
                        goalCard={goalCard}
                        breedSelected={breedSelected}
                        saveAllBreeds={saveAllBreeds}
                        updateColumnDetails={updateColumnDetails}
                        emptyValue={emptyValue}
                        anchorEl={anchorEl}
                        expanded={expanded}
                        beforeEdit={beforeEdit}
                        setshowsnackbaralert={setshowsnackbaralert}
                    /> : null

            }
        </Container>
        {localStorage.getItem("save_edit") === "yes" ? <TwoButtonDialog
            content={"Do you want to proceed without saving?"}
            open={openmodal}
            handleClose={() => { setopenmodal(false) }}
            btnText1="Yes"
            btnText2="No"
            handleActionBtn1={() => {
                setopenmodal(false);
                localStorage.setItem("save_edit", "no")
                setshowsnackbaralert(false);
                setBreedCard(breedClicked);
                props.setgoalname(breedClicked);
                handleChangeBreed(breedClicked);
                setErrorLactationPercent(false);
            }}
            handleActionBtn2={() => {
                setopenmodal(false);
            }}
        /> : null}
        <Snackbar open={showsnackbaralert} autoHideDuration={6000} onClose={() => {
            setshowsnackbaralert(false);
        }}>
            <Alert severity="warning">
                Heads up! You have unsaved changes.
                </Alert>
        </Snackbar>
        <ModalFlyout state={showpreview} setstate={setshowpreview} width="xl">
            <br /><br />
            <GoalAccordion
                clickEditColumn={clickEditColumn}
                setExpanded={setExpanded}
                setExpandedPanel={setExpandedPanel}
                open={open}
                setAnchorEl={setAnchorEl}
                setOpen={setOpen}
                setUpdateDetails={setUpdateDetails}
                setclickEditColumn={setclickEditColumn}
                goals={defaultgoalsarray}
                expandedPanel={expandedPanel}
                updateValue={updateValue}
                setUpdateValue={setUpdateValue}
                setemptyValue={setemptyValue}
                flags={flags}
                setEditable={setEditable}
                setbeforeEdit={setbeforeEdit}
                editable={editable}
                type={props.type}
                goalCard={goalCard}
                breedSelected={breedSelected}
                saveAllBreeds={saveAllBreeds}
                updateColumnDetails={updateColumnDetails}
                emptyValue={emptyValue}
                anchorEl={anchorEl}
                expanded={expanded}
                beforeEdit={beforeEdit}
                setshowsnackbaralert={setshowsnackbaralert}
            />
        </ModalFlyout>
    </React.Fragment>


    if (servererror !== null) {
        var t = props.type;
        goalbody = <div style={{ color: "red" }}>
            <span> Could not fetch the {(t.substr(0, t.length - 5) + " " + t.substr(-5)).toUpperCase()}</span>
        </div>
    }
    return (
        (loaderstatus || openImportModal) ?
            <React.Fragment>{
                loaderstatus ? <Loader radius={50} type="large" /> :
                    <React.Fragment>
                        <CustomImport
                            breedSelected={breedSelected}
                            openImportModal={openImportModal}
                            setOpenImportModal={setOpenImportModal}
                            setImportBreed={setImportBreed}
                            customimportbreed={customimportbreed}
                            importDefaultGoalsMethod={importDefaultGoalsMethod}
                            setclickImport={setclickImport}
                            importBreed={importBreed}
                        />
                    </React.Fragment>
            }
            </React.Fragment> :
            <React.Fragment>
                {goalbody}
            </React.Fragment>
    );
};

export default Goal;

