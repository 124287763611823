import React from "react";
// Userdefined component import
import Goal from "../../Goal/Goal";



const SubherdGoals = (props) => {
    return (
        <article>
            <Goal type="subherdgoals" herddata={props.herddata} subherddata={props.subherddata} />
        </article>
    )
};

export default SubherdGoals;

