import React from 'react';
import { Redirect,Route } from 'react-router-dom';
import { appConstants } from '../common/constants/constants';

const ProtectedRoute = (props) => {
    let isauthenticated = localStorage.getItem(appConstants.LoginSessionKey);

    if(isauthenticated){
        isauthenticated=JSON.parse(isauthenticated);
        let jwttoken = isauthenticated.access_token;
        const jwt = JSON.parse(atob(jwttoken.split('.')[1]));
        let expirationdatetime = jwt && jwt.exp ?jwt.exp * 1000 : null;
        let currentdatetimeinms=Date.now();

        if(currentdatetimeinms > expirationdatetime){
            return <Redirect to={{ pathname: '/login' }} />
        }else{
            return (<Route path={props.path} component={props.component} />)
        }
    }else{
        return <Redirect to={{ pathname: '/login' }} />
    }
}

export default ProtectedRoute;