import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from "react";


const RadioButton = (props) => {
    let smalltext = null;
    if (props.data.smalltext) {
        smalltext = <small>{props.data.smalltext}</small>
    }

    let disabled = false;

    if (props.disableedit) {
        disabled = props.disableedit;
    }

    const [value, setValue] = React.useState('female');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    let body = <>
        <b>{props.data.heading}</b><br />
        {smalltext}
        {
            props.data.handler ?
                <RadioGroup row aria-label="position" name={props.data.name} defaultValue="top">
                    <FormControlLabel control={
                        <Radio color="primary" value={props.data.yes} checked={props.data.formdata[props.data.name] === props.data.yes} onChange={(event) => {
                            props.data.handler(event);
                        }}
                        />
                    } label={props.data.yes} disabled={disabled} />

                    <FormControlLabel control={
                        <Radio color="primary" value={props.data.no} checked={props.data.formdata[props.data.name] === props.data.no} onChange={(event) => {
                            props.data.handler(event);

                        }} disabled={disabled} />
                    } label={props.data.no} />

                </RadioGroup> :
                <RadioGroup row aria-label="position" name={props.data.name} defaultValue="top">
                    <FormControlLabel
                        disabled={disabled}
                        control={
                            <Radio color="primary" value={props.data.yes} checked={props.data.formdata[props.data.name] === props.data.yes} onChange={(event) => {
                                let temp = { ...props.data.formdata };
                                temp[props.data.name] = event.target.value;
                                props.data.setformdata(temp);
                            }}
                            />
                        } label={props.data.yes} />

                    <FormControlLabel control={
                        <Radio color="primary" value={props.data.no} checked={props.data.formdata[props.data.name] === props.data.no} onChange={(event) => {
                            let temp = { ...props.data.formdata };
                            temp[props.data.name] = event.target.value;
                            props.data.setformdata(temp);
                        }} />
                    } label={props.data.no} disabled={disabled} />

                </RadioGroup>
        }
    </>

    if (props.type && props.type === "vertical") {
        body = <p>
            <br />
            <b>{props.data.heading}</b>
            <br />
            <FormControl component="fieldset">
                <RadioGroup aria-label="position" name={props.data.name} value={value}>
                    <FormControlLabel control={<Radio color="primary" value={props.data.no} checked={props.data.formdata[props.data.name] === false} onChange={(event) => {
                        let temp = { ...props.data.formdata };
                        temp[props.data.name] = false;
                        if (props.data.handler) {
                            props.data.handler(temp, false);
                        } else {
                            props.data.setformdata(temp);
                        }
                    }} />} label={props.data.no} disabled={disabled} />

                    <FormControlLabel control={<Radio color="primary" value={props.data.yes} checked={props.data.formdata[props.data.name] === true} onChange={(event) => {
                        let temp = { ...props.data.formdata };
                        temp[props.data.name] = true;
                        if (props.data.handler) {
                            props.data.handler(temp, true);
                        } else {
                            props.data.setformdata(temp);
                        }
                    }}
                    />} label={props.data.yes} disabled={disabled} />
                </RadioGroup>
            </FormControl>
        </p>
    }
    return (
        <React.Fragment>
            {body}
        </React.Fragment>
    );
};

export default RadioButton;

