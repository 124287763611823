import React from "react";

// material import
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import Fade from "@material-ui/core/Fade";
import Popper from '@material-ui/core/Popper';
import { goal_description, non_editable_component_analysis }  from '../../../common/constants/constants';



const GoalAccordion = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        table: {
            minWidth: 650,
        }
    }));

    const classes = useStyles();

    const applyEdit = () => {
        if (props.updateValue !== '') {
            props.goals.forEach((item) => {
                if (item.name === props.expandedPanel) {
                    item.state.forEach((data) => {
                        data[props.updateColumnDetails.id] = props.updateValue;
                    })
                }
            });
            props.setUpdateValue('');
            props.setOpen(false);
            props.setAnchorEl(null);
            localStorage.setItem("save_edit","yes");
            props.setshowsnackbaralert(true);
        }else {
            props.setemptyValue(true);
        }
    }

    const cancelEdit = () => {
        props.setemptyValue(false);
        props.setUpdateValue('');
        props.setOpen(false);
        props.setAnchorEl(null);
    }

    let columns = [
        { id: 'active', label: 'Visibility', minWidth: 100 },
        { id: 'Name', label: 'Goal Name', minWidth: 200 },
        { id: 'Goal_Value', label: 'Goal Value', minWidth: 200 },
        { id: 'Initial_Threshold', label: 'Initial Threshold', minWidth: 200 },
        { id: 'Critical_Threshold', label: 'Critical Threshold', minWidth: 200 },
        { id: 'Attention_Flag', label: 'Attention Flag', minWidth: 200 },
        { id: 'EditIcon', label: 'Edit', minWidth: 200, textAlign: 'center' }
    ]

    if(props.goalCard==="predefined"){
        columns = [
            { id: 'active', label: 'Visibility', minWidth: 100 },
            { id: 'Name', label: 'Goal Name', minWidth: 200 },
            { id: 'Goal_Value', label: 'Goal Value', minWidth: 200 },
            { id: 'Initial_Threshold', label: 'Initial Threshold', minWidth: 200 },
            { id: 'Critical_Threshold', label: 'Critical Threshold', minWidth: 200 },
            { id: 'Attention_Flag', label: 'Attention Flag', minWidth: 200 },
        ]
    }

    const handleChange = (panel, accordionName) => (event, isExpanded) => {
        if (props.clickEditColumn) {
            alert("Please save the edited data to proceed further");
        }
        else {
            props.setExpanded(isExpanded ? panel : false);
            props.setExpandedPanel(accordionName);
        }
    };

    const editColumValue = (column) => (event) => {
        if (column.label !== 'Edit') {
            if (!props.open) {
                props.setAnchorEl(event.currentTarget);
                props.setOpen(true);
                props.setUpdateDetails({ id: column.id, label: column.label });
            }
        }
        else {
            props.setclickEditColumn(true);
            props.goals.forEach((item) => {
                if (item.name === props.expandedPanel) {
                    let temp = [...item.state];
                    temp.forEach((data, index) => {
                        temp[index] = { ...data, isEditable: true }
                        item.setstate(temp);
                    })
                }
            });
        }
    };


    /**
     * 
     * @param {*} colId 
     * @param {*} value 
     * @param {*} rowId 
     */
    const getRow = (colId, value, row, index, state, setstate) => {
        let tooltip_copy = null;
        
        switch (colId) {
            case 'active':
                let activeflag=false,nonrequiredclass="";
                if(["All Cows","Lactation 1","Lactation 2","Lactation 3+"].includes(row.Goal_Section)){
                    activeflag=true;
                    nonrequiredclass="notallowed_cursor non_rquired_goal_switch"
                    row.active=true;
                }else{
                    switch(row.Goal_Section){
                        case "Component Analysis":
                            if(non_editable_component_analysis.includes(row.Header)){
                                activeflag=true;
                                nonrequiredclass="notallowed_cursor non_rquired_goal_switch"
                                row.active=true;
                            }
                            break;
                        case "Reproduction":
                            if(["Days_Open_Pregnant_Cows","Days_Open_Not_Pregnant_DIM_Greater_VWP", "Min_Projected_Calving_Interval"].includes(row.Header)){
                                activeflag=true;
                                nonrequiredclass="notallowed_cursor non_rquired_goal_switch"
                                row.active=true;
                            }
                            break;
                        case "Milk Quality":
                            if(["Percent_SCC_Less_50","Percent_SCC_50_to_200","Percent_SCC_200_to_400","Percent_SCC_400_to_800", "Percent_SCC_Greater_800"].includes(row.Header)){
                                activeflag=true;
                                nonrequiredclass="notallowed_cursor non_rquired_goal_switch"
                                row.active=true;
                            }
                            break;
                        case "Herd Inventory":
                            if([ "Percent_1st_Calf_Heifers","Percent_Heifers_Fresh"].includes(row.Header)){
                                activeflag=true;
                                nonrequiredclass="notallowed_cursor non_rquired_goal_switch"
                                row.active=true;
                            }
                            break;
                        case "Culling":
                            if([  "Annual_Cull_Rate","Annual_Death_Rate"].includes(row.Header)){
                                activeflag=true;
                                nonrequiredclass="notallowed_cursor non_rquired_goal_switch"
                                row.active=true;
                            }
                            break;
                        default: console.log();
                    }
                }
                return <div
                >
                    <Switch
                        className={nonrequiredclass}
                        checked={row.active}
                        disabled={props.goalCard==="predefined" || activeflag}
                        onChange={(event) => {
                            let temp = [...state];
                            temp[index][colId] = event.target.checked;
                            setstate(temp);
                            localStorage.setItem("save_edit","yes");
                            props.setshowsnackbaralert(true);
                        }}
                    />
                </div>
            case 'Name':
                let allowed_source_systems_str = ""
                if(["Lactation 2","Lactation 3+","Lactation 1","All Cows", "Reproduction", "Milk Quality", "Heifer Enterprise"].includes(row.Goal_Section)) {
                    let allowed_source_systems = []                    
                    // add source system
                    if (["Management_Milk_Level", "Summit_Milk", "First_Month_305_ME", "annual_cull_rate", "annual_death_rate", "Number_New_Clinicals_Fresh", 
                    "Percent_New_Clinicals_Fresh", "Number_New_Clinicals_All", "Percent_New_Clinicals_All", "Percent_New_Chronic_All"].includes(row.Header)) {
                        if(row.Header !== "Summit_Milk") {
                            allowed_source_systems.push("DC305");
                        }
                        allowed_source_systems.push("PCDART");
                    }
                    
                    if(["Milk_Week_2", "Milk_Week_4", "Milk_Week_8", "Milk_Week_15", "Milk_Week_21", "Milk_Week_25", "Milk_Week_32", "Milk_Week_37", 
                    "Percent_Pregnant_Vet_Check_Last_20_Days", "Percent_Herd_Diagnosed_Last_30_Days", "Percent_Eligible_Cows_Bred", 
                    "Percent_New_Pregnant_Days_Open_Greater_Than_180", "Percent_New_Pregnant_150_DIM", "Percent_New_Pregnant_115_DIM", 
                    "Recent_First_Service_Conception_Rate", "Recent_Services_per_Conception", "Recent_Conception_Rate", 
                    "Projected_Calving_Interval_Recent_Pregnant", "Percent_Herd_Coded_DNB", "Percent_Herd_Coded_DNB_This_Period"].includes(row.Header)) {
                        allowed_source_systems.push("DC305");
                        allowed_source_systems.push("DPLAN");
                    }
                    if (allowed_source_systems.length > 0) {
                        allowed_source_systems_str = "(" + allowed_source_systems.join(', ') + ")"
                    }                    
                }
                if (goal_description.hasOwnProperty(row.Goal_Section) && goal_description[row.Goal_Section].hasOwnProperty(row.Header)) {
                    tooltip_copy = goal_description[row.Goal_Section][row.Header];
                }
                if (row.Goal_Section === "Component Analysis") {      
                    if (non_editable_component_analysis.includes(row.Header)) 
                        return (<span style={{fontWeight:"bolder"}}>{value} <span className="subtext-teal">{allowed_source_systems_str}</span></span>);
                    else
                        return (<span>{value} <span className="subtext-teal">{allowed_source_systems_str}</span></span>);
                }
                if(["Lactation 2","Lactation 3+","Lactation 1","All Cows"].includes(row.Goal_Section)){                    
                    if (tooltip_copy !== null && tooltip_copy !== "") {
                        if(!["Percent_Fat","Percent_Protein","Average_DIM"].includes(row.Header)){
                            // return (<p style={{backgroundColor:"#008080",fontWeight:"bold",color:"white"}}>{value}</p>)
                            return (
                            <Tooltip title={<span style={{ whiteSpace: 'pre-line'}}>{tooltip_copy}</span>} arrow classes={{tooltip: "tooltip-goal", arrow: "tooltip-arrow"}}>
                                <span style={{fontWeight:"bolder"}}>{value} <span className="subtext-teal">{allowed_source_systems_str}</span></span>
                            </Tooltip>)
                        }                        
                    }
                    else {
                        return (<span style={{fontWeight:"bolder"}}>{value} <span className="subtext-teal">{allowed_source_systems_str}</span></span>)
                    }
                }
                if (tooltip_copy !== null && tooltip_copy !== "") {
                    return (
                        <Tooltip title={<span style={{ whiteSpace: 'pre-line'}}>{tooltip_copy}</span>} arrow classes={{tooltip: "tooltip-goal", arrow: "tooltip-arrow"}}>
                            <span>{value} <span className="subtext-teal">{allowed_source_systems_str}</span></span>
                        </Tooltip>);
                }
                return (<span>{value} <span className="subtext-teal">{allowed_source_systems_str}</span></span>);
            case 'Goal_Value':
                tooltip_copy = "This value is calculated from " + row.Goal_Section + " Test Day Milk, % Fat & % Protein"
                return (
                    <React.Fragment>
                        {!row.isEditable ? 
                            (["ECM"].includes(row.Header) ?
                            <Tooltip title={<span style={{ whiteSpace: 'pre-line'}}>{tooltip_copy}</span>} arrow classes={{tooltip: "tooltip-goal", arrow: "tooltip-arrow"}}>
                                <span>{value}</span>
                            </Tooltip>
                             : value)
                        : <Input
                            id={`"goal-value"-${index}`}
                            value={value}
                            className="text-field-root required-style"
                            onChange={(event) => {
                                let temp = [...state];
                                temp[index][colId] = event.target.value;
                                setstate(temp);
                            }}
                        />
                        }
                    </React.Fragment>
                );
            case 'Initial_Threshold':
                return (
                    <React.Fragment>
                        {!row.isEditable ? value + '%' :
                            <Input
                                id={`"initial-threshold-${index}"`}
                                value={value}
                                className="text-field-root required-style"
                                onChange={(event) => {
                                    let temp = [...state];
                                    temp[index][colId] = event.target.value;
                                    setstate(temp);
                                }}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                        }
                    </React.Fragment>
                );
            case 'Critical_Threshold':
                return (
                    <React.Fragment>
                        {!row.isEditable ? value + '%' :
                            <Input
                                id={`"critical-threshold-${index}"`}
                                value={value}
                                className="text-field-root required-style"
                                onChange={(event) => {
                                    let temp = [...state];
                                    temp[index][colId] = event.target.value;
                                    setstate(temp);
                                }}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                        }
                    </React.Fragment>
                );
            case 'Attention_Flag':
                return (
                    <React.Fragment>
                        {!row.isEditable ? value :
                            <TextField
                                id="default-flag"
                                select
                                label="Select"
                                className="text-field-root required-style"
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                value={row?.Attention_Flag}
                                onChange={
                                    (event) => {
                                        let temp = [...state];
                                        temp[index][colId] = event.target.value;
                                        setstate(temp);
                                    }
                                }>
                                {props.flags.map((option) => (
                                    <MenuItem key={option} value={option} >
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                        }
                    </React.Fragment>
                );
            case 'EditIcon':
                if (!props.clickEditColumn) {
                    return (
                        <React.Fragment>
                            {!row.isEditable ?
                                <Button
                                    variant="outlined"
                                    className="button grey edit-icon"
                                    onClick={
                                        (event) => {
                                            if (props.editable) {
                                                props.setEditable(false);
                                                let temp = [...state];
                                                temp[index] = { ...temp[index], isEditable: true }
                                                setstate(temp);
                                                props.setbeforeEdit(state);
                                            }
                                        }
                                    }><i className="ag-icon ag-icon-pencil-edit"></i></Button>
                                : <div className="icon-wrapper">
                                    <Button
                                        variant="outlined"
                                        className="button"
                                        type="button"
                                        size="small"
                                        onClick={
                                            (event, value) => {
                                                let temp = [...state];
                                                temp[index] = { ...temp[index], isEditable: false }
                                                setstate(temp);
                                                localStorage.setItem("save_edit","yes");
                                                props.setshowsnackbaralert(true);
                                                props.setEditable(true);
                                            }
                                        }>
                                        <i className="ag-icon ag-icon-check-mdi"></i>
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        className="button grey close"
                                        size="small"
                                        onClick={
                                            (event) => {
                                                props.setEditable(true);
                                                setstate(props.beforeEdit)
                                            }
                                        }><i className="ag-icon ag-icon-close"></i></Button>


                                </div>}
                        </React.Fragment >
                    )
                }
                break;
            default: return null;
        }
    }


    const gettable = (goaldata) => {
        let goalsArr = goaldata.state;
        return <Paper className={classes.root + " paper-root-table-wrapper"} elevation={0}>
            <TableContainer component={Paper} className="table-wrapper goals-table" elevation={0}>
                <Table stickyHeader aria-label="sticky table" className="table-root">
                    <TableHead className="table-thead">
                        <TableRow className="table-tr">
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    className="table-th"
                                >
                                    <h4>
                                        {column.label}
                                        {(column.label === 'Edit') ? <span>
                                            {props.clickEditColumn ? <div className="icon-wrapper">
                                                <Button
                                                    variant="outlined"
                                                    className="button"
                                                    type="button"
                                                    size="small"
                                                    onClick={() => {
                                                        props.setclickEditColumn(false);
                                                        localStorage.setItem("save_edit","yes");
                                                        props.setshowsnackbaralert(true);
                                                        props.goals.forEach((item) => {
                                                            if (item.name === props.expandedPanel) {
                                                                let temp = [...item.state];
                                                                temp.forEach((data, index) => {
                                                                    temp[index] = { ...data, isEditable: false }
                                                                    item.setstate(temp);
                                                                })
                                                            }
                                                        })
                                                    }
                                                    }>
                                                    <i className="ag-icon ag-icon-check-mdi"></i>
                                                </Button>
                                            </div>
                                                : <i className="edit-icon ag-icon ag-icon-pencil-edit" onClick={editColumValue(column)}></i>}

                                        </span> : null}

                                        {
                                        !props.clickEditColumn ? (
                                            (column.label === 'Visibility') || (column.label === 'Goal Name') || (column.label === 'Goal Value') || (column.label === 'Edit') ||(props.goalCard==="predefined")
                                            ) ? null : <i className="edit-icon ag-icon ag-icon-pencil-edit" onClick={editColumValue(column)}></i> : null
                                        }
                                    </h4>
                                </TableCell>
                            ))}

                        </TableRow>
                    </TableHead>
                    <TableBody className="table-tbody">
                        {
                            goalsArr.map((row, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={row.id} className="table-tr">
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} scope="row" className="table-td">
                                                    {getRow(column.id, value, row, index, goaldata.state, goaldata.setstate)}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    }
    const rendergoal = () => {
        let condition=props.breedSelected;

        if(props.type==="usergoals")
        condition=props.breedSelected

        if(props.type==="subherdgoals")
            condition=(props.goalCard === 'custom') && (props.breedSelected);

        if (condition) {
            return (
                <div className="goals-wrapper">
                    {
                        props.goals.map((goaldata, i) => {
                            return <Accordion
                                key={i}
                                expanded={props.expanded === goaldata.expanded}
                                onChange={handleChange(goaldata.expanded, goaldata.name)}
                                className="goals-accordion-wrapper"
                                elevation={0}
                                square={true}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={goaldata.expanded + "-content"}
                                    id={goaldata.expanded + "-header"}
                                    className="goals-accordion-summary"
                                >
                                    <i className="ag-icon ag-icon-dots-eight-vertical grab-dots"></i>
                                    <h3 className="goals-accordion-header">{goaldata.name} <span className="header-subtext-teal">{goaldata.name === "Health" ? "(DC305)" : null }</span></h3>
                                </AccordionSummary>
                                <AccordionDetails className="goals-accordion-details">
                                    {
                                        props.expanded === goaldata.expanded ? gettable(goaldata) : null
                                    }
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                </div>
            )
        }
    }

   return (
        <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {rendergoal()}
                        </Grid>
                    </Grid>
                    <Popper open={props.open} anchorEl={props.anchorEl} transition id="popper">
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <div className="popper-fields">Bulk update all values in this column</div>
                            {props.updateColumnDetails.id === 'Attention_Flag' ? <TextField
                                id="default-flag"
                                select
                                label="Select"
                                className="text-field-root required-style"
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                onChange={
                                    (event) => {
                                        props.setUpdateValue(event.target.value);
                                        props.setemptyValue(false);
                                    }
                                }>
                                {props.flags.map((option) => (
                                    <MenuItem key={option} value={option} >
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                                : <OutlinedInput
                                    id="critical-threshold"
                                    placeholder={props.updateColumnDetails.label}
                                    className="text-field-root required-style popper-fields"
                                    onChange={(event) => {
                                        props.setUpdateValue(event.target.value);
                                        props.setemptyValue(false);
                                    }}
                                    endAdornment={(props.updateColumnDetails.id === 'Initial_Threshold' || props.updateColumnDetails.id === 'Critical_Threshold') ? <InputAdornment position="end">%</InputAdornment> : null}
                                />}
                            {props.emptyValue ? <div className="error">Please enter a value</div> : null}
                            <Button
                                variant="outlined"
                                className="button popper-fields"
                                type="button"
                                size="small"
                                onClick={applyEdit}
                            >
                                APPLY UPDATE
                                </Button>
                            <Button
                                variant="outlined"
                                className="button popper-fields"
                                type="button"
                                size="small"
                                onClick={cancelEdit}
                            >
                                CANCEL
                                </Button>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </React.Fragment>
   )
}

export default GoalAccordion;

