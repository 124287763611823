import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from "react";
import { stateoptions } from '../../../common/constants/constants';
import Dropdown from '../../ui_utilities/Dropdown';
import Textbox from '../../ui_utilities/Textbox';

const Contact_and_Information = (props) => {
    const [mobilemasked, setmobilemasked] = useState(props.formdata["dairy_mobile_number"]);
    const [phonemasked, setphonemasked] = useState(props.formdata["dairy_phone_number"]);
    const [phonenumbercursor, setphonenumbercursor] = useState(null);
    const [mobilecursor, setmobilecursor] = useState(null);
    const [rendermasked, setrendermasked] = useState(false);

    const phonechangehandler = (event, maskedstate, setmaskedstate) => {
        if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(event.target.value[event.target.value.length - 1])) {
            let cursorposition = event.target.selectionStart;
            if (cursorposition - 1 === maskedstate.length) {
                if (maskedstate.length < 5) {
                    let temp = event.target.value;
                    temp = temp.replace(/\(/g, "").replace(/\)/g, "");
                    setmaskedstate("(" + temp + ")");
                } else if (maskedstate.length < 9) {

                    let temp = event.target.value;
                    let secondtemp = temp.substring(5);
                    secondtemp = secondtemp.replace(/-/g, "");
                    if (secondtemp !== "")
                        setmaskedstate(maskedstate.substring(0, 5) + "-" + secondtemp);
                } else if (maskedstate.length < 14) {
                    let temp = event.target.value;
                    let secondtemp = temp.substring(9);
                    secondtemp = secondtemp.replace(/-/g, "");
                    if (secondtemp !== "")
                        setmaskedstate(maskedstate.substring(0, 9) + "-" + secondtemp);
                } else {

                }
            } else {
                let temp = event.target.value;
                if (temp.includes("(")) {
                    temp = temp.replace(/\(/g, "").replace(/\)/g, "").replace(/-/g, "");
                }

                if (temp.length <= 10) {
                    if (temp.length <= 3) {
                        temp = "(" + temp + ")";
                    } else if (temp.length <= 6) {
                        temp = "(" + temp.substring(0, 3) + ")-" + temp.substring(3, 6);
                    } else {
                        temp = "(" + temp.substring(0, 3) + ")-" + temp.substring(3, 6) + "-" + temp.substring(6);
                    }
                    setmaskedstate(temp);
                }
            }
        }

    }

    const keydownchangehandler = (event, maskedstate, setmaskedstate) => {
        if (event.keyCode === 8) {
            let cursorposition = event.target.selectionStart;
            let localmaskedstate = maskedstate.length === undefined ? maskedstate.toString() : maskedstate;
            event.preventDefault();
            if (cursorposition === localmaskedstate.length) {
                let temp = localmaskedstate;
                if (localmaskedstate.length <= 5) {
                    temp = temp.replace(/\(/, "").replace(/\)/, "");
                    temp = temp.substring(0, temp.length - 1);
                    if (temp.length === 0) {
                        setmaskedstate("")
                    } else {
                        setmaskedstate("(" + temp + ")");
                    }
                } else if (localmaskedstate.length <= 9) {
                    temp = localmaskedstate.substring(0, 5);

                    let secondtemp = localmaskedstate.substring(5);
                    secondtemp = secondtemp.replace(/-/g, "");
                    secondtemp = secondtemp.substring(0, secondtemp.length - 1);
                    if (secondtemp.length === 0) {
                        setmaskedstate(temp)
                    } else {
                        event.preventDefault();
                        setmaskedstate(temp + "-" + secondtemp);
                    }
                } else if (localmaskedstate.length <= 14) {
                    temp = localmaskedstate.substring(0, 9);
                    let secondtemp = localmaskedstate.substring(9);
                    secondtemp = secondtemp.replace(/-/g, "");
                    secondtemp = secondtemp.substring(0, secondtemp.length - 1);
                    if (secondtemp.length === 0) {
                        setmaskedstate(temp)
                    } else {
                        event.preventDefault();
                        setmaskedstate(temp + "-" + secondtemp);
                    }

                }
                setphonenumbercursor(13);
                setmobilecursor(13);
            } else {
                event.preventDefault();
                let temp = localmaskedstate;
                temp = temp.split("");
                temp = temp.filter((data, index) => index !== (cursorposition - 1))
                temp = temp.join("");
                temp = temp.replace(/\(/g, "").replace(/\)/g, "").replace(/-/g, "");
                if (temp.length < 3) {
                    temp = "(" + temp + ")";
                } else if (temp.length < 6) {
                    temp = "(" + temp.substring(0, 3) + ")-" + temp.substring(3, 6);
                } else {
                    temp = "(" + temp.substring(0, 3) + ")-" + temp.substring(3, 6) + "-" + temp.substring(6);
                }
                setphonenumbercursor(event.target.selectionEnd - 1)
                setmobilecursor(event.target.selectionEnd - 1)
                setmaskedstate(temp);
            }
        }
    }

    return (
        <section className="form-section">
            <header>
                <h3>Contact Information & Location</h3>
            </header>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} id="dairy_name">
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root required-style",
                            name: "dairy_name",
                            id: "dairy_name",
                            type: "text",
                            label: "Name of Dairy *"
                        }
                        } />
                    {
                        props.formerror.dairy_name ? (
                            <div className="required-style">{props.formerror.dairy_name}</div>
                        ) : null
                    }
                </Grid>

                <Grid item xs={12} sm={6}></Grid>

                <Grid item xs={12} sm={6} id="dairy_owner_name">
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root required-style",
                            name: "dairy_owner_name",
                            id: "dairy_owner_name",
                            type: "text",
                            label: "Owner's Name *",

                        }
                        } />
                    {
                        props.formerror.dairy_owner_name ? (
                            <div className="required-style">{props.formerror.dairy_owner_name}</div>
                        ) : null
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root",
                            name: "dairy_manager_name",
                            id: "dairy_manager_name",
                            type: "text",
                            label: "Manager's Name",

                        }
                        } />
                </Grid>

                <Grid item xs={12} sm={6} id="dairy_email">
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root",
                            name: "dairy_email",
                            id: "dairy_email",
                            type: "email",
                            label: "Email",

                        }
                        } />
                    {/* {            
                props.formerror.dairy_email ? (
                            <div className="required-style">{props.formerror.dairy_email}</div>
                        ) : null
                } */}
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6} id="dairy_street_address">
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root",
                            name: "dairy_street_address",
                            id: "dairy_street_address",
                            type: "text",
                            label: "Street Address",

                        }
                        } />
                    {/* {            
                props.formerror.dairy_street_address ? (
                            <div className="required-style">{props.formerror.dairy_street_address}</div>
                        ) : null
                } */}
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root",
                            name: "dairy_street_address_2",
                            id: "dairy_street_address_2",
                            type: "text",
                            label: "Street Address 2",

                        }
                        } />
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6} id="dairy_city">
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root required-style",
                            name: "dairy_city",
                            id: "dairy_city",
                            type: "text",
                            label: "City *",

                        }
                        } />
                    {
                        props.formerror.dairy_city ? (
                            <div className="required-style">{props.formerror.dairy_city}</div>
                        ) : null
                    }
                </Grid>
                <Grid item xs={6} sm={3} id="dairy_state">
                    <Dropdown
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "drop-down-root required-style",
                            label: "State *",
                            size: 4,
                            name: "dairy_state",
                            id: "dairy_state",
                            options: stateoptions
                        }} />
                    <br />
                    {
                        props.formerror.dairy_state ? (
                            <div className="required-style">{props.formerror.dairy_state}</div>
                        ) : null
                    }
                </Grid>
                <Grid item xs={6} sm={3} id="dairy_zip_code">
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            formdata: props.formdata,
                            setformdata: props.setformdata,
                            className: "text-field-root required-style",
                            name: "dairy_zip_code",
                            id: "dairy_zip_code",
                            type: "number",
                            label: "Zip Code *",
                        }} 
                        onlyNumber={true}
                        forZipcode={true}
                    />
                    {
                        props.formerror.dairy_zip_code ? (
                            <div className="required-style">{props.formerror.dairy_zip_code}</div>
                        ) : null
                    }
                </Grid>
                <Grid item xs={6} sm={4} id="dairy_phone_number">
                    <TextField
                        disabled={props.disableedit}
                        className="text-field-root"
                        name="dairy_phone_number"
                        id="dairy_phone_number"
                        type="text"
                        label="Phone Number"
                        variant="outlined"
                        value={phonemasked}
                        onBlur={(event) => {
                            let temp = { ...props.formdata };
                            temp["dairy_phone_number"] = event.target.value;
                            props.setformdata(temp);
                        }}

                        onKeyUp={(event) => {
                            if (event.keyCode === 8) {
                                console.log("changed :: " + phonenumbercursor)
                                event.target.selectionStart = event.target.selectionEnd = phonenumbercursor;
                                setrendermasked(!rendermasked)
                            }
                        }}

                        onKeyDown={(event) => {
                            keydownchangehandler(event, phonemasked, setphonemasked);

                        }}
                        onChange={(event) => {
                            phonechangehandler(event, phonemasked, setphonemasked);
                        }}
                    />
                    {/* {
                        props.formerror.dairy_phone_number ? (
                            <div className="required-style">{props.formerror.dairy_phone_number}</div>
                        ) : null
                    } */}
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField
                        disabled={props.disableedit}
                        className="text-field-root"
                        name="dairy_mobile_number"
                        id="dairy_mobile_number"
                        type="text"
                        label="Mobile Number"
                        variant="outlined"
                        value={mobilemasked}
                        onBlur={(event) => {
                            let temp = { ...props.formdata };
                            temp["dairy_mobile_number"] = event.target.value;
                            props.setformdata(temp);
                        }}
                        onKeyDown={(event) => {
                            keydownchangehandler(event, mobilemasked, setmobilemasked);
                        }}

                        onKeyUp={(event) => {
                            if (event.keyCode === 8) {
                                event.target.selectionStart = event.target.selectionEnd = mobilecursor;
                                setrendermasked(!rendermasked)
                            }
                        }}

                        onChange={(event) => {
                            phonechangehandler(event, mobilemasked, setmobilemasked);
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <Textbox
                        disableedit={props.disableedit}
                        data={{
                            setformdata: props.setformdata,
                            formdata: props.formdata,
                            className: "text-field-root",
                            name: "dairy_fax_number",
                            id: "dairy_fax_number",
                            type: "number",
                            label: "Fax Number",

                        }
                        } />
                </Grid>
            </Grid>
        </section>
    );
};

export default Contact_and_Information;
