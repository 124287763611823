import axios from 'axios';
// import { authHeader } from './authheader';
// import { URLConfig } from '../common/urlstore.service';
// import NetworkService from './networkService';
// import { appConstants } from '../common/constants';
// import history from '../history';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    // 'Accept':'*/*',
    // 'Accept-Encoding':'gzip, deflate, br'
  }
});

const httpClientUpload = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': "multipart/form-data",
    'Access-Control-Allow-Origin': '*',
    // 'Accept':'*/*',
    // 'Accept-Encoding':'gzip, deflate, br'
  }
});


let authorizationHeader = '';

const onSuccess = (res) => Promise.resolve(res.data);
const onError = (response) => Promise.reject(response);

httpClient.interceptors.request.use((request) => {

  // const authData = useAuthHeader();
  // if (authData && authData.Authorization) {
  //   request.headers.Authorization = authData.Authorization;
  // }

  if (authorizationHeader && authorizationHeader.length) {
    request.headers.Authorization = authorizationHeader;
    if (request.options && request.options['withCredentials']) {
      request['withCredentials'] = true;
    }
  } else {
    request['withCredentials'] = true;
  }
  // console.log('request', request.options);
  if (request.options && request.options['iscontentSet']) {
    delete request.headers['Content-Type'];
  } else if (request.options) {
    request.headers['Content-Type'] = request.options && request.options['contentType'];
  }
  // console.log(`[API_INITIATED]: ${request.url} at ${new Date()}, ${new Date().getTime()}`);
  return request;
});

httpClientUpload.interceptors.request.use((request) => {
  if (authorizationHeader && authorizationHeader.length) {
    request.headers.Authorization = authorizationHeader;
    if (request.options && request.options['withCredentials']) {
      request['withCredentials'] = true;
    }
  } else {
    request['withCredentials'] = true;
  }

  if (request.options && request.options['iscontentSet']) {
    delete request.headers['Content-Type'];
  } else if (request.options) {
    request.headers['Content-Type'] = request.options && request.options['contentType'];
  }

  return request;
});

httpClient.interceptors.response.use((response) => {
  // console.log(`[API_RECEIVED]: ${response.config.url} at ${new Date()}, ${new Date().getTime()}`);
  return response;
}, async (error) => {
  // console.log('error is', error);
  const { config: originalReq, response } = error;
  // console.log('originalReq is', originalReq, response);
  // skip refresh token request, retry attempts to avoid infinite loops
  if (!originalReq.isRetryAttempt && response && response.status === 401) {
    throw error;
    // try {
    //   const newToken = await refreshToken();
    //   console.log('---new token--', newToken);
    //   originalReq.isRetryAttempt = true;
    //   originalReq.headers['Authorization'] = 'bearer ' + newToken.access_token;
    //   return await axios.request(originalReq);
    // } catch (e) { // in case refresh token throw error, redirect to login page;
    //   console.log('---e--', e);
    //   history.push('/login');
    //   throw e;
    // }
  } else {
    throw error;
  }
});

httpClientUpload.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // console.log('error is', error);
  const { config: originalReq, response } = error;
  // skip refresh token request, retry attempts to avoid infinite loops
  if (!originalReq.isRetryAttempt && response && response.status === 401) {
    throw error;
  } else {
    throw error;
  }
});

httpClient.interceptors.response.use(onSuccess, onError);
httpClientUpload.interceptors.response.use(onSuccess, onError);

// function refreshToken() {
//   const loginData = 'grant_type=refresh_token&client_id=r7_web';
//   return NetworkService.postData(URLConfig.getLoginUrl(), loginData, { contentType: 'application/x-www-form-urlencoded', 'withCredentials': 'true' })
//     .then((response) => {
//       console.log('response is', response);
//       NetworkService.setAuthHeader(response);
//       localStorage.setItem(appConstants.LoginSessionKey, JSON.stringify(response));
//       return response;
//     }, error => {
//       console.log('error in refresh token', error);
//     })
//     .catch(() => []);
// };

// export default httpClient;
export default {
  getData: (url, options, responseType, queryParams) =>
    httpClient({
      method: "GET",
      url: url,
      params: queryParams,
      responseType: responseType
    }),
  postData: (url, requestBody, options) =>
    httpClient({
      method: "POST",
      url: url,
      data: requestBody,
      options: options,
    }),
  postDataUpload: (url, requestBody, options) =>
    httpClientUpload({
      method: "POST",
      url: url,
      data: requestBody,
      options: options,
    }),
  searchData: (url, requestBody, options) =>
    httpClient({
      method: "SEARCH",
      url: url,
      data: requestBody,
    }),
  putData: (url, requestBody, options) =>
    httpClient({
      method: "PUT",
      url: url,
      data: requestBody,
      options: options
    }),

  /**
   * Method will set authorization header 
   * @param {*} requestToken 
   */
  setAccessTokenAuthHeader: (requestToken) => {
    if (requestToken && requestToken.access_token) {
      authorizationHeader = "Bearer " + requestToken.access_token;
    }
  },

  /**
   * Set id_token in request headers.
   * @param {*} requestToken 
   */
  setIdTokenAuthHeader: (requestToken) => {
    if (requestToken && requestToken.id_token) {
      authorizationHeader = "bearer " + requestToken.id_token;
    }
  },
  deleteData: (url, responseType, queryParams) =>
    httpClient({
      method: "DELETE",
      url: url,
      params: queryParams,
      responseType: responseType
    }),
};