import {
    Breadcrumbs,
    Container,
    Link,
    Button
} from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TwoButtonDialog from "../../common/dialogs/two-button-dialog/two-button-dialog";
import { getHerdDetailsFromHerdid } from '../../common/services/herd.service';
import { checkadmin } from '../../common/services/herdAccess.service';
import PageHeader from '../pageHeader/pageHeader';
import EditHerd from './EditHerd';
import HerdAccessAndAssignment from './HerdAccessAndAssignment';
import { decodeApiResponse } from '../../common/utils/utility';

const HerdAssignment = (props) => {
    const history = useHistory();

    const [activetab, setactivetab] = useState("herddetails");
    const [herdname, setherdname] = useState("");
    const [systemadmin, setsystemadmin] = useState(true);
    const [openmodal, setopenmodal] = useState(false);
    const [herddata, setherddata] = useState(null);
    const [subherddata, setsubherddata] = useState([]);
    const [showViewSubherdBtn, setShowViewWSubherdBtn] = useState(false)

    const params = new URLSearchParams(props.location.search);
    const current_herd_id = params.get("herd_id");

    useEffect(() => {
        getHerdDetailsFromHerdid(current_herd_id).then((data) => {
            const decodedResponse = decodeApiResponse(data)
            checkadmin().then((response) => {
                if (JSON.stringify(response) === '"Administrator"') {
                    setsystemadmin(true);
                }
                if (!(data?.AccessDetails?.access)) {
                    decodedResponse.AccessDetails = {
                        ...decodedResponse.AccessDetails?.AccessDetails
                    }
                }
                setherdname(decodedResponse.dairy_name);
                setherddata(decodedResponse)
                setsubherddata(decodedResponse.sub_herds)
            }).catch((err) => {
                history.push('/dashboard/herds');
            })
        }).catch((err) => {
            history.push('/dashboard/herds');
        })
    }, [])

    useEffect(() => {
        if(subherddata.length > 0) setShowViewWSubherdBtn(true)
        else setShowViewWSubherdBtn(false)
    }, [subherddata])

    let useraccess = herddata?.AccessDetails ? herddata.AccessDetails?.access : "owner";

    let showherdaccess = systemadmin || ["admin", "owner"].includes(useraccess);

    return (
        <article>
            <PageHeader h1={herdname} />
            <Container maxWidth={false} className="container-align-left breadcrumb-wrapper">
                <Breadcrumbs separator="›" aria-label="breadcrumb" className="breadcrumb-container">
                    <Link color="inherit" href="#" onClick={(event) => {
                        if (localStorage.getItem("save_edit") === "yes") {
                            localStorage.setItem("goto", "herd");
                            setopenmodal(true);
                        } else {
                            history.push("/dashboard/herds?t=p")
                        }
                        return;
                    }}>
                        Herds
                            </Link>
                    <Link color="inherit" href="#" onClick={(event) => {
                        setactivetab("herddetails");
                    }}>
                        {herdname}
                    </Link>
                </Breadcrumbs>
            </Container>
            {/* TODO: Convert Tabs to MUI Tabs */}

            <Container maxWidth={false} className="container-align-left page-tabs-wrapper">
                <div className="page-tabs">
                    <div className={activetab === "herddetails" ? "tab-item active" : "tab-item"}
                        onClick={() => {
                            setactivetab("herddetails");
                        }}
                    >
                        HERD DETAILS
                    </div>
                    {
                        showherdaccess ? <div className={activetab === "herdaccess" ? "tab-item active" : "tab-item"}
                            onClick={() => {
                                if (localStorage.getItem("save_edit") === "yes") {
                                    localStorage.setItem("goto", "herdaccess");
                                    setopenmodal(true);
                                } else {
                                    setactivetab("herdaccess");
                                }
                            }}>
                            HERD ACCESS
                               </div> : null
                    }

                    {showViewSubherdBtn ?
                        <div className="extra-action">
                            <Button
                                variant="contained"
                                className="button size-mini"
                                type="button"
                                size="small"
                                endIcon={<OpenInNewIcon />}
                                onClick={() => {
                                    history.push("/dashboard/herds/subherd/detail?herd_id=" + current_herd_id + "&subherd_id=" + subherddata[0].sub_herd_id);
                                }}
                            >
                                View a Sub-Herd
                            </Button>
                        </div> : null
                    }
                </div>
            </Container>

            {activetab === "herddetails" && herddata !== null ? <EditHerd herddata={herddata} setherddata={setherddata} /> : null}
            {activetab === "herdaccess" ? <HerdAccessAndAssignment herddata={herddata} /> : null}
            {openmodal ? <TwoButtonDialog
                content={"Do you want to proceed without saving?"}
                open={openmodal}
                handleClose={() => { setopenmodal(false) }}
                btnText1="Yes"
                btnText2="No"
                handleActionBtn1={() => {
                    localStorage.setItem("save_edit", "no");
                    setopenmodal(false);
                    if (localStorage.getItem("goto") === "herd") {
                        history.push("/dashboard/herds?t=p")
                    } else {
                        setactivetab("herdaccess");
                    }
                }}
                handleActionBtn2={() => {
                    setopenmodal(false);
                }}
            /> : null}
        </article>
    );
};

export default HerdAssignment;
