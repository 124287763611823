import React from "react";
import { 
  Grid, 
  FormControlLabel, 
  TextareaAutosize,
  Switch,
  Checkbox
} from '@material-ui/core';

const AddSubherd = (props) => {

  return <React.Fragment>
            <Grid item xs={12}>
                <h1 className="appbar-headline text-uppercase">Add A Sub Herd</h1>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    control={<Switch
                        onChange={() => {
                          props.setAddsubherd({ ...props.addsubherd, active: true });
                        }}
                        checked={props.activeflag}
                    ></Switch>}
                    label={props.activeflag ? 'This Sub-Herd is Active' : 'This Sub-Herd is Inactive'}
                />
            </Grid>


            <Grid item xs={12}>
                <TextareaAutosize minRows={2} aria-label="empty textarea" placeholder="Enter a Description" className="textarea"
                    value={props.addsubherd.description}
                    onChange={(event) => {
                        props.setAddsubherd({ ...props.addsubherd, description: event.target.value });
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    disabled={props.herddata.software_herd_software === "PCDART" || props.herddata.parlor_daily_milk_weights === false}
                    control={<Switch
                        checked={props.addsubherd.use_avg_daily_milk}
                        className="text-bold"
                        onChange={() => {
                          props.setAddsubherd({ ...props.addsubherd, use_avg_daily_milk: !props.addsubherd.use_avg_daily_milk })
                        }}
                    ></Switch>}
                    label="Use average daily milk for production calculations"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    className="text-bold"
                    style={{ marginBottom: "15px" }}
                    disabled={props.herddata.use_ecm_peer_avg === false}
                    control={<Switch
                        checked={props.addsubherd.use_ecm_peer_avg}
                        className="text-bold"
                        onChange={() => {
                            props.setAddsubherd({ ...props.addsubherd, use_ecm_peer_avg: !props.addsubherd.use_ecm_peer_avg })
                          }}
                        name="checkedECM"
                    ></Switch>}
                    label="Use ECM for peer average calculations"
                />
            </Grid>

            <Grid item xs={12}>
                <h3>Breeds</h3>
                <p>
                    Breed selection will determine both default sub-herd goals and peer group filters. You will be able to customize goals by breed or for each sub-herd. Herds with any combination of breeds including Holstein or Crossbred will be assigned to Holstein-based peer groups. Herds with only Jersey and/or Other Purebred will be assigned to Jersey-based peer groups
                </p>
            </Grid>
            {
                props.breeds.map((data, index) => {
                    return <Grid item xs={12} key={index}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        let tempbreed = props.addsubherd.breeds;
                                        if (tempbreed.includes(data.name)) {
                                            if (!event.target.checked) {
                                                tempbreed = tempbreed.filter((tempbreed) => {
                                                    return data.name !== tempbreed;
                                                })
                                            }
                                        } else {
                                            if (event.target.checked) {
                                                tempbreed = [...tempbreed, data.name]
                                            }
                                        }
                                        props.setAddsubherd({ ...props.addsubherd, breeds: tempbreed });
                                    }}
                                    name={data.name}
                                    color="primary"
                                    checked={props.addsubherd.breeds.includes(data.name)}
                                />
                            }
                            label={data.name === "Other" ? "Other Purebred" : data.name}
                        />
                    </Grid>
                })
            }
        </React.Fragment>
}

export default AddSubherd;