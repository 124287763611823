import React from "react";
import TextField from '@material-ui/core/TextField';
import { formdata } from "../../common/constants/constants";

const Textbox = (props) => {
    let disabled = false;
    let maxLength = props.forZipcode ? 5 : undefined;


    if (props.disableedit) {
        disabled = props.disableedit;
    }

    const handleVWPChange = (value) => {
        const sanitizedValue = value.replace(/[^0-9]+/g, "");
        const onlyNumbers = /^([1-9][0-9]*)?$|^0$/; // Regular expression to match numbers without leading zeros or consecutive zeroes
      
        if (onlyNumbers.test(sanitizedValue) || sanitizedValue === "") {
          let temp = { ...props.data.formdata };
            if (!props.forZipcode) 
                temp[props.data.name] = sanitizedValue;
            else {
                temp[props.data.name] = sanitizedValue.slice(0, maxLength); // enforce maximum length of 5 digits
            }  
            props.data.setformdata(temp);
        }
    };

    return (
        <React.Fragment>
            {
                props.data.formdata ?
                    <TextField
                        id={props.data.id}
                        label={props.data.label}
                        name={props.data.name}
                        type={props.data.type}
                        variant="outlined"
                        disabled={disabled}
                        onBlur={(event) => {
                            if (props.data.name === "reproductive_voluntary_waiting_period") {
                                let temp = { ...props.data.formdata };
                                if (event.target.value === "") {
                                    temp[props.data.name] = formdata.reproductive_voluntary_waiting_period;
                                    props.data.setformdata(temp);
                                }
                            }
                            if (props.data.name === "weaning_day") {
                                let temp = { ...props.data.formdata };
                                if (event.target.value === "") {
                                    temp[props.data.name] = formdata.weaning_day;
                                    props.data.setformdata(temp);
                                }
                            }
                        }
                        }
                        onChange={(event) => {
                            if(props.onlyNumber) {
                                handleVWPChange(event.target.value)
                            } else {
                                let temp = { ...props.data.formdata };
                                temp[props.data.name] = event.target.value;
                                props.data.setformdata(temp);
                            }
                        }}
                        value={props.data.formdata[props.data.name]}
                        className={props.data.className}
                    />
                    :
                    <TextField
                        id={props.data.id}
                        disabled={disabled}
                        label={props.data.label}
                        name={props.data.name}
                        type={props.data.type}
                        variant="outlined"
                        className={props.data.className}
                        onChange={(event) => {
                            if (props.onchangefunc) {
                                props.onchangefunc(event);
                            }
                        }}
                        onBlur={(event) => {
                            if (props.handler) {
                                props.handler(event);
                            }
                        }}
                    />
            }
        </React.Fragment>
    );
};

export default Textbox;

