import React from "react";
// import Grid from '@material-ui/core/Grid';

const PeerGroup = (props) => {

    return (
        <div style={{textAlign:"center",padding:"10% 0",fontWeight:"bolder"}}>
             Peer Group Coming Soon...
        </div>
    )};

export default PeerGroup;

