import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';

export async function getSnapshotGraphsUser(subHerdId, sourceType, testDay) {
    let response = await NetworkService.getData(ApiUrl.getSnapshotGraphsUrl(subHerdId, sourceType, testDay));
    return response;
}

export async function postSnapshotGraphsUrl(requestBody) {
    let response = await NetworkService.postData(ApiUrl.postSnapshotGraphsUrl(), requestBody);
    return response;
}
