import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from "react";
import { AUTH_CONFIG } from '../../common/configs/auth.config';
import { ENVIRONMENT, SSO_BASE_PATH } from '../../common/constants/constants';
import { getEnvironment } from '../../common/utils';

const Signinhelp = (props) => {
    const EnvFlag = getEnvironment();

    return (
        <section class="external-page-container">
            <section class="external-content-container">
                <div class="content">
                    <div class="logo-container">                    
                        <i class="logo"></i>
                    </div>
                    <h1>Sign In Help</h1>
                    <p>If you don't know your username and password, it is the same as your BusinessLink account.</p>
                   
                   
                    <div class="help-link-container">
                        <p>If you don't have an account, please register.</p>
                            <Button
                                variant="contained"
                                className="button"
                                type="button"

                                onClick={() => {
                                    let url = "https://landolakesinc.my.site.com/ems/EMSSelfAppRegistrationPage";

                                    if (EnvFlag !== ENVIRONMENT.PROD) {
                                        url = "https://landolakesinc--uat.sandbox.my.site.com/ems/EMSSelfAppRegistrationPage";
                                    }
                                    window.open(url, '_blank');
                                }}>
                                New User Registration
                            </Button>                  
                    </div>

                    <div class="help-link-container">
                        <p>Need to look up your BusinessLink User ID?</p>
                        <Button
                            variant="contained"
                            className="button"
                            type="button"

                            onClick={() => {
                                let url = "https://credentials.lolportals.com/UserAccess/ForgotUsername";
                                window.open(url, '_blank');
                            }}>
                            Forgot User ID
                        </Button>
                    </div>

                    <div class="help-link-container">
                        <p>Forgot Password?</p>
                        <Button
                            variant="contained"
                            className="button"
                            type="button"

                            onClick={() => {
                                let url = "https://credentials.lolportals.com/UserAccess/ForgotPassword";
                                window.open(url, '_blank');
                            }}>
                            Forgot Password
                        </Button>
                    </div>

                    <div class="help-link-container">
                        <p><a href onClick={() => {
                                let REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_LOCAL;
                                if (EnvFlag === ENVIRONMENT.PROD) {
                                    REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_PROD;
                                } else if (EnvFlag === ENVIRONMENT.QA) {
                                    REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_QA;
                                } else if (EnvFlag === ENVIRONMENT.DEV) {
                                    REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_DEV;
                                }
                                let client_id = AUTH_CONFIG.EXT_CLIENT_ID;
                                let url = SSO_BASE_PATH() + "/as/authorization.oauth2?response_type=code&client_id=" + client_id + "&redirect_uri=" +
                                    REDIRECT_URI + "&scope=openid%20profile%20email"
                                window.open(url, '_self');
                            }}><i class="ag-icon ag-icon-chevron-double-left"></i> Back to Sign In page.</a></p>
                        
                    </div>

                </div>
            </section>
            <section class="external-supportive-container">
                <div class="supportive-content">

                </div>
            </section>
        </section>

    )

}

export default Signinhelp;