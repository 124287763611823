import Button from '@material-ui/core/Button';
import Alert from '@mui/material/Alert';
import React from "react";
import { useHistory } from "react-router-dom";


const UploadSuccess = (props) => {
    const history = useHistory();
    let clear = null;

    function gotoReportingPage() {
        if (clear !== null) {
            clearInterval(clear);
        }
        props.gotoReportingPage();
    }

    function gotoUploadPage() {
        props.setState(false);
    }

    let body = <React.Fragment>
        <h3>Upload Successful</h3>
        <Button variant="contained"
            className="button"
            type="button"
            size="small" onClick={gotoUploadPage}>
            Upload More Data
                </Button>
        <Button variant="contained" className="button" type="button"
            size="small" onClick={gotoReportingPage}>
            View Reporting Page
                </Button>
    </React.Fragment>

    if (props?.selectedHerd?.software_herd_software === "DC305") {
        body = <React.Fragment>
            <div style={{ marginTop: "10%", textAlign: "left" }}>
                <Alert severity="success">
                    Your DC305 files have been saved and are being processed in the background .  Click to go to the
                    <span style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => {
                        // let subherd = props?.selectedHerd?.sub_herds.find(ele => ele.sub_herd_id === props.subHerdSelected.subHerdId);
                        history.push('/dashboard/herds/subherd/detail?herd_id=' + props?.selectedHerd.herdId + "&subherd_id=" + props.subHerdSelected.subHerdId + "&software=" + props.software);
                    }}> View Sub-herd Details </span>
                     to access the Recently Uploaded Data.  Once there, the Status of the file processing will be available.  Once the files are processed, the test date will be available for Reporting.
                    </Alert>
                <Button variant="contained"
                    className="button"
                    type="button"
                    size="small" onClick={gotoUploadPage}>
                    Upload More Data
                </Button>
            </div>
        </React.Fragment>
    }

    return (
        <div className="upload_success_container">
            {body}
        </div>
    );
};

export default UploadSuccess;

