import { ApiUrl } from '../configs/path.config';
import NetworkService from './networkService';

export async function saveHerd(reqBody) {
    let response = await NetworkService.postData(ApiUrl.saveHerdUrl(), reqBody);
    return response;
}

export async function saveHerdWithAssignment(reqBody) {
    let response = await NetworkService.postData(ApiUrl.saveHerdWithAssignmentUrl(), reqBody);
    return response;
}

export async function getHerdForUser(userrole) {
    let response = await NetworkService.getData(ApiUrl.getHerdsUrl(userrole));
    return response;
}

export async function getHerdDetailsFromHerdid(herdid) {
    let response = await NetworkService.getData(ApiUrl.getHerddetailsfromherdid(herdid));
    return response;
}

export async function getSubherdFromHerdId(herd_id) {
    let response = await NetworkService.getData(ApiUrl.getSubherdFromHerdIdUrl(herd_id));
    return response;
}

export async function getRecentlyUploadedfilesforsubherd(sub_herd_id) {
    let response = await NetworkService.getData(ApiUrl.getRecentlyUploadedfilesforsubherd(sub_herd_id));
    return response;
}

export async function deleteHerd(reqBody) {
    let response = await NetworkService.postData(ApiUrl.deleteHerd(), reqBody);
    return response;
}

export async function getdplanherduploadstatus(herdid) {
    let response = await NetworkService.getData(ApiUrl.getdplanherduploadstatus(herdid));
    return response;
}