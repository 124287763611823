import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { AUTH_CONFIG } from '../common/configs/auth.config';
import { ENVIRONMENT, SSO_BASE_PATH } from '../common/constants/constants';
import { getEnvironment } from '../common/utils';
import Dashboard from '../containers/dashboardMain/dashboard';
import ExternalLoginScreen from './login/ExternalLoginScreen';
import LoginScreen from './login/loginScreen';
import Signinhelp from './login/Signinhelp';
import ProtectedRoute from './ProtectedRoute';

const ENV = getEnvironment();
let REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_LOCAL;
if (ENV === ENVIRONMENT.PROD) {
  REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_PROD;
} else if (ENV === ENVIRONMENT.QA) {
  REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_QA;
} else if (ENV === ENVIRONMENT.DEV) {
  REDIRECT_URI = AUTH_CONFIG.EXT_REDIRECT_URI_DEV;
}

let client_id = AUTH_CONFIG.EXT_CLIENT_ID;
let url = SSO_BASE_PATH() + "/as/authorization.oauth2?response_type=code&client_id=" + client_id + "&redirect_uri=" + REDIRECT_URI + "&scope=openid%20profile%20email";

const AppRoute = (props) => {
  return (
    <Router>
      <Switch>
        <Route path='/login' component={() => {
          if (localStorage.getItem("businessLinkId") !== null) {
            let businessLinkId = localStorage.getItem("businessLinkId");
            url = url + "&businessLinkId=" + businessLinkId;
            localStorage.removeItem("businessLinkId");
          }
          window.location.href = url;
          return null;
        }} />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <Route path="/auth" component={LoginScreen} />
        <Route path="/ext/auth" component={ExternalLoginScreen} />
        <Route path="/sign-in-help" component={Signinhelp} />
        <Route path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="">
          <Redirect to="/login" />
        </Route>
        <Route render={() => <h1>404 Page not found</h1>} />
      </Switch>
    </Router>
  );
}

export default AppRoute;