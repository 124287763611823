import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React from "react";


const Dropdown = (props) => {
    let disabled = false;

    if (props.disableedit) {
        disabled = props.disableedit;
    }

    let body = props.data.formdata ? <Select
        native
        value={props.data.formdata[props.data.name]}
        onChange={(event) => {
            let temp = { ...props.data.formdata };
            temp[props.data.name] = event.target.value;
            props.data.setformdata(temp);
        }}
        label={props.data.label}
        inputProps={{
            name: props.data.name,
            id: props.data.id,
        }}
    >
        <option aria-label="none" value="" />
        {
            props.data.options.map((data, index) => (
                <option value={data.value} key={index}>{data.name}</option>
            ))
        }
    </Select> : <Select
        native
        label={props.data.label}
        value={props.value ? props.value : ""}
        inputProps={{
            name: props.data.name,
            id: props.data.id,
        }}
        onChange={(event) => {
            if (props.handler) {
                props.handler(event);
            }
        }}
    >
            <option aria-label="none" value="" />
            {
                props.data.options.map((data, index) => (
                    <option value={data.value} key={index}>{data.name}</option>
                ))
            }
        </Select>

    return (
        <FormControl variant="outlined" className={props.data.className} disabled={disabled}>
            <InputLabel htmlFor={props.data.id}>{props.data.label}</InputLabel>
            {body}
        </FormControl>
    );
};

export default Dropdown;

