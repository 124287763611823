import React from "react";

// material import
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import GoalAccordion from './GoalAccordion';


const GoalSection = (props) => {

    /**
     * Render print and save button at bottom of the screen.
     */
    const renderButtons = () => {
        let condition=props.breedSelected;

        if(props.type==="usergoals")
        condition=(props.goalCard !== 'predefined') && (props.breedSelected)

        if(props.type==="subherdgoals"){
            condition=props.goalCard === 'custom';
        }

        if (condition) {
            return <Button
                variant="contained"
                className="button"
                size="large"
                onClick={()=>props.saveAllBreeds(null)}>
                Save Changes
            </Button>
        }
    }

    let savebutton=<Grid container style={{marginBottom:"2%"}}>
                        <Grid item xs={12} className="button-submit-container-left">
                            {renderButtons()}
                        </Grid>
                    </Grid>
   return (
        <React.Fragment>
                    <section className="content-container">
                    {savebutton}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GoalAccordion
                                  clickEditColumn={props.clickEditColumn}
                                  setExpanded={props.setExpanded}
                                  setExpandedPanel={props.setExpandedPanel}
                                  open={props.open}
                                  setAnchorEl={props.setAnchorEl}
                                  setOpen={props.setOpen}
                                  setUpdateDetails={props.setUpdateDetails}
                                  setclickEditColumn={props.setclickEditColumn}
                                  goals={props.goals}
                                  expandedPanel={props.expandedPanel}
                                  updateValue={props.updateValue}
                                  setUpdateValue={props.setUpdateValue}
                                  setemptyValue={props.setemptyValue}
                                  flags={props.flags}
                                  setEditable={props.setEditable}
                                  setbeforeEdit={props.setbeforeEdit}
                                  editable={props.editable}
                                  type={props.type}
                                  goalCard={props.goalCard}
                                  breedSelected={props.breedSelected}
                                  saveAllBreeds={props.saveAllBreeds}
                                  updateColumnDetails={props.updateColumnDetails}
                                  emptyValue={props.emptyValue}
                                  anchorEl={props.anchorEl}
                                  expanded={props.expanded}
                                  beforeEdit={props.beforeEdit}
                                  setshowsnackbaralert={props.setshowsnackbaralert}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                    {savebutton}
                </section>
        </React.Fragment>
   )
}

export default GoalSection;

