import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import {
	adminReducer
} from './reducers';

import thunk from 'redux-thunk';

const rootReducer = combineReducers({
	admin: adminReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
);

export { store };
