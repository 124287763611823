import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import HerdDetailAccess from '../../components/HerdDetailAccess/HerdDetailAccess';
import Subherd from '../../components/herds/Subherd/Subherd';
import AddHerds from '../dashboardMain/addHerds/addHerds';
import DefaultGoals from '../dashboardMain/defaultGoals/defaultGoals';
import Herds from '../dashboardMain/herds/herds';
import Reporting from '../dashboardMain/reporting/reportMain';
import UploadData from '../dashboardMain/uploadData/uploadData';
import UserGoals from '../dashboardMain/userGoals/userGoal';

const DashboardMainRoute = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/herds/subherd/detail`} component={Subherd} />
            <Route path={`${path}/herds/detail`} component={HerdDetailAccess} />
            <Route path={`${path}/herds`} component={Herds} />
            <Route path={`${path}/addHerd`} component={AddHerds} />
            <Route path={`${path}/uploadData`} component={UploadData} />
            <Route path={`${path}/defaultGoals`} component={DefaultGoals} />
            <Route path={`${path}/userGoals`} component={UserGoals} />
            <Route path={`${path}/reporting`} component={Reporting} />

        </Switch>
    );
}


export default DashboardMainRoute;