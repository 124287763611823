import { 
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    TextareaAutosize
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { SNACKBAR_TYPE } from '../../common/constants/constants';
import Loader from '../../common/dialogs/loader/loader';
import TwoButtonDialog from "../../common/dialogs/two-button-dialog/two-button-dialog";
import { deleteHerd } from '../../common/services/herd.service';
import ContactAndInformation from './EnrollmentFormComponents/ContactAndInformation';
import DHIAEnrollment from './EnrollmentFormComponents/DHIAEnrollment';
import FacilitiesAndGrouping from './EnrollmentFormComponents/FacilitiesAndGrouping';
import ManagementSoftware from './EnrollmentFormComponents/ManagementSoftware';
import MilkingSystems from './EnrollmentFormComponents/MilkingSystems';
// import PeerGroup from './EnrollmentFormComponents/PeerGroup'
import ReproductiveManagement from './EnrollmentFormComponents/ReproductiveManagement';
import SupplementaryQuestions from './EnrollmentFormComponents/SupplementaryQuestions';

const EnrollmentForm = (props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    
    const [formdata, setformdata] = useState(props.formdata);
    const [initialformdata, setinitialformdata] = useState(props.formdata);
    const [initialselectBenchmark, setinitialselectBenchmark] = useState(formdata["benchmark_report"] ? formdata["benchmark_report"] : false);
    const [selectBenchmark, setselectBenchmark] = useState(false);
    const [formerror, setformerror] = useState({});
    const [openmodal, setopenmodal] = useState(false);
    const [submiterrorcontent, setsubmiterrorcontent] = useState("");
    const [topflag, settopflag] = useState(true);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isvwawarning, setisvwawarning] = useState(false);
    const [loaderstatus, setloaderstatus] = useState(false);
    const [comments, setComments] = useState(props.formdata.comments);
    
    let validationfields = [];

    useEffect(() => {
        localStorage.setItem("save_edit", "no");
        if (topflag && props.type !== "add") {
            window.scrollTo(0, 0);
        }
        settopflag(false);
        return () => {
            localStorage.setItem("save_edit", "no");
        }
    }, [topflag, props.type, history])

    const submitform = () => {
        let values = { ...formdata };
        values['comments'] = comments;
        
        values["supplemental_producer_works_with_purina_specialist"] = values["supplemental_producer_works_with_purina_specialist"] === "Yes" ? true : false;

        values["software_split_report"] = values["software_split_report"] === "Yes" ? true : false;

        values["dhia_daily_milk_weights_recorded"] = values["dhia_daily_milk_weights_recorded"] === "Yes" ? true : false;

        values["dhia_dhi_or_other_service_used"] = values["dhia_dhi_or_other_service_used"] === "Yes" ? true : false;

        values["milking_cows_fed_in_parlor"] = values["milking_cows_fed_in_parlor"] === "Yes" ? true : false;

        values["reproductive_record_of_sires"] = values["reproductive_record_of_sires"] === "Yes" ? true : false;

        values["reproductive_record_breed_dates"] = values["reproductive_record_breed_dates"] === "Yes" ? true : false;

        values["reproductive_cows_designated_do_not_breed"] = values["reproductive_cows_designated_do_not_breed"] === "Yes" ? true : false;

        values["reproductive_record_non_breed_heat_dates"] = values["reproductive_record_non_breed_heat_dates"] === "Yes" ? true : false;

        values["reproductive_calving_results_recorded_with_details"] = values["reproductive_calving_results_recorded_with_details"] === "Yes" ? true : false;

        values["reproductive_calving_difficulty_scores_recorded"] = values["reproductive_calving_difficulty_scores_recorded"] === "Yes" ? true : false;

        values["reproductive_replacement_records_same_Software"] = values["reproductive_replacement_records_same_Software"] === "Yes" ? true : false;

        values["reproductive_reuse_lactating_cow_ids_within_1_year"] = values["reproductive_reuse_lactating_cow_ids_within_1_year"] === "Yes" ? true : false;

        values["supplemental_producer_uses_full_anion_supplementation"] = values["supplemental_producer_uses_full_anion_supplementation"] === "Yes" ? true : false;

        values["supplemental_producer_uses_feed_additives"] = values["supplemental_producer_uses_feed_additives"] === "Yes" ? true : false;

        values["software_remote_access_code"] = formdata["software_herd_software"] === 'DRMS' ? formdata["software_remote_access_code"] : "";
        // call edit or add form
        props.addeditherd(values);
    }

    validationfields = ["dairy_name", "dairy_owner_name", "dairy_city", "dairy_state", "dairy_zip_code", "software_herd_code", "software_herd_software", "software_breeds"];
    if (formdata["software_herd_software"] === 'DRMS') 
        validationfields = ["dairy_name", "dairy_owner_name", "dairy_city", "dairy_state", "dairy_zip_code", "software_herd_code", "software_herd_software", "software_remote_access_code"];

    useEffect(() => {
        if (formdata["software_herd_software"] === 'DRMS') 
            validationfields = ["dairy_name", "dairy_owner_name", "dairy_city", "dairy_state", "dairy_zip_code", "software_herd_code", "software_herd_software", "software_remote_access_code"];
        else 
            validationfields = ["dairy_name", "dairy_owner_name", "dairy_city", "dairy_state", "dairy_zip_code", "software_herd_code", "software_herd_software", "software_breeds"];
    }, [formdata['software_herd_software']])

    const validateform = () => {
        let errors = {};
        validationfields.forEach((field) => {
            if (!formdata[field]) {
                errors[field] = 'Required';
            } else {
                if (field === "software_breeds" || field === "milking_facility_type") {
                    if (formdata[field].length === 0)
                        errors[field] = 'Required';
                }
                if (field === "dairy_zip_code") {
                    if (formdata[field].length < 5 || formdata[field].length > 5) 
                    errors[field] = 'Must have minimum and maximum of 5 digit zip code';
                }
            }
            {/* Commented it on 6/2/2022 : Since We are providing only short form options and reducing the required field */ }

            // if (field === "dairy_email") {
            //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.dairy_email)) {
            //         errors.dairy_email = 'Invalid email address';
            //     }
            // }

            // if (field === "dairy_zip_code") {
            //     if (formdata["dairy_zip_code"].toString().length !== 5) {
            //         errors.dairy_zip_code = "Zip Code must be of 5 digit."
            //     }
            // }
            // if (field === "dairy_phone_number") {
            //     if (formdata["dairy_phone_number"].length !== 14) {
            //         errors.dairy_phone_number = "Please Provide a 10 digit Phone Number."
            //     }
            // }
        })

        return errors;
    }

    const handlesubmit = (event) => {
        event.preventDefault();
        let errors = validateform();

        const getanchor = (id, name) => {
            id = "#" + id;

            return <a href={id} onClick={() => {
                setopenmodal(false);
            }}>{name}</a>
        }

        if (!(Object.keys(errors).length === 0 && errors.constructor === Object)) {
            let errorcontent = <React.Fragment>The following required field(s) are not complete. You can click the item(s) below to navigate to the missing field(s).<br />
                {
                    errors.dairy_name ? <React.Fragment>{getanchor("dairy_name", "Name of Dairy")} <br /></React.Fragment> : ""
                }
                {
                    errors.dairy_owner_name ? <React.Fragment>{getanchor("dairy_owner_name", "Owner's Name")} <br /></React.Fragment> : ""
                }
                {
                    errors.dairy_street_address ? <React.Fragment>{getanchor("dairy_street_address", "Street Address")} <br /></React.Fragment> : ""
                }
                {
                    errors.dairy_city ? <React.Fragment>{getanchor("dairy_city", "City")} <br /></React.Fragment> : ""
                }
                {
                    errors.dairy_state ? <React.Fragment>{getanchor("dairy_state", "State")} <br /></React.Fragment> : ""
                }
                {
                    errors.dairy_zip_code ? <React.Fragment>{getanchor("dairy_zip_code", "Zip Code")} <br /></React.Fragment> : ""
                }
                {
                    errors.software_herd_code ? <React.Fragment>{getanchor("software_herd_code", "Herdcode")} <br /></React.Fragment> : ""
                }
                {
                    errors.dairy_phone_number ? <React.Fragment>{getanchor("dairy_phone_number", "Phone Number")} <br /></React.Fragment> : ""
                }
                {
                    errors.dairy_email ? <React.Fragment>{getanchor("dairy_email", "Email")} <br /></React.Fragment> : ""
                }
                {
                    errors.reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program ? <React.Fragment>{getanchor("reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program", "Primary Tool Used in Breeding Program")} <br /></React.Fragment> : ""
                }
                {
                    errors.milking_facility_type ? <React.Fragment>{getanchor("milking_facility_type", "What type of facility is used?")} <br /></React.Fragment> : ""
                }
                {
                    errors.milking_milking_frequency_per_day ? <React.Fragment>{getanchor("milking_milking_frequency_per_day", "Milking Frequency (per day)")} <br /></React.Fragment> : ""
                }
                {
                    errors.software_breeds && formdata["software_herd_software"] !== "DRMS" ? <React.Fragment>{getanchor("software_breeds", "Herd Breed(s)")} <br /></React.Fragment> : ""
                }
                {
                    errors.software_remote_access_code && formdata["software_herd_software"] === "DRMS" ? <React.Fragment>{getanchor("software_remote_access_code", "RAC")} <br /></React.Fragment> : ""
                }
            </React.Fragment>
            setformerror(errors);
            setopenmodal(true);
            setsubmiterrorcontent(errorcontent)
        } else {
            if (props.type === "add") {
                localStorage.setItem("save_edit", "no");
                setformerror({});
                submitform();
            } else {
                let formvwa = formdata.reproductive_voluntary_waiting_period;
                let herdvwa = props.herddata.reproductive_voluntary_waiting_period;
                let form_weaningday = formdata.weaning_day;
                let herd_weaningday = props.herddata.weaning_day;
                if ((parseInt(formvwa) !== parseInt(herdvwa)) || (parseInt(form_weaningday) !== parseInt(herd_weaningday))) {
                    setisvwawarning(true);
                } else {
                    localStorage.setItem("save_edit", "no");
                    setformerror({});
                    submitform();
                }
            }
        }
    }

    {/* Commented it on 6/2/2022 : Since We are providing only short form options and reducing the required field */ }

    // if (selectBenchmark) {
    //     validationfields = ["dairy_name", "dairy_owner_name", "dairy_street_address", "dairy_city", "dairy_state", "dairy_zip_code", "dairy_phone_number", "dairy_email", "software_herd_software", "reproductive_primary_breeding_method", "reproductive_Primary_Tool_Used_In_Adult_Cow_Breeding_Program", "milking_facility_type", "milking_milking_frequency_per_day", "software_breeds"];
    // } else {
    //     validationfields = ["dairy_name", "dairy_owner_name", "dairy_street_address", "dairy_city", "dairy_state", "dairy_zip_code", "dairy_phone_number", "dairy_email", "software_herd_software", "software_breeds"]
    // }
    
    const admincheckresponse = useSelector(
        (state) => state.admin.usertype
    );

    let adminflag = false;
    if (admincheckresponse === "Administrator") {
        adminflag = true;
    }

    let accesspermission = props.herddata ? ["owner", "admin", "contributor"].includes(props.herddata.AccessDetails?.access) : true;

    accesspermission = accesspermission || adminflag || props.type === "add";

    let disableedit = false;
    let submitButtonCopy = "Submit Herd";

    if (props.herds) {
        disableedit = !accesspermission;
        submitButtonCopy = "Save Herd";
    }

    if (!disableedit && localStorage.getItem("save_edit") === "no") {
        let changeflag = false;
        for (let k in initialformdata) {
            if (typeof (initialformdata[k]) !== "object") {
                if (initialformdata[k] !== formdata[k]) {
                    // changes to the form has been made
                    localStorage.setItem("save_edit", "yes");
                    changeflag = true;
                    break;
                }
            } else {
                if (Array.isArray(initialformdata[k])) {
                    if (["reproductive_additional_tools_used_in_adult_cow_breeding_program", "reproductive_additional_tools_used_in_virgin_heifer_program", "milking_facility_type", "software_breeds", "supplemental_programs"].includes(k)) {
                        //check if content of array are same or not
                        let t = [...initialformdata[k]];
                        let t1 = [...formdata[k]];
                        if (t.sort().toString() != t1.sort().toString()) {
                            // Changes has been made
                            localStorage.setItem("save_edit", "yes");
                            changeflag = true;
                            break;
                        }
                    } else {
                        // check if sub_herds array data has been deleted or not
                        if (initialformdata[k].length !== formdata[k].length) {
                            // Changes has been made
                            localStorage.setItem("save_edit", "yes");
                            changeflag = true;
                            break;
                        }
                    }
                } else {
                    if (["facilties_and_grouping_close_up_dry_form", "facilties_and_grouping_far_off_dry_form", "facilties_and_grouping_lactating_rations_form", "facilties_and_grouping_post_fresh_lactating_cows_form", "facilties_and_grouping_single_group_for_dry_cows_form"].includes(k)) {
                        let key = k.replace("_form", "");
                        // check inside form data ,only if Adult cow switch is on and both are equal
                        if (initialformdata[key] == formdata[key] && formdata[key]) {
                            for (let kk in initialformdata[k]) {
                                if (typeof (initialformdata[k][kk]) !== "object") {
                                    if (initialformdata[k][kk] !== formdata[k][kk]) {
                                        // changes to the form has been made
                                        localStorage.setItem("save_edit", "yes");
                                        changeflag = true;
                                    }
                                } else {
                                    if (Array.isArray(initialformdata[k][kk])) {
                                        //check if content of array are same or not
                                        let t = [...initialformdata[k][kk]];
                                        let t1 = [...formdata[k][kk]];
                                        if (t.sort().toString() != t1.sort().toString()) {
                                            // Changes has been made
                                            localStorage.setItem("save_edit", "yes");
                                            changeflag = true;
                                        }
                                    }
                                }
                                if (changeflag) {
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            if (changeflag) {
                break;
            }
        }

        // if (initialselectBenchmark != selectBenchmark) {
        //     localStorage.setItem("save_edit", "yes");
        // }

    }



    let addherd_body = <React.Fragment>
        {selectBenchmark ? <React.Fragment>
            {/* Benchmark Yes Sections */}
            <ContactAndInformation formdata={formdata} setformdata={setformdata} setformerror={setformerror} formerror={formerror} disableedit={disableedit} />

            <ManagementSoftware herddata={props.herddata} formdata={formdata} setformdata={setformdata} formerror={formerror} disableedit={disableedit} type={props.type} dplanuploadstatus={props.dplanuploadstatus} />

            <DHIAEnrollment formdata={formdata} setformdata={setformdata} disableedit={disableedit} />

            <ReproductiveManagement formdata={formdata} setformdata={setformdata} setformerror={setformerror} formerror={formerror} disableedit={disableedit} />

            <MilkingSystems formdata={formdata} setformdata={setformdata} setformerror={setformerror} formerror={formerror} disableedit={disableedit} />

            <FacilitiesAndGrouping formdata={formdata} setformdata={setformdata} disableedit={disableedit} />

            <SupplementaryQuestions formdata={formdata} setformdata={setformdata} disableedit={disableedit} />
            {/* <PeerGroup formdata={formdata} setformdata={setformdata}/> */}
            </React.Fragment> : <React.Fragment>
                <ContactAndInformation formdata={formdata} setformdata={setformdata} setformerror={setformerror} formerror={formerror} disableedit={disableedit} />

                <ManagementSoftware herddata={props.herddata} formdata={formdata} setformdata={setformdata} formerror={formerror} disableedit={disableedit} type={props.type} dplanuploadstatus={props.dplanuploadstatus} />

                <DHIAEnrollment formdata={formdata} setformdata={setformdata} disableedit={disableedit} />

                <ReproductiveManagement formdata={formdata} setformdata={setformdata} setformerror={setformerror} formerror={formerror} disableedit={disableedit} />
                {/* <PeerGroup formdata={formdata}/> */}
                {admincheckresponse === 'Administrator' && (
                    <section className="form-section">
                        <header>
                            <h3>Comments</h3>
                        </header>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10} lg={8}>
                                <TextareaAutosize rowsMin={3} aria-label="empty textarea" placeholder="Comments" className="textarea"
                                    value={comments} onChange={(e) => setComments(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </section>
                )}
            </React.Fragment>
        }
    </React.Fragment>

    return (
        loaderstatus ? <Loader radius={50} type="large" /> :
            <Container maxWidth="lg" className="container-left-align">
                <section className="content-container">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <p className="form-intro">
                                Enter information as completely and accurately as possible, ideally with the input of the client - if unknown, leave blank. All entries are completely voluntary; personal and management information submitted here will only be used to return reports and characterize the herd for entry into the peer groups database. A peer group will only be assigned if the required fields are provided. Information will not be released to any other outside entities, unless requested by the owner.
                            </p>
                            {/* <p className="form-intro">
                                <strong>Fields with marked with <sup>*</sup> are required. </strong>
                            </p> */}
                        </Grid>

                        {/* <div className="page-controls form-page-controls">
                            <Grid container spacing={2} className="toggle-control-input-wrapper">
                                <Grid item xs={1} className="">
                                    <span className="addherd_container_span">
                                        <Switch onChange={((event) => {
                                            formdata["benchmark_report"] = !selectBenchmark;
                                            setselectBenchmark(!selectBenchmark);
                                        })} checked={selectBenchmark} disabled={disableedit} />
                                    </span>
                                </Grid>
                                <Grid item xs={8}>
                                    <b> Add All Fields</b>
                                    <br />
                                    When set to active, the client has agreed to provide additional herd characteristics to improve reporting.
                                </Grid>
                            </Grid>
                        </div> */}
                    </Grid>

                    <form onSubmit={handlesubmit} className="addHerd_form">
                        {addherd_body}
                        <Grid container className="button-submit-container">
                            <Grid item xs={12} className="button-submit-container-left">
                                <Button variant="contained" className="button" type="submit" disabled={!accesspermission}>
                                    {submitButtonCopy}
                                </Button>

                                {
                                    adminflag && props.type !== "add" ? <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} className="button" style={{ marginLeft: "2%" }} onClick={() => {
                                        setDialogOpen(true)
                                    }}>
                                        Delete Herd
                                </Button> : null
                                }

                            </Grid>
                        </Grid>
                    </form>
                </section>
                <Dialog
                    onClose={() => {
                        setopenmodal(false);
                    }} aria-labelledby="simple-dialog-title" open={openmodal} className="two-button-dialog">
                    <DialogContent className="dialog-content">
                        <DialogContentText>
                            <span style={{ color: "#e87722", fontWeight: "bold" }}>{submiterrorcontent}</span><br />
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="action-btns centeredaction">
                        <Button size="small" variant="contained" onClick={() => {
                            setopenmodal(false)
                        }}>OK</Button>
                    </DialogActions>
                </Dialog>

                {
                    isDialogOpen ? <TwoButtonDialog
                        content={"Are you sure you want to Delete this Herd?"}
                        open={isDialogOpen}
                        handleClose={() => {
                            setDialogOpen(false)
                        }}
                        btnText1="Yes"
                        btnText2="No"
                        handleActionBtn1={() => {
                            let requestbody = {
                                herd_id: props.herddata.herd_id

                            }
                            setDialogOpen(false);
                            setloaderstatus(true);
                            enqueueSnackbar('Deleting Herd, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
                            deleteHerd(requestbody).then(() => {
                                setloaderstatus(false);
                                enqueueSnackbar('Herd Deletion Complete.', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                                history.push('/dashboard/herds');
                            }).catch((err) => {
                                setloaderstatus(false);
                            })
                        }}
                        handleActionBtn2={() => {
                            setDialogOpen(false)
                        }}
                    /> : null
                }

                {
                    isvwawarning ? <TwoButtonDialog
                        content={<><span style={{ color: "red", textAlign: "justify", display: "inline-block" }}>Editing Voluntary Waiting Period and weaning age impacts report calculations for all test days and changes are processed in the order they are received. The processing time varies by herd size, which can be couple minutes or more.</span><span style={{ color: "black", fontWeight: "bold", display: "inline-block", marginTop: "2%" }}>Do you want to Continue?</span></>}
                        open={isvwawarning}
                        handleClose={() => {
                            setisvwawarning(false)
                        }}
                        btnText1="Yes"
                        btnText2="No"
                        handleActionBtn1={() => {
                            localStorage.setItem("save_edit", "no");
                            setisvwawarning(false)
                            setformerror({});
                            submitform();
                        }}
                        handleActionBtn2={() => {
                            setisvwawarning(false)
                        }}
                    /> : null
                }
            </Container>
    );
};

export default EnrollmentForm;
