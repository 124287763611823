import React from "react";

// material import
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

// Component import
import PageHeader from '../../../components/pageHeader/pageHeader';
import Goal from '../../../components/Goal/Goal';

import {checkadmin} from '../../../common/services/herdAccess.service';

const DefaultGoals = (props) => {
    const [goalname,setgoalname]=React.useState("Default Goals : Holstein")

    React.useEffect(()=>{
        checkadmin().then((response)=>{
            if (JSON.stringify(response)==='"User"'){
               props.history.push("/dashboard/herds")
            }else{
                setshowgoal(true)
            }
        }).catch((err)=>{
            console.log("Error Occurred "+JSON.stringify(err));
        })
    },[props])

    const [showgoal,setshowgoal]=React.useState(false)

    return (

        <article>
        {
            showgoal?<React.Fragment>
                    <PageHeader h1={goalname} />
                    {/* START: Breadcrumbs */}
                    <Container maxWidth={false} className="container-align-left breadcrumb-wrapper hide">
                        <Breadcrumbs separator="›" aria-label="breadcrumb" className="breadcrumb-container">
                            <Link color="inherit" href="#" >
                                Default Goals
                            </Link>
                        </Breadcrumbs>
                    </Container>
                    {/* END: Breadcrumbs */}
                    <Goal type="globalgoals" setgoalname={setgoalname}/>
            </React.Fragment>:null
        }
        </article>
    );
};

export default DefaultGoals;

