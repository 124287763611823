import moment from "moment-timezone";
import React from "react";
import critical_thresold_flag from "../../../assets/images/critical_thresold_flag.png";
import initial_thresold_flag from "../../../assets/images/initial_thresold_flag.jpg";
import purina_logo from "../../../assets/images/logo-addie-teal.png";

function generateReport(response, report, chartgraph, herd, subherd) {
  let todaydate = new Date();
  let today = moment(todaydate).format("l");

  if (report === "Snapshot Goal") {
    response.colDef.push({
      col_header: "",
      col_name: "dash",
      col_seq: 5,
    });
  }

  if (report === "Culling History") {
    let month = {
      "01": "JAN",
      "02": "FEB",
      "03": "MAR",
      "04": "APR",
      "05": "MAY",
      "06": "JUN",
      "07": "JUL",
      "08": "AUG",
      "09": "SEP",
      "10": "OCT",
      "11": "NOV",
      "12": "DEC",
    };
    let t = response.colDef.pop();

    response.colDef = response.colDef.map((d) => {
      return {
        ...d,
        date: response.TestDates[d.col_name],
        year: response.TestDates[d.col_name].split("-")[0],
      };
    });

    response.colDef.sort((a, b) => {
      if (a["date"] < b["date"]) {
        return -1;
      }
      if (a["date"] > b["date"]) {
        return 1;
      }
      return 0;
    });
    response.colDef = response.colDef.map((d, i) => {
      d.col_header = month[d.col_header.split("/")[0]];
      if (i === 0 || i === response.colDef.length - 1) {
        d.col_header += " - " + d.year;
      }
      return d;
    });
    response.colDef.push(t);
  }

  let reportpreparedfor = herd.dairy_name;
  let subherd_desc = "";
  if (herd.sub_herds.length > 0) {
    subherd_desc = subherd?.description ?? subherd?.subHerdSelected;
  }

  const accessToken = JSON.parse(
    localStorage.getItem("LoginSessionKey")
  ).access_token;
  const user =
    JSON.parse(atob(accessToken.split(".")[1])).fname +
    " " +
    JSON.parse(atob(accessToken.split(".")[1])).lname;

  const sourcesystem = herd.software_herd_software;

  let sections = response.Sections;
  let allcows = [],
    lactation1 = [],
    lactation2 = [],
    lactation3 = [],
    herdinventory = [],
    heiferenterprise = [],
    reproduction = [],
    culling = [],
    componentAnalysis = [],
    milkquality = [],
    calves = [],
    health = [];

  sections.forEach((section) => {
    switch (section.Section_Name) {
      case "All_Cows":
        allcows = section;
        break;
      case "Lactation_1":
        lactation1 = section;
        break;
      case "Lactation_2":
        lactation2 = section;
        break;
      case "Lactation_3":
        lactation3 = section;
        break;
      case "HerdInventory":
        herdinventory = section;
        break;
      case "HeiferEnterprise":
        heiferenterprise = section;
        break;
      case "Reproduction":
        reproduction = section;
        break;
      case "Culling":
        culling = section;
        break;
      case "ComponentAnalysis":
        componentAnalysis = section;
        break;
      case "Milk_Quality":
        milkquality = section;
        break;
      case "Calves":
        calves = section;
        break;
      case "Health":
        health = section;
        break;
      default:
        console.log("default");
    }
  });

  let settings = [];

  if (sourcesystem === "PCDART") {
    settings = [
      {
        sections: [allcows, lactation1],
      },
    ];

    // if(report==="Snapshot Goal"){
    //   settings.push({
    //     sections:[lactation2]
    //   });
    //   settings.push({
    //     sections:[lactation3]
    //   });
    // }else{
    //   settings.push({
    //     sections:[lactation2, lactation3]
    //   });
    // }
    settings.push({
      sections: [lactation2],
    });
    settings.push({
      sections:
        report === "Benchmark" ? [lactation3, componentAnalysis] : [lactation3],
    });

    let temp = [
      { sections: [componentAnalysis] },
      { sections: [reproduction] },
      { sections: [milkquality] },
      { sections: [herdinventory, calves] },
      { sections: [heiferenterprise] },
      { sections: [culling] },
    ];

    if (report === "Benchmark") {
      temp = [
        { sections: [reproduction] },
        { sections: [milkquality] },
        { sections: [herdinventory, calves] },
        { sections: [heiferenterprise] },
        { sections: [culling] },
      ];
    }

    settings = [...settings, ...temp];
  }

  if (sourcesystem === "DPLAN" || sourcesystem === "DC305") {
    settings = [
      {
        sections: [allcows, lactation1],
      },
    ];

    settings.push({
      sections: [lactation2],
    });
    settings.push({
      sections:
        report === "Benchmark" ? [lactation3, componentAnalysis] : [lactation3],
    });

    let temp = [
      { sections: [componentAnalysis] },
      { sections: [reproduction] },
      { sections: [health] },
      { sections: [milkquality] },
      { sections: [herdinventory, calves, heiferenterprise] },
      { sections: [culling] },
    ];

    if (report === "Benchmark") {
      temp = [
        { sections: [reproduction] },
        { sections: [health] },
        { sections: [milkquality] },
        { sections: [herdinventory, calves] },
        { sections: [heiferenterprise] },
        { sections: [culling] },
      ];
    }

    settings = [...settings, ...temp];
  }

  settings = settings.filter((d) => {
    d.sections = d.sections.filter((sec) => {
      if (sec.length === 0) {
        return false;
      } else {
        return true;
      }
    });

    if (d.sections.length === 0) {
      return false;
    }
    return true;
  });

  // remove reproduction section if Last Pregnancy Date is null or empty
  let removeflag = false;
  // Didn't remove for backup purpose
  // if (!(response?.Additional_days?.Last_Pregnancy_Exam_Date)) {
  //   removeflag = true;
  // } else {
  //   if (response.Additional_days.Last_Pregnancy_Exam_Date === null || response.Additional_days.Last_Pregnancy_Exam_Date === "" || response.Additional_days.Last_Pregnancy_Exam_Date === "1000-1-1") {
  //     removeflag = true;
  //   }
  // }

  if (["Snapshot", "Benchmark", "Snapshot Goal", "SnapSix"].includes(report)) {
    if (typeof response?.Report_Visibility?.Health !== "undefined") {
      if (response?.Report_Visibility?.Health === false) {
        settings = settings.filter(function (e) {
          if (e["sections"].length === 1) {
            return e["sections"][0]["Section_Name"] !== "Health";
          }
          e["sections"] = e["sections"].filter(function (el) {
            return el["Section_Name"] !== "Health";
          });
          return e;
        });
      }
    }

    if (typeof response?.Report_Visibility?.Milk_Quality !== "undefined") {
      if (response?.Report_Visibility?.Milk_Quality === false) {
        settings = settings.filter(function (e) {
          if (e["sections"].length === 1) {
            return e["sections"][0]["Section_Name"] !== "Milk_Quality";
          }
          e["sections"] = e["sections"].filter(function (el) {
            return el["Section_Name"] !== "Milk_Quality";
          });
          return e;
        });
      }
    }

    if (
      typeof response?.Report_Visibility?.Component_Analysis !== "undefined"
    ) {
      if (response?.Report_Visibility?.Component_Analysis === false) {
        settings = settings.filter(function (e) {
          if (e["sections"].length === 1) {
            return e["sections"][0]["Section_Name"] !== "ComponentAnalysis";
          }
          e["sections"] = e["sections"].filter(function (el) {
            return el["Section_Name"] !== "ComponentAnalysis";
          });
          return e;
        });
      }
    }
  }

  if (report !== "Snapshot Goal") {
    if (removeflag) {
      settings = settings.filter(function (e) {
        return e["sections"][0]["Section_Name"] !== "Reproduction";
      });
    }
  }

  settings = settings.map((d, i) => {
    return {
      page: i + 1,
      ...d,
    };
  });

  let heifer_2nd_settings = [];
  if (report === "Young Animal" && sourcesystem === "DC305") {
    let month = {
      "01": "JAN",
      "02": "FEB",
      "03": "MAR",
      "04": "APR",
      "05": "MAY",
      "06": "JUN",
      "07": "JUL",
      "08": "AUG",
      "09": "SEP",
      "10": "OCT",
      "11": "NOV",
      "12": "DEC",
    };
    let new_header = response.Health_Culling_History.colDef.pop();

    response.Health_Culling_History.colDef =
      response.Health_Culling_History.colDef.map((d) => {
        return {
          ...d,
          date: response.Health_Culling_History.TestDates[d.col_name],
          year: response.Health_Culling_History.TestDates[d.col_name].split(
            "-"
          )[0],
        };
      });

    response.Health_Culling_History.colDef.sort((a, b) => {
      if (a["date"] < b["date"]) {
        return -1;
      }
      if (a["date"] > b["date"]) {
        return 1;
      }
      return 0;
    });

    response.Health_Culling_History.colDef =
      response.Health_Culling_History.colDef.map((d, i) => {
        d.col_header = month[d.col_header.split("/")[0]];

        if (
          i === 0 ||
          i === response.Health_Culling_History.colDef.length - 1
        ) {
          d.col_header += " - " + d.year;
        }
        return d;
      });
    response.Health_Culling_History.colDef.push(new_header);

    if (response.Health_Culling_History) {
      let second_sections = response.Health_Culling_History.Sections;
      let heifer_lactation1 = [],
        heifer_lactation2 = [],
        heifer_lactation3 = [],
        heifer_herdinventory = [],
        heifer_heiferenterprise = [],
        heifer_reproduction = [],
        heifer_culling = [],
        heifer_componentAnalysis = [],
        heifer_milkquality = [],
        heifer_calves = [],
        heifer_health = [];

      second_sections.forEach((section) => {
        switch (section.Section_Name) {
          case "Lactation_1":
            heifer_lactation1 = section;
            break;
          case "Lactation_2":
            heifer_lactation2 = section;
            break;
          case "Lactation_3":
            heifer_lactation3 = section;
            break;
          case "HerdInventory":
            heifer_herdinventory = section;
            break;
          case "HeiferEnterprise":
            heifer_heiferenterprise = section;
            break;
          case "Reproduction":
            heifer_reproduction = section;
            break;
          case "Culling":
            heifer_culling = section;
            break;
          case "ComponentAnalysis":
            heifer_componentAnalysis = section;
            break;
          case "Milk_Quality":
            heifer_milkquality = section;
            break;
          case "Calves":
            heifer_calves = section;
            break;
          case "Health":
            heifer_health = section;
            break;
          default:
            console.log("default");
        }
      });

      let heifer_2nd_temp = [
        { sections: [heifer_health] },
        { sections: [heifer_culling] },
      ];

      heifer_2nd_settings = [...heifer_2nd_settings, ...heifer_2nd_temp];

      heifer_2nd_settings = heifer_2nd_settings.map((d, i) => {
        return {
          page: i + 1,
          ...d,
        };
      });
    }
  }

  let pageheader = {
    reportpreparedfor: reportpreparedfor,
    report: report,
    subherd_desc: subherd_desc,
    user: user,
    today: today,
  };

  let pagefooterdefinition = null;

  if (report === "Snapshot") {
    pagefooterdefinition = (
      <div className="footer_details" style={{ fontSize: "7pt" }}>
        <p>Attention Flag</p>
        <img
          src={initial_thresold_flag}
          className="pagerfooterflag"
          alt="initial_threshold"
        />
        indicates a value needing attention
        <br />
        <img
          src={critical_thresold_flag}
          className="pagerfootercriticalflag"
          alt="critical_thresold"
        />
        indicates a value needing critical attention
        <br />
        Based on herd goals and flag thresholds configured within Addie.
        <br />
        <br />
      </div>
    );
  } else if (report === "Benchmark") {
    let d = new Date();
    d += "";
    d = d.substring(4, 15);

    let from = d + " 08:00:00"; // 8am utc
    let to = d + " 20:00:00"; // 8pm utc

    from = moment.utc(from).tz(moment.tz.guess()).format("hh:mm:ss A");
    to = moment.utc(to).tz(moment.tz.guess()).format("hh:mm:ss A");

    let timezone = moment.tz(moment.tz.guess()).format("zz");
    let peerfooterbody = null;
    let benchmarkpeermessage = (
      <>
        <ul>
          <li>
            The Purina Herdview Benchmark report summarizes recent dairy herd
            performance. Peer data are aggregated to compare herd performance to
            recent data for other herds with the same breed and geography. Peer
            data are not specific to herd management styles and should be
            interpreted with caution when targeting areas that need further
            attention. Recently uploaded data will reflect peer data from the
            previous report immediately after upload. Peer data are aggregated
            every 12 h ({from} and {to} {timezone}) using the most recent test
            date in the last 90 days for all herds. When peer group criteria are
            unmet, the report will dynamically offer national average and goals
            for selected breeds. Reports should be saved to view changes in peer
            performance over time.
          </li>
        </ul>
      </>
    );

    if (response.Peer_Footer_Definition.Peer_Average_Availability) {
      // if peer average is available then Peer_Average_Availability will not be there in the Peer_Footer_Definition object
      peerfooterbody = (
        <>
          <li>Peer average is not available.</li>
        </>
      );
    } else {
      peerfooterbody = (
        <>
          <li>{response.Peer_Footer_Definition.Peer_Restrictions}</li>
          <ul>
            {response.Peer_Footer_Definition.Peer_Average_Availability ? (
              <li>
                {response.Peer_Footer_Definition.Peer_Average_Availability}
              </li>
            ) : null}
            <li>{response.Peer_Footer_Definition.Herd_Count}</li>
            <li>{response.Peer_Footer_Definition.Herd_Size_Rage}</li>
            <li>{response.Peer_Footer_Definition.Milk_Prod_Range}</li>
          </ul>
        </>
      );
    }

    pagefooterdefinition = (
      <div className="footer_details">
        <ul>
          <li>
            Last Year Quarter Average (LY Qtr Average) uses data from the same
            time period in the prior year.
          </li>
        </ul>
        <ul>{peerfooterbody}</ul>

        <ul>
          <li>
            {response.National_Average_Footer_Definition.National_Restrictions}
          </li>

          <ul>
            <li>{response.National_Average_Footer_Definition.Herd_Count}</li>

            <li>
              {response.National_Average_Footer_Definition.Herd_Size_Rage}
            </li>

            <li>
              {response.National_Average_Footer_Definition.Milk_Prod_Range}
            </li>
          </ul>
        </ul>
        {benchmarkpeermessage}
        <br />
        <br />
      </div>
    );
  } else {
    pagefooterdefinition = null;
  }

  let footerperpage = (
    <div className="pager_footer">
      <div className="reportFooterWrapper">
        <p style={{ fontFamily: "Helvetica", fontSize: "6pt" }}>
          Disclaimer; Limited Rights: This report includes assumptions and expectations in light of currently available information. Actual analytics regarding dairy cow herd inventories and performance metrics may differ. THIS REPORT IS PROVIDED AS-IS, WHERE-IS, WITH ALL FAULTS. ALL WARRANTIES ARE DISCLAIMED, INCLUDING WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE AND FITNESS FOR A PARTICULAR PURPOSE. This report and the Addie™ Insights are confidential information and subject to limited license rights limited to the specified dairy farming operations only. This notice must be recreated on all copies and excerpts of this report.
        </p>
      </div>
    </div>
  );

  let lastpagefooter = (
    <div className="pager_footer">
      <div className="reportFooterWrapper">
        <img src={purina_logo} className="purina_image" alt="purina logo" />
        <p style={{ fontFamily: "Helvetica", fontSize: "6pt" }}>
          Disclaimer; Limited Rights: This report includes assumptions and expectations in light of currently available information. Actual analytics regarding dairy cow herd inventories and performance metrics may differ. THIS REPORT IS PROVIDED AS-IS, WHERE-IS, WITH ALL FAULTS. ALL WARRANTIES ARE DISCLAIMED, INCLUDING WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE AND FITNESS FOR A PARTICULAR PURPOSE. This report and the Addie™ Insights are confidential information and subject to limited license rights limited to the specified dairy farming operations only. This notice must be recreated on all copies and excerpts of this report.
        </p>
      </div>
    </div>
  );

  let chartpagesetting = [];
  if (chartgraph.length > 0) {
    chartgraph.map((chart) => {
      response.graphselected.forEach((d) => {
        if (chart.id === d.id) {
          chart.width = d.width;
        }
      });
      switch (chart.width) {
        case "half_page":
          chart.className = "reporthalfpage";
          break;
        case "one_third":
          chart.className = "reportonethirdpage";
          break;
        case "full_page":
        default:
          chart.className = "reportfullpage";
      }
      return chart;
    });

    for (let i = 0; i < chartgraph.length; ) {
      chartpagesetting.push({
        chart: [chartgraph[i]],
      });
      i += 1;
    }
  }

  return {
    settings,
    heifer_2nd_settings,
    chartpagesetting,
    pageheader,
    pagefooterdefinition,
    footerperpage,
    lastpagefooter,
  };
}

export default generateReport;

export function generateSnapshotReport(response, chartgraph, herd, subherd) {
  return generateReport(response, "Snapshot", chartgraph, herd, subherd);
}

export function generateBenchmarkReport(response, chartgraph, herd, subherd) {
  return generateReport(response, "Benchmark", chartgraph, herd, subherd);
}

export function generateSnapsixReport(response, chartgraph, herd, subherd) {
  return generateReport(response, "SnapSix", chartgraph, herd, subherd);
}

export function generatePeriodComparisonReport(
  response,
  chartgraph,
  herd,
  subherd
) {
  return generateReport(
    response,
    "Period Comparison",
    chartgraph,
    herd,
    subherd
  );
}

export function generateCullingHistoryReport(
  response,
  chartgraph,
  herd,
  subherd
) {
  return generateReport(response, "Culling History", chartgraph, herd, subherd);
}

export function generateSnapshotGoalReport(
  response,
  chartgraph,
  herd,
  subherd
) {
  return generateReport(response, "Snapshot Goal", chartgraph, herd, subherd);
}

export function generateSubherdComparisonReport(
  response,
  chartgraph,
  herd,
  subherd
) {
  return generateReport(
    response,
    "Sub-herd Comparison",
    chartgraph,
    herd,
    subherd
  );
}

export function generateHeiferReport(response, chartgraph, herd, subherd) {
  return generateReport(response, "Young Animal", chartgraph, herd, subherd);
}
