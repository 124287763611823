import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
// material import
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    TextField
}from '@material-ui/core';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Textbox from "../../ui_utilities/Textbox";
import MultiSelectDropdown from '../../ui_utilities/MultiSelectDropdown';
import RadioButton from '../../ui_utilities/RadioButton';
// import HealthDataMapping from './HealthDataMapping'
import AddSubherdReport from './AddSubherdReport';
import { getEnvironment } from "../../../common/utils/utility";

// import CullCodeMapping from './CullCodeMapping/CullCodeMapping'
// import BreedingCodeMapping from './BreedingCodeMapping';

const Management_Software = (props) => {
    const ENV = getEnvironment();
    
    const [software_herd_software, setsoftware_herd_software] = useState(props.formdata["software_herd_software"].toLowerCase())

    let buttonClass = "psudeo-input-item";
    let activebuttonClass = "psudeo-input-item active";
    let drmsButtonClass = "";
    const [showaddsubherdreport, setshowaddsubherdreport] = useState(false);
    const [vieweditsubherd, setvieweditsubherd] = useState(false);
    const [showdefaultview, setshowdefaultview] = useState(false);
    const [subherddata, setsubherddata] = useState("");
    const [editindex, seteditindex] = useState(-1);
    const [changebreederror, setchangebreederror] = useState(false);
    const [forDRMS, setForDRMS] = useState(false);

    const admincheckresponse = useSelector(
        (state) => state.admin.usertype
    );

    // disable the DRMS button or related field if user is not an Admin and ENV not equal to LOCAL or DEV
    let disableDRMS = admincheckresponse !== "Administrator" || !["LOCAL", "DEV"].includes(ENV);
    if (disableDRMS) {
        drmsButtonClass = "disabled_button";
    }
    
    useEffect(() => {
        let temp = { ...props.formdata };
        const newSoftwareHerdSoftware = software_herd_software.toUpperCase();
    
        if (newSoftwareHerdSoftware !== temp["software_herd_software"]) {
            temp["software_herd_software"] = newSoftwareHerdSoftware;
    
            if (newSoftwareHerdSoftware === "DRMS") {
                temp["software_breeds"] = [];
            } else {
                temp["software_remote_access_code"] = "";
            }
    
            props.setformdata(temp);
        }
    }, [software_herd_software, props.formdata, props.setformdata]);

    useEffect(() => {
        setForDRMS(disableDRMS);
    });

    useEffect(() => {
        if (props.formdata.sub_herds.length === 0) {
            let temp = { ...props.formdata };
            temp["sub_herds"][0] = {
                default: true,
                active: true,
                breeds: props.formdata.software_breeds,
                description: "All Cows"
            }
            props.setformdata(temp);
        }
    })

    const splitreporthandler = (event) => {
        if (props.formdata.sub_herds.length === 1) {
            let temp = { ...props.formdata };
            temp["software_split_report"] = event.target.value;
            props.setformdata(temp);
        }
    }

    const herbreedhandler = (herdbreed, temp) => {
        let changebreedflagerror = false;
        if (props.formdata.sub_herds.length > 1) {
            let breeds = [];
            props.formdata.sub_herds.forEach((ele) => {
                if (!ele.default) {
                    breeds.push(...ele.breeds)
                }
            })
            breeds = breeds.map((ele) => {
                if (ele === "Other") {
                    return "Other Purebred"
                } else {
                    return ele;
                }
            })
            //Check if all breeds item are in herdbreed
            breeds = breeds.filter((ele) => {
                if (herdbreed.includes(ele))
                    return false;
                return true;
            })
            if (breeds.length > 0)
                changebreedflagerror = true;
        } else {
            changebreedflagerror = false;
        }

        if (changebreedflagerror) {
            setchangebreederror(true)
        } else {
            let defaultsubherd_index = 0;
            for (let i = 0; i < props.formdata.sub_herds.length; i++) {
                if (props.formdata.sub_herds[i].default) {
                    defaultsubherd_index = i;
                }
            }
            temp["sub_herds"][defaultsubherd_index] = {
                ...temp["sub_herds"][defaultsubherd_index],
                default: true,
                active: true,
                breeds: herdbreed,
                description: "All Cows"
            }
            props.setformdata(temp);
        }
    }

    const softwarebreedsoption = [{
        name: "Holstein",
        value: "holstein"
    }, {
        name: "Jersey",
        value: "jersey"
    }, {
        name: "Crossbred",
        value: "crossbred"
    }, {
        name: "Other Purebred",
        value: "other"
    }]


    const rendersubherdlist = () => {
        return <React.Fragment>
            {
                props.formdata.sub_herds.map((data, index) => {
                    return <Grid key={index} container spacing={2} className="enrollment-form-manage-sub-herd-list-row">
                        <Grid item xs={8} sm={8} className="sub-herd-list-name">
                            <span>
                                {data.description}
                            </span>
                        </Grid>

                        <Grid item xs={4} sm={4} className="sub-herd-list-action">
                            {data.default ?
                                <Button
                                    variant="outlined"
                                    className="button size-mini variant-"
                                    type="button"
                                    onClick={() => {
                                        setshowdefaultview(true);
                                        setsubherddata(data);
                                    }}>
                                    View
                        </Button> :
                                <Button
                                    variant="outlined"
                                    className="button size-mini variant-"
                                    type="button"
                                    onClick={() => {
                                        setsubherddata(data);
                                        seteditindex(index);
                                        setvieweditsubherd(true);
                                    }}>
                                    View/ Edit
                        </Button>
                            }
                        </Grid>
                    </Grid>
                })
            }
        </React.Fragment>
    }

    const parlordailymilkchange = (temp, choice) => {
        temp.sub_herds = temp.sub_herds.map((d) => {
            return {
                ...d,
                use_avg_daily_milk: false
            }
        })
        props.setformdata(temp);
    }

    const subherddescription = <React.Fragment>
        Reports will include the Name of Dairy plus the unique sub-herd name (e.g. if the Name of Dairy is “Milkyway Farms” and the sub-herd is named “Holsteins” the reports headers will read “Milkyway Farms Holsteins”). If the sum of the cows in the sub-herds created do not represent all cows without missing or duplication of animals, then a separate sub-herd should be created to load raw data for all cows without missing or duplicated records.
    <br /><br /></React.Fragment>

    const addsubherdreportcontainer = <React.Fragment>
        <Grid item xs={12}>{
            props.formdata.sub_herds.length > 1 ? <p className="form-intro">
                {subherddescription}
                                                            Current sub-herd(s) associated with this herd:
                                                    </p> : <React.Fragment>
                    <p className="form-intro">
                        There are currently no split reports for this herd.<br /> A default sub-herd called 'All Cows' will be used for reporting the entire herd.
                                                                <br /><br />
                        {subherddescription}
                    </p>
                </React.Fragment>
        }
        </Grid>
        {rendersubherdlist()}
        <Grid item xs={12}>
            <Button
                disabled={props.formdata.software_herd_software === "DRMS" || props.disableedit}
                variant="contained"
                className="button add-edit-subherd-button"
                type="button"
                startIcon={<AddOutlinedIcon />}
                onClick={() => {
                    setshowaddsubherdreport(true);
                }}
            >
                Add a Sub-Herd
            </Button>
        </Grid>
    </React.Fragment>

    if (props.type === "edit") {
        buttonClass += " disabled_button";
    }

    return (
        <React.Fragment>
            <section className="form-section">
                <header>
                    <h3>Herd Management</h3>
                </header>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            disabled={props.disableedit}
                            className="text-field-root required-style"
                            name="software_herd_code"
                            id="software_herd_code"
                            type="number"
                            label="Herdcode *"
                            variant="outlined"
                            onChange={(event) => {
                                if (event.target.value.toString().length <= 8) {
                                    let temp = { ...props.formdata };
                                    temp["software_herd_code"] = event.target.value;
                                    props.setformdata(temp);
                                }
                            }}
                            value={props.formdata["software_herd_code"]}
                        />
                        {
                            props.formerror.software_herd_code ? (
                                <div className="required-style">{props.formerror.software_herd_code}</div>
                            ) : null
                        }
                    </Grid>
                    {   // only shows if user selected DRMS
                        props.formdata["software_herd_software"] === "DRMS" && (
                            <Grid item xs={12} sm={6} md={3}>
                                <Textbox 
                                    disableedit={props.disableedit}
                                    data={{
                                        formdata:props.formdata,
                                        setformdata:props.setformdata,
                                        className:props.formdata["software_herd_software"] === "DRMS" ? "text-field-root required-style" : "text-field-root",
                                        // className:"text-field-root required-style",
                                        name:"software_remote_access_code",
                                        id:"software_remote_access_code",
                                        type:"password",
                                        label:"RAC *",
                                        helpText:"Remote Access Code"
                                    }
                                }/>
                                {
                                    props.formerror.software_remote_access_code ? (
                                        <div className="required-style">{props.formerror.software_remote_access_code}</div>
                                    ) : null
                                }
                            </Grid>
                        )
                    }
                    <Grid item xs={12} md={6} ></Grid>
                    <Grid item xs={12}>
                        <label className="form-label">Current Herd Software *</label>
                        <p className="form-label-description">Which software does the dairy currently use to manage this herd? This cannot be changed once a herd is enrolled. If a herd has more than one software on farm or has recently changed software, please complete a separate herd enrollment for each software you will use to generate reports.</p>
                        <div className="psuedo-button-radio-group">
                            <span className={software_herd_software === "pcdart" ? activebuttonClass : buttonClass} onClick={() => {
                                if (!props.disableedit && props.type !== "edit") {
                                    props.formdata["software_herd_software"] = "PCDART";
                                    setsoftware_herd_software("pcdart");
                                }
                            }}>
                                PCDART
                            </span>
                            <span className={software_herd_software === "dc305" ? activebuttonClass : buttonClass} onClick={() => {
                                if (!props.disableedit && props.type !== "edit") {
                                    props.formdata["software_herd_software"] = "DC305";
                                    setsoftware_herd_software("dc305");
                                }
                            }}>
                                DAIRY COMP 305
                            </span>
                            <span className={software_herd_software === "dplan" ? activebuttonClass : buttonClass} onClick={() => {
                                if (!props.disableedit && props.type !== "edit") {
                                    props.formdata["software_herd_software"] = "DPLAN";
                                    setsoftware_herd_software("dplan");
                                }
                            }}>
                                DAIRY PLAN
                            </span>
                            {/* disable the DRMS button or related field if user is not an Admin and ENV not equal to LOCAL or DEV */}
                            <span className={`${software_herd_software === "drms" ? activebuttonClass : buttonClass} ${drmsButtonClass}`} 
                                onClick={() => {
                                    if (!props.disableedit && props.type !== "edit" && !disableDRMS) {
                                        props.formdata["software_herd_software"] = "DRMS";
                                        setsoftware_herd_software("drms");
                                        setForDRMS(true);
                                    }
                                }}
                            >
                                DRMS DHIA
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12}></Grid>


                    <Grid container spacing={2} style={{ marginLeft: '0', marginBottom: '1rem' }}>
                        {/* {software_herd_software==="dc305"||software_herd_software==="dplan"?<HealthDataMapping formik={props.formik} software_herd_software={software_herd_software} formdata={props.formdata} setformdata={props.setformdata} disableedit={props.disableedit}/>:null}    */}

                        {/*software_herd_software==="dplan"?<BreedingCodeMapping formik={props.formik} formdata={props.formdata} setformdata={props.setformdata}/>:null*/}
                        {/* {software_herd_software==="dc305"||software_herd_software==="dplan"?<CullCodeMapping formik={props.formik}/>:null}   */}
                    </Grid>

                    <Grid item xs={12} id="software_breeds">
                        <label className="form-label">Herd Breed(s) *</label>
                        <p className="form-label-description">Select all that apply</p>
                        <p>
                            Breed selection will determine both default sub-herd goals and peer group filters. You will be able to customize goals by breed or for each sub-herd. Herds with any combination of breeds including Holstein or Crossbred will be assigned to Holstein-based peer groups. Herds with only Jersey and/or Other Purebred will be assigned to Jersey-based peer groups.
                    </p>
                    </Grid>
                    <Grid item xs={12} md={6} className="drms_field">
                        <MultiSelectDropdown
                            disableedit={props.disableedit}
                            data={
                                {
                                    formdata: props.formdata,
                                    handler: herbreedhandler,
                                    setformdata: props.setformdata,
                                    labelid: "software_breeds_label",
                                    id: "software_breeds",
                                    inputid: "software_breeds_inputid",
                                    options: softwarebreedsoption,

                                }
                            } />
                        {
                            props.formerror.software_breeds ? (
                                <div className="required-style">{props.formerror.software_breeds}</div>
                            ) : null
                        }
                    </Grid>


                </Grid>
            </section>
            <section className="form-section">

                <header>
                    {/* <h3>DHIA Enrollment</h3> */}
                    <span>
                        For DC305 and DPLAN herds, test day or average weekly milk data may be available in the raw data upload from on farm backups. Please use this switch to determine which values to use to calculate production parameters on reports. If these meters are not calibrated or cows are not properly identified, test day milk should be used. For herds with multiple milking facilities, we recommend splitting the export from DC305 or DPLAN and using the sub-herd feature to properly calculate production data.
                </span>
                </header>
                <Grid item xs={12} className="radio-group">
                    <RadioButton
                        disableedit={props.dplanuploadstatus ? props.dplanuploadstatus : props.disableedit}
                        type={"vertical"}
                        data={{
                            name: "parlor_daily_milk_weights",
                            heading: "Does this herd have parlor meters to record daily milk weights?",
                            no: "Not in any milking facility in this herd.",
                            yes: "Yes, in at least one milking facility for this herd.",
                            handler: (temp, choice) => {
                                parlordailymilkchange(temp, choice)
                            },
                            formdata: props.formdata,
                            setformdata: props.setformdata
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <label className="form-label top-border">SUB-HERD(S)</label>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xs={12} className="radio-group">
                        <RadioButton
                            disableedit={props.disableedit}
                            data={{
                                handler: splitreporthandler,
                                formdata: props.formdata,
                                name: "software_split_report",
                                heading: "Will this herd require unique reports by breed, pen, or another variable?",
                                yes: "Yes",
                                no: "No"
                            }} />
                    </Grid>
                    {/* Sub Herd Details to be collected here */}
                    {
                        props.formdata["software_split_report"] === "Yes" ? <React.Fragment>
                            {addsubherdreportcontainer}
                        </React.Fragment> : <Grid container spacing={2} style={{ marginLeft: "0" }}>
                                <Grid item xs={12}>
                                    <p className="form-intro">
                                        For reporting purposes, this herd will have a default sub-herd called 'All Cows'
                                <br /><br />
                                        {subherddescription}
                                    </p>
                                </Grid>
                                {rendersubherdlist()}
                            </Grid>
                    }
                </Grid>
            </section>
            {
                vieweditsubherd ? <AddSubherdReport
                    herddata={props.herddata}
                    disableedit={props.disableedit}
                    type="viewedit"
                    setstate={setvieweditsubherd}
                    editindex={editindex}
                    state={vieweditsubherd}
                    subherddata={subherddata}
                    formdata={props.formdata}
                    setformdata={props.setformdata}
                /> : null
            }
            {
                showaddsubherdreport ? <AddSubherdReport
                    herddata={props.herddata}
                    disableedit={props.disableedit}
                    type="add"
                    setstate={setshowaddsubherdreport}
                    state={showaddsubherdreport}
                    formdata={props.formdata}
                    setformdata={props.setformdata}
                /> : null
            }
            {
                showdefaultview ? <AddSubherdReport
                    herddata={props.herddata}
                    disableedit={props.disableedit}
                    type="default"
                    setstate={setshowdefaultview}
                    state={showdefaultview}
                    subherddata={subherddata}
                    formdata={props.formdata}
                    setformdata={props.setformdata}
                /> : null
            }
            <Dialog
                onClose={setchangebreederror} aria-labelledby="simple-dialog-title" open={changebreederror} className="two-button-dialog">
                <DialogContent className="dialog-content">
                    <DialogContentText>
                        <span style={{ color: "#e87722", fontWeight: "bold" }}>
                            There is a sub-herd with this breed, therefore, this breed cannot be removed. Please edit the sub-herd breed or remove the sub-herd before editing herd breeds.
                            *Removing a sub-herd will remove all associated data uploads.
                        </span><br />
                    </DialogContentText>
                </DialogContent>

                <DialogActions className="action-btns centeredaction">
                    <Button size="small" variant="contained" onClick={() => {
                        setchangebreederror(false)
                    }}>OK</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Management_Software;
